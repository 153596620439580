import styled from "styled-components";
import { ModalContent } from "../../../components/BaseModal/style";
import { LAYER_COLORS } from "../../../constants/design/colors";
import { COMPONENTS } from "../../../constants/design/spacing";

export const TradeSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${COMPONENTS.marketsSectionWidth}px;
  min-height: fit-content;
`;

export const BaseModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  height: 100%;
  background-color: ${LAYER_COLORS.one};
  position: relative;
  border-radius: 8px;
`;

export const StyledModalContent = styled(ModalContent)`
  border: none;
`;
