import { ethers } from "ethers";
import { useContext } from "react";
import { COLORS } from "../../../constants/design/colors";
import { TradeInstrumentContext } from "../../../contexts/TradeInstrumentContext";
import { FormValidatorKeysEnum } from "../../../enums/form";
import useAssetPriceData from "../../../hooks/api/prices/useAssetPriceData";
import useUSDC from "../../../hooks/contracts/usdc/useUSDC";
import { formatStringDecimals } from "../../../utils/strings";
import { Input } from "../../shared/Input";
import { IInputProps } from "../form";
import {
  InputWrapper,
  LabelContainer,
  Label,
  WarningLabel,
  LabelValue,
} from "../style";

export function ContractInput({ register, errors }: IInputProps) {
  const usdc = useUSDC();

  const usdcBalance = usdc.userBalance
    ? formatStringDecimals(
      ethers.utils.formatUnits(usdc.userBalance, usdc.decimals),
      2
    )
    : "-";

  const hasError = Boolean(errors?.contracts?.type);
  return (
    <InputWrapper>
      <LabelContainer>
        <Label>Contracts</Label>
        <Label>
          Wallet Balance:{" "}
          <LabelValue
            color={
              errors.contracts?.type === FormValidatorKeysEnum.notEnoughBalance
                ? COLORS.negative.one
                : COLORS.white.one
            }
          >
            {usdcBalance}
          </LabelValue>
        </Label>
      </LabelContainer>
      <Input error={hasError} placeholder="0" type="number" {...register} />
      {errors?.contracts?.type === FormValidatorKeysEnum.required && (
        <WarningLabel>Contracts required</WarningLabel>
      )}
      {errors?.contracts?.type === FormValidatorKeysEnum.decimalsTooLarge && (
        <WarningLabel>Maximum of 1 d.p allowed</WarningLabel>
      )}
      {errors?.contracts?.type
        === FormValidatorKeysEnum.notionalOutOfBounds && (
        <WarningLabel>Notional out of bounds</WarningLabel>
      )}
      {errors.contracts?.type === FormValidatorKeysEnum.notEnoughBalance && (
        <WarningLabel>Insufficient USDC balance</WarningLabel>
      )}
      {errors.contracts?.type === FormValidatorKeysEnum.totalPremiumTooLow && (
        <WarningLabel>Total Premium too low</WarningLabel>
      )}
    </InputWrapper>
  );
}

export function ExpectedExpiryInput({ register }: IInputProps) {
  const { selectedValues } = useContext(TradeInstrumentContext);
  const { useAssetPrice } = useAssetPriceData();
  const { loading, price } = useAssetPrice({ asset: selectedValues.asset });
  return (
    <InputWrapper>
      <LabelContainer>
        <Label>Expected Price at Expiry</Label>
      </LabelContainer>
      <Input
        error={false}
        placeholder={loading ? "0" : price.toFixed(2)}
        type="number"
        {...register}
      />
    </InputWrapper>
  );
}

export function Amount({ register }: IInputProps) {
  const { selectedValues } = useContext(TradeInstrumentContext);
  const { useAssetPrice } = useAssetPriceData();
  const { loading, price } = useAssetPrice({ asset: selectedValues.asset });
  return (
    <InputWrapper>
      <LabelContainer>
        <Label>Expected Price at Expiry</Label>
      </LabelContainer>
      <Input
        error={false}
        placeholder={loading ? "0" : price.toFixed(2)}
        type="number"
        {...register}
      />
    </InputWrapper>
  );
}

export function CustomPriceInput({ register, errors }: IInputProps) {
  const hasError = Boolean(errors?.price?.type);
  return (
    <InputWrapper>
      <LabelContainer>
        <Label>Price</Label>
      </LabelContainer>
      <Input error={hasError} placeholder="0" type="number" {...register} />
    </InputWrapper>
  );
}
