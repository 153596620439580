import useSWR from "swr";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Assets } from "../../../../utils/assets";
import { IOrder, OrdersResponse, Reason } from "../../../../interfaces/Orders";
import { OptionTypeResponse } from "../../../../interfaces/PreloadedStrikes";
import { getInstrumentName } from "../../../../utils/instruments";
import { defaultOrdersResponse } from "../../../../contexts/OptionsDataContext";
import useWallet from "../../../wallet/useWallet";
import { GET_USER_TRADE_HISTORY_URL } from "../../../../constants/endpoints/endpoints";

export interface IUserTradeHistory {
  order_id: number;
  underlyer: Assets;
  type: OptionTypeResponse;
  expiry: string;
  strike: string;
  size: string;
  entry_price?: string;
  underlyer_price_at_expiry?: string;
  performance?: string;
  roi?: string;
  reason?: Reason;
  is_unwind_order?: "true" | "false";
}

// Get the current account
const useFetchUserTradeHistory = () => {
  const { account } = useWallet();
  const [userTradeHistoryData, setUserTradeHistoryData]
    = useState<OrdersResponse>(defaultOrdersResponse);

  const userTradeHistoryApiURL = GET_USER_TRADE_HISTORY_URL;

  const fetcher = (params: any) => (url: string) => {
    if (params.address !== undefined) {
      return axios.get(url, { params }).then((res) => res.data);
    }
    return Promise.resolve(null); // Return a resolved Promise with a null value if address is undefined
  };

  const userTradeHistorySwr = useSWR<IUserTradeHistory[]>(
    userTradeHistoryApiURL,
    fetcher({
      address: account,
    }),
    {
      refreshInterval: 5000,
    }
  );

  const { data: userTradeHistoryDataSwr, error: userTradeHistorySwrError }
    = userTradeHistorySwr;

  const fetchUserTradeHistory = useCallback(async () => {
    if (!userTradeHistoryDataSwr || userTradeHistorySwrError || !account) {
      // API call hasn't completed yet or error, return early
      return;
    }

    const userTradeHistory = userTradeHistoryDataSwr.map(
      (tradeHistoryData) => ({
        instrumentName: getInstrumentName(
          tradeHistoryData.underlyer,
          tradeHistoryData.expiry,
          tradeHistoryData.strike,
          tradeHistoryData.type
        ),
        orderId: tradeHistoryData.order_id,
        optionType: tradeHistoryData.type,
        asset: tradeHistoryData.underlyer,
        expiry: tradeHistoryData.expiry,
        strike: tradeHistoryData.strike,
        contracts: tradeHistoryData.size,
        totalPremiumPaid: tradeHistoryData.entry_price
          ? String(
            parseFloat(tradeHistoryData.size)
                * parseFloat(tradeHistoryData.entry_price)
          )
          : "0",
        entryPrice: tradeHistoryData.entry_price,
        performance: tradeHistoryData.performance,
        roi: tradeHistoryData.roi,
        priceAtExpiry: tradeHistoryData.underlyer_price_at_expiry,
        reason: tradeHistoryData.reason,
        isUnwindOrder: tradeHistoryData.is_unwind_order === "true",
      })
    ) as IOrder[];
    setUserTradeHistoryData({
      loading: false,
      orders: userTradeHistory,
    } as OrdersResponse);
  }, [userTradeHistoryDataSwr, userTradeHistorySwrError, account]);

  useEffect(() => {
    fetchUserTradeHistory();
  }, [fetchUserTradeHistory]);

  return {
    userTradeHistoryData,
  };
};

export default useFetchUserTradeHistory;
