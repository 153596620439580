/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { useCallback, useEffect, useState } from "react";
import { IERC20, IERC20__factory } from "../../../codegen";
import useWallet from "../../wallet/useWallet";
import { useWeb3Context } from "../../../contexts/Web3Context";
import useOtcWrapper from "../otcWrapper/useOtcWrapper";
import addresses from "../../../constants/addresses/addresses.json";

export const getOToken = (oTokenAddress: string, provider: any, useSigner = true): IERC20 | undefined => {
  const signerOrProvider = useSigner ? provider?.getSigner() : provider;
  if (signerOrProvider && oTokenAddress) {
    return IERC20__factory.connect(oTokenAddress, signerOrProvider);
  }

  return undefined;
};

const useOToken = (orderId?: number) => {
  const { active, provider } = useWallet();
  const { baseProvider } = useWeb3Context();
  const [contract, setContract] = useState<IERC20>();
  const { contract: otcWrapperContract } = useOtcWrapper();
  const zeroAddressEth = addresses.mainnet.contracts.zeroAddress;

  const getOTokenAddress = useCallback(async () => {
    if (orderId && otcWrapperContract) {
      const oTokenAddress = (await otcWrapperContract.orders(orderId)).oToken;
      if (!(oTokenAddress === zeroAddressEth)) {
        setContract(getOToken(oTokenAddress, provider || baseProvider, active));
      }
    }
  }, [active, baseProvider, orderId, otcWrapperContract, provider, zeroAddressEth]);

  useEffect(() => {
    getOTokenAddress();
  }, [getOTokenAddress]);

  return {
    contract,
  };
};

export default useOToken;
