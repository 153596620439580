/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  MarginRequirement,
  MarginRequirementInterface,
} from "../MarginRequirement";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_addressBook",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [],
    name: "MAX_INITIAL_MARGIN",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "NOTIONAL_DECIMALS",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ORACLE_DECIMALS",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "addressBook",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_account",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_notional",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_underlying",
        type: "address",
      },
      {
        internalType: "bool",
        name: "_isPut",
        type: "bool",
      },
      {
        internalType: "uint256",
        name: "_collateralAmount",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_collateralAsset",
        type: "address",
      },
    ],
    name: "checkMintCollateral",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_account",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_notional",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_withdrawAmount",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_otokenAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_vaultID",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "address[]",
            name: "shortOtokens",
            type: "address[]",
          },
          {
            internalType: "address[]",
            name: "longOtokens",
            type: "address[]",
          },
          {
            internalType: "address[]",
            name: "collateralAssets",
            type: "address[]",
          },
          {
            internalType: "uint256[]",
            name: "shortAmounts",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "longAmounts",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "collateralAmounts",
            type: "uint256[]",
          },
        ],
        internalType: "struct MarginVault.Vault",
        name: "_vault",
        type: "tuple",
      },
    ],
    name: "checkWithdrawCollateral",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "initialMargin",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "maintenanceMargin",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "oracle",
    outputs: [
      {
        internalType: "contract OracleInterface",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_underlying",
        type: "address",
      },
      {
        internalType: "address",
        name: "_collateralAsset",
        type: "address",
      },
      {
        internalType: "bool",
        name: "_isPut",
        type: "bool",
      },
      {
        internalType: "address",
        name: "_account",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_initialMargin",
        type: "uint256",
      },
    ],
    name: "setInitialMargin",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_vaultID",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_maintenanceMargin",
        type: "uint256",
      },
    ],
    name: "setMaintenanceMargin",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

export class MarginRequirement__factory {
  static readonly abi = _abi;
  static createInterface(): MarginRequirementInterface {
    return new utils.Interface(_abi) as MarginRequirementInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MarginRequirement {
    return new Contract(address, _abi, signerOrProvider) as MarginRequirement;
  }
}
