import styled from "styled-components";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_SIZE } from "../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";

export const HeaderText = styled.p`
  margin: 0;
  font-size: ${FONT_SIZE.two};
  color: ${TEXT_COLORS.one};
`;

export const DropdownHeader = styled.div``;

export const DropdownFooter = styled.div`
  border-top: 1px solid ${BORDER_COLORS.one};
  height: ${COMPONENTS.modalHeaderHeight}px;
  > * {
    padding: ${SPACING.two}px;
    margin: 0;
  }
`;

export const TradeDropdownWrapper = styled.div`
  margin: auto;
  display: flex;
  text-align: left;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  > * {
    display: flex;
  }

  > div > img {
    margin-right: ${SPACING.two}px;
  }
`;

export const TradeLabelSeparator = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 ${SPACING.three}px;
  font-size: ${FONT_SIZE.one};
  color: ${TEXT_COLORS.three};
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 6px 6px 0 0;
  background-color: ${BACKGROUND_COLORS.eleven};
  cursor: default;
`;

export const TradeLabelWrapper = styled.div`
  display: flex;
  padding: ${SPACING.two}px ${SPACING.twoHalf}px;
  img {
    margin-right: ${SPACING.two}px;
  }

  &:hover {
    background-color: ${BACKGROUND_COLORS.four};
  }
`;

export const TradeLabelValues = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-right: ${SPACING.two}px;

  > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const SubText = styled.p`
  margin: 0;
  font-size: ${FONT_SIZE.one};
  color: ${TEXT_COLORS.three};
`;

export const EmptySearchResult = styled.div`
  padding: ${SPACING.three}px;
  font-size: ${FONT_SIZE.one};
  color: ${TEXT_COLORS.three};
  text-align: center;
`;

export const ClearButton = styled.button`
  opacity: 0.4;
`;

export const SublabelWrapper = styled.div`
  display: flex;
`;
