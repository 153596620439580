import styled, { css } from "styled-components";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { FONT_WEIGHT } from "../../../constants/design/fontWeight";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";

export const SelectWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  width: fit-content;
`;

export const OptionWrapper = styled.div<{ spacing?: number }>`
  display: flex;
  justify-content: center;
  position: relative;

  
  &:not(:last-of-type) {
    margin-right: ${({ spacing }) => spacing ?? SPACING.two}px;
  }
`;

export const Tag = styled.span`
  display: inline-block;
  margin-left: ${SPACING.one}px;
  padding: 0 ${SPACING.two}px;
  border-radius: ${SPACING.three}px;
`;

export const SelectButton = styled.button.attrs({
  type: "button",
}) <{
  isActive?: boolean;
  isHighlighted?: boolean;
  isRound?: boolean;
  css?: any;
  size?: string;
}>`
  height: ${COMPONENTS.selectHeight}px;
  border-radius: ${({ isRound }) => (isRound ? 20 : 6)}px;
  color: ${(props) => ((props.isActive || props.isHighlighted) ? COLORS.blue.one : TEXT_COLORS.two)};
  box-shadow: ${(props) => ((props.isActive) ? `0 0 0 1px ${COLORS.blue.one} inset` : "none")};
  padding: ${SPACING.one}px ${SPACING.twoHalf}px;
  background-color: ${COLORS.white.five};
  white-space: nowrap;
  transition: 0.2s all ease-in-out;
  
  ${Tag} {
    background-color: ${(props) => (props.isActive ? COLORS.blue.three : COLORS.white.two)};
  }

  font-size: ${({ size }) => size ?? FONT_SIZE.one};
  font-weight: ${FONT_WEIGHT.medium};
  ${(props) => props.css ?? null};

  &:not(:last-of-type) {
    margin-right: ${SPACING.two}px;
  }

  display: flex;
  align-items: center;
  ${({ isHighlighted }) => {
    if (isHighlighted) {
      return css`
        &:before {
          content: "";
          background-color: ${COLORS.blue.one};
          width: 4px;
          height: 4px;
          border-radius: 4px;
          margin-right: ${SPACING.one}px;
        }
      `;
    }
    return "";
  }}
`;
