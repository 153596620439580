import styled, { css } from "styled-components";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";
import { TableWrapper } from "../../shared/Table/style";

export const EmptyContent = styled.div`
  color: ${TEXT_COLORS.three};
  padding-top: ${SPACING.three}px;
  padding-left: ${SPACING.three}px;
  font-size: ${FONT_SIZE.oneHalf};
`;

export const PortfolioTableWrapper = styled(TableWrapper)<{
  firstColSticky?: boolean;
}>`
  height: 100%;
  thead {
    background: transparent !important;
  }

  ${({ firstColSticky }) => firstColSticky
    && css`
      th,
      td {
        &:first-child {
          position: sticky;
          left: 0;
          z-index: 1;
        }
      }
    `}
`;

export const Header = styled.div`
  color: ${TEXT_COLORS.one};
  h2 {
    font-size: ${FONT_SIZE.three};
    margin: 0;
  }
  display: flex;
  align-items: center;
`;

export const MarketCellContainer = styled.div`
  display: flex;
  text-transform: capitalize;
  align-items: center;
  height: 100%;
  color: ${TEXT_COLORS.one};
`;

export const MobileMarketCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  align-items: flex-start;
  justify-content: space-between;
  height: ${COMPONENTS.tableRowMobileHeight}px;
  color: ${TEXT_COLORS.one};
  padding: ${SPACING.three}px 0;
`;

export const CSVLinkWrapper = styled.div`
  a {
    color: ${TEXT_COLORS.three} !important;
    text-decoration: none;
    font-size: ${FONT_SIZE.one};
    cursor: pointer;
    &:hover {
      color: ${COLORS.blue.one} !important;
    }
  }

  a[aria-disabled="true"] {
    &:hover {
      cursor: not-allowed;
      color: ${TEXT_COLORS.three} !important;
    }
  }
`;

export const ViewDetailsContent = styled.div`
  > *:not(:last-child) {
    margin-bottom: ${SPACING.three}px;
  }
`;

export const ViewDetailsRow = styled.div<{ clickable?: boolean }>`
  display: flex;
  justify-content: space-between;
  font-size: ${FONT_SIZE.one};
  text-transform: capitalize;

  span:first-child {
    color: ${TEXT_COLORS.three};
  }

  span:last-child {
    display: flex;
    align-items: center;
    color: ${TEXT_COLORS.one};
  }

  ${({ clickable }) => clickable
    && css`
      cursor: pointer;
      &:hover {
        span:last-child {
          transition: opacity 0.2s ease-in-out;
          opacity: 0.6;
        }
      }
    `}
`;

export const ViewDetailsButton = styled.button`
  color: ${TEXT_COLORS.three};
  font-size: ${FONT_SIZE.one};

  &:hover {
    color: ${COLORS.blue.one};
  }
`;

export const TextContainer = styled.div<{numRows?: number}>`
  display: flex;
  flex-direction: column;
  ${({ numRows }) => (numRows && `width: calc(100% / ${numRows})`)};
`;

export const SecondaryText = styled.div<{ color?: string }>`
  ${({ color }) => `color: ${color};`}
  font-size: ${FONT_SIZE.oneHalf};
  text-transform: none;
  span {
    color: ${TEXT_COLORS.one};
  }
`;

export const TertiaryText = styled.span<{ color?: string }>`
  ${({ color }) => color && `color: ${color};`}
  font-size: ${FONT_SIZE.one};
  text-transform: none;
`;

export const TopContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
`;

export const BottomContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${SPACING.two}px;
  height: 50%;
  width: 100%;
`;

export const InfoTitle = styled.div<{ color?: string }>`
  ${({ color }) => color && `color: ${color};`}
  font-size: ${FONT_SIZE.xs};
  text-transform: none;
  span {
    color: ${TEXT_COLORS.two};
  }
`;

export const InfoContent = styled.div<{ color?: string }>`
  ${({ color }) => color && `color: ${color};`}
  font-size: ${FONT_SIZE.oneHalf};
  text-transform: none;
  span {
    color: ${TEXT_COLORS.two};
  }
`;
