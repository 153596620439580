import { CSSProperties, forwardRef, PropsWithChildren } from "react";
import { InputWrapper, SuffixWrapper } from "./styles";
// Should we separate inputs to string and number types?
// Reckon this would be better for different use cases
interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  suffix?: JSX.Element;
  disabled?: boolean;
  integerOnly?: boolean;
  placeholder?: string;
  type?: string;
  leftAccessory?: JSX.Element;
  rightAccessory?: JSX.Element;
  wrapperStyles?: CSSProperties;
  inputStyles?: CSSProperties;
  error?: boolean;
  decimalPlaces?: number;
  value?: string;
}

function handleKeyPress(event: any) {
  if (event.key === "." || event.key === "-") {
    event.preventDefault();
  }
}

export const Input = forwardRef<any, PropsWithChildren<IInputProps>>(
  (
    {
      step = "any",
      disabled = false,
      integerOnly = false,
      suffix,
      placeholder,
      type = "number",
      leftAccessory,
      rightAccessory,
      error,
      wrapperStyles,
      inputStyles,
      ...others
    },
    ref
  ) => (
    <InputWrapper error={error} style={wrapperStyles}>
      {leftAccessory}
      <input
        step={step}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        {...others}
        ref={ref}
        onKeyDown={(e) => integerOnly && handleKeyPress(e)}
        onWheel={(e) => e.currentTarget.blur()}
        style={inputStyles}
      />
      <SuffixWrapper>{suffix}</SuffixWrapper>
      {rightAccessory}
    </InputWrapper>
  )
);
