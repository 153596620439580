import moment from "moment";
import { Assets } from "../assets";
import {
  OptionResponse, OptionTypeResponse,
} from "../../interfaces/PreloadedStrikes";
import { shortExpiryTime } from "../date";

/**
 * Extract the expiry date in DD-MM-YYYY format from a given symbol
 * @param symbol eg. "ETH-27MAY22-2000-C" or "ETH-PERP"
 */
export const getExpiryFromSymbol = (symbol: string) => {
  const date = symbol.split("-")[1]; // eg. "27MAY22"
  const m = moment(date, "DDMMMYY");
  if (m.isValid()) {
    return m.format("DD-MM-YYYY");
  }
  return undefined;
};

export const getInstrumentName = (asset: Assets, expiry: string, strike: string, optionType: OptionTypeResponse) => `${asset} - ${shortExpiryTime(expiry)} - ${strike} - ${optionType === OptionResponse.Call ? "C" : "P"}`;

export const getOptionsTypeFromSymbol = (symbol: string) => {
  const split = symbol.split("-");
  const letter = split[split.length - 1];
  if (letter.toUpperCase() === "C") {
    return OptionResponse.Call;
  }
  if (letter.toUpperCase() === "P") {
    return OptionResponse.Put;
  }
  return undefined;
};

// Symbol same format as above
export const getAssetFromSymbol = (symbol: string) => symbol.split("-")[0] as Assets;

/**
 * Calculates the profit per option.
 * To get total profit, multiply this by your position size
 */
export const calculateProfitPerOption = (
  isPut: boolean,
  currentPrice: number,
  strikePrice: number,
  premium: number
) => {
  if (isPut) {
    return currentPrice <= strikePrice
      ? strikePrice - currentPrice - premium
      : -premium;
  }
  return currentPrice >= strikePrice
    ? currentPrice - strikePrice - premium
    : -premium;
};

export const calculateBreakevenForOption = (
  isPut: boolean,
  strikePrice: number,
  premium: number
) => (isPut ? strikePrice - premium : strikePrice + premium);
