// Custom dropdown implementation with reference from
// https://react-bootstrap.github.io/components/dropdowns/#custom-dropdown-components
import { AnimatePresence, motion } from "framer-motion";
import { forwardRef, PropsWithChildren, Children, CSSProperties } from "react";
import { Dropdown as BootstrapDropdown } from "react-bootstrap";
import { DropdownMenuProps } from "react-bootstrap/esm/DropdownMenu";
import Transition from "../Transition";
import { DropdownButton, DropdownItemButton, DropdownMenuWrapper } from "./style";

const props = {
  transition: {
    duration: 0.25,
    ease: "easeInOut",
  },
  initial: {
    opacity: 0,
    translateY: 8
  },
  animate: {
    opacity: 1,
    translateY: 0
  },
  exit: {
    opacity: 0,
    translateY: 8
  },
};

// THE TOGGLE
interface IDropdownToggleProps {
  onClick: () => void;
  style: CSSProperties;
}
const DropdownToggle = forwardRef<any, PropsWithChildren<IDropdownToggleProps>>(({ children, onClick, style }, ref) => (
  <DropdownButton ref={ref} onClick={onClick} style={style}>
    {children}
  </DropdownButton>
));

// THE MENU
interface IDropdownMenuProps {
  style: CSSProperties;
  className: string;
  "aria-labelledby": string;
}
const DropdownMenu = forwardRef<any, PropsWithChildren<IDropdownMenuProps>>(({ children, style, className, "aria-labelledby": labeledBy }, ref) => (
  <DropdownMenuWrapper ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
    {/* <ul className="list-unstyled"> */}
    {Children.toArray(children)}
    {/* </ul> */}
  </DropdownMenuWrapper>
));

// THE ITEMS
interface IDropdownItemProps {
  active: boolean;
  onClick?: () => void;
}
const DropdownItem = forwardRef<any, PropsWithChildren<IDropdownItemProps>>(({ children, onClick }, ref) => (
  <DropdownItemButton
    ref={ref}
    type="button"
    onClick={onClick}
    {...props}
  >
    {children}
  </DropdownItemButton>
));

export interface IDropdownItemsItem {
  label: string | JSX.Element;
  isSeparator?: boolean;
  active?: boolean;
  onSelect?: () => void;
}

// THE COMPONENT
interface IDropdownProps {
  title: string | JSX.Element;
  items: IDropdownItemsItem[];
  width?: number | string;
  height?: number | string;
  toggleStyles?: CSSProperties;
  dropdownMenuProps?: DropdownMenuProps;
  dropdownMenuContainerStyles?: CSSProperties;
  dropdownHeader?: JSX.Element;
  dropdownFooter?: JSX.Element;

  // Optional callback on dropdown open
  onToggle?: (open: boolean) => void;
}
function Dropdown({
  title,
  items,
  width,
  height,
  dropdownHeader,
  dropdownFooter,
  onToggle,
  toggleStyles,
  dropdownMenuProps,
  dropdownMenuContainerStyles,
}: IDropdownProps) {
  return (
    <BootstrapDropdown
      style={{
        width,
        height: height ?? "100%",
      }}
      onToggle={onToggle}
    >
      <BootstrapDropdown.Toggle
        as={DropdownToggle}
        style={{
          width,
          ...toggleStyles,
        }}
      >
        <Transition>
          {title}
        </Transition>
      </BootstrapDropdown.Toggle>

      <BootstrapDropdown.Menu
        as={DropdownMenu}
        style={{
          width,
          ...dropdownMenuContainerStyles,
        }}
        {...dropdownMenuProps}
      >
        {dropdownHeader}
        <AnimatePresence>
          {items.map((item, i) => (item.isSeparator ? (
            <motion.div
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              {...props}
            >
              {item.label}
            </motion.div>
          ) : (
            <BootstrapDropdown.Item
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              as={DropdownItem}
              active={item.active}
              onClick={item.onSelect}
            >
              {item.label}
            </BootstrapDropdown.Item>
          )))}
        </AnimatePresence>
        {dropdownFooter}
      </BootstrapDropdown.Menu>
    </BootstrapDropdown>
  );
}

export default Dropdown;
