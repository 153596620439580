import { AnimatePresence } from "framer-motion";
import { useToast } from "../../../hooks/toast";
import { ToastListWrapper } from "./style";
import SingleToast from "./SingleToast";
import useScreenSize from "../../../hooks/screenSize/useScreenSize";

export function Toast() {
  const { toasts } = useToast();
  const { isMobileScreen } = useScreenSize();
  return (
    <ToastListWrapper $isMobileScreen={isMobileScreen}>
      <AnimatePresence>
        {toasts.map((toast) => (
          <SingleToast key={toast.hash} toast={toast} />
        ))}
      </AnimatePresence>
    </ToastListWrapper>
  );
}
