import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { Web3ReactProvider } from "@web3-react/core";
import { lazy, Suspense, useContext, useRef } from "react";
import { ChainContextProvider } from "./contexts/ChainContext";
import {
  AppContainer,
  BodyContainer,
  FloatingBackgroundContainer,
} from "./App.style";
import { ToastContextProvider } from "./contexts/ToastContext";
import useEagerConnect from "./hooks/wallet/useEagerConnect";
import { ConnectWalletContextProvider } from "./contexts/ConnectWalletContext";
import { ContractContextProvider } from "./contexts/ContractContext";
import { Toast } from "./components/shared/Toast";
import {
  NotificationContext,
  NotificationContextProvider,
} from "./contexts/NotificationContext";
import useOutsideAlerter from "./hooks/outsideAlerter/useOutsideAlerter";
import useKeydownHandler, {
  KeydownHandlerKeyEnum,
} from "./hooks/useKeydownHandler";
import { PricesContextProvider } from "./contexts/PricesContext";
import MobileHeader from "./components/shared/Header/MobileHeader";
import DesktopHeader from "./components/shared/Header/DesktopHeader";
import DesktopFooter from "./components/shared/Footer/DesktopFooter";
import MobileFooter from "./components/shared/Footer/MobileFooter";
import PriceMarquee from "./components/PriceMarquee";
import SideNavigator from "./components/shared/SideNavigator";
import RequestPositionModal from "./components/Modals/RequestPositionModal";
import useScreenSize from "./hooks/screenSize/useScreenSize";
import MobileNavigator from "./components/MobileNavigator";
import background from "./assets/video/bg.mp4";
import { TradeInstrumentContextProvider } from "./contexts/TradeInstrumentContext";
import { OptionsDataContextProvider } from "./contexts/OptionsDataContext";
import {
  RequestPositionContext,
  RequestPositionContextProvider,
} from "./contexts/RequestPositionContext";
import { SettingsContextProvider } from "./contexts/SettingsContext";
import { ImpersonateContextProvider } from "./contexts/ImpersonateContext";
import { allConnectors } from "./utils/wallet/connectors";

const Trades = lazy(() => import("./pages/TradePage"));
const Portfolio = lazy(() => import("./pages/PortfolioPage"));
const Requests = lazy(() => import("./pages/RequestsPage"));
const MMPortfolio = lazy(() => import("./pages/MMPortfolioPage"));

interface IRoute {
  path: string;
  page: JSX.Element;
}

const routes: IRoute[] = [
  {
    path: "/",
    page: <Navigate to="/trade" />,
  },
  {
    path: "/mm",
    page: <Navigate to="/mm/requests" />,
  },
  {
    path: "/trade/*",
    page: (
      <Suspense>
        <Trades />
      </Suspense>
    ),
  },
  {
    path: "/portfolio/*",
    page: (
      <Suspense>
        <Portfolio />
      </Suspense>
    ),
  },
  {
    path: "/mm/requests/*",
    page: (
      <Suspense>
        <Requests />
      </Suspense>
    ),
  },
  {
    path: "/mm/portfolio/*",
    page: (
      <Suspense>
        <MMPortfolio />
      </Suspense>
    ),
  },
];

function Root() {
  useEagerConnect();
  const { setShowPanel } = useContext(NotificationContext);
  const { isMobileScreen } = useScreenSize();
  const {
    order,
    setOrder,
    orderType,
    showRequestPositionModal,
    setShowRequestPositionModal,
  } = useContext(RequestPositionContext);
  const modalRef = useRef(null);
  const notifButtonRef = useRef(null);
  const onHide = () => setShowPanel(false);
  useOutsideAlerter([modalRef, notifButtonRef], onHide);
  useKeydownHandler(KeydownHandlerKeyEnum.ESC, onHide);
  const { video } = useScreenSize();
  return (
    <AppContainer>
      <BrowserRouter>
        {isMobileScreen ? <MobileHeader /> : <DesktopHeader />}
        {!isMobileScreen && <PriceMarquee />}
        {!isMobileScreen && <SideNavigator />}
        <Toast />
        <FloatingBackgroundContainer>
          <ReactPlayer
            key="video-player"
            url={`${background}`}
            playing
            width={video.width}
            height={video.height}
            style={{
              minWidth: video.width,
              minHeight: video.height,
            }}
            muted
            loop
          />
        </FloatingBackgroundContainer>
        {order && orderType && showRequestPositionModal && (
          <RequestPositionModal
            order={order}
            setOrder={setOrder}
            orderType={orderType}
            showModal={showRequestPositionModal}
            setShowModal={setShowRequestPositionModal}
          />
        )}
        <BodyContainer isMobileScreen={isMobileScreen}>
          <Routes>
            {routes.map((route) => (
              <Route key={route.path} path={route.path} element={route.page} />
            ))}
          </Routes>
        </BodyContainer>
        {isMobileScreen && <MobileNavigator />}
        {isMobileScreen ? <MobileFooter /> : <DesktopFooter />}
      </BrowserRouter>
    </AppContainer>
  );
}

function App() {
  return (
    <SettingsContextProvider>
      <ChainContextProvider>
        <ImpersonateContextProvider>
          <ConnectWalletContextProvider>
            <ToastContextProvider>
              <ContractContextProvider>
                <PricesContextProvider>
                  <Web3ReactProvider connectors={allConnectors}>
                    <OptionsDataContextProvider>
                      <RequestPositionContextProvider>
                        <NotificationContextProvider>
                          <TradeInstrumentContextProvider>
                            <Root />
                          </TradeInstrumentContextProvider>
                        </NotificationContextProvider>
                      </RequestPositionContextProvider>
                    </OptionsDataContextProvider>
                  </Web3ReactProvider>
                </PricesContextProvider>
              </ContractContextProvider>
            </ToastContextProvider>
          </ConnectWalletContextProvider>
        </ImpersonateContextProvider>
      </ChainContextProvider>
    </SettingsContextProvider>
  );
}

export default App;
