import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";
import { BACKGROUND_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { FONT_WEIGHT } from "../../../constants/design/fontWeight";
import { SelectButton } from "../Select/style";

export const DropdownButton = styled(SelectButton)`
  display: flex;
  border-radius: 6px;
  font-size: ${FONT_SIZE.two};
  font-weight: ${FONT_WEIGHT.medium};
  height: 100%;
  margin: auto 0;

  > * {
    margin: auto;
    width: 100%;
  }
`;

const fadeIn = keyframes`
  to {
    opacity: 1;
  }
`;

export const DropdownMenuWrapper = styled.ul`
  background-color: ${BACKGROUND_COLORS.eleven};
  padding: 0;
  opacity: 0;
  animation: ${fadeIn} 0.3s ease-in-out forwards;
`;

export const DropdownItemButton = styled(motion.button)`
  color: ${TEXT_COLORS.one};
  font-size: ${FONT_SIZE.two};
  width: 100%;
  text-align: left;
  padding: 0;
`;
