/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useRef, useState } from "react";
import {
  AbsoluteLeftContainer,
  LeftFooterWrapper,
  SvgContainer,
} from "./style";
import { orders as Orders } from "../../../assets/styledSvg/orders";
import { close as Close } from "../../../assets/styledSvg/close";
import { COLORS, ICON_COLORS } from "../../../constants/design/colors";
import { PendingOrdersPanel } from "../PendingOrdersPanel";
import useScreenSize from "../../../hooks/screenSize/useScreenSize";
import useOutsideAlerter from "../../../hooks/outsideAlerter/useOutsideAlerter";
import useKeydownHandler, {
  KeydownHandlerKeyEnum,
} from "../../../hooks/useKeydownHandler";
import { OptionsDataContext } from "../../../contexts/OptionsDataContext";
import { Badge } from "../../shared/Badge";

export function MobilePendingOrdersFooter() {
  const [isOrdersOpen, setOrdersOpen] = useState<boolean>(false);
  const { isMobileScreen } = useScreenSize();
  const {
    pendingOrders: { loading, orders: pendingOrders },
  } = useContext(OptionsDataContext);
  const hasPendingOrders = pendingOrders.length > 0;
  const modalContentRef = useRef(null);
  const buttonRef = useRef(null);
  useOutsideAlerter([buttonRef, modalContentRef], () => setOrdersOpen(false));
  useKeydownHandler(KeydownHandlerKeyEnum.ESC, () => setOrdersOpen(false));
  return (
    <>
      <LeftFooterWrapper>
        <SvgContainer
          ref={buttonRef}
          isOpen={isOrdersOpen || hasPendingOrders}
          onClick={() => setOrdersOpen(!isOrdersOpen)}
        >
          {!isOrdersOpen ? (
            <Orders
              color={
                isOrdersOpen || hasPendingOrders
                  ? COLORS.blue.one
                  : ICON_COLORS.two
              }
            />
          ) : (
            <Close />
          )}
        </SvgContainer>
        {pendingOrders.length > 0 && !isOrdersOpen && (
          <Badge color={COLORS.blue.one} backgroundColor="none">
            {pendingOrders.length}
          </Badge>
        )}
      </LeftFooterWrapper>
      {isOrdersOpen && (
        <AbsoluteLeftContainer
          ref={modalContentRef}
          isMobileScreen={isMobileScreen}
        >
          <PendingOrdersPanel loading={loading} pendingOrders={pendingOrders} />
        </AbsoluteLeftContainer>
      )}
    </>
  );
}
