import styled, { css } from "styled-components";
import { BACKGROUND_COLORS, BORDER_COLORS, ICON_COLORS, LAYER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";

const textFormat = css`
  a {
    cursor: pointer;
    color: ${TEXT_COLORS.two};
    text-decoration: none;

    margin-right: ${SPACING.three}px;
    margin-left: ${SPACING.three}px;
  }
`;

export const MainHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid ${BORDER_COLORS.one};
  padding-right ${SPACING.twoHalf}px;
  height: ${COMPONENTS.header.main}px;
  background: ${BACKGROUND_COLORS.five};
  z-index: 2;
`;

export const Divider = styled.div<{height?: string; horizontalMargin?: string}>`
  background-color: ${BORDER_COLORS.one};
  width: 1px;
  height: ${({ height }) => (height || "100%")};
  margin-left: ${({ horizontalMargin }) => (horizontalMargin || `${SPACING.twoHalf}px`)};
  margin-right: ${({ horizontalMargin }) => (horizontalMargin || `${SPACING.twoHalf}px`)};
`;

export const LeftHeaderWrapper = styled.div`
  font-size: ${FONT_SIZE.two};
  display: flex;
  align-items: center;
  justify-content: center;

  ${textFormat}
  a {
    text-decoration: none;
    cursor: pointer;
    color: ${TEXT_COLORS.two};
    margin-right: ${SPACING.twoHalf}px;
  }
`;

export const MiddleHeaderWrapper = styled.div`
  font-size: ${FONT_SIZE.two};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 1;
  
  ${textFormat}
`;

export const RightHeaderWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const NotificationButton = styled.button`
  width: 32px;
  height: 32px;
  padding: 0;
  position: relative;
`;

export const RightHeaderComponent = styled.div`
  margin-right: ${SPACING.two}px;
  display: flex;
  align-items: center;
`;

export const LinkText = styled.span<{ isActive?: boolean }>`
  ${FONT_CSS.header.six}
  transition: 0.2s ease-in-out;
  color: ${(props) => (props.isActive ? TEXT_COLORS.one : TEXT_COLORS.three)};
  svg {
    stroke: ${(props) => (props.isActive ? TEXT_COLORS.one : TEXT_COLORS.three)};
  }

  &:hover {
    color: ${TEXT_COLORS.one};
    svg {
      stroke: ${TEXT_COLORS.one};
    }
  }
`;

export const MobileMenuButton = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: ${SPACING.three}px;
  padding: 0 10px;

  &:before {
    content: "";
    border-radius: 100px;
    background-color: ${BACKGROUND_COLORS.one};
    width: 18px;
    height: 2px;
    margin-bottom: ${SPACING.one}px;
  }
  &:after {
    content: "";
    border-radius: 100px;
    background-color: ${BACKGROUND_COLORS.one};
    width: 18px;
    height: 2px;
  }
`;

export const BellWrapper = styled.div<{ color: string }>`
  display: flex;
  
  > * {
    margin: auto 0;
  }

  path {
    stroke-opacity: 1;
    stroke: ${({ color }) => color};
  }

  span {
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    height: 16px; 
  }
`;

export const CommunityRowLabel = styled.span`
  transition: all 0.2s ease-in-out;
  > svg {
    transition: all 0.2s ease-in-out;
    margin-left: ${SPACING.one}px;
    stroke: ${TEXT_COLORS.three};
  }
  padding-left: ${SPACING.two}px;
`;

export const DropdownWrapper = styled.div`
  a {
    margin: 0;
  }
`;

export const CommunityRow = styled.a.attrs({
  target: "_blank",
  rel: "noreferrer",
})<{noBorder?: boolean; svgIsFill?: boolean}>`
  ${FONT_CSS.header.six}
  color: ${TEXT_COLORS.three};
  padding: ${SPACING.two}px;
  height: 48px;
  width: 240px;

  display: flex;
  align-items: center;
  background: ${BACKGROUND_COLORS.seven};
  border-bottom: ${({ noBorder }) => (noBorder ? "none" : `1px solid ${BORDER_COLORS.one}`)};

  > svg {
    width: 32px;
    height: 32px;
    background-color: ${LAYER_COLORS.two};
    border-radius: ${SPACING.two}px;
    padding: ${SPACING.two}px;
    transition: all 0.2s ease-in-out;
    ${({ svgIsFill }) => (
    svgIsFill
      ? css`fill: ${ICON_COLORS.two};`
      : css`stroke: ${ICON_COLORS.two};`)}    
  }

  &:hover {
    color: ${TEXT_COLORS.one};
    > svg {
      ${({ svgIsFill }) => (
    svgIsFill
      ? css`fill: ${ICON_COLORS.one};`
      : css`stroke: ${ICON_COLORS.one};`)}    
    }

    ${CommunityRowLabel} {
      svg {
        stroke: ${TEXT_COLORS.one};
      }
    }
  }
`;

export const AboutModalContainer = styled.div`
  ${FONT_CSS.body.three}
  width: 280px;
  color: ${TEXT_COLORS.two};

  a {
    color: ${TEXT_COLORS.one};
  }
`;
