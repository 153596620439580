import currency from "currency.js";
import { SecondaryText, TertiaryText, TextContainer } from "./style";
import { COLORS } from "../../../constants/design/colors";

interface IMarginCellProps {
  marginPosted?: string;
  requireTopUp: boolean;
}

function MarginCell({ marginPosted, requireTopUp }: IMarginCellProps) {
  const color = requireTopUp ? COLORS.negative.one : undefined;
  return (
    <TextContainer>
      <SecondaryText color={color}>
        {marginPosted ? `${currency(marginPosted).format()}` : "-"}
      </SecondaryText>
      {requireTopUp && (
        <TertiaryText color={color}>
          Top-up margin
        </TertiaryText>
      )}
    </TextContainer>
  );
}

export default MarginCell;
