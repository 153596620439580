/* eslint-disable no-underscore-dangle */
import React, { useCallback, useMemo } from "react";
import { ChartOptions, ChartData } from "chart.js";
import { Line } from "react-chartjs-2";
import currency from "currency.js";
import { getRange } from "../../utils/math";
import {
  BACKGROUND_COLORS,
  COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";
import { FONT_SIZE } from "../../constants/design/fontSize";
import { drawRoundRect } from "../../utils/canvas";
import {
  calculateBreakevenForOption,
  calculateProfitPerOption,
} from "../../utils/instruments";
import { Assets, getAssetPrecision } from "../../utils/assets";

/**
 * Strike: Strike price of the option
 * Price: The price of which the profit is to be calculated
 * Premium: Premium of the option, assume per unit over here
 * Quantity: Quantity of which the user has
 * Expiry: Expiry of the option
 */
interface IProfitChartProps {
  asset: Assets;
  strike: number;
  price: number;
  premium: number;
  isPut: boolean;
  onHover: (price: number | undefined) => void;
}

const ProfitChart: React.FC<IProfitChartProps> = ({
  asset,
  strike,
  price,
  premium,
  isPut,
  onHover,
}) => {
  const calculateProfit = useCallback(
    (p: number) => {
      const profit = calculateProfitPerOption(isPut, p, strike, premium);
      // eslint-disable-next-line no-compare-neg-zero
      return profit === 0 ? Math.abs(profit) : profit;
    },
    [isPut, premium, strike]
  );

  const breakeven = useMemo(
    () => calculateBreakevenForOption(isPut, strike, premium),
    [isPut, premium, strike]
  );

  const findNeighbourPoint = useCallback((nums: number[], point: number) => {
    let closestSmallerNum: number | undefined;
    let closestBiggerNum: number | undefined;

    for (let i = 0; i < nums.length; i += 1) {
      const currNum = nums[i];

      // Find closest bigger number
      if (
        currNum >= point
        && (closestSmallerNum === undefined || currNum < closestSmallerNum)
      ) {
        if (closestSmallerNum === undefined || currNum < closestSmallerNum) {
          closestSmallerNum = currNum;
        }
      }

      // Find closest smaller number
      if (
        currNum <= point
        && (closestBiggerNum === undefined || currNum > closestBiggerNum)
      ) {
        closestBiggerNum = currNum;
      }
    }

    // return 2 closest point
    return [closestSmallerNum!, closestBiggerNum!];
  }, []);

  const priceRange = useMemo(
    () => (premium > 0
      ? getRange(
        breakeven * (1 - (premium * 5) / strike),
        breakeven * (1 + (premium * 5) / strike),
        breakeven
              * (premium > 0
                ? premium / strike / 20
                : breakeven * (1 + (premium * 5) / strike))
      )
      : getRange(breakeven * 0.9, breakeven * 1.1, breakeven * 0.02)),
    [breakeven, premium, strike]
  );

  const drawSpotLabel = useCallback(
    (
      ctx: CanvasRenderingContext2D,
      label: string,
      value: string,
      valueColor: string,
      x: number,
      y: number
    ) => {
      ctx.save();
      ctx.font = `${FONT_SIZE.one} BaseFont`;
      const valueWithSpace = ` ${value}`;
      const labelLength = ctx.measureText(label).width;
      const valueLength = ctx.measureText(valueWithSpace).width;

      const canvasWidth = ctx.canvas.clientWidth;
      const minLabelX = 28;
      const maxLabelX = canvasWidth - labelLength - valueLength - 24;
      let labelX = x - (labelLength + valueLength) / 2;
      if (labelX < minLabelX) {
        labelX = minLabelX;
      } else if (labelX > maxLabelX) {
        labelX = maxLabelX;
      }
      const valueX = labelX + labelLength;

      // DRAW RECT W/ ROUNDED CORNER
      const rectPadding = SPACING.two;
      const rectHeight = 24;
      const rectWidth = labelLength + valueLength + rectPadding * 2;
      ctx.fillStyle = BACKGROUND_COLORS.eight;
      ctx.beginPath();

      drawRoundRect(
        ctx,
        labelX - rectPadding,
        y - rectHeight / 2,
        rectWidth,
        rectHeight,
        {
          upperLeft: 8,
          upperRight: 8,
          lowerLeft: 8,
          lowerRight: 8,
        },
        true,
        false
      );

      // Draw text
      const labelY = y + rectHeight * 0.5 * 0.5 * 0.5;
      ctx.fillStyle = TEXT_COLORS.three;
      ctx.textAlign = "left";
      ctx.fillText(label, labelX, labelY);

      ctx.fillStyle = valueColor;
      ctx.fillText(valueWithSpace, valueX, labelY);

      ctx.restore();
    },
    []
  );

  const drawStrikeLabel = useCallback(
    (
      ctx: CanvasRenderingContext2D,
      label: string,
      value: string,
      valueColor: string,
      x: number,
      y: number
    ) => {
      ctx.save();
      ctx.font = `${FONT_SIZE.one} BaseFont`;
      const valueWithSpace = ` ${value}`;
      const labelLength = ctx.measureText(label).width;
      const valueLength = ctx.measureText(valueWithSpace).width;

      const canvasWidth = ctx.canvas.clientWidth;
      const minLabelX = 28;
      const maxLabelX = canvasWidth - labelLength - valueLength - 24;
      let labelX = x - (labelLength + valueLength) / 2;
      if (labelX < minLabelX) {
        labelX = minLabelX;
      } else if (labelX > maxLabelX) {
        labelX = maxLabelX;
      }
      const valueX = labelX + labelLength;

      // DRAW RECT W/ ROUNDED CORNER
      const rectHeight = 24;
      ctx.fillStyle = BACKGROUND_COLORS.eight;
      ctx.beginPath();

      // Draw text
      const labelY = y + rectHeight * 0.5 * 0.5 * 0.5;
      ctx.fillStyle = TEXT_COLORS.three;
      ctx.textAlign = "left";
      ctx.fillText(label, labelX, labelY);

      ctx.fillStyle = valueColor;
      ctx.fillText(valueWithSpace, valueX, labelY);

      ctx.restore();
    },
    []
  );

  const drawPricePoint = useCallback(
    (chart: any, pricePoint: number, drawIndex: number, y: number) => {
      // eslint-disable-next-line prefer-destructuring
      const ctx: CanvasRenderingContext2D = chart.chart.ctx;

      // Get breakeven datasaet because of stability over every point
      const datasetIndex = chart.chart.data.datasets.findIndex(
        (dataset: any) => dataset.label === "breakeven"
      );
      const meta = chart.chart.getDatasetMeta(datasetIndex);
      const topY = chart.chart.scales["y-axis-0"].top;
      /**
       * Draw price point
       */
      const priceElement = meta.data[drawIndex];
      const priceX = priceElement._view.x;
      const profit = calculateProfit(pricePoint);

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(priceX, topY);
      ctx.lineTo(priceX, y);
      ctx.lineWidth = 1;

      // TODO: - UPDATE
      ctx.strokeStyle = COLORS.white.one;
      ctx.stroke();
      ctx.restore();

      // Draw price text
      drawSpotLabel(
        ctx,
        `${
          // eslint-disable-next-line no-nested-ternary
          drawIndex === 0
            ? "<<< "
            : drawIndex === meta.data.length - 1
              ? ">>> "
              : ""
        }${asset} Spot`,
        currency(pricePoint, {
          precision: getAssetPrecision(asset),
        }).format(),
        profit >= 0 ? COLORS.positive.one : COLORS.negative.one,
        priceX,
        topY
      );
    },
    [asset, calculateProfit, drawSpotLabel]
  );

  const ticks = useMemo(
    () => ({
      min: isPut
        ? -calculateProfit(priceRange[0]) * 0.8
        : -calculateProfit(priceRange[priceRange.length - 1]) * 0.8,
      max: isPut
        ? calculateProfit(priceRange[0])
        : calculateProfit(priceRange[priceRange.length - 1]),
    }),
    [calculateProfit, isPut, priceRange]
  );

  const options = useMemo(
    (): ChartOptions => ({
      maintainAspectRatio: false,
      legend: { display: false },
      layout: { padding: { top: SPACING.three, bottom: 1, left: 1, right: 1 } },
      scales: {
        yAxes: [
          {
            display: false,
            ticks,
          },
        ],
        xAxes: [{ display: false }],
      },
      animation: { duration: 0 },
      hover: { animationDuration: 0, intersect: false },
      tooltips: {
        enabled: false,
        intersect: false,
        mode: "nearest",
      },
      onHover: (_: any, elements: any) => {
        if (elements && elements.length) {
          onHover(priceRange[elements[0]._index]);
          return;
        }
        onHover(undefined);
      },
    }),
    [ticks, onHover, priceRange]
  );

  const getData = useCallback(
    (canvas: any): ChartData => {
      const ctx = canvas.getContext("2d");
      const green = ctx.createLinearGradient(0, 0, 0, 120);
      green.addColorStop(0, "rgba(0, 225, 0, 0.128)");
      green.addColorStop(1, "rgba(0, 225, 0, 0)");
      const red = ctx.createLinearGradient(0, 250, 0, 148);
      red.addColorStop(0, "rgba(252, 10, 84, 0.16)");
      red.addColorStop(1, "rgba(252, 10, 84, 0.0384)");
      const neighbourPointToBreakeven = findNeighbourPoint(
        priceRange,
        breakeven
      );
      const neighbourPointToPrice = findNeighbourPoint(
        priceRange,
        price
      ).filter((neighbour) => neighbour);
      const closestPricePoint
        = neighbourPointToPrice.length < 2
        || Math.abs(price - neighbourPointToPrice[0])
          < Math.abs(price - neighbourPointToPrice[1])
          ? neighbourPointToPrice[0]
          : neighbourPointToPrice[1];

      const greenData = priceRange.map((p) => (neighbourPointToBreakeven.includes(p) || calculateProfit(p) >= 0
        ? calculateProfit(p)
        : null));
      const redData = priceRange.map((p) => (neighbourPointToBreakeven.includes(p) || calculateProfit(p) <= 0
        ? calculateProfit(p)
        : null));
      const priceData = priceRange.map((p) => (p === closestPricePoint ? price : null));

      return {
        labels: priceRange,
        datasets: [
          {
            label: "breakeven",
            data: priceRange.map(() => 0),
            type: "line",
            pointRadius: 0,
            pointHoverRadius: 0,
            borderColor: COLORS.white.two,
            borderWidth: 1,
            lineTension: 0,
          },
          {
            label: "green",
            data: greenData,
            type: "line",
            pointRadius: 0,
            pointHoverRadius: 0,
            borderDash: undefined,
            borderWidth: 1,
            borderColor: COLORS.positive.one,
            backgroundColor: green,
            fill: "-1",
            lineTension: 0,
          },
          {
            label: "red",
            data: redData,
            type: "line",
            pointRadius: 0,
            pointHoverRadius: 0,
            borderDash: undefined,
            borderWidth: 1,
            borderColor: COLORS.negative.one,
            backgroundColor: red,
            fill: "-2",
            lineTension: 0,
          },
          {
            label: "price",
            data: priceData,
            type: "line",
            pointRadius: 0,
            pointHoverRadius: 0,
          },
        ],
      };
    },
    [breakeven, calculateProfit, findNeighbourPoint, price, priceRange]
  );

  const chart = useMemo(() => {
    const neighbourPointToStrike = findNeighbourPoint(priceRange, strike);
    const closestStrikePoint
      = Math.abs(strike - neighbourPointToStrike[0])
      < Math.abs(strike - neighbourPointToStrike[1])
        ? neighbourPointToStrike[0]
        : neighbourPointToStrike[1];
    const closestStrikeIndex = priceRange.findIndex(
      (p) => p === closestStrikePoint
    );

    return (
      <Line
        type="line"
        data={getData}
        options={options}
        plugins={[
          {
            afterDraw: (c: any) => {
              // eslint-disable-next-line prefer-destructuring
              const ctx: CanvasRenderingContext2D = c.chart.ctx;
              // Get breakeven datasaet because of stability over every point
              const datasetIndex = c.chart.data.datasets.findIndex(
                (dataset: any) => dataset.label === "breakeven"
              );
              const meta = c.chart.getDatasetMeta(datasetIndex);
              const leftX = c.chart.scales["x-axis-0"].left;
              const rightX = c.chart.scales["x-axis-0"].right;
              const topY = c.chart.scales["y-axis-0"].top;
              const bottomY = c.chart.scales["y-axis-0"].bottom;
              const strikeElement = meta.data[closestStrikeIndex];
              const strikeX = strikeElement._view.x;

              // Draw borders
              ctx.save();
              ctx.beginPath();
              ctx.moveTo(leftX, bottomY);
              ctx.lineTo(rightX, bottomY);
              ctx.lineWidth = 1;
              ctx.strokeStyle = COLORS.white.four;
              ctx.stroke();
              ctx.restore();

              ctx.save();
              ctx.beginPath();
              ctx.moveTo(leftX, bottomY - SPACING.five);
              ctx.lineTo(rightX, bottomY - SPACING.five);
              ctx.lineWidth = 1;
              ctx.strokeStyle = COLORS.white.four;
              ctx.stroke();
              ctx.restore();

              // DRAW THE STRIKE LABEL
              const strikeTextY = bottomY - SPACING.three;
              drawStrikeLabel(
                ctx,
                "Strike",
                currency(strike, {
                  precision: getAssetPrecision(asset),
                }).format(),
                TEXT_COLORS.one,
                strikeX,
                strikeTextY
              );

              // Draw strike point
              ctx.save();
              ctx.beginPath();
              ctx.setLineDash([1, 1]);
              ctx.moveTo(strikeX, topY);
              ctx.lineTo(strikeX, bottomY - SPACING.five);
              ctx.lineWidth = 1;
              ctx.strokeStyle = COLORS.white.four;
              ctx.stroke();
              ctx.restore();

              // Draw price point
              const priceDatasetIndex = c.chart.data.datasets.findIndex(
                (dataset: any) => dataset.label === "price"
              );
              const priceDataset = c.chart.data.datasets[priceDatasetIndex];

              const priceIndex = priceDataset.data.findIndex(
                (data: any) => data !== null
              );

              drawPricePoint(
                c,
                priceDataset.data[priceIndex],
                priceIndex,
                bottomY - SPACING.five
              );
            },
          },
        ]}
      />
    );
  }, [
    findNeighbourPoint,
    priceRange,
    strike,
    getData,
    options,
    drawStrikeLabel,
    asset,
    drawPricePoint,
  ]);

  return chart;
};

export default ProfitChart;
