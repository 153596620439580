import { AnimatePresence } from "framer-motion";
import {
  CSSProperties,
  MutableRefObject,
  PropsWithChildren,
  useMemo,
  useRef,
} from "react";
import { ModalProps } from "react-bootstrap";
import { createPortal } from "react-dom";
import useOutsideAlerter from "../../hooks/outsideAlerter/useOutsideAlerter";
import PageIndicator from "../PageIndicator";
import {
  ModalBackground,
  ModalBody,
  ModalContent,
  ModalFrame,
  ModalWrapper,
  PageIndicatorContainer,
  Title,
  ModalHeader,
  Content,
  CloseButton,
} from "./style";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
import useKeydownHandler, {
  KeydownHandlerKeyEnum,
} from "../../hooks/useKeydownHandler";
import { ContentType, ModalFooterContent } from "../ModalFooterContent";
import { PageType } from "../TradeForm/OptionsTradeForm";
import { PayoffType } from "../PayoffContent";

export interface IBaseModalProps extends ModalProps {
  title?: string | JSX.Element;
  style?: CSSProperties;
  noContentPadding?: boolean;
  animateTitle?: boolean;
  header?: boolean;
  modalRef?: MutableRefObject<null>;
  page?: {
    currentPage: number;
    totalPages: number;
  };
  footerContent?: {
    contentType: ContentType;
    showFooterContent: boolean;
    pageType?: PageType;
    setPage?: (pageType: PageType) => void;
    setPayoffType?: (payoffType: PayoffType) => void;
    showExtraModal?: boolean;
    setShowExtraModal?: (showExtraModal: boolean) => void;
    requireTopUp?: boolean;
  };
}

export function BaseModal({
  title,
  onHide,
  page,
  style,
  children,
  show,
  noContentPadding,
  animateTitle,
  header = true,
  modalRef,
  footerContent = undefined,
  ...others
}: PropsWithChildren<IBaseModalProps>) {
  const modalContentRef = useRef(null);
  useOutsideAlerter([modalRef || modalContentRef], onHide);
  useKeydownHandler(KeydownHandlerKeyEnum.ESC, onHide);

  const root = useMemo(() => {
    const ele = document.getElementById("root");
    return ele ?? undefined;
  }, []);

  if (root) {
    return createPortal(
      <AnimatePresence>
        {show && (
          <ModalWrapper {...others} show={show}>
            <ModalBackground
              transition={{
                duration: 0.4,
                ease: "easeInOut",
              }}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
            />
            <ModalBody
              style={style}
              ref={modalRef ?? modalContentRef}
              transition={{
                duration: 0.4,
                ease: "easeInOut",
              }}
              initial={{
                scale: 0.9,
                opacity: 0,
              }}
              animate={{
                scale: 1,
                opacity: 1,
              }}
              exit={{
                scale: 0.9,
                opacity: 0,
              }}
            >
              {/* FRAME */}
              <ModalFrame>
                <PageIndicatorContainer>
                  {page && page.totalPages > 1 && (
                    <PageIndicator
                      totalPages={page.totalPages}
                      currentPage={page.currentPage}
                    />
                  )}
                </PageIndicatorContainer>
              </ModalFrame>

              {/* CONTENT */}
              <ModalContent
                transition={{
                  duration: 0.2,
                  ease: "easeInOut",
                }}
                exit={{
                  scale: 0.9,
                  opacity: 0,
                }}
              >
                {header && (
                  <ModalHeader>
                    <AnimatePresence exitBeforeEnter>
                      <Title
                        key={
                          animateTitle && typeof title === "string" ? title : ""
                        }
                        transition={{
                          duration: 0.4,
                          ease: "easeInOut",
                        }}
                        initial={{
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                        }}
                        exit={{
                          opacity: 0,
                        }}
                      >
                        {title || ""}
                      </Title>
                    </AnimatePresence>
                    {onHide && (
                      <CloseButton onClick={onHide}>
                        <Close />
                      </CloseButton>
                    )}
                  </ModalHeader>
                )}
                <Content noPadding={noContentPadding}>{children}</Content>
              </ModalContent>
              {footerContent?.showFooterContent && (
                <ModalFooterContent
                  contentType={footerContent.contentType}
                  pageType={footerContent.pageType}
                  setPage={footerContent.setPage}
                  showExtraModal={footerContent.showExtraModal}
                  setShowExtraModal={footerContent.setShowExtraModal}
                  requireTopUp={footerContent.requireTopUp}
                />
              )}
            </ModalBody>
          </ModalWrapper>
        )}
      </AnimatePresence>,
      root
    );
  }
  return null;
}
