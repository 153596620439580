import React from "react";

type SVGProps = React.SVGAttributes<SVGElement>;

export const refresh = ({
  color,
  ...props
}: SVGProps & { color: string }): JSX.Element => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.66669 10.6667V14.6667H12.6667"
      stroke={color || "#00E100"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3333 21.3333V17.3333H19.3333"
      stroke={color || "#00E100"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.66 14C21.3219 13.0445 20.7473 12.1903 19.9897 11.517C19.2322 10.8436 18.3164 10.3732 17.3278 10.1495C16.3393 9.92584 15.3102 9.95624 14.3366 10.2379C13.3629 10.5195 12.4765 11.0432 11.76 11.76L8.66669 14.6667M23.3334 17.3333L20.24 20.24C19.5235 20.9569 18.6371 21.4805 17.6635 21.7622C16.6899 22.0438 15.6608 22.0742 14.6722 21.8505C13.6837 21.6268 12.7679 21.1564 12.0103 20.4831C11.2528 19.8097 10.6781 18.9555 10.34 18"
      stroke={color || "#00E100"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
