import React, { ReactElement, useState } from "react";
import { IToast } from "../../interfaces/Toast";

interface IToastContextType {
  toasts: IToast[];
  setToasts: (toasts: IToast[]) => void;
}

interface IToastContextProviderProps {
  children: ReactElement;
}

export const ToastContext = React.createContext<IToastContextType>({
  toasts: [],
  setToasts: () => {},
});

export function ToastContextProvider({ children }: IToastContextProviderProps) {
  const [toasts, setToasts] = useState<IToast[]>([]);

  return <ToastContext.Provider value={{ toasts, setToasts }}>{children}</ToastContext.Provider>;
}
