import { useContext } from "react";
import { Assets, UnderlyerAssetsList } from "../../../utils/assets";
import { PricesContext } from "../../../contexts/PricesContext";

const useAssetPriceData = () => {
  const useAssetPrice = ({ asset }: { asset: Assets } = { asset: "ETH" }) => {
    const contextData = useContext(PricesContext);

    return {
      price: contextData[asset].latestPrice,
      percentChange: contextData[asset].percentChange,
      loading: contextData[asset].loading,
    };
  };

  const useAssetsPrice = () => {
    const contextData = useContext(PricesContext);

    return {
      prices: Object.fromEntries(
        UnderlyerAssetsList.map((asset) => [
          asset,
          {
            price: contextData[asset].latestPrice,
            percentChange: contextData[asset].percentChange,
            loading: contextData[asset].loading,
          },
        ])
      ) as {
        [asset in Assets]: {
          price: number;
          percentChange: number;
          loading: boolean;
        };
      },
    };
  };

  return { useAssetPrice, useAssetsPrice };
};

export default useAssetPriceData;
