import styled, { css } from "styled-components";
import { BACKGROUND_COLORS, BORDER_COLORS, COLORS, ICON_COLORS, LAYER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";
import { FONT_CSS, FONT_SIZE } from "../../../constants/design/fontSize";

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${BACKGROUND_COLORS.five};
  z-index: 2;
`;

export const SvgContainer = styled.button<{ isOpen?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ isOpen }) => (isOpen ? COLORS.blue.six : LAYER_COLORS.one)};
  border: ${({ isOpen }) => (isOpen ? "none" : `1px solid ${BORDER_COLORS.one}`)};
  border-radius: 8px;
  min-width: ${COMPONENTS.xlButtonHeight}px;
  height: ${COMPONENTS.xlButtonHeight}px;
  &:hover {
    border: 1px solid
      ${({ isOpen }) => (isOpen ? COLORS.blue.one : COLORS.white.one)};
  }
`;

export const MobileSvgContainer = styled.button<{ isOpen?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isOpen }) => (isOpen && `background: ${COLORS.blue.six};`)}
  border-right: 1px solid ${BORDER_COLORS.two};
  min-width: ${COMPONENTS.footer.sub}px;
  height: ${COMPONENTS.footer.sub}px;
`;

export const MainFooterWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: ${SPACING.three}px;
  height: ${COMPONENTS.footer.main}px;
  background: ${BACKGROUND_COLORS.five};
`;

const textFormat = css`
  a {
    cursor: pointer;
    color: ${TEXT_COLORS.two};
    text-decoration: none;

    margin-right: ${SPACING.twoHalf}px;
    margin-left: ${SPACING.twoHalf}px;
  }
`;

export const MiddleFooterWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: ${FONT_SIZE.two};
  ${textFormat}
`;

export const MobileFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  height: ${COMPONENTS.footer.sub}px;
  border-top: 1px solid ${BORDER_COLORS.two};
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${TEXT_COLORS.two};
    text-decoration: none;
    height: 100%;
    width: calc((100vw - ${2 * COMPONENTS.footer.sub}px) / 2);
    text-align: center;
    &:not(:last-child) {
      border-right: 1px solid ${BORDER_COLORS.two};
    }
  }
`;

export const NavigationContainer = styled.div`
  height: ${COMPONENTS.footer.mobileNav}px;
  display: flex;
  border-top: 1px solid ${BORDER_COLORS.one};
  border-bottom: 1px solid ${BORDER_COLORS.one};

  > * {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:last-child) {
      border-right: 1px solid ${BORDER_COLORS.one};
    }
  }

  a {
    cursor: pointer;
    color: ${TEXT_COLORS.two};
    text-decoration: none;
    width: calc((100 - ${COMPONENTS.footer.sub} * 2) / 2);
    &:first-child {
      margin-left: ${SPACING.two}px;
    }
  }
`;

export const LinkText = styled.span<{ isActive?: boolean }>`
  ${FONT_CSS.header.six}
  transition: 0.2s ease-in-out;
  color: ${(props) => (props.isActive ? TEXT_COLORS.one : TEXT_COLORS.three)};
  svg {
    stroke: ${(props) => (props.isActive ? TEXT_COLORS.one : TEXT_COLORS.three)};
  }
`;

export const MobileMenuButton = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: ${SPACING.three}px;
  padding: 0 10px;

  &:before {
    content: "";
    border-radius: 100px;
    background-color: ${ICON_COLORS.three};
    width: 18px;
    height: 2px;
    margin-bottom: ${SPACING.one}px;
  }
  &:after {
    content: "";
    border-radius: 100px;
    background-color: ${ICON_COLORS.three};
    width: 18px;
    height: 2px;
  }
`;
