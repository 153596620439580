import {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import currency from "currency.js";
import { AnimatePresence } from "framer-motion";
import moment from "moment";
import { useForm, useWatch } from "react-hook-form";
import {
  ButtonsContainer,
  ChangePercent,
  Container,
  CurrentPrice,
  CurrentSpotRow,
  ExpiryTag,
  FilterContent,
  FilterRow,
  FilterSelections,
  Header,
  StrikeButtonsWrapper,
  Tab,
  Title,
} from "./style";
import { TradeInstrumentContext } from "../../contexts/TradeInstrumentContext";
import usePrevious from "../../hooks/usePrevious";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { shorthandTimeLeftToExpiry } from "../../utils/date";
import { Chevron } from "../shared/Chevron/style";
import { PageType } from "../TradeForm/OptionsTradeForm";
import { Spinner } from "../shared/Spinner";
import { TEXT_COLORS } from "../../constants/design/colors";
import useAssetPriceData from "../../hooks/api/prices/useAssetPriceData";
import { getNumberColor } from "../../utils/math";
import { getAssetPrecision } from "../../utils/assets";
import useOutsideAlerter from "../../hooks/outsideAlerter/useOutsideAlerter";
import { ConfirmButton, ReverseChevronButton } from "../Buttons/styles";
import { ExpiryTexts } from "../../interfaces/PreloadedStrikes";
import useScreenSize from "../../hooks/screenSize/useScreenSize";
import { OptionsDataContext } from "../../contexts/OptionsDataContext";
import useWallet from "../../hooks/wallet/useWallet";
import CustomStrikeContent from "./CustomStrikeContent";
import CustomExpiryContent from "./CustomExpiryContent";
import { OptionSide, OptionSideResponse } from "../../interfaces/Orders";

export interface IOptionFilterProps {
  page: PageType;
  additionalHeight: number;
  optionSide?: OptionSideResponse;
}

export enum FilterByEnum {
  Type = "Option Type",
  Expiry = "Expiry",
  Strike = "Strike Price",
  StrikeCustom = "Enter Custom Strike Price",
  ExpiryCustom = "Select Custom Expiry",
}

function OptionFilter({
  page,
  additionalHeight,
  optionSide,
}: PropsWithChildren<IOptionFilterProps>) {
  const {
    selectedValues,
    optionTypes,
    setOptionType,
    expiries,
    reversedExpiriesMap,
    setExpiry,
    strikes,
    setStrike,
    isManualExpiry,
    setManualStrikeExtraValues,
    setManualExpiryExtraValues,
    reset,
  } = useContext(TradeInstrumentContext);
  const { isMediumScreen } = useScreenSize();
  const [loading, setLoading] = useState<boolean>(false);
  const { account } = useWallet();
  const { whitelistedUsers } = useContext(OptionsDataContext);
  const [manualExpiry, setManualExpiry] = useState<number>();
  const isClientAddress = useMemo(
    () => (account ? whitelistedUsers.includes(account) : false),
    [account, whitelistedUsers]
  );

  const {
    register,
    control,
    reset: resetInput,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const registerStrike = register("strike", {
    required: true,
    validate: {},
  });

  const strikeInput = useWatch({ control, name: "strike" });
  const { useAssetPrice } = useAssetPriceData();
  const { price, percentChange } = useAssetPrice({
    asset: selectedValues.asset,
  });

  const optionFilterHeight = useMemo(() => {
    if (isMediumScreen) {
      return (
        COMPONENTS.marketModalMobileHeight
        + additionalHeight
        - COMPONENTS.modalHeaderHeight
        - COMPONENTS.smallButtonHeight
        - 4 * SPACING.three
        - (isClientAddress ? COMPONENTS.baseButtonHeight + SPACING.three : 0)
      );
    }
    return (
      (page === PageType.Payoff
        ? COMPONENTS.baseButtonHeight + SPACING.three
        : 0)
      + COMPONENTS.marketModalHeight
      + additionalHeight
      - COMPONENTS.modalHeaderHeight
      - COMPONENTS.smallButtonHeight
      - COMPONENTS.baseButtonHeight
      - 5 * SPACING.three
    );
  }, [additionalHeight, isClientAddress, isMediumScreen, page]);
  // check if inputed strikePrice is
  // greater than currentPrice multipled by upperBound
  // lower than currentPrice multiplied by lowerBOund
  const expiryStrikeOutOfBounds = useCallback(
    (expiry: ExpiryTexts, spotPrice: number, strikePrice: number) => {
      const expiryBounds = {
        [ExpiryTexts.Weekly]: {
          lowerBound: 0.5,
          upperBound: 2,
        },
        [ExpiryTexts.Biweekly]: {
          lowerBound: 0.3,
          upperBound: 2.5,
        },
        [ExpiryTexts.Triweekly]: {
          lowerBound: 0.3,
          upperBound: 2.5,
        },
        [ExpiryTexts.Monthly]: {
          lowerBound: 0.2,
          upperBound: 4,
        },
        [ExpiryTexts.NextMonth]: {
          lowerBound: 0.2,
          upperBound: 4,
        },
      };
      return (
        strikePrice > expiryBounds[expiry].upperBound * spotPrice
        || strikePrice < expiryBounds[expiry].lowerBound * spotPrice
      );
    },
    []
  );
  const isStrikeOutOfBounds = useMemo(() => {
    if (
      !selectedValues.expiry
      || !strikeInput
      || isManualExpiry
      || optionSide === OptionSide.Sell
    ) {
      return false;
    }
    // checks what expiryType is the expiry
    const expiryType = reversedExpiriesMap[selectedValues.expiry];
    return expiryStrikeOutOfBounds(expiryType, price, Number(strikeInput));
  }, [
    expiryStrikeOutOfBounds,
    isManualExpiry,
    optionSide,
    price,
    reversedExpiriesMap,
    selectedValues.expiry,
    strikeInput,
  ]);

  const [filteringBy, setFilteringBy] = useState<FilterByEnum>();
  const prevFilteringBy = usePrevious(filteringBy);

  const modalContentRef = useRef(null);

  useOutsideAlerter([modalContentRef], () => {
    setFilteringBy(undefined);
  });
  const onToggleFilter = useCallback(
    (filter: FilterByEnum) => {
      if (
        filteringBy === filter
        || filteringBy === FilterByEnum.StrikeCustom
        || filteringBy === FilterByEnum.ExpiryCustom
      ) {
        setFilteringBy(undefined);
      } else {
        setFilteringBy(filter);
      }
    },
    [filteringBy]
  );

  const handleSelectStrike = useCallback(async () => {
    if (filteringBy !== FilterByEnum.StrikeCustom) {
      setFilteringBy(FilterByEnum.StrikeCustom);
    } else {
      setLoading(true);
      await setManualStrikeExtraValues({
        underlyer: selectedValues.asset,
        expiry: String(selectedValues.expiry),
        strike: Number(strikeInput).toFixed(2),
        type: selectedValues.optionType,
        add_charges: true,
        side: OptionSide.Buy
      });
      setLoading(false);
      resetInput();
      setFilteringBy(undefined);
    }
  }, [
    filteringBy,
    resetInput,
    selectedValues.asset,
    selectedValues.expiry,
    selectedValues.optionType,
    setManualStrikeExtraValues,
    strikeInput,
  ]);

  const handleSelectExpiry = useCallback(async () => {
    if (filteringBy !== FilterByEnum.ExpiryCustom) {
      setFilteringBy(FilterByEnum.ExpiryCustom);
    } else {
      setLoading(true);
      if (!manualExpiry) {
        return;
      }
      if (expiries.includes(manualExpiry) || optionSide === OptionSide.Sell) {
        setExpiry(manualExpiry);
      } else {
        await setManualExpiryExtraValues({
          underlyer: selectedValues.asset,
          expiry: String(manualExpiry),
          strike: Number(strikeInput).toFixed(2),
          type: selectedValues.optionType,
          add_charges: true,
          side: OptionSide.Buy
        });
      }
      setLoading(false);
      resetInput();
      setFilteringBy(undefined);
    }
  }, [
    expiries,
    filteringBy,
    manualExpiry,
    optionSide,
    resetInput,
    selectedValues.asset,
    selectedValues.optionType,
    setExpiry,
    setManualExpiryExtraValues,
    strikeInput,
  ]);

  const filterSelections = useMemo(() => {
    if (filteringBy === FilterByEnum.Type) {
      return optionTypes.map((type) => (
        <FilterRow
          key={type}
          selected={selectedValues.optionType === type}
          onClick={() => {
            setOptionType(type);
            reset();
            setFilteringBy(undefined);
          }}
        >
          {type} Option
        </FilterRow>
      ));
    }
    if (filteringBy === FilterByEnum.Expiry) {
      return expiries.map((expiry) => (
        <FilterRow
          key={expiry}
          selected={selectedValues.expiry === expiry}
          onClick={() => {
            setExpiry(expiry);
            setFilteringBy(undefined);
          }}
        >
          <span>{moment.unix(expiry).format("DD MMM YYYY")}</span>
          <ExpiryTag>{shorthandTimeLeftToExpiry(expiry)}</ExpiryTag>
        </FilterRow>
      ));
    }
    if (filteringBy === FilterByEnum.Strike) {
      const strikeWithCurrent: {
        strike: string;
        isCurrent?: boolean;
      }[] = price
        ? [
          ...strikes.map((s) => ({
            strike: s,
            isCurrent: false,
          })),
          {
            strike: String(price),
            isCurrent: true,
          },
        ].sort((a, b) => Number(a.strike) - Number(b.strike))
        : strikes.map((s) => ({
          strike: s,
          isCurrent: false,
        }));
      return strikeWithCurrent.map(({ strike, isCurrent }) => (isCurrent ? (
        <CurrentSpotRow key={strike}>
          <span>Current {selectedValues.asset} Spot:</span>
          <CurrentPrice>
            {currency(strike, {
              precision: getAssetPrecision(selectedValues.asset),
            }).format()}
          </CurrentPrice>
          {percentChange && (
          <ChangePercent color={getNumberColor(percentChange)}>
            {percentChange > 0 ? "+" : ""}
            {percentChange.toFixed(2)}%
          </ChangePercent>
          )}
        </CurrentSpotRow>
      ) : (
        <FilterRow
          key={strike}
          selected={selectedValues.strike === String(strike)}
          onClick={() => {
            setStrike(String(strike));
            setFilteringBy(undefined);
          }}
        >
          <span>
            {currency(strike, {
              precision: getAssetPrecision(selectedValues.asset),
            }).format()}
          </span>
        </FilterRow>
      )));
    }
    if (filteringBy === FilterByEnum.StrikeCustom) {
      return (
        <CustomStrikeContent
          register={registerStrike}
          strike={strikeInput}
          errors={errors}
          customError={
            isStrikeOutOfBounds ? "Strike selected outside liquid range" : ""
          }
        />
      );
    }
    if (filteringBy === FilterByEnum.ExpiryCustom) {
      return <CustomExpiryContent setManualExpiry={setManualExpiry} />;
    }
    return null;
  }, [
    filteringBy,
    optionTypes,
    selectedValues.optionType,
    selectedValues.expiry,
    selectedValues.asset,
    selectedValues.strike,
    setOptionType,
    reset,
    expiries,
    setExpiry,
    price,
    strikes,
    percentChange,
    setStrike,
    registerStrike,
    strikeInput,
    errors,
    isStrikeOutOfBounds,
  ]);

  return (
    <Container ref={modalContentRef}>
      <Header>
        {(page === PageType.Payoff || isMediumScreen || isClientAddress) && (
          <Tab
            isActive={filteringBy === FilterByEnum.Type}
            onClick={() => onToggleFilter(FilterByEnum.Type)}
          >
            {selectedValues.optionType}
            <Chevron
              direction={filteringBy === FilterByEnum.Type ? "up" : "down"}
            />
          </Tab>
        )}
        <Tab
          isActive={filteringBy === FilterByEnum.Expiry}
          onClick={() => onToggleFilter(FilterByEnum.Expiry)}
        >
          {moment.unix(selectedValues.expiry!).format("DD MMM YY")}
          <Chevron
            direction={filteringBy === FilterByEnum.Expiry ? "up" : "down"}
          />
        </Tab>
        <Tab
          isActive={
            filteringBy === FilterByEnum.Strike || !selectedValues.strike
          }
          flashing={!selectedValues.strike}
          onClick={() => onToggleFilter(FilterByEnum.Strike)}
        >
          {selectedValues.strike
            ? currency(selectedValues.strike, {
              precision: getAssetPrecision(selectedValues.asset),
            }).format()
            : "Strike"}
          <Chevron
            direction={filteringBy === FilterByEnum.Strike ? "up" : "down"}
          />
        </Tab>
      </Header>
      <AnimatePresence>
        {Boolean(filteringBy) && (
          <FilterContent
            transition={{
              duration: 0.1,
              ease: "easeOut",
            }}
            initial={{ height: 0 }}
            animate={{
              height: optionFilterHeight,
            }}
            exit={{ height: 0 }}
          >
            <Title>
              {filteringBy !== FilterByEnum.StrikeCustom && "Select"}{" "}
              {filteringBy || prevFilteringBy}
            </Title>
            <FilterSelections>{filterSelections}</FilterSelections>
            <ButtonsContainer>
              <StrikeButtonsWrapper>
                {filteringBy === FilterByEnum.Strike && (
                  <ConfirmButton onClick={handleSelectStrike}>
                    Select Custom Price{" "}
                  </ConfirmButton>
                )}
                {filteringBy === FilterByEnum.StrikeCustom && (
                  <>
                    <ReverseChevronButton
                      role="button"
                      onClick={() => {
                        setFilteringBy(FilterByEnum.Strike);
                        resetInput();
                      }}
                    >
                      <Chevron direction="left" />
                    </ReverseChevronButton>
                    <ConfirmButton
                      disabled={
                        loading
                        || !strikeInput
                        || Number(strikeInput) <= 0
                        || isStrikeOutOfBounds
                      }
                      onClick={() => handleSelectStrike()}
                    >
                      {loading ? (
                        <Spinner color={TEXT_COLORS.two} />
                      ) : (
                        "Confirm"
                      )}
                    </ConfirmButton>
                  </>
                )}
                {filteringBy === FilterByEnum.Expiry && isClientAddress && (
                  <ConfirmButton onClick={handleSelectExpiry}>
                    Select Custom Expiry{" "}
                  </ConfirmButton>
                )}
                {filteringBy === FilterByEnum.ExpiryCustom && (
                  <>
                    <ReverseChevronButton
                      role="button"
                      onClick={() => {
                        setFilteringBy(FilterByEnum.Expiry);
                        resetInput();
                      }}
                    >
                      <Chevron direction="left" />
                    </ReverseChevronButton>
                    <ConfirmButton
                      disabled={loading || !manualExpiry}
                      onClick={handleSelectExpiry}
                    >
                      {loading ? (
                        <Spinner color={TEXT_COLORS.two} />
                      ) : (
                        "Confirm"
                      )}
                    </ConfirmButton>
                  </>
                )}
              </StrikeButtonsWrapper>
            </ButtonsContainer>
          </FilterContent>
        )}
      </AnimatePresence>
    </Container>
  );
}

export default OptionFilter;
