import { useEffect, useMemo, useState } from "react";
import { ReactComponent as Success } from "../../../assets/svg/big-success.svg";
import { ReactComponent as Failure } from "../../../assets/svg/failure.svg";
import { COLORS } from "../../../constants/design/colors";
import { longhandTimeLeftToExpiry } from "../../../utils/date";
import AnimatedCircle from "./AnimatedCircle";
import {
  CircleAbsoluteWrapper,
  Container,
  Detail,
  TextContainer,
  Title,
} from "./style";

interface ICircularProgressBarProps {
  size: number;
  strokeWidth: number;
  timeNow?: number;
  startTime?: number;
  endTime?: number;
  isOrderConfirmationTimeWindow?: boolean;
  detail?: string;
  type: "success" | "failure" | "pending";
}

function CircularProgressBar({
  size,
  strokeWidth,
  timeNow,
  startTime,
  endTime,
  isOrderConfirmationTimeWindow = false,
  detail = "",
  type,
}: ICircularProgressBarProps) {
  const [currentTimeLeft, currentPercentage] = useMemo(() => {
    if (!endTime || !startTime || !timeNow) {
      return ["0", 0];
    }
    const duration = endTime - startTime; // duration in seconds
    const elapsed = timeNow - startTime; // elapsed time in seconds
    return [longhandTimeLeftToExpiry(endTime), (elapsed / duration) * 100]; // percentage calculation
  }, [endTime, startTime, timeNow]);
  const [percentage, setPercentage] = useState<number>(currentPercentage);
  const [timeLeft, setTimeLeft] = useState<string>(currentTimeLeft);

  // initial state setter
  useEffect(() => {
    setPercentage(currentPercentage > 100 ? 100 : currentPercentage);
    setTimeLeft(currentTimeLeft);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage(currentPercentage > 100 ? 100 : currentPercentage);
      setTimeLeft(currentTimeLeft);
    }, 1000);
    return () => clearInterval(interval);
  }, [currentPercentage, currentTimeLeft]);

  const content = useMemo(() => {
    if (type === "success") {
      return <Success />;
    }
    if (type === "failure") {
      return <Failure />;
    }
    return (
      <>
        <Title>{timeLeft}</Title>
        <Detail>{detail}</Detail>
      </>
    );
  }, [detail, timeLeft, type]);

  const [circleColor, secondaryCircleColor, tertiaryColor] = useMemo(() => {
    const colorsPositive = [COLORS.positive.one, COLORS.positive.five, COLORS.positive.six];
    const colorsNeutral = [COLORS.white.one, COLORS.white.five, "none"];
    const colorsNegative = [COLORS.negative.one, COLORS.negative.five, COLORS.negative.six];
    if (type === "success" || isOrderConfirmationTimeWindow!!) {
      return colorsPositive;
    }
    if (type === "failure") {
      return colorsNegative;
    }
    return colorsNeutral;
  }, [isOrderConfirmationTimeWindow, type]);

  return (
    <Container>
      <CircleAbsoluteWrapper>
        <AnimatedCircle
          size={size}
          strokeWidth={strokeWidth}
          percentage={type === "success" || type === "failure" ? 100 : percentage}
          color={circleColor}
          secondaryColor={secondaryCircleColor}
          tertiaryColor={tertiaryColor}
        />
      </CircleAbsoluteWrapper>
      <TextContainer>{content}</TextContainer>
    </Container>
  );
}

export default CircularProgressBar;
