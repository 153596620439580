import styled from "styled-components";
import { COLORS } from "../../../constants/design/colors";
import { FONT_SIZE, FONT_STYLE } from "../../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";

export const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  margin-top: ${SPACING.three}px;
`;

export const CircleAbsoluteWrapper = styled.div`
  position: absolute;
  width: ${COMPONENTS.progressCircleDimension}px;
  height: ${COMPONENTS.progressCircleDimension}px;
`;

export const Title = styled.div`
  font-size: ${FONT_SIZE.three};
`;

export const Detail = styled.div`
  margin-top: ${SPACING.two}px;
  padding: 0 ${SPACING.three}px;
  ${FONT_STYLE.label.three};
  text-align: center;
  color: ${COLORS.white.four};
`;

export const TextContainer = styled.div`
  width: ${COMPONENTS.progressCircleDimension}px;
  height: ${COMPONENTS.progressCircleDimension}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
