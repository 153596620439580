import currency from "currency.js";
import { useMemo } from "react";
import {
  getProfitBackgroundColor,
  getProfitTextColor,
} from "../../../utils/strings";
import { PerformanceWrapper, Label, PerformanceText, RoiText } from "./style";

interface IUnfilledOrderModalProps {
  title: string;
  performance: number;
  roi?: number;
}

function PerformanceContent({
  title,
  performance,
  roi,
}: IUnfilledOrderModalProps) {
  const pnlContent = useMemo(
    () => (
      <PerformanceWrapper color={getProfitBackgroundColor(performance)}>
        <div>
          <Label>{title}</Label>
          <PerformanceText color={getProfitTextColor(performance)}>
            {currency(performance).format()}
          </PerformanceText>
        </div>
        {roi && (
          <div>
            <Label>ROI</Label>
            <RoiText color={getProfitTextColor(roi)}>
              {performance >= 0 ? "+" : ""}
              {`${roi}%`}
            </RoiText>
          </div>
        )}
      </PerformanceWrapper>
    ),
    [performance, title, roi]
  );

  return pnlContent;
}

export default PerformanceContent;
