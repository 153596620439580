import currency from "currency.js";
import { useForm, useWatch } from "react-hook-form";
import { parseUnits } from "ethers/lib/utils";
import { ethers } from "ethers";
import { useState, useMemo, MutableRefObject, useCallback } from "react";
import { BaseModal } from "../../BaseModal";
import { BottomText, InputHeaderWrapper } from "../RequestPositionModal/style";
import { Input } from "../../shared/Input";
import {
  ButtonsContainer,
  InfoDetail,
  InfoRow,
  InputContainer,
  InputError,
  InputLabel,
  MinMaxButton,
  PageContentContainer,
} from "./style";
import useMarginRequirement from "../../../hooks/contracts/marginRequirement/useMarginRequirement";
import useUSDC from "../../../hooks/contracts/usdc/useUSDC";
import { useToast } from "../../../hooks/toast";
import useWallet from "../../../hooks/wallet/useWallet";
import { IInfoRowCol } from "../../../interfaces/InfoRow";
import { ModalButtonV2 } from "../../Buttons/styles";
import { Spinner } from "../../shared/Spinner";
import { COLORS } from "../../../constants/design/colors";
import { ActionType } from "../../MarginHealthContent";
import useSignature from "../../../hooks/contracts/usdc/useSignature";
import { ToastEnum, ToastStatusEnum } from "../../../utils/toast";
import { IOrder } from "../../../interfaces/Orders";
import useOrder, {
  IUpdateMMCollateralBody,
} from "../../../hooks/api/order/mm/useOrder";
import { IPermitStruct } from "../../../interfaces/Signing";
import { isProduction } from "../../../utils/env";
import { getProfitTextColor } from "../../../utils/strings";
import useSetOraclePrice from "../../../hooks/api/prices/useSetOraclePrice";
import useOtcWrapper from "../../../hooks/contracts/otcWrapper/useOtcWrapper";

interface ICollateralModalProps {
  order: IOrder;
  title: string;
  actionType: ActionType;
  showCollateralModal: boolean;
  setShowCollateralModal: (showCollateralModal: boolean) => void;
  modalRef: MutableRefObject<null>;
  onHide: () => void;
}

function CollateralModal({
  order,
  title,
  actionType,
  showCollateralModal,
  setShowCollateralModal,
  modalRef,
  onHide,
}: ICollateralModalProps) {
  const {
    register,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
    control,
  } = useForm({
    mode: "onChange",
  });
  const { account } = useWallet();
  const { addToast, addErrorToast } = useToast();
  const { handleSetOraclePrice } = useSetOraclePrice();
  const [loading, setLoading] = useState(false);
  const {
    asset,
    orderId,
    performance,
    marginPosted,
    marginRequirement,
    withdrawableCollateral,
  } = order;
  const amount: string | undefined = useWatch({ control, name: "amount" });
  const { updateMMCollateral } = useOrder();
  const {
    data: { maintenanceMargin, checkWithdrawCollateral },
  } = useMarginRequirement({ orderId, withdrawAmount: withdrawableCollateral });
  const { contract } = useOtcWrapper();

  const usdc = useUSDC();
  const usdcBalance = useMemo(
    () => (usdc.userBalance
      ? ethers.utils.formatUnits(usdc.userBalance, usdc.decimals)
      : undefined),
    [usdc.decimals, usdc.userBalance]
  );

  // check that maintenanceMargin set on contract is lte marginRequirement from backend
  // and checkWithdrawCollateral from contract is true
  const canWithdrawCollateral = useMemo(() => {
    if (actionType !== "Withdraw") {
      return true;
    }
    if (!maintenanceMargin || !marginRequirement) {
      return false;
    }
    return (
      maintenanceMargin.lte(parseUnits(marginRequirement, usdc.decimals))
      && checkWithdrawCollateral
    );
  }, [
    actionType,
    checkWithdrawCollateral,
    maintenanceMargin,
    marginRequirement,
    usdc.decimals,
  ]);

  const additionalMarginRequired = useMemo(() => {
    const difference = Number(marginRequirement) - Number(marginPosted);
    return difference > 0 ? difference : 0;
  }, [marginPosted, marginRequirement]);

  const onMinMaxClick = useCallback(() => {
    clearErrors();
    if (actionType === "Post") {
      setValue("amount", Number(additionalMarginRequired || 0));
    } else {
      setValue("amount", Number(withdrawableCollateral || 0));
    }
  }, [
    clearErrors,
    actionType,
    setValue,
    additionalMarginRequired,
    withdrawableCollateral,
  ]);

  const {
    permitting,
    handlePermit,
    approvedAmountAndSignature,
    hasValidSignature,
  } = useSignature({
    amount: String(amount || 0),
  });

  const infoRows: IInfoRowCol[] = useMemo(() => {
    const rows = [];
    rows.push({
      title: "Margin Posted",
      detail: marginPosted ? currency(marginPosted).format() : "-",
    });
    rows.push({
      title: "Margin Required",
      detail: marginRequirement ? currency(marginRequirement).format() : "-",
    });
    if (actionType === "Post") {
      rows.push({
        title: "Additional Margin Required",
        detail: withdrawableCollateral
          ? currency(additionalMarginRequired).format()
          : "-",
      });
    }
    rows.push({
      title: "Unrealized PNL",
      detail: performance ? currency(performance).format() : "-",
      color: getProfitTextColor(performance ? Number(performance) : 0),
    });
    if (actionType === "Withdraw") {
      rows.push({
        title: "Withdrawable Collateral",
        detail: withdrawableCollateral
          ? currency(withdrawableCollateral).format()
          : "-",
      });
    }
    return rows;
  }, [
    actionType,
    additionalMarginRequired,
    marginPosted,
    marginRequirement,
    performance,
    withdrawableCollateral,
  ]);

  const handleContractCall = useCallback(async () => {
    const { approvedAmount, deadline, signature } = approvedAmountAndSignature;
    try {
      setLoading(true);
      if (actionType === "Post") {
        if (contract && signature && approvedAmount && deadline && account) {
          if (isProduction()) {
            await contract.depositCollateral(
              String(orderId),
              parseUnits(approvedAmount, usdc.decimals),
              {
                amount: parseUnits(approvedAmount, usdc.decimals),
                deadline: String(deadline),
                acct: account!,
                v: String(signature.v),
                r: signature.r,
                s: signature.s,
              } as IPermitStruct
            );
          }
          await updateMMCollateral({
            order_id: Number(orderId),
            collateral: approvedAmount,
            action_type: "post",
          } as IUpdateMMCollateralBody);
          addToast(
            {
              type: ToastEnum.SIMPLE,
              header: <p>Collateral Posted</p>,
              subheader: <span>{approvedAmount} posted</span>,
              status: ToastStatusEnum.SUCCESS,
            },
            10000
          );
        } else {
          throw Error;
        }
      } else if (account && amount && contract) {
        if (isProduction()) {
          await handleSetOraclePrice(asset);
          await contract.withdrawCollateral(
            String(orderId),
            parseUnits(amount, usdc.decimals)
          );
        }
        await updateMMCollateral({
          order_id: Number(orderId),
          collateral: amount,
          action_type: "withdraw",
        } as IUpdateMMCollateralBody);
        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: <p>Collateral Withdrawn</p>,
            subheader: <span>{amount} withdrawn</span>,
            status: ToastStatusEnum.SUCCESS,
          },
          10000
        );
      } else {
        throw Error;
      }
      setLoading(false);
      onHide();
    } catch (error) {
      setLoading(false);
      onHide();
      throw Error;
    }
  }, [
    account,
    actionType,
    addToast,
    amount,
    approvedAmountAndSignature,
    asset,
    contract,
    handleSetOraclePrice,
    onHide,
    orderId,
    updateMMCollateral,
    usdc.decimals,
  ]);

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      if (!hasValidSignature && actionType === "Post") {
        await handlePermit();
      } else {
        await handleContractCall();
      }
      setLoading(false);
    } catch (error) {
      addErrorToast("Something went wrong", "Please try again");
      setShowCollateralModal(false);
    }
  }, [
    hasValidSignature,
    actionType,
    handlePermit,
    handleContractCall,
    addErrorToast,
    setShowCollateralModal,
  ]);

  const buttonText = useMemo(() => {
    if (loading || permitting) {
      return <Spinner color={COLORS.white.one} />;
    }
    if (!hasValidSignature && actionType === "Post") {
      return "Permit USDC";
    }
    return title;
  }, [actionType, hasValidSignature, loading, permitting, title]);

  return (
    <BaseModal
      onHide={() => {
        setShowCollateralModal(false);
        reset();
      }}
      show={showCollateralModal}
      title={title}
      modalRef={modalRef}
    >
      <PageContentContainer>
        {canWithdrawCollateral && (
          <InputContainer>
            <InputHeaderWrapper>
              <InputLabel>Amount</InputLabel>
              <InputLabel>
                Wallet Balance:{" "}
                {usdcBalance
                  ? currency(usdcBalance, {
                    symbol: "",
                  }).format()
                  : "-"}{" "}
              </InputLabel>
            </InputHeaderWrapper>
            {actionType === "Post" && (
              <>
                <Input
                  placeholder="0.00"
                  type="number"
                  disabled={loading}
                  rightAccessory={
                    <MinMaxButton onClick={onMinMaxClick} type="button">
                      Min
                    </MinMaxButton>
                  }
                  {...register("amount", {
                    required: true,
                    validate: {
                      moreThanZero: (v) => parseFloat(v) > 0,
                      lessThanEqualToBalance: (v) => parseFloat(v) <= Number(usdcBalance || 0),
                    },
                  })}
                  error={Boolean(Object.keys(errors).length)}
                />
                {errors.amount?.type === "required" && (
                  <InputError>Amount is required</InputError>
                )}
                {errors.amount?.type === "moreThanZero" && (
                  <InputError>Amount must be more than zero</InputError>
                )}
                {errors.amount?.type === "lessThanEqualToBalance" && (
                  <InputError>Insufficient USDC Balance</InputError>
                )}
              </>
            )}
            {actionType === "Withdraw" && canWithdrawCollateral && (
              <>
                <Input
                  placeholder="0.00"
                  type="number"
                  disabled={loading}
                  rightAccessory={
                    <MinMaxButton onClick={onMinMaxClick} type="button">
                      Max
                    </MinMaxButton>
                  }
                  {...register("amount", {
                    required: true,
                    validate: {
                      moreThanZero: (v) => parseFloat(v) > 0,
                      lessThanEqualToBalance: (v) => parseFloat(v) <= (Number(withdrawableCollateral) || 0),
                    },
                  })}
                  error={Boolean(Object.keys(errors).length)}
                />
                {errors.amount?.type === "required" && (
                  <InputError>Amount is required</InputError>
                )}
                {errors.amount?.type === "moreThanZero" && (
                  <InputError>Amount must be more than zero</InputError>
                )}
                {errors.amount?.type === "lessThanEqualToBalance" && (
                  <InputError>
                    Amount is greater than withdrawable balance
                  </InputError>
                )}
              </>
            )}
          </InputContainer>
        )}
        {infoRows.map((infoRow, i) => (
          <InfoRow paddingTop={i === 0 ? 0 : undefined} key={infoRow.title}>
            <span>{infoRow.title} </span>
            <InfoDetail color={infoRow.color}>{infoRow.detail}</InfoDetail>
          </InfoRow>
        ))}
        {canWithdrawCollateral ? (
          <ButtonsContainer>
            <ModalButtonV2
              type={"button"}
              onClick={() => handleSubmit()}
              disabled={loading || !amount || Boolean(errors?.amount?.type)}
              style={{ flex: 1 }}
            >
              {buttonText}
            </ModalButtonV2>
          </ButtonsContainer>
        ) : (
          <BottomText>
            You cannot withdraw collateral at this moment. Please contact aevo
            team.
          </BottomText>
        )}
      </PageContentContainer>
    </BaseModal>
  );
}

export default CollateralModal;
