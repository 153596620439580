import React, { useCallback, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { OverlayTriggerRenderProps } from "react-bootstrap/esm/OverlayTrigger";
import { Explanation, PopoverContainer, Title } from "./style";

interface ITooltipExplanationProps {
  title?: string;
  explanation: React.ReactNode;
  renderContent: (
    props: OverlayTriggerRenderProps & React.HTMLAttributes<HTMLElement>
  ) => React.ReactNode;
}

function TooltipExplanation({
  title,
  explanation,
  renderContent,
}: ITooltipExplanationProps) {
  const [show, setShow] = useState(false);

  const onMouseEnter = useCallback(() => {
    setShow(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <OverlayTrigger
      placement="bottom"
      show={show}
      overlay={(props) => (
        <PopoverContainer
          {...props}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {
            title && <Title>{title}</Title>
          }
          <Explanation>
            {explanation}
          </Explanation>
        </PopoverContainer>
      )}
    >
      {(props) => renderContent({
        ...props,
        onMouseEnter,
        onMouseLeave,
      })}
    </OverlayTrigger>
  );
}

export default TooltipExplanation;
