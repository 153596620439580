import React, { useContext } from "react";
import { StaticJsonRpcProvider } from "@ethersproject/providers";

export type Web3ContextData = {
  baseProvider: StaticJsonRpcProvider;
};

const defaultProvider = new StaticJsonRpcProvider(
  process.env.REACT_APP_MAINNET_URI || ""
);
export const Web3Context = React.createContext<Web3ContextData>({
  baseProvider: defaultProvider,
});

export const useWeb3Context = () => useContext(Web3Context);
