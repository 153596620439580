import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  TEXT_COLORS,
} from "../constants/design/colors";
import { FONT_SIZE } from "../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../constants/design/spacing";

export const Title = styled.div`
  font-size: ${FONT_SIZE.two};
  margin-bottom: ${SPACING.three}px;
`;

export const TabWrapper = styled.div`
  position: absolute;
  left: ${SPACING.three}px;
  top: 50%;
  transform: translateY(-50%);
`;

export const TabFooterWrapper = styled(motion.div)`
  border-top: 1px solid ${BORDER_COLORS.one};

  button {
    &:not(:first-child) {
      margin-top: ${SPACING.three}px;
    }
  }
`;

export const TabLinkWrapper = styled(Link)<{
  $isActive: boolean;
}>`
  transition: 0.2s ease-in-out;
  height: ${COMPONENTS.sideNavigatorHeight}px;
  display: flex;
  align-items: center;
  padding-right: ${SPACING.two}px;
  font-size: ${FONT_SIZE.two};
  color: ${({ $isActive }) => ($isActive ? TEXT_COLORS.one : TEXT_COLORS.three)};
  text-decoration: none !important;
  margin: auto 0;
  border-radius: 8px;

  &:hover {
    color: ${TEXT_COLORS.one};
  }
`;

export const TabImageWrapper = styled.div`
  display: flex;
  background: ${BACKGROUND_COLORS.four};
  border-radius: 8px;
  margin-right: ${SPACING.two}px;
  height: ${COMPONENTS.xlButtonHeight}px;
  width: ${COMPONENTS.xlButtonHeight}px;
`;

export const TabImage = styled.img<{ isActive: boolean }>`
  display: flex;
  width: 16px;
  height: 16px;
  margin: auto;

  opacity: ${({ isActive }) => (isActive ? "1" : "0.48")};
  transition: 0.2s ease-in-out;
`;

export const ContentsWrapper = styled.div<{ isMobileScreen?: boolean }>`
  display: flex;
  width: 100%;
  padding-top: ${SPACING.three}px;
  justify-content: center;
`;

export const InnerWrapper = styled.div<{ isMobileScreen?: boolean }>`
  height: 100%;
  ${({ isMobileScreen }) => isMobileScreen && "width: 100%;"}
  overflow: auto;
`;

export const EmptyContent = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: ${TEXT_COLORS.one};
  font-size: ${FONT_SIZE.two};
`;
