import styled from "styled-components";
import { TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";
import { FullWidthButton } from "../../Buttons/styles";

export const HeaderSubText = styled.p`
  color: ${TEXT_COLORS.two};
  font-size: ${FONT_SIZE.two};
  margin-bottom: 0;
  padding: ${SPACING.three}px;
  padding-bottom: 0;

  a {
    text-decoration: none;
    color: ${TEXT_COLORS.one};
  }
`;

export const StepButton = styled(FullWidthButton)`
  margin: ${SPACING.three}px 0;
`;
