import { motion } from "framer-motion";
import styled, { css } from "styled-components";

export type WidthType = "fullWidth" | "maxContent";

export const SegmentControlContainer = styled.div<{
  color?: string;
  backgroundColor?: string;
  flexDirection?: string;
  widthType: WidthType;
}>`
  background-color: ${(props) => {
    if (props.backgroundColor) {
      return props.backgroundColor;
    }

    if (props.color) {
      return `${props.color}14`;
    }

    return "";
  }};
  display: flex;
  position: relative;
  ${(props) => props.flexDirection && `flex-direction: ${props.flexDirection}`};
  ${(props) => {
    switch (props.widthType) {
      case "fullWidth":
        return `
          flex: 1;
        `;
      default:
        return "";
    }
  }}

  overflow-y: auto;

  /* Firefox */
  scrollbar-width: none;

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    height: 0px;
  }
`;

export const SegmentControlButton = styled.div.attrs({
  className: "d-flex align-items-center justify-content-center",
})<{
  widthType: WidthType;
  px?: number;
  py?: number;
  width?: number;
  height?: number;
  backgroundColor?: string;
  disabled?: boolean;
}>`
  padding: ${(props) => `${props.py || 0}px ${props.px || 0}px`};
  width: ${({ width }) => (width === undefined ? undefined : `${width}px`)};
  height: ${({ height }) => (height === undefined ? undefined : `${height}px`)};
  z-index: 1;
  transition: 0.25s background-color ease-in;
  background-color: ${({ backgroundColor }) => backgroundColor};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};

  &:last-child {
    margin-right: 0px;
  }

  ${(props) => {
    switch (props.widthType) {
      case "fullWidth":
        return `
          display: flex;
          flex: 1;
          justify-content: center;
        `;
      default:
        return "";
    }
  }}
`;

export const SegmentControlButtonText = styled.span<{ color?: string }>`
  white-space: nowrap;
  ${({ color }) => {
    if (color) {
      return css`
        color: ${color};
      `;
    }
    return "";
  }}
`;

export const ActiveBackground = styled(motion.div)<{
  color?: string;
  theme: "outline" | "default";
}>`
  position: absolute;
  z-index: 1;
  ${(props) => {
    if (props.theme === "outline") {
      return `
        border: 1px solid ${props.color ? props.color : "white"};
      `;
    }
    return `
      background-color: ${props.color ? props.color : "transparent"};
    `;
  }}
`;
