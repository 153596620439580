import { useCallback, useMemo } from "react";
import currency from "currency.js";
import { ReactComponent as Close } from "../../../../../assets/svg/close.svg";
import CircularProgressBar from "../../../../shared/CircularProgressBar";
import {
  InfoRow,
  HeaderContainer,
  AssetHeaderWrapper,
  InfoDetail,
  Form,
} from "../../style";
import { IOrder } from "../../../../../interfaces/Orders";
import { LargeModalButtonV2 } from "../../../../Buttons/styles";
import AssetWrapper from "../../../../shared/AssetWrapper";
import { fullExpiryTime } from "../../../../../utils/date";
import { COLORS } from "../../../../../constants/design/colors";
import { getAssetIndicPrecision, getAssetPrecision } from "../../../../../utils/assets";
import { IInfoRowCol } from "../../../../../interfaces/InfoRow";

interface IRequestModalProps {
  order: IOrder;
  setShowModal: (show: boolean) => void;
}

function UnmatchedRequestModal({ order, setShowModal }: IRequestModalProps) {
  const {
    contracts,
    optionType,
    expiry,
    strike,
    asset,
    offerPrice,
    winningOffer,
    reason,
    isUnwindOrder,
  } = order;

  const onHide = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const infoRows: IInfoRowCol[] = useMemo(() => {
    const rows = [];
    if (reason) {
      rows.push({
        title: "Outcome",
        detail: reason,
        color: COLORS.negative.one,
      });
    }
    if (isUnwindOrder) {
      rows.push({
        title: "Order Type",
        detail: "Unwind",
        color: COLORS.blue.one,
      });
    }
    rows.push({
      title: "Option Type",
      detail: `European ${optionType.replace(/^\w/, (c) => c.toUpperCase())}`,
    });
    rows.push({
      title: "Expiry",
      detail: `${fullExpiryTime(expiry)}`,
    });
    rows.push({
      title: "Strike",
      detail: `${strike}`,
    });
    rows.push({
      title: "Offer Price",
      detail: offerPrice
        ? `${currency(parseFloat(offerPrice), {
          precision: getAssetIndicPrecision(asset),
        }).format()}`
        : "-",
    });
    rows.push({
      title: "Winning Offer",
      detail: winningOffer ? `${currency(winningOffer).format()}` : "-",
      color: winningOffer ? COLORS.negative.one : undefined,
    });
    rows.push({
      title: "Contracts",
      detail: `${contracts}`,
    });
    return rows;
  }, [reason, isUnwindOrder, optionType, expiry, strike, offerPrice, asset, winningOffer, contracts]);

  const headerContent = useMemo(
    () => (
      <>
        <AssetHeaderWrapper>
          <AssetWrapper asset={asset} />
        </AssetHeaderWrapper>
        <LargeModalButtonV2 type={"button"} onClick={onHide}>
          <Close />
        </LargeModalButtonV2>
      </>
    ),
    [onHide, asset]
  );

  return (
    <Form onSubmit={() => {}}>
      <HeaderContainer>{headerContent}</HeaderContainer>
      <CircularProgressBar size={160} strokeWidth={4} type={"failure"} />
      {infoRows.map((infoRow) => (
        <InfoRow key={infoRow.title}>
          <span>{infoRow.title}</span>
          <InfoDetail color={infoRow.color}>{infoRow.detail}</InfoDetail>
        </InfoRow>
      ))}
    </Form>
  );
}

export default UnmatchedRequestModal;
