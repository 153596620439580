import useSWR from "swr";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { defaultPreloadedStrikes } from "../../../contexts/OptionsDataContext";
import {
  PreloadedStrikes,
  PreloadedStrikesResponse,
} from "../../../interfaces/PreloadedStrikes";
import { GET_PRELOADED_STRIKES_URL } from "../../../constants/endpoints/endpoints";

const useFetchPreloadedStrikes = () => {
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<PreloadedStrikesResponse>(
    defaultPreloadedStrikes
  );
  const apiURL = GET_PRELOADED_STRIKES_URL;
  const fetcher = (url: string) => axios.get(url).then((res) => res.data);
  const swr = useSWR<PreloadedStrikes>(apiURL, fetcher);
  const { data: preloadedStrikes, error: swrError } = swr;

  const fetchPreloadedStrikes = useCallback(async () => {
    // if (!preloadedStrikes || swrError) {
    //   // API call hasn't completed yet or error, return early
    //   return;
    // }
    setData({
      loading: false,
      preloadedStrikes: {
        expiries: {
          weekly: 1702022400,
          biweekly: 1702627200,
          monthly: 1703836800,
          next_month: 1706256000,
        },
        data: [
          {
            underlyer: "BTC",
            spot_ref: "41611.06",
            prices: {
              weekly: {
                call: [
                  {
                    strike: "41500.00",
                    iv: "48.5",
                    delta: "0.5442",
                    theta: "-113.4374",
                    vega: "14.5761",
                    indic: "864.3981630463568",
                  },
                  {
                    strike: "42000.00",
                    iv: "48.0",
                    delta: "0.4216",
                    theta: "-110.8935",
                    vega: "14.3822",
                    indic: "623.3474653222693",
                  },
                  {
                    strike: "42500.00",
                    iv: "48.8",
                    delta: "0.3101",
                    theta: "-101.5261",
                    vega: "12.9723",
                    indic: "446.29033378944587",
                  },
                  {
                    strike: "43000.00",
                    iv: "50.4",
                    delta: "0.2219",
                    theta: "-88.5268",
                    vega: "10.9395",
                    indic: "322.1183164429822",
                  },
                  {
                    strike: "43500.00",
                    iv: "52.7",
                    delta: "0.1579",
                    theta: "-75.0188",
                    vega: "8.8688",
                    indic: "236.34340671245263",
                  },
                  {
                    strike: "44000.00",
                    iv: "55.4",
                    delta: "0.1133",
                    theta: "-62.7381",
                    vega: "7.0614",
                    indic: "176.71716719817232",
                  },
                  {
                    strike: "44500.00",
                    iv: "58.2",
                    delta: "0.0825",
                    theta: "-52.2698",
                    vega: "5.5944",
                    indic: "134.59105496508255",
                  },
                ],
                put: [
                  {
                    strike: "41500.00",
                    iv: "48.5",
                    delta: "-0.4558",
                    theta: "-113.4374",
                    vega: "14.5761",
                    indic: "715.8881630463547",
                  },
                  {
                    strike: "41000.00",
                    iv: "50.1",
                    delta: "-0.3413",
                    theta: "-108.4746",
                    vega: "13.4898",
                    indic: "521.5657130062209",
                  },
                  {
                    strike: "40500.00",
                    iv: "52.7",
                    delta: "-0.2481",
                    theta: "-98.3093",
                    vega: "11.6353",
                    indic: "381.86913496865236",
                  },
                  {
                    strike: "40000.00",
                    iv: "55.8",
                    delta: "-0.1787",
                    theta: "-86.0268",
                    vega: "9.6021",
                    indic: "282.9504081933319",
                  },
                  {
                    strike: "39500.00",
                    iv: "59.4",
                    delta: "-0.1291",
                    theta: "-73.7932",
                    vega: "7.7405",
                    indic: "212.6604047484634",
                  },
                  {
                    strike: "39000.00",
                    iv: "63.2",
                    delta: "-0.0942",
                    theta: "-62.6715",
                    vega: "6.1761",
                    indic: "162.10400205411952",
                  },
                  {
                    strike: "38500.00",
                    iv: "67.2",
                    delta: "-0.0696",
                    theta: "-53.0147",
                    vega: "4.9150",
                    indic: "125.19593968357731",
                  },
                ],
              },
              biweekly: {
                call: [
                  {
                    strike: "41500.00",
                    iv: "51.1",
                    delta: "0.5450",
                    theta: "-66.5424",
                    vega: "27.1667",
                    indic: "1601.3926642563747",
                  },
                  {
                    strike: "42000.00",
                    iv: "51.0",
                    delta: "0.4847",
                    theta: "-66.8289",
                    vega: "27.3210",
                    indic: "1358.8589668493187",
                  },
                  {
                    strike: "42500.00",
                    iv: "51.2",
                    delta: "0.4257",
                    theta: "-65.9278",
                    vega: "26.8659",
                    indic: "1148.8166192483113",
                  },
                  {
                    strike: "43000.00",
                    iv: "51.6",
                    delta: "0.3700",
                    theta: "-63.9733",
                    vega: "25.8774",
                    indic: "969.4416402393053",
                  },
                  {
                    strike: "43500.00",
                    iv: "52.1",
                    delta: "0.3190",
                    theta: "-61.1808",
                    vega: "24.4776",
                    indic: "817.9394882523247",
                  },
                  {
                    strike: "44000.00",
                    iv: "52.9",
                    delta: "0.2735",
                    theta: "-57.7983",
                    vega: "22.8050",
                    indic: "690.9808175267663",
                  },
                  {
                    strike: "44500.00",
                    iv: "53.7",
                    delta: "0.2336",
                    theta: "-54.0638",
                    vega: "20.9895",
                    indic: "585.1039004768882",
                  },
                ],
                put: [
                  {
                    strike: "41500.00",
                    iv: "51.1",
                    delta: "-0.4550",
                    theta: "-66.5424",
                    vega: "27.1667",
                    indic: "1359.1026642563738",
                  },
                  {
                    strike: "41000.00",
                    iv: "51.4",
                    delta: "-0.3954",
                    theta: "-65.0529",
                    vega: "26.3966",
                    indic: "1134.6432922359527",
                  },
                  {
                    strike: "40500.00",
                    iv: "52.0",
                    delta: "-0.3388",
                    theta: "-62.4723",
                    vega: "25.0786",
                    indic: "942.2874880050331",
                  },
                  {
                    strike: "40000.00",
                    iv: "52.7",
                    delta: "-0.2868",
                    theta: "-59.0142",
                    vega: "23.3377",
                    indic: "779.8777397843332",
                  },
                  {
                    strike: "39500.00",
                    iv: "53.7",
                    delta: "-0.2404",
                    theta: "-54.9451",
                    vega: "21.3260",
                    indic: "644.3754552914161",
                  },
                  {
                    strike: "39000.00",
                    iv: "54.9",
                    delta: "-0.2001",
                    theta: "-50.5344",
                    vega: "19.1919",
                    indic: "532.305550672987",
                  },
                  {
                    strike: "38500.00",
                    iv: "56.3",
                    delta: "-0.1657",
                    theta: "-46.0167",
                    vega: "17.0586",
                    indic: "440.14683330738444",
                  },
                ],
              },
              monthly: {
                call: [
                  {
                    strike: "41500.00",
                    iv: "50.9",
                    delta: "0.5568",
                    theta: "-42.5947",
                    vega: "42.2941",
                    indic: "2508.6400909869117",
                  },
                  {
                    strike: "42000.00",
                    iv: "50.9",
                    delta: "0.5181",
                    theta: "-43.0331",
                    vega: "42.6843",
                    indic: "2268.7639285283913",
                  },
                  {
                    strike: "42500.00",
                    iv: "51.1",
                    delta: "0.4799",
                    theta: "-43.1969",
                    vega: "42.6741",
                    indic: "2052.175044023774",
                  },
                  {
                    strike: "43000.00",
                    iv: "51.5",
                    delta: "0.4430",
                    theta: "-43.0989",
                    vega: "42.2910",
                    indic: "1857.8342480395331",
                  },
                  {
                    strike: "43500.00",
                    iv: "52.0",
                    delta: "0.4078",
                    theta: "-42.7630",
                    vega: "41.5810",
                    indic: "1684.2829818386217",
                  },
                  {
                    strike: "44000.00",
                    iv: "52.5",
                    delta: "0.3746",
                    theta: "-42.2208",
                    vega: "40.6009",
                    indic: "1529.7955845346787",
                  },
                  {
                    strike: "44500.00",
                    iv: "53.2",
                    delta: "0.3438",
                    theta: "-41.5072",
                    vega: "39.4107",
                    indic: "1392.5251613347064",
                  },
                ],
                put: [
                  {
                    strike: "41500.00",
                    iv: "50.9",
                    delta: "-0.4432",
                    theta: "-42.5947",
                    vega: "42.2941",
                    indic: "2091.24009098691",
                  },
                  {
                    strike: "41000.00",
                    iv: "51.0",
                    delta: "-0.4046",
                    theta: "-41.8832",
                    vega: "41.5001",
                    indic: "1854.8958264021057",
                  },
                  {
                    strike: "40500.00",
                    iv: "51.3",
                    delta: "-0.3669",
                    theta: "-40.9152",
                    vega: "40.3259",
                    indic: "1642.2065043306648",
                  },
                  {
                    strike: "40000.00",
                    iv: "51.7",
                    delta: "-0.3307",
                    theta: "-39.7205",
                    vega: "38.8189",
                    indic: "1452.4388100468059",
                  },
                  {
                    strike: "39500.00",
                    iv: "52.3",
                    delta: "-0.2966",
                    theta: "-38.3388",
                    vega: "37.0439",
                    indic: "1284.3358663950003",
                  },
                  {
                    strike: "39000.00",
                    iv: "53.0",
                    delta: "-0.2649",
                    theta: "-36.8145",
                    vega: "35.0744",
                    indic: "1136.2560830970124",
                  },
                  {
                    strike: "38500.00",
                    iv: "53.9",
                    delta: "-0.2359",
                    theta: "-35.1925",
                    vega: "32.9839",
                    indic: "1006.3316654658938",
                  },
                ],
              },
              next_month: {
                call: [
                  {
                    strike: "41500.00",
                    iv: "58.3",
                    delta: "0.5767",
                    theta: "-33.1080",
                    vega: "62.3704",
                    indic: "4235.00558203147",
                  },
                  {
                    strike: "42000.00",
                    iv: "58.4",
                    delta: "0.5540",
                    theta: "-33.4747",
                    vega: "62.9654",
                    indic: "4002.2358596490776",
                  },
                  {
                    strike: "42500.00",
                    iv: "58.5",
                    delta: "0.5315",
                    theta: "-33.7502",
                    vega: "63.3504",
                    indic: "3781.857092866281",
                  },
                  {
                    strike: "43000.00",
                    iv: "58.7",
                    delta: "0.5093",
                    theta: "-33.9361",
                    vega: "63.5313",
                    indic: "3573.536809200905",
                  },
                  {
                    strike: "43500.00",
                    iv: "58.9",
                    delta: "0.4875",
                    theta: "-34.0352",
                    vega: "63.5171",
                    indic: "3376.889590445975",
                  },
                  {
                    strike: "44000.00",
                    iv: "59.1",
                    delta: "0.4661",
                    theta: "-34.0513",
                    vega: "63.3194",
                    indic: "3191.487400283746",
                  },
                  {
                    strike: "44500.00",
                    iv: "59.3",
                    delta: "0.4454",
                    theta: "-33.9892",
                    vega: "62.9514",
                    indic: "3016.8697895060723",
                  },
                ],
                put: [
                  {
                    strike: "41500.00",
                    iv: "58.3",
                    delta: "-0.4233",
                    theta: "-33.1080",
                    vega: "62.3704",
                    indic: "3455.1455820314695",
                  },
                  {
                    strike: "41000.00",
                    iv: "58.3",
                    delta: "-0.4005",
                    theta: "-32.6497",
                    vega: "61.5626",
                    indic: "3200.575816213277",
                  },
                  {
                    strike: "40500.00",
                    iv: "58.2",
                    delta: "-0.3778",
                    theta: "-32.1012",
                    vega: "60.5432",
                    indic: "2958.864131027589",
                  },
                  {
                    strike: "40000.00",
                    iv: "58.3",
                    delta: "-0.3552",
                    theta: "-31.4652",
                    vega: "59.3174",
                    indic: "2730.1285004415513",
                  },
                  {
                    strike: "39500.00",
                    iv: "58.3",
                    delta: "-0.3330",
                    theta: "-30.7459",
                    vega: "57.8945",
                    indic: "2514.401692409483",
                  },
                  {
                    strike: "39000.00",
                    iv: "58.4",
                    delta: "-0.3111",
                    theta: "-29.9493",
                    vega: "56.2877",
                    indic: "2311.628097018931",
                  },
                  {
                    strike: "38500.00",
                    iv: "58.6",
                    delta: "-0.2899",
                    theta: "-29.0824",
                    vega: "54.5139",
                    indic: "2121.6632920135016",
                  },
                ],
              },
            },
          },
          {
            underlyer: "ETH",
            spot_ref: "2197.23",
            prices: {
              weekly: {
                call: [
                  {
                    strike: "2150.00",
                    iv: "51.3",
                    delta: "0.7183",
                    theta: "-5.3957",
                    vega: "0.6556",
                    indic: "72.73584250228669",
                  },
                  {
                    strike: "2200.00",
                    iv: "49.3",
                    delta: "0.5082",
                    theta: "-6.1335",
                    vega: "0.7746",
                    indic: "42.324351120526444",
                  },
                  {
                    strike: "2250.00",
                    iv: "49.8",
                    delta: "0.2950",
                    theta: "-5.3603",
                    vega: "0.6701",
                    indic: "22.685731161570743",
                  },
                  {
                    strike: "2300.00",
                    iv: "52.3",
                    delta: "0.1509",
                    theta: "-3.8183",
                    vega: "0.4546",
                    indic: "11.900682709652926",
                  },
                  {
                    strike: "2350.00",
                    iv: "55.9",
                    delta: "0.0748",
                    theta: "-2.4633",
                    vega: "0.2744",
                    indic: "6.382085596274976",
                  },
                  {
                    strike: "2400.00",
                    iv: "60.0",
                    delta: "0.0380",
                    theta: "-1.5440",
                    vega: "0.1603",
                    indic: "3.55452482132452",
                  },
                  {
                    strike: "2450.00",
                    iv: "64.3",
                    delta: "0.0201",
                    theta: "-0.9717",
                    vega: "0.0942",
                    indic: "2.0599566093520707",
                  },
                ],
                put: [
                  {
                    strike: "2150.00",
                    iv: "51.3",
                    delta: "-0.2817",
                    theta: "-5.3957",
                    vega: "0.6556",
                    indic: "22.67584250228697",
                  },
                  {
                    strike: "2100.00",
                    iv: "55.2",
                    delta: "-0.1425",
                    theta: "-3.8737",
                    vega: "0.4374",
                    indic: "11.842368845032695",
                  },
                  {
                    strike: "2050.00",
                    iv: "60.2",
                    delta: "-0.0695",
                    theta: "-2.5064",
                    vega: "0.2592",
                    indic: "6.250077157440586",
                  },
                  {
                    strike: "2000.00",
                    iv: "65.9",
                    delta: "-0.0343",
                    theta: "-1.5607",
                    vega: "0.1475",
                    indic: "3.3820761706022466",
                  },
                  {
                    strike: "1950.00",
                    iv: "72.0",
                    delta: "-0.0174",
                    theta: "-0.9657",
                    vega: "0.0836",
                    indic: "1.8821169634673822",
                  },
                  {
                    strike: "1900.00",
                    iv: "78.2",
                    delta: "-0.0092",
                    theta: "-0.6020",
                    vega: "0.0480",
                    indic: "1.0759812161048572",
                  },
                  {
                    strike: "1850.00",
                    iv: "84.6",
                    delta: "-0.0050",
                    theta: "-0.3801",
                    vega: "0.0280",
                    indic: "0.6304552053813168",
                  },
                ],
              },
              biweekly: {
                call: [
                  {
                    strike: "2150.00",
                    iv: "51.4",
                    delta: "0.6418",
                    theta: "-3.3300",
                    vega: "1.3526",
                    indic: "108.87029393751095",
                  },
                  {
                    strike: "2200.00",
                    iv: "51.0",
                    delta: "0.5296",
                    theta: "-3.5257",
                    vega: "1.4410",
                    indic: "81.17417061017568",
                  },
                  {
                    strike: "2250.00",
                    iv: "51.6",
                    delta: "0.4181",
                    theta: "-3.4992",
                    vega: "1.4144",
                    indic: "59.72090664020175",
                  },
                  {
                    strike: "2300.00",
                    iv: "52.9",
                    delta: "0.3199",
                    theta: "-3.2850",
                    vega: "1.2951",
                    indic: "43.8703343440277",
                  },
                  {
                    strike: "2350.00",
                    iv: "54.7",
                    delta: "0.2410",
                    theta: "-2.9609",
                    vega: "1.1284",
                    indic: "32.46712605536618",
                  },
                  {
                    strike: "2400.00",
                    iv: "56.9",
                    delta: "0.1808",
                    theta: "-2.5999",
                    vega: "0.9532",
                    indic: "24.328109304380575",
                  },
                  {
                    strike: "2450.00",
                    iv: "59.2",
                    delta: "0.1363",
                    theta: "-2.2486",
                    vega: "0.7916",
                    indic: "18.493160995360427",
                  },
                ],
                put: [
                  {
                    strike: "2150.00",
                    iv: "51.4",
                    delta: "-0.3582",
                    theta: "-3.3300",
                    vega: "1.3526",
                    indic: "52.81029393751089",
                  },
                  {
                    strike: "2100.00",
                    iv: "52.6",
                    delta: "-0.2592",
                    theta: "-2.9563",
                    vega: "1.1730",
                    indic: "36.482208062171026",
                  },
                  {
                    strike: "2050.00",
                    iv: "54.6",
                    delta: "-0.1814",
                    theta: "-2.4985",
                    vega: "0.9549",
                    indic: "25.09031912184531",
                  },
                  {
                    strike: "2000.00",
                    iv: "57.2",
                    delta: "-0.1248",
                    theta: "-2.0426",
                    vega: "0.7449",
                    indic: "17.343065333689424",
                  },
                  {
                    strike: "1950.00",
                    iv: "60.3",
                    delta: "-0.0856",
                    theta: "-1.6374",
                    vega: "0.5667",
                    indic: "12.111223758687373",
                  },
                  {
                    strike: "1900.00",
                    iv: "63.7",
                    delta: "-0.0590",
                    theta: "-1.2994",
                    vega: "0.4256",
                    indic: "8.562198601287406",
                  },
                  {
                    strike: "1850.00",
                    iv: "67.3",
                    delta: "-0.0409",
                    theta: "-1.0270",
                    vega: "0.3181",
                    indic: "6.129821864956924",
                  },
                ],
              },
              monthly: {
                call: [
                  {
                    strike: "2150.00",
                    iv: "51.1",
                    delta: "0.6191",
                    theta: "-2.1797",
                    vega: "2.1564",
                    indic: "155.63014735857928",
                  },
                  {
                    strike: "2200.00",
                    iv: "51.2",
                    delta: "0.5463",
                    theta: "-2.2716",
                    vega: "2.2425",
                    indic: "129.62267491174703",
                  },
                  {
                    strike: "2250.00",
                    iv: "51.7",
                    delta: "0.4745",
                    theta: "-2.3043",
                    vega: "2.2531",
                    indic: "107.69746507101513",
                  },
                  {
                    strike: "2300.00",
                    iv: "52.5",
                    delta: "0.4072",
                    theta: "-2.2815",
                    vega: "2.1963",
                    indic: "89.5427379161514",
                  },
                  {
                    strike: "2350.00",
                    iv: "53.5",
                    delta: "0.3467",
                    theta: "-2.2140",
                    vega: "2.0889",
                    indic: "74.68425087147614",
                  },
                  {
                    strike: "2400.00",
                    iv: "54.8",
                    delta: "0.2940",
                    theta: "-2.1149",
                    vega: "1.9497",
                    indic: "62.59332801530934",
                  },
                  {
                    strike: "2450.00",
                    iv: "56.2",
                    delta: "0.2491",
                    theta: "-1.9964",
                    vega: "1.7951",
                    indic: "52.765161620473805",
                  },
                ],
                put: [
                  {
                    strike: "2150.00",
                    iv: "51.1",
                    delta: "-0.3809",
                    theta: "-2.1797",
                    vega: "2.1564",
                    indic: "90.74014735857963",
                  },
                  {
                    strike: "2100.00",
                    iv: "51.4",
                    delta: "-0.3113",
                    theta: "-2.0342",
                    vega: "2.0003",
                    indic: "70.88193456510783",
                  },
                  {
                    strike: "2050.00",
                    iv: "52.1",
                    delta: "-0.2487",
                    theta: "-1.8496",
                    vega: "1.7932",
                    indic: "54.920280921240646",
                  },
                  {
                    strike: "2000.00",
                    iv: "53.2",
                    delta: "-0.1951",
                    theta: "-1.6447",
                    vega: "1.5606",
                    indic: "42.38857204886898",
                  },
                  {
                    strike: "1950.00",
                    iv: "54.7",
                    delta: "-0.1512",
                    theta: "-1.4370",
                    vega: "1.3264",
                    indic: "32.707716319769816",
                  },
                  {
                    strike: "1900.00",
                    iv: "56.5",
                    delta: "-0.1163",
                    theta: "-1.2393",
                    vega: "1.1075",
                    indic: "25.2971223063862",
                  },
                  {
                    strike: "1850.00",
                    iv: "58.6",
                    delta: "-0.0893",
                    theta: "-1.0592",
                    vega: "0.9134",
                    indic: "19.6437890273603",
                  },
                ],
              },
              next_month: {
                call: [
                  {
                    strike: "2150.00",
                    iv: "59.3",
                    delta: "0.6130",
                    theta: "-1.7393",
                    vega: "3.2221",
                    indic: "248.27014528258815",
                  },
                  {
                    strike: "2200.00",
                    iv: "59.4",
                    delta: "0.5707",
                    theta: "-1.7880",
                    vega: "3.3049",
                    indic: "223.73198402206822",
                  },
                  {
                    strike: "2250.00",
                    iv: "59.7",
                    delta: "0.5288",
                    theta: "-1.8200",
                    vega: "3.3489",
                    indic: "201.56312075619508",
                  },
                  {
                    strike: "2300.00",
                    iv: "60.1",
                    delta: "0.4881",
                    theta: "-1.8357",
                    vega: "3.3562",
                    indic: "181.6442913720349",
                  },
                  {
                    strike: "2350.00",
                    iv: "60.6",
                    delta: "0.4492",
                    theta: "-1.8363",
                    vega: "3.3304",
                    indic: "163.82301172856933",
                  },
                  {
                    strike: "2400.00",
                    iv: "61.1",
                    delta: "0.4124",
                    theta: "-1.8235",
                    vega: "3.2764",
                    indic: "147.92695699907097",
                  },
                  {
                    strike: "2450.00",
                    iv: "61.8",
                    delta: "0.3780",
                    theta: "-1.7993",
                    vega: "3.1994",
                    indic: "133.77582494491526",
                  },
                ],
                put: [
                  {
                    strike: "2150.00",
                    iv: "59.3",
                    delta: "-0.3870",
                    theta: "-1.7393",
                    vega: "3.2221",
                    indic: "164.34014528258797",
                  },
                  {
                    strike: "2100.00",
                    iv: "59.3",
                    delta: "-0.3451",
                    theta: "-1.6743",
                    vega: "3.1011",
                    indic: "141.32065982411314",
                  },
                  {
                    strike: "2050.00",
                    iv: "59.5",
                    delta: "-0.3043",
                    theta: "-1.5946",
                    vega: "2.9450",
                    indic: "120.76031926621692",
                  },
                  {
                    strike: "2000.00",
                    iv: "59.8",
                    delta: "-0.2655",
                    theta: "-1.5026",
                    vega: "2.7592",
                    indic: "102.61500905818662",
                  },
                  {
                    strike: "1950.00",
                    iv: "60.3",
                    delta: "-0.2293",
                    theta: "-1.4014",
                    vega: "2.5513",
                    indic: "86.78195438451769",
                  },
                  {
                    strike: "1900.00",
                    iv: "61.0",
                    delta: "-0.1963",
                    theta: "-1.2943",
                    vega: "2.3298",
                    indic: "73.10863794966741",
                  },
                  {
                    strike: "1850.00",
                    iv: "61.9",
                    delta: "-0.1667",
                    theta: "-1.1849",
                    vega: "2.1031",
                    indic: "61.406483583812815",
                  },
                ],
              },
            },
          },
          {
            underlyer: "MATIC",
            spot_ref: "0.7900",
            prices: {
              weekly: {
                call: [
                  {
                    strike: "0.7500",
                    iv: "91.4",
                    delta: "0.8119",
                    theta: "-0.0023",
                    vega: "0.0002",
                    indic: "0.05214162960597046",
                  },
                  {
                    strike: "0.8000",
                    iv: "84.0",
                    delta: "0.4248",
                    theta: "-0.0031",
                    vega: "0.0003",
                    indic: "0.022158624778360003",
                  },
                  {
                    strike: "0.8500",
                    iv: "90.7",
                    delta: "0.1211",
                    theta: "-0.0017",
                    vega: "0.0001",
                    indic: "0.00844423631005134",
                  },
                  {
                    strike: "0.9000",
                    iv: "103.3",
                    delta: "0.0325",
                    theta: "-0.0007",
                    vega: "0.0001",
                    indic: "0.003454983475187842",
                  },
                  {
                    strike: "0.9500",
                    iv: "116.7",
                    delta: "0.0102",
                    theta: "-0.0003",
                    vega: "0.0000",
                    indic: "0.0015525027285741982",
                  },
                  {
                    strike: "1.0000",
                    iv: "129.5",
                    delta: "0.0038",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0007563961901475777",
                  },
                  {
                    strike: "1.0500",
                    iv: "171.2",
                    delta: "0.0016",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0013696764169083935",
                  },
                ],
                put: [
                  {
                    strike: "0.7500",
                    iv: "91.4",
                    delta: "-0.1881",
                    theta: "-0.0023",
                    vega: "0.0002",
                    indic: "0.011872612268409605",
                  },
                  {
                    strike: "0.7000",
                    iv: "109.3",
                    delta: "-0.0450",
                    theta: "-0.0010",
                    vega: "0.0001",
                    indic: "0.004242248050821282",
                  },
                  {
                    strike: "0.6500",
                    iv: "130.9",
                    delta: "-0.0115",
                    theta: "-0.0004",
                    vega: "0.0000",
                    indic: "0.0015595130935777066",
                  },
                  {
                    strike: "0.6000",
                    iv: "154.4",
                    delta: "-0.0033",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0005926761711790279",
                  },
                  {
                    strike: "0.5500",
                    iv: "229.7",
                    delta: "-0.0010",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0009964620266975574",
                  },
                  {
                    strike: "0.5000",
                    iv: "338.6",
                    delta: "-0.0004",
                    theta: "-0.0000",
                    vega: "0.0000",
                    indic: "0.0016625802495053021",
                  },
                  {
                    strike: "0.4500",
                    iv: "503.3",
                    delta: "-0.0001",
                    theta: "-0.0000",
                    vega: "0.0000",
                    indic: "0.002895643612231817",
                  },
                ],
              },
              biweekly: {
                call: [
                  {
                    strike: "0.7500",
                    iv: "88.7",
                    delta: "0.6907",
                    theta: "-0.0017",
                    vega: "0.0005",
                    indic: "0.07054949078235223",
                  },
                  {
                    strike: "0.8000",
                    iv: "87.3",
                    delta: "0.4855",
                    theta: "-0.0019",
                    vega: "0.0005",
                    indic: "0.04384484515601156",
                  },
                  {
                    strike: "0.8500",
                    iv: "90.6",
                    delta: "0.3016",
                    theta: "-0.0017",
                    vega: "0.0005",
                    indic: "0.027014499073000686",
                  },
                  {
                    strike: "0.9000",
                    iv: "96.5",
                    delta: "0.1807",
                    theta: "-0.0014",
                    vega: "0.0003",
                    indic: "0.01716577263501501",
                  },
                  {
                    strike: "0.9500",
                    iv: "103.4",
                    delta: "0.1102",
                    theta: "-0.0011",
                    vega: "0.0002",
                    indic: "0.011364294899352298",
                  },
                  {
                    strike: "1.0000",
                    iv: "110.4",
                    delta: "0.0698",
                    theta: "-0.0008",
                    vega: "0.0002",
                    indic: "0.007817547067734867",
                  },
                  {
                    strike: "1.0500",
                    iv: "117.3",
                    delta: "0.0459",
                    theta: "-0.0006",
                    vega: "0.0001",
                    indic: "0.005558419393290014",
                  },
                ],
                put: [
                  {
                    strike: "0.7500",
                    iv: "88.7",
                    delta: "-0.3093",
                    theta: "-0.0017",
                    vega: "0.0005",
                    indic: "0.029677999857370463",
                  },
                  {
                    strike: "0.7000",
                    iv: "95.3",
                    delta: "-0.1583",
                    theta: "-0.0013",
                    vega: "0.0003",
                    indic: "0.01595516257733484",
                  },
                  {
                    strike: "0.6500",
                    iv: "105.6",
                    delta: "-0.0764",
                    theta: "-0.0008",
                    vega: "0.0002",
                    indic: "0.008601860237002046",
                  },
                  {
                    strike: "0.6000",
                    iv: "118.4",
                    delta: "-0.0371",
                    theta: "-0.0005",
                    vega: "0.0001",
                    indic: "0.004717899596976542",
                  },
                  {
                    strike: "0.5500",
                    iv: "125.9",
                    delta: "-0.0184",
                    theta: "-0.0003",
                    vega: "0.0001",
                    indic: "0.002027491912992535",
                  },
                  {
                    strike: "0.5000",
                    iv: "147.9",
                    delta: "-0.0094",
                    theta: "-0.0002",
                    vega: "0.0000",
                    indic: "0.001429836373350384",
                  },
                  {
                    strike: "0.4500",
                    iv: "175.7",
                    delta: "-0.0049",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0011108575317566125",
                  },
                ],
              },
              monthly: {
                call: [
                  {
                    strike: "0.7500",
                    iv: "115.3",
                    delta: "0.6353",
                    theta: "-0.0015",
                    vega: "0.0008",
                    indic: "0.11357939762527186",
                  },
                  {
                    strike: "0.8000",
                    iv: "115.1",
                    delta: "0.5330",
                    theta: "-0.0016",
                    vega: "0.0008",
                    indic: "0.08991399727399024",
                  },
                  {
                    strike: "0.8500",
                    iv: "116.5",
                    delta: "0.4366",
                    theta: "-0.0016",
                    vega: "0.0008",
                    indic: "0.07144033783288845",
                  },
                  {
                    strike: "0.9000",
                    iv: "119.0",
                    delta: "0.3533",
                    theta: "-0.0016",
                    vega: "0.0008",
                    indic: "0.0572875263405091",
                  },
                  {
                    strike: "0.9500",
                    iv: "122.2",
                    delta: "0.2850",
                    theta: "-0.0015",
                    vega: "0.0007",
                    indic: "0.04648767323972633",
                  },
                  {
                    strike: "1.0000",
                    iv: "125.7",
                    delta: "0.2308",
                    theta: "-0.0014",
                    vega: "0.0006",
                    indic: "0.03819943239130541",
                  },
                  {
                    strike: "1.0500",
                    iv: "129.4",
                    delta: "0.1881",
                    theta: "-0.0012",
                    vega: "0.0005",
                    indic: "0.031770869362985094",
                  },
                ],
                put: [
                  {
                    strike: "0.7500",
                    iv: "115.3",
                    delta: "-0.3647",
                    theta: "-0.0015",
                    vega: "0.0008",
                    indic: "0.07134553053506854",
                  },
                  {
                    strike: "0.7000",
                    iv: "117.5",
                    delta: "-0.2677",
                    theta: "-0.0014",
                    vega: "0.0007",
                    indic: "0.050610977908981",
                  },
                  {
                    strike: "0.6500",
                    iv: "121.9",
                    delta: "-0.1862",
                    theta: "-0.0012",
                    vega: "0.0005",
                    indic: "0.0351727824746173",
                  },
                  {
                    strike: "0.6000",
                    iv: "128.5",
                    delta: "-0.1245",
                    theta: "-0.0009",
                    vega: "0.0004",
                    indic: "0.024150487366905796",
                  },
                  {
                    strike: "0.5500",
                    iv: "136.9",
                    delta: "-0.0812",
                    theta: "-0.0007",
                    vega: "0.0003",
                    indic: "0.016473870174786714",
                  },
                  {
                    strike: "0.5000",
                    iv: "147.2",
                    delta: "-0.0521",
                    theta: "-0.0006",
                    vega: "0.0002",
                    indic: "0.01118769123799343",
                  },
                  {
                    strike: "0.4500",
                    iv: "159.3",
                    delta: "-0.0331",
                    theta: "-0.0004",
                    vega: "0.0001",
                    indic: "0.007560223902034369",
                  },
                ],
              },
              next_month: {
                call: [
                  {
                    strike: "0.7500",
                    iv: "118.1",
                    delta: "0.6331",
                    theta: "-0.0011",
                    vega: "0.0011",
                    indic: "0.15955802159038102",
                  },
                  {
                    strike: "0.8000",
                    iv: "118.0",
                    delta: "0.5658",
                    theta: "-0.0011",
                    vega: "0.0012",
                    indic: "0.13773762169984421",
                  },
                  {
                    strike: "0.8500",
                    iv: "118.5",
                    delta: "0.5014",
                    theta: "-0.0011",
                    vega: "0.0012",
                    indic: "0.11927006224325748",
                  },
                  {
                    strike: "0.9000",
                    iv: "119.5",
                    delta: "0.4421",
                    theta: "-0.0011",
                    vega: "0.0012",
                    indic: "0.10373460266691892",
                  },
                  {
                    strike: "0.9500",
                    iv: "120.8",
                    delta: "0.3887",
                    theta: "-0.0011",
                    vega: "0.0011",
                    indic: "0.09068930073449838",
                  },
                  {
                    strike: "1.0000",
                    iv: "122.3",
                    delta: "0.3417",
                    theta: "-0.0011",
                    vega: "0.0011",
                    indic: "0.07972014853786513",
                  },
                  {
                    strike: "1.0500",
                    iv: "124.0",
                    delta: "0.3007",
                    theta: "-0.0010",
                    vega: "0.0010",
                    indic: "0.0704646902817874",
                  },
                ],
                put: [
                  {
                    strike: "0.7500",
                    iv: "118.1",
                    delta: "-0.3669",
                    theta: "-0.0011",
                    vega: "0.0011",
                    indic: "0.11530879969297575",
                  },
                  {
                    strike: "0.7000",
                    iv: "119.1",
                    delta: "-0.2999",
                    theta: "-0.0010",
                    vega: "0.0010",
                    indic: "0.09080701134279254",
                  },
                  {
                    strike: "0.6500",
                    iv: "121.1",
                    delta: "-0.2366",
                    theta: "-0.0009",
                    vega: "0.0009",
                    indic: "0.07013069276832176",
                  },
                  {
                    strike: "0.6000",
                    iv: "124.3",
                    delta: "-0.1804",
                    theta: "-0.0008",
                    vega: "0.0008",
                    indic: "0.05319821048305187",
                  },
                  {
                    strike: "0.5500",
                    iv: "128.7",
                    delta: "-0.1332",
                    theta: "-0.0007",
                    vega: "0.0006",
                    indic: "0.03970791284689791",
                  },
                  {
                    strike: "0.5000",
                    iv: "134.4",
                    delta: "-0.0955",
                    theta: "-0.0005",
                    vega: "0.0005",
                    indic: "0.029206377964612534",
                  },
                  {
                    strike: "0.4500",
                    iv: "123.2",
                    delta: "-0.0668",
                    theta: "-0.0004",
                    vega: "0.0004",
                    indic: "0.014228773706665775",
                  },
                ],
              },
            },
          },
          {
            underlyer: "BNB",
            spot_ref: "229.2000",
            prices: {
              weekly: {
                call: [
                  {
                    strike: "225.0000",
                    iv: "59.0",
                    delta: "0.6910",
                    theta: "-0.5609",
                    vega: "0.0713",
                    indic: "8.511825993322475",
                  },
                  {
                    strike: "230.0000",
                    iv: "57.2",
                    delta: "0.4756",
                    theta: "-0.6143",
                    vega: "0.0806",
                    indic: "5.73558131294287",
                  },
                  {
                    strike: "235.0000",
                    iv: "58.1",
                    delta: "0.2702",
                    theta: "-0.5189",
                    vega: "0.0669",
                    indic: "3.7677023982226956",
                  },
                  {
                    strike: "240.0000",
                    iv: "61.3",
                    delta: "0.1373",
                    theta: "-0.3631",
                    vega: "0.0445",
                    indic: "2.4769384706896744",
                  },
                  {
                    strike: "245.0000",
                    iv: "65.5",
                    delta: "0.0683",
                    theta: "-0.2330",
                    vega: "0.0267",
                    indic: "1.650280826013642",
                  },
                  {
                    strike: "250.0000",
                    iv: "70.3",
                    delta: "0.0349",
                    theta: "-0.1461",
                    vega: "0.0156",
                    indic: "1.1177769415342453",
                  },
                  {
                    strike: "255.0000",
                    iv: "75.2",
                    delta: "0.0186",
                    theta: "-0.0922",
                    vega: "0.0092",
                    indic: "0.7696219141891234",
                  },
                ],
                put: [
                  {
                    strike: "225.0000",
                    iv: "59.0",
                    delta: "-0.3090",
                    theta: "-0.5609",
                    vega: "0.0713",
                    indic: "4.216844942327597",
                  },
                  {
                    strike: "220.0000",
                    iv: "63.2",
                    delta: "-0.1588",
                    theta: "-0.4131",
                    vega: "0.0490",
                    indic: "2.722347743269701",
                  },
                  {
                    strike: "215.0000",
                    iv: "68.9",
                    delta: "-0.0779",
                    theta: "-0.2708",
                    vega: "0.0295",
                    indic: "1.7566646331191649",
                  },
                  {
                    strike: "210.0000",
                    iv: "75.3",
                    delta: "-0.0385",
                    theta: "-0.1697",
                    vega: "0.0169",
                    indic: "1.138313859927397",
                  },
                  {
                    strike: "205.0000",
                    iv: "82.2",
                    delta: "-0.0196",
                    theta: "-0.1053",
                    vega: "0.0096",
                    indic: "0.7417178155821915",
                  },
                  {
                    strike: "200.0000",
                    iv: "89.3",
                    delta: "-0.0103",
                    theta: "-0.0658",
                    vega: "0.0055",
                    indic: "0.4861411792042247",
                  },
                  {
                    strike: "195.0000",
                    iv: "96.6",
                    delta: "-0.0056",
                    theta: "-0.0416",
                    vega: "0.0032",
                    indic: "0.3205062504293945",
                  },
                ],
              },
              biweekly: {
                call: [
                  {
                    strike: "225.0000",
                    iv: "62.0",
                    delta: "0.6094",
                    theta: "-0.3799",
                    vega: "0.1447",
                    indic: "13.085478210613829",
                  },
                  {
                    strike: "230.0000",
                    iv: "61.9",
                    delta: "0.5074",
                    theta: "-0.3941",
                    vega: "0.1503",
                    indic: "10.537577650488203",
                  },
                  {
                    strike: "235.0000",
                    iv: "62.6",
                    delta: "0.4084",
                    theta: "-0.3883",
                    vega: "0.1464",
                    indic: "8.461172529971947",
                  },
                  {
                    strike: "240.0000",
                    iv: "64.1",
                    delta: "0.3211",
                    theta: "-0.3662",
                    vega: "0.1350",
                    indic: "6.808895563084107",
                  },
                  {
                    strike: "245.0000",
                    iv: "66.0",
                    delta: "0.2493",
                    theta: "-0.3342",
                    vega: "0.1196",
                    indic: "5.510074886506416",
                  },
                  {
                    strike: "250.0000",
                    iv: "68.3",
                    delta: "0.1929",
                    theta: "-0.2984",
                    vega: "0.1032",
                    indic: "4.491915900640677",
                  },
                  {
                    strike: "255.0000",
                    iv: "70.8",
                    delta: "0.1496",
                    theta: "-0.2629",
                    vega: "0.0877",
                    indic: "3.6911954639776425",
                  },
                ],
                put: [
                  {
                    strike: "225.0000",
                    iv: "62.0",
                    delta: "-0.3906",
                    theta: "-0.3799",
                    vega: "0.1447",
                    indic: "8.549352001154034",
                  },
                  {
                    strike: "220.0000",
                    iv: "63.0",
                    delta: "-0.2963",
                    theta: "-0.3478",
                    vega: "0.1303",
                    indic: "6.572376981531889",
                  },
                  {
                    strike: "215.0000",
                    iv: "64.9",
                    delta: "-0.2173",
                    theta: "-0.3044",
                    vega: "0.1108",
                    indic: "5.02787116929759",
                  },
                  {
                    strike: "210.0000",
                    iv: "67.4",
                    delta: "-0.1561",
                    theta: "-0.2576",
                    vega: "0.0902",
                    indic: "3.844974453666403",
                  },
                  {
                    strike: "205.0000",
                    iv: "70.5",
                    delta: "-0.1112",
                    theta: "-0.2132",
                    vega: "0.0714",
                    indic: "2.9474643983478472",
                  },
                  {
                    strike: "200.0000",
                    iv: "74.0",
                    delta: "-0.0791",
                    theta: "-0.1740",
                    vega: "0.0555",
                    indic: "2.267972682066059",
                  },
                  {
                    strike: "195.0000",
                    iv: "77.8",
                    delta: "-0.0565",
                    theta: "-0.1411",
                    vega: "0.0428",
                    indic: "1.7525754259404067",
                  },
                ],
              },
              monthly: {
                call: [
                  {
                    strike: "225.0000",
                    iv: "79.0",
                    delta: "0.5844",
                    theta: "-0.3165",
                    vega: "0.2291",
                    indic: "22.43026535328353",
                  },
                  {
                    strike: "230.0000",
                    iv: "79.2",
                    delta: "0.5330",
                    theta: "-0.3235",
                    vega: "0.2336",
                    indic: "20.09042200017113",
                  },
                  {
                    strike: "235.0000",
                    iv: "79.7",
                    delta: "0.4827",
                    theta: "-0.3263",
                    vega: "0.2342",
                    indic: "18.002645136302803",
                  },
                  {
                    strike: "240.0000",
                    iv: "80.4",
                    delta: "0.4347",
                    theta: "-0.3251",
                    vega: "0.2312",
                    indic: "16.150802666457352",
                  },
                  {
                    strike: "245.0000",
                    iv: "81.3",
                    delta: "0.3899",
                    theta: "-0.3206",
                    vega: "0.2254",
                    indic: "14.51477463198664",
                  },
                  {
                    strike: "250.0000",
                    iv: "82.4",
                    delta: "0.3488",
                    theta: "-0.3133",
                    vega: "0.2173",
                    indic: "13.072610003684474",
                  },
                  {
                    strike: "255.0000",
                    iv: "83.7",
                    delta: "0.3115",
                    theta: "-0.3039",
                    vega: "0.2077",
                    indic: "11.802264153348077",
                  },
                ],
                put: [
                  {
                    strike: "225.0000",
                    iv: "79.0",
                    delta: "-0.4156",
                    theta: "-0.3165",
                    vega: "0.2291",
                    indic: "17.488653918907403",
                  },
                  {
                    strike: "220.0000",
                    iv: "79.1",
                    delta: "-0.3644",
                    theta: "-0.3053",
                    vega: "0.2207",
                    indic: "15.090517007010334",
                  },
                  {
                    strike: "215.0000",
                    iv: "79.6",
                    delta: "-0.3152",
                    theta: "-0.2904",
                    vega: "0.2088",
                    indic: "12.956532308222279",
                  },
                  {
                    strike: "210.0000",
                    iv: "80.4",
                    delta: "-0.2690",
                    theta: "-0.2725",
                    vega: "0.1939",
                    indic: "11.080471249575993",
                  },
                  {
                    strike: "205.0000",
                    iv: "81.5",
                    delta: "-0.2271",
                    theta: "-0.2524",
                    vega: "0.1771",
                    indic: "9.44852708279847",
                  },
                  {
                    strike: "200.0000",
                    iv: "82.9",
                    delta: "-0.1899",
                    theta: "-0.2312",
                    vega: "0.1594",
                    indic: "8.041168390569439",
                  },
                  {
                    strike: "195.0000",
                    iv: "84.7",
                    delta: "-0.1577",
                    theta: "-0.2097",
                    vega: "0.1416",
                    indic: "6.835480858399286",
                  },
                ],
              },
              next_month: {
                call: [
                  {
                    strike: "225.0000",
                    iv: "74.6",
                    delta: "0.5882",
                    theta: "-0.2028",
                    vega: "0.3382",
                    indic: "30.92970589710204",
                  },
                  {
                    strike: "230.0000",
                    iv: "74.8",
                    delta: "0.5514",
                    theta: "-0.2068",
                    vega: "0.3438",
                    indic: "28.706949041505595",
                  },
                  {
                    strike: "235.0000",
                    iv: "75.1",
                    delta: "0.5154",
                    theta: "-0.2092",
                    vega: "0.3464",
                    indic: "26.658100974199442",
                  },
                  {
                    strike: "240.0000",
                    iv: "75.5",
                    delta: "0.4804",
                    theta: "-0.2103",
                    vega: "0.3462",
                    indic: "24.773966772002083",
                  },
                  {
                    strike: "245.0000",
                    iv: "76.1",
                    delta: "0.4469",
                    theta: "-0.2101",
                    vega: "0.3436",
                    indic: "23.044211889353903",
                  },
                  {
                    strike: "250.0000",
                    iv: "76.6",
                    delta: "0.4150",
                    theta: "-0.2088",
                    vega: "0.3388",
                    indic: "21.457893043048117",
                  },
                  {
                    strike: "255.0000",
                    iv: "77.3",
                    delta: "0.3850",
                    theta: "-0.2065",
                    vega: "0.3322",
                    indic: "20.00390566080054",
                  },
                ],
                put: [
                  {
                    strike: "225.0000",
                    iv: "74.6",
                    delta: "-0.4118",
                    theta: "-0.2028",
                    vega: "0.3382",
                    indic: "25.28763415493502",
                  },
                  {
                    strike: "220.0000",
                    iv: "74.5",
                    delta: "-0.3749",
                    theta: "-0.1974",
                    vega: "0.3295",
                    indic: "22.691780648412802",
                  },
                  {
                    strike: "215.0000",
                    iv: "74.6",
                    delta: "-0.3384",
                    theta: "-0.1906",
                    vega: "0.3178",
                    indic: "20.28252943425737",
                  },
                  {
                    strike: "210.0000",
                    iv: "74.8",
                    delta: "-0.3027",
                    theta: "-0.1826",
                    vega: "0.3034",
                    indic: "18.062371181312002",
                  },
                  {
                    strike: "205.0000",
                    iv: "75.2",
                    delta: "-0.2686",
                    theta: "-0.1734",
                    vega: "0.2866",
                    indic: "16.03079998923711",
                  },
                  {
                    strike: "200.0000",
                    iv: "75.8",
                    delta: "-0.2364",
                    theta: "-0.1633",
                    vega: "0.2679",
                    indic: "14.184273517182916",
                  },
                  {
                    strike: "195.0000",
                    iv: "76.5",
                    delta: "-0.2065",
                    theta: "-0.1526",
                    vega: "0.2480",
                    indic: "12.516421519208961",
                  },
                ],
              },
            },
          },
          {
            underlyer: "LTC",
            spot_ref: "71.72",
            prices: {
              weekly: {
                call: [
                  {
                    strike: "71.00",
                    iv: "58.6",
                    delta: "0.6129",
                    theta: "-0.1894",
                    vega: "0.0242",
                    indic: "2.3142564117293034",
                  },
                  {
                    strike: "72.00",
                    iv: "57.8",
                    delta: "0.4715",
                    theta: "-0.1941",
                    vega: "0.0252",
                    indic: "1.7915572577114318",
                  },
                  {
                    strike: "73.00",
                    iv: "58.1",
                    delta: "0.3353",
                    theta: "-0.1788",
                    vega: "0.0231",
                    indic: "1.3729700406874379",
                  },
                  {
                    strike: "74.00",
                    iv: "59.5",
                    delta: "0.2247",
                    theta: "-0.1505",
                    vega: "0.0190",
                    indic: "1.0494500520830599",
                  },
                  {
                    strike: "75.00",
                    iv: "61.6",
                    delta: "0.1457",
                    theta: "-0.1189",
                    vega: "0.0145",
                    indic: "0.8042854831224631",
                  },
                  {
                    strike: "76.00",
                    iv: "64.2",
                    delta: "0.0936",
                    theta: "-0.0906",
                    vega: "0.0106",
                    indic: "0.6197109664441403",
                  },
                  {
                    strike: "77.00",
                    iv: "67.1",
                    delta: "0.0604",
                    theta: "-0.0678",
                    vega: "0.0076",
                    indic: "0.48059083874309216",
                  },
                ],
                put: [
                  {
                    strike: "71.00",
                    iv: "58.6",
                    delta: "-0.3871",
                    theta: "-0.1894",
                    vega: "0.0242",
                    indic: "1.5664312128921978",
                  },
                  {
                    strike: "70.00",
                    iv: "60.5",
                    delta: "-0.2646",
                    theta: "-0.1672",
                    vega: "0.0207",
                    indic: "1.1898814917776264",
                  },
                  {
                    strike: "69.00",
                    iv: "63.3",
                    delta: "-0.1728",
                    theta: "-0.1366",
                    vega: "0.0162",
                    indic: "0.9005762490891875",
                  },
                  {
                    strike: "68.00",
                    iv: "66.7",
                    delta: "-0.1105",
                    theta: "-0.1062",
                    vega: "0.0119",
                    indic: "0.6813142882715741",
                  },
                  {
                    strike: "67.00",
                    iv: "70.5",
                    delta: "-0.0703",
                    theta: "-0.0802",
                    vega: "0.0085",
                    indic: "0.516030506679277",
                  },
                  {
                    strike: "66.00",
                    iv: "74.6",
                    delta: "-0.0449",
                    theta: "-0.0597",
                    vega: "0.0060",
                    indic: "0.3915792001232745",
                  },
                  {
                    strike: "65.00",
                    iv: "79.0",
                    delta: "-0.0290",
                    theta: "-0.0441",
                    vega: "0.0042",
                    indic: "0.2977966468637101",
                  },
                ],
              },
              biweekly: {
                call: [
                  {
                    strike: "71.00",
                    iv: "57.9",
                    delta: "0.5721",
                    theta: "-0.1135",
                    vega: "0.0463",
                    indic: "3.6093105035254567",
                  },
                  {
                    strike: "72.00",
                    iv: "58.0",
                    delta: "0.5021",
                    theta: "-0.1155",
                    vega: "0.0470",
                    indic: "3.11871480987687",
                  },
                  {
                    strike: "73.00",
                    iv: "58.4",
                    delta: "0.4338",
                    theta: "-0.1147",
                    vega: "0.0464",
                    indic: "2.691721955941709",
                  },
                  {
                    strike: "74.00",
                    iv: "59.2",
                    delta: "0.3701",
                    theta: "-0.1115",
                    vega: "0.0445",
                    indic: "2.3243151184213673",
                  },
                  {
                    strike: "75.00",
                    iv: "60.2",
                    delta: "0.3129",
                    theta: "-0.1064",
                    vega: "0.0418",
                    indic: "2.0105976920518565",
                  },
                  {
                    strike: "76.00",
                    iv: "61.4",
                    delta: "0.2631",
                    theta: "-0.1001",
                    vega: "0.0385",
                    indic: "1.74383655371782",
                  },
                  {
                    strike: "77.00",
                    iv: "62.8",
                    delta: "0.2207",
                    theta: "-0.0930",
                    vega: "0.0350",
                    indic: "1.517303840130694",
                  },
                ],
                put: [
                  {
                    strike: "71.00",
                    iv: "57.9",
                    delta: "-0.4279",
                    theta: "-0.1135",
                    vega: "0.0463",
                    indic: "2.80480249895707",
                  },
                  {
                    strike: "70.00",
                    iv: "58.3",
                    delta: "-0.3597",
                    theta: "-0.1088",
                    vega: "0.0441",
                    indic: "2.3602377156543817",
                  },
                  {
                    strike: "69.00",
                    iv: "59.0",
                    delta: "-0.2966",
                    theta: "-0.1019",
                    vega: "0.0408",
                    indic: "1.9786330886901062",
                  },
                  {
                    strike: "68.00",
                    iv: "60.1",
                    delta: "-0.2409",
                    theta: "-0.0934",
                    vega: "0.0367",
                    indic: "1.655408369517076",
                  },
                  {
                    strike: "67.00",
                    iv: "61.5",
                    delta: "-0.1934",
                    theta: "-0.0842",
                    vega: "0.0323",
                    indic: "1.3842337737718502",
                  },
                  {
                    strike: "66.00",
                    iv: "63.2",
                    delta: "-0.1542",
                    theta: "-0.0749",
                    vega: "0.0280",
                    indic: "1.1580858389158362",
                  },
                  {
                    strike: "65.00",
                    iv: "65.1",
                    delta: "-0.1224",
                    theta: "-0.0659",
                    vega: "0.0239",
                    indic: "0.9700725736301123",
                  },
                ],
              },
              monthly: {
                call: [
                  {
                    strike: "71.00",
                    iv: "68.6",
                    delta: "0.5639",
                    theta: "-0.0868",
                    vega: "0.0724",
                    indic: "6.082082342348087",
                  },
                  {
                    strike: "72.00",
                    iv: "68.8",
                    delta: "0.5261",
                    theta: "-0.0880",
                    vega: "0.0732",
                    indic: "5.621724631206675",
                  },
                  {
                    strike: "73.00",
                    iv: "69.1",
                    delta: "0.4888",
                    theta: "-0.0886",
                    vega: "0.0733",
                    indic: "5.197474330230893",
                  },
                  {
                    strike: "74.00",
                    iv: "69.6",
                    delta: "0.4528",
                    theta: "-0.0886",
                    vega: "0.0728",
                    indic: "4.807731218519688",
                  },
                  {
                    strike: "75.00",
                    iv: "70.1",
                    delta: "0.4182",
                    theta: "-0.0880",
                    vega: "0.0718",
                    indic: "4.450549503834299",
                  },
                  {
                    strike: "76.00",
                    iv: "70.8",
                    delta: "0.3855",
                    theta: "-0.0870",
                    vega: "0.0703",
                    indic: "4.1237621980893735",
                  },
                  {
                    strike: "77.00",
                    iv: "71.5",
                    delta: "0.3548",
                    theta: "-0.0855",
                    vega: "0.0684",
                    indic: "3.825094519480146",
                  },
                ],
                put: [
                  {
                    strike: "71.00",
                    iv: "68.6",
                    delta: "-0.4361",
                    theta: "-0.0868",
                    vega: "0.0724",
                    indic: "5.1653166681579705",
                  },
                  {
                    strike: "70.00",
                    iv: "68.5",
                    delta: "-0.3982",
                    theta: "-0.0850",
                    vega: "0.0709",
                    indic: "4.662915627968722",
                  },
                  {
                    strike: "69.00",
                    iv: "68.6",
                    delta: "-0.3608",
                    theta: "-0.0826",
                    vega: "0.0688",
                    indic: "4.198324524812666",
                  },
                  {
                    strike: "68.00",
                    iv: "68.9",
                    delta: "-0.3245",
                    theta: "-0.0796",
                    vega: "0.0661",
                    indic: "3.7714883101299073",
                  },
                  {
                    strike: "67.00",
                    iv: "69.3",
                    delta: "-0.2899",
                    theta: "-0.0762",
                    vega: "0.0629",
                    indic: "3.381722094673929",
                  },
                  {
                    strike: "66.00",
                    iv: "69.9",
                    delta: "-0.2574",
                    theta: "-0.0724",
                    vega: "0.0593",
                    indic: "3.0277596530249156",
                  },
                  {
                    strike: "65.00",
                    iv: "70.6",
                    delta: "-0.2272",
                    theta: "-0.0684",
                    vega: "0.0554",
                    indic: "2.7078475465528165",
                  },
                ],
              },
              next_month: {
                call: [
                  {
                    strike: "71.00",
                    iv: "82.6",
                    delta: "0.5754",
                    theta: "-0.0707",
                    vega: "0.1065",
                    indic: "10.08015082351158",
                  },
                  {
                    strike: "72.00",
                    iv: "82.7",
                    delta: "0.5542",
                    theta: "-0.0714",
                    vega: "0.1074",
                    indic: "9.647338674365898",
                  },
                  {
                    strike: "73.00",
                    iv: "82.9",
                    delta: "0.5333",
                    theta: "-0.0720",
                    vega: "0.1080",
                    indic: "9.234958675489555",
                  },
                  {
                    strike: "74.00",
                    iv: "83.1",
                    delta: "0.5127",
                    theta: "-0.0724",
                    vega: "0.1083",
                    indic: "8.842372578598592",
                  },
                  {
                    strike: "75.00",
                    iv: "83.3",
                    delta: "0.4925",
                    theta: "-0.0726",
                    vega: "0.1084",
                    indic: "8.468889672426034",
                  },
                  {
                    strike: "76.00",
                    iv: "83.6",
                    delta: "0.4728",
                    theta: "-0.0727",
                    vega: "0.1081",
                    indic: "8.11378027518785",
                  },
                  {
                    strike: "77.00",
                    iv: "83.9",
                    delta: "0.4536",
                    theta: "-0.0727",
                    vega: "0.1077",
                    indic: "7.776288345570229",
                  },
                ],
                put: [
                  {
                    strike: "71.00",
                    iv: "82.6",
                    delta: "-0.4246",
                    theta: "-0.0707",
                    vega: "0.1065",
                    indic: "8.958725324052086",
                  },
                  {
                    strike: "70.00",
                    iv: "82.5",
                    delta: "-0.4033",
                    theta: "-0.0698",
                    vega: "0.1052",
                    indic: "8.41254145779008",
                  },
                  {
                    strike: "69.00",
                    iv: "82.5",
                    delta: "-0.3820",
                    theta: "-0.0687",
                    vega: "0.1036",
                    indic: "7.887853128293546",
                  },
                  {
                    strike: "68.00",
                    iv: "82.5",
                    delta: "-0.3607",
                    theta: "-0.0675",
                    vega: "0.1017",
                    indic: "7.385058457344293",
                  },
                  {
                    strike: "67.00",
                    iv: "82.6",
                    delta: "-0.3395",
                    theta: "-0.0661",
                    vega: "0.0995",
                    indic: "6.904450401020259",
                  },
                  {
                    strike: "66.00",
                    iv: "82.7",
                    delta: "-0.3187",
                    theta: "-0.0645",
                    vega: "0.0970",
                    indic: "6.446207120256272",
                  },
                  {
                    strike: "65.00",
                    iv: "82.9",
                    delta: "-0.2983",
                    theta: "-0.0629",
                    vega: "0.0942",
                    indic: "6.010385083913192",
                  },
                ],
              },
            },
          },
          {
            underlyer: "DOGE",
            spot_ref: "0.0882900",
            prices: {
              weekly: {
                call: [
                  {
                    strike: "0.0850000",
                    iv: "105.3",
                    delta: "0.7187",
                    theta: "-0.0004",
                    vega: "0.0000",
                    indic: "0.0053225238897546615",
                  },
                  {
                    strike: "0.0900000",
                    iv: "100.8",
                    delta: "0.4031",
                    theta: "-0.0004",
                    vega: "0.0000",
                    indic: "0.002624968790746411",
                  },
                  {
                    strike: "0.0950000",
                    iv: "106.0",
                    delta: "0.1607",
                    theta: "-0.0003",
                    vega: "0.0000",
                    indic: "0.0012198546183994734",
                  },
                  {
                    strike: "0.1000000",
                    iv: "116.2",
                    delta: "0.0596",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0005893711397253545",
                  },
                  {
                    strike: "0.1050000",
                    iv: "127.6",
                    delta: "0.0237",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.000303059906003972",
                  },
                  {
                    strike: "0.1100000",
                    iv: "139.0",
                    delta: "0.0104",
                    theta: "-0.0000",
                    vega: "0.0000",
                    indic: "0.00016524155762907356",
                  },
                  {
                    strike: "0.1150000",
                    iv: "173.6",
                    delta: "0.0049",
                    theta: "-0.0000",
                    vega: "0.0000",
                    indic: "0.00021202314025786087",
                  },
                ],
                put: [
                  {
                    strike: "0.0850000",
                    iv: "105.3",
                    delta: "-0.2813",
                    theta: "-0.0004",
                    vega: "0.0000",
                    indic: "0.0020016629435760346",
                  },
                  {
                    strike: "0.0800000",
                    iv: "118.8",
                    delta: "-0.0996",
                    theta: "-0.0002",
                    vega: "0.0000",
                    indic: "0.0008765718370572686",
                  },
                  {
                    strike: "0.0750000",
                    iv: "136.9",
                    delta: "-0.0337",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0003856182570137886",
                  },
                  {
                    strike: "0.0700000",
                    iv: "157.4",
                    delta: "-0.0119",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0001730407385458978",
                  },
                  {
                    strike: "0.0650000",
                    iv: "179.5",
                    delta: "-0.0045",
                    theta: "-0.0000",
                    vega: "0.0000",
                    indic: "7.921720708258125e-05",
                  },
                  {
                    strike: "0.0600000",
                    iv: "276.8",
                    delta: "-0.0018",
                    theta: "-0.0000",
                    vega: "0.0000",
                    indic: "0.00017672480734586037",
                  },
                  {
                    strike: "0.0550000",
                    iv: "390.4",
                    delta: "-0.0007",
                    theta: "-0.0000",
                    vega: "0.0000",
                    indic: "0.0002625363881240861",
                  },
                ],
              },
              biweekly: {
                call: [
                  {
                    strike: "0.0850000",
                    iv: "92.1",
                    delta: "0.6456",
                    theta: "-0.0002",
                    vega: "0.0001",
                    indic: "0.007349485951207449",
                  },
                  {
                    strike: "0.0900000",
                    iv: "91.6",
                    delta: "0.4676",
                    theta: "-0.0002",
                    vega: "0.0001",
                    indic: "0.0048538960337818",
                  },
                  {
                    strike: "0.0950000",
                    iv: "94.6",
                    delta: "0.3112",
                    theta: "-0.0002",
                    vega: "0.0001",
                    indic: "0.003198369435475284",
                  },
                  {
                    strike: "0.1000000",
                    iv: "99.6",
                    delta: "0.2012",
                    theta: "-0.0002",
                    vega: "0.0000",
                    indic: "0.002155851276994792",
                  },
                  {
                    strike: "0.1050000",
                    iv: "105.6",
                    delta: "0.1313",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0014982075830527013",
                  },
                  {
                    strike: "0.1100000",
                    iv: "111.8",
                    delta: "0.0879",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0010726694986531466",
                  },
                  {
                    strike: "0.1150000",
                    iv: "117.9",
                    delta: "0.0605",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0007886874039210127",
                  },
                ],
                put: [
                  {
                    strike: "0.0850000",
                    iv: "92.1",
                    delta: "-0.3544",
                    theta: "-0.0002",
                    vega: "0.0001",
                    indic: "0.00395881612006907",
                  },
                  {
                    strike: "0.0800000",
                    iv: "96.8",
                    delta: "-0.2062",
                    theta: "-0.0002",
                    vega: "0.0000",
                    indic: "0.0023250504033856256",
                  },
                  {
                    strike: "0.0750000",
                    iv: "104.8",
                    delta: "-0.1117",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0013547504626371595",
                  },
                  {
                    strike: "0.0700000",
                    iv: "115.2",
                    delta: "-0.0596",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0007965811140840211",
                  },
                  {
                    strike: "0.0650000",
                    iv: "127.3",
                    delta: "-0.0320",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0004744862921262352",
                  },
                  {
                    strike: "0.0600000",
                    iv: "134.9",
                    delta: "-0.0175",
                    theta: "-0.0000",
                    vega: "0.0000",
                    indic: "0.00023394705014588308",
                  },
                  {
                    strike: "0.0550000",
                    iv: "155.7",
                    delta: "-0.0098",
                    theta: "-0.0000",
                    vega: "0.0000",
                    indic: "0.0001744217049436162",
                  },
                ],
              },
              monthly: {
                call: [
                  {
                    strike: "0.0850000",
                    iv: "102.6",
                    delta: "0.6165",
                    theta: "-0.0002",
                    vega: "0.0001",
                    indic: "0.011183370288904058",
                  },
                  {
                    strike: "0.0900000",
                    iv: "102.8",
                    delta: "0.5140",
                    theta: "-0.0002",
                    vega: "0.0001",
                    indic: "0.008879328229338776",
                  },
                  {
                    strike: "0.0950000",
                    iv: "104.4",
                    delta: "0.4187",
                    theta: "-0.0002",
                    vega: "0.0001",
                    indic: "0.00707929188718575",
                  },
                  {
                    strike: "0.1000000",
                    iv: "106.9",
                    delta: "0.3371",
                    theta: "-0.0002",
                    vega: "0.0001",
                    indic: "0.005696157586877949",
                  },
                  {
                    strike: "0.1050000",
                    iv: "110.0",
                    delta: "0.2708",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.004636236633313456",
                  },
                  {
                    strike: "0.1100000",
                    iv: "113.4",
                    delta: "0.2183",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.003819060747701889",
                  },
                  {
                    strike: "0.1150000",
                    iv: "116.9",
                    delta: "0.1773",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.0031824298483752456",
                  },
                ],
                put: [
                  {
                    strike: "0.0850000",
                    iv: "102.6",
                    delta: "-0.3835",
                    theta: "-0.0002",
                    vega: "0.0001",
                    indic: "0.0076353562973331054",
                  },
                  {
                    strike: "0.0800000",
                    iv: "104.1",
                    delta: "-0.2847",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.005489429586110171",
                  },
                  {
                    strike: "0.0750000",
                    iv: "107.5",
                    delta: "-0.2005",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.00387181734783831",
                  },
                  {
                    strike: "0.0700000",
                    iv: "112.7",
                    delta: "-0.1358",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0027019455730805855",
                  },
                  {
                    strike: "0.0650000",
                    iv: "119.6",
                    delta: "-0.0898",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0018764704393658075",
                  },
                  {
                    strike: "0.0600000",
                    iv: "127.8",
                    delta: "-0.0587",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0013004020618995315",
                  },
                  {
                    strike: "0.0550000",
                    iv: "137.5",
                    delta: "-0.0381",
                    theta: "-0.0000",
                    vega: "0.0000",
                    indic: "0.0008995405994844967",
                  },
                ],
              },
              next_month: {
                call: [
                  {
                    strike: "0.0850000",
                    iv: "106.3",
                    delta: "0.6176",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.01608922386248944",
                  },
                  {
                    strike: "0.0900000",
                    iv: "106.5",
                    delta: "0.5510",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.013933436007264807",
                  },
                  {
                    strike: "0.0950000",
                    iv: "107.1",
                    delta: "0.4875",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.01210191707566572",
                  },
                  {
                    strike: "0.1000000",
                    iv: "108.2",
                    delta: "0.4291",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.01055464958256196",
                  },
                  {
                    strike: "0.1050000",
                    iv: "109.5",
                    delta: "0.3767",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.009249750646466107",
                  },
                  {
                    strike: "0.1100000",
                    iv: "111.1",
                    delta: "0.3305",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.008147910488477073",
                  },
                  {
                    strike: "0.1150000",
                    iv: "112.8",
                    delta: "0.2904",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.007214557550408982",
                  },
                  {
                    strike: "0.12",
                    iv: "112.8",
                    delta: "0.2904",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.007214557550408982",
                  },
                ],
                put: [
                  {
                    strike: "0.0850000",
                    iv: "106.3",
                    delta: "-0.3824",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.012255845823150861",
                  },
                  {
                    strike: "0.0800000",
                    iv: "106.9",
                    delta: "-0.3157",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.009767332372586517",
                  },
                  {
                    strike: "0.0750000",
                    iv: "108.3",
                    delta: "-0.2522",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.007649914221367085",
                  },
                  {
                    strike: "0.0700000",
                    iv: "110.7",
                    delta: "-0.1951",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.005897951377135554",
                  },
                  {
                    strike: "0.0650000",
                    iv: "114.1",
                    delta: "-0.1466",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.004485103990870425",
                  },
                  {
                    strike: "0.0600000",
                    iv: "118.5",
                    delta: "-0.1074",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.0033700898857561574",
                  },
                  {
                    strike: "0.0550000",
                    iv: "107.7",
                    delta: "-0.0770",
                    theta: "-0.0000",
                    vega: "0.0000",
                    indic: "0.0016220318514144948",
                  },
                ],
              },
            },
          },
          {
            underlyer: "LDO",
            spot_ref: "2.3250",
            prices: {
              weekly: {
                call: [
                  {
                    strike: "2.3000",
                    iv: "118.7",
                    delta: "0.5784",
                    theta: "-0.0113",
                    vega: "0.0008",
                    indic: "0.11817745793054835",
                  },
                  {
                    strike: "2.4000",
                    iv: "117.9",
                    delta: "0.3425",
                    theta: "-0.0106",
                    vega: "0.0008",
                    indic: "0.07344366622455356",
                  },
                  {
                    strike: "2.5000",
                    iv: "122.9",
                    delta: "0.1729",
                    theta: "-0.0077",
                    vega: "0.0005",
                    indic: "0.04531622478124586",
                  },
                  {
                    strike: "2.6000",
                    iv: "131.0",
                    delta: "0.0836",
                    theta: "-0.0049",
                    vega: "0.0003",
                    indic: "0.028488818281366013",
                  },
                  {
                    strike: "2.7000",
                    iv: "140.3",
                    delta: "0.0414",
                    theta: "-0.0030",
                    vega: "0.0002",
                    indic: "0.0183798024063192",
                  },
                  {
                    strike: "2.8000",
                    iv: "150.0",
                    delta: "0.0216",
                    theta: "-0.0019",
                    vega: "0.0001",
                    indic: "0.012167251884773855",
                  },
                  {
                    strike: "2.9000",
                    iv: "159.6",
                    delta: "0.0118",
                    theta: "-0.0012",
                    vega: "0.0001",
                    indic: "0.008248685637493869",
                  },
                ],
                put: [
                  {
                    strike: "2.3000",
                    iv: "118.7",
                    delta: "-0.4216",
                    theta: "-0.0113",
                    vega: "0.0008",
                    indic: "0.09155533146284256",
                  },
                  {
                    strike: "2.2000",
                    iv: "126.2",
                    delta: "-0.2176",
                    theta: "-0.0091",
                    vega: "0.0006",
                    indic: "0.05454443528759545",
                  },
                  {
                    strike: "2.1000",
                    iv: "138.5",
                    delta: "-0.1002",
                    theta: "-0.0059",
                    vega: "0.0004",
                    indic: "0.03197244967270346",
                  },
                  {
                    strike: "2.0000",
                    iv: "153.7",
                    delta: "-0.0451",
                    theta: "-0.0036",
                    vega: "0.0002",
                    indic: "0.018693007164533204",
                  },
                  {
                    strike: "1.9000",
                    iv: "170.6",
                    delta: "-0.0207",
                    theta: "-0.0021",
                    vega: "0.0001",
                    indic: "0.010945650770075782",
                  },
                  {
                    strike: "1.8000",
                    iv: "188.7",
                    delta: "-0.0098",
                    theta: "-0.0012",
                    vega: "0.0001",
                    indic: "0.0064241606543827545",
                  },
                  {
                    strike: "1.7000",
                    iv: "207.9",
                    delta: "-0.0048",
                    theta: "-0.0007",
                    vega: "0.0000",
                    indic: "0.003778017275686267",
                  },
                ],
              },
              biweekly: {
                call: [
                  {
                    strike: "2.3000",
                    iv: "130.5",
                    delta: "0.5631",
                    theta: "-0.0077",
                    vega: "0.0015",
                    indic: "0.21223621932280468",
                  },
                  {
                    strike: "2.4000",
                    iv: "130.7",
                    delta: "0.4606",
                    theta: "-0.0078",
                    vega: "0.0015",
                    indic: "0.16838198703577512",
                  },
                  {
                    strike: "2.5000",
                    iv: "132.6",
                    delta: "0.3673",
                    theta: "-0.0075",
                    vega: "0.0014",
                    indic: "0.13388365219807075",
                  },
                  {
                    strike: "2.6000",
                    iv: "135.6",
                    delta: "0.2889",
                    theta: "-0.0069",
                    vega: "0.0013",
                    indic: "0.10719314413294645",
                  },
                  {
                    strike: "2.7000",
                    iv: "139.4",
                    delta: "0.2263",
                    theta: "-0.0063",
                    vega: "0.0012",
                    indic: "0.08663651048236609",
                  },
                  {
                    strike: "2.8000",
                    iv: "143.6",
                    delta: "0.1777",
                    theta: "-0.0056",
                    vega: "0.0010",
                    indic: "0.07074710715028398",
                  },
                  {
                    strike: "2.9000",
                    iv: "148.1",
                    delta: "0.1404",
                    theta: "-0.0049",
                    vega: "0.0009",
                    indic: "0.0583666363906295",
                  },
                ],
                put: [
                  {
                    strike: "2.3000",
                    iv: "130.5",
                    delta: "-0.4369",
                    theta: "-0.0077",
                    vega: "0.0015",
                    indic: "0.18303636687112568",
                  },
                  {
                    strike: "2.2000",
                    iv: "132.2",
                    delta: "-0.3353",
                    theta: "-0.0072",
                    vega: "0.0014",
                    indic: "0.1371232881778327",
                  },
                  {
                    strike: "2.1000",
                    iv: "136.0",
                    delta: "-0.2455",
                    theta: "-0.0064",
                    vega: "0.0012",
                    indic: "0.10122334465461169",
                  },
                  {
                    strike: "2.0000",
                    iv: "141.8",
                    delta: "-0.1735",
                    theta: "-0.0054",
                    vega: "0.0010",
                    indic: "0.07411101334298731",
                  },
                  {
                    strike: "1.9000",
                    iv: "149.2",
                    delta: "-0.1200",
                    theta: "-0.0045",
                    vega: "0.0008",
                    indic: "0.05407497541960943",
                  },
                  {
                    strike: "1.8000",
                    iv: "158.0",
                    delta: "-0.0821",
                    theta: "-0.0036",
                    vega: "0.0006",
                    indic: "0.039426691140786174",
                  },
                  {
                    strike: "1.7000",
                    iv: "168.0",
                    delta: "-0.0559",
                    theta: "-0.0028",
                    vega: "0.0004",
                    indic: "0.0287565700315153",
                  },
                ],
              },
              monthly: {
                call: [
                  {
                    strike: "2.3000",
                    iv: "160.7",
                    delta: "0.5810",
                    theta: "-0.0060",
                    vega: "0.0023",
                    indic: "0.377718718149487",
                  },
                  {
                    strike: "2.4000",
                    iv: "160.7",
                    delta: "0.5278",
                    theta: "-0.0062",
                    vega: "0.0024",
                    indic: "0.33622189586576434",
                  },
                  {
                    strike: "2.5000",
                    iv: "161.3",
                    delta: "0.4768",
                    theta: "-0.0062",
                    vega: "0.0024",
                    indic: "0.2998951964549956",
                  },
                  {
                    strike: "2.6000",
                    iv: "162.4",
                    delta: "0.4292",
                    theta: "-0.0061",
                    vega: "0.0023",
                    indic: "0.26822761154888897",
                  },
                  {
                    strike: "2.7000",
                    iv: "163.9",
                    delta: "0.3855",
                    theta: "-0.0060",
                    vega: "0.0023",
                    indic: "0.240673219469288",
                  },
                  {
                    strike: "2.8000",
                    iv: "165.7",
                    delta: "0.3460",
                    theta: "-0.0059",
                    vega: "0.0022",
                    indic: "0.21669739006860367",
                  },
                  {
                    strike: "2.9000",
                    iv: "167.6",
                    delta: "0.3105",
                    theta: "-0.0057",
                    vega: "0.0021",
                    indic: "0.1958054392479931",
                  },
                ],
                put: [
                  {
                    strike: "2.3000",
                    iv: "160.7",
                    delta: "-0.4190",
                    theta: "-0.0060",
                    vega: "0.0023",
                    indic: "0.3437550827906676",
                  },
                  {
                    strike: "2.2000",
                    iv: "161.4",
                    delta: "-0.3651",
                    theta: "-0.0058",
                    vega: "0.0022",
                    indic: "0.29083709473032926",
                  },
                  {
                    strike: "2.1000",
                    iv: "162.9",
                    delta: "-0.3121",
                    theta: "-0.0055",
                    vega: "0.0021",
                    indic: "0.24375906373038636",
                  },
                  {
                    strike: "2.0000",
                    iv: "165.3",
                    delta: "-0.2618",
                    theta: "-0.0052",
                    vega: "0.0019",
                    indic: "0.202566913579184",
                  },
                  {
                    strike: "1.9000",
                    iv: "168.6",
                    delta: "-0.2158",
                    theta: "-0.0048",
                    vega: "0.0017",
                    indic: "0.16708354443934648",
                  },
                  {
                    strike: "1.8000",
                    iv: "172.9",
                    delta: "-0.1751",
                    theta: "-0.0043",
                    vega: "0.0015",
                    indic: "0.1369356233082042",
                  },
                  {
                    strike: "1.7000",
                    iv: "178.3",
                    delta: "-0.1401",
                    theta: "-0.0038",
                    vega: "0.0013",
                    indic: "0.11161121286381775",
                  },
                ],
              },
              next_month: {
                call: [
                  {
                    strike: "2.3000",
                    iv: "166.0",
                    delta: "0.6100",
                    theta: "-0.0042",
                    vega: "0.0034",
                    indic: "0.564247448295986",
                  },
                  {
                    strike: "2.4000",
                    iv: "165.7",
                    delta: "0.5755",
                    theta: "-0.0043",
                    vega: "0.0035",
                    indic: "0.5253042334423167",
                  },
                  {
                    strike: "2.5000",
                    iv: "165.6",
                    delta: "0.5419",
                    theta: "-0.0043",
                    vega: "0.0035",
                    indic: "0.4896620971579133",
                  },
                  {
                    strike: "2.6000",
                    iv: "165.8",
                    delta: "0.5096",
                    theta: "-0.0043",
                    vega: "0.0035",
                    indic: "0.4570755916704038",
                  },
                  {
                    strike: "2.7000",
                    iv: "166.2",
                    delta: "0.4787",
                    theta: "-0.0043",
                    vega: "0.0035",
                    indic: "0.4272954674111199",
                  },
                  {
                    strike: "2.8000",
                    iv: "166.7",
                    delta: "0.4494",
                    theta: "-0.0043",
                    vega: "0.0035",
                    indic: "0.4000771387023603",
                  },
                  {
                    strike: "2.9000",
                    iv: "167.3",
                    delta: "0.4218",
                    theta: "-0.0043",
                    vega: "0.0035",
                    indic: "0.3751866870654944",
                  },
                ],
                put: [
                  {
                    strike: "2.3000",
                    iv: "166.0",
                    delta: "-0.3900",
                    theta: "-0.0042",
                    vega: "0.0034",
                    indic: "0.5208060880378159",
                  },
                  {
                    strike: "2.2000",
                    iv: "166.6",
                    delta: "-0.3549",
                    theta: "-0.0041",
                    vega: "0.0033",
                    indic: "0.46328109755654523",
                  },
                  {
                    strike: "2.1000",
                    iv: "167.5",
                    delta: "-0.3197",
                    theta: "-0.0039",
                    vega: "0.0032",
                    indic: "0.4094909664458073",
                  },
                  {
                    strike: "2.0000",
                    iv: "168.9",
                    delta: "-0.2848",
                    theta: "-0.0038",
                    vega: "0.0030",
                    indic: "0.35959728363985455",
                  },
                  {
                    strike: "1.9000",
                    iv: "170.7",
                    delta: "-0.2510",
                    theta: "-0.0036",
                    vega: "0.0028",
                    indic: "0.31370711497624404",
                  },
                  {
                    strike: "1.8000",
                    iv: "173.0",
                    delta: "-0.2186",
                    theta: "-0.0033",
                    vega: "0.0026",
                    indic: "0.27186276220331207",
                  },
                  {
                    strike: "1.7000",
                    iv: "175.8",
                    delta: "-0.1882",
                    theta: "-0.0031",
                    vega: "0.0024",
                    indic: "0.23403622372316302",
                  },
                ],
              },
            },
          },
          {
            underlyer: "APT",
            spot_ref: "7.3788",
            prices: {
              weekly: {
                call: [
                  {
                    strike: "7.0000",
                    iv: "141.1",
                    delta: "0.7503",
                    theta: "-0.0347",
                    vega: "0.0021",
                    indic: "0.5859948308336529",
                  },
                  {
                    strike: "7.5000",
                    iv: "133.1",
                    delta: "0.4375",
                    theta: "-0.0407",
                    vega: "0.0026",
                    indic: "0.3057234337733892",
                  },
                  {
                    strike: "8.0000",
                    iv: "138.8",
                    delta: "0.1771",
                    theta: "-0.0279",
                    vega: "0.0017",
                    indic: "0.15135604465793806",
                  },
                  {
                    strike: "8.5000",
                    iv: "151.5",
                    delta: "0.0654",
                    theta: "-0.0150",
                    vega: "0.0008",
                    indic: "0.07770267727877478",
                  },
                  {
                    strike: "9.0000",
                    iv: "166.2",
                    delta: "0.0259",
                    theta: "-0.0077",
                    vega: "0.0004",
                    indic: "0.04220825361480507",
                  },
                  {
                    strike: "9.5000",
                    iv: "211.9",
                    delta: "0.0113",
                    theta: "-0.0041",
                    vega: "0.0002",
                    indic: "0.0462180217123106",
                  },
                  {
                    strike: "10.0000",
                    iv: "263.2",
                    delta: "0.0054",
                    theta: "-0.0023",
                    vega: "0.0001",
                    indic: "0.053090471647726734",
                  },
                ],
                put: [
                  {
                    strike: "7.0000",
                    iv: "141.1",
                    delta: "-0.2497",
                    theta: "-0.0347",
                    vega: "0.0021",
                    indic: "0.20247644033834877",
                  },
                  {
                    strike: "6.5000",
                    iv: "160.8",
                    delta: "-0.0846",
                    theta: "-0.0193",
                    vega: "0.0010",
                    indic: "0.09225011514597248",
                  },
                  {
                    strike: "6.0000",
                    iv: "186.8",
                    delta: "-0.0277",
                    theta: "-0.0092",
                    vega: "0.0004",
                    indic: "0.04179154072990943",
                  },
                  {
                    strike: "5.5000",
                    iv: "216.2",
                    delta: "-0.0095",
                    theta: "-0.0043",
                    vega: "0.0002",
                    indic: "0.018995584409852162",
                  },
                  {
                    strike: "5.0000",
                    iv: "372.8",
                    delta: "-0.0034",
                    theta: "-0.0020",
                    vega: "0.0001",
                    indic: "0.04293866757592402",
                  },
                  {
                    strike: "4.5000",
                    iv: "589.8",
                    delta: "-0.0013",
                    theta: "-0.0009",
                    vega: "0.0000",
                    indic: "0.072877306924436",
                  },
                  {
                    strike: "4.0000",
                    iv: "928.4",
                    delta: "-0.0005",
                    theta: "-0.0005",
                    vega: "0.0000",
                    indic: "0.12241722273026912",
                  },
                ],
              },
              biweekly: {
                call: [
                  {
                    strike: "7.0000",
                    iv: "104.1",
                    delta: "0.6837",
                    theta: "-0.0176",
                    vega: "0.0043",
                    indic: "0.736041262412706",
                  },
                  {
                    strike: "7.5000",
                    iv: "102.6",
                    delta: "0.4803",
                    theta: "-0.0194",
                    vega: "0.0048",
                    indic: "0.47711246110503636",
                  },
                  {
                    strike: "8.0000",
                    iv: "106.4",
                    delta: "0.3004",
                    theta: "-0.0176",
                    vega: "0.0042",
                    indic: "0.3093734156705614",
                  },
                  {
                    strike: "8.5000",
                    iv: "113.1",
                    delta: "0.1818",
                    theta: "-0.0142",
                    vega: "0.0032",
                    indic: "0.20664476566713064",
                  },
                  {
                    strike: "9.0000",
                    iv: "120.9",
                    delta: "0.1122",
                    theta: "-0.0109",
                    vega: "0.0023",
                    indic: "0.1430377296787746",
                  },
                  {
                    strike: "9.5000",
                    iv: "128.8",
                    delta: "0.0718",
                    theta: "-0.0084",
                    vega: "0.0017",
                    indic: "0.10229123654720518",
                  },
                  {
                    strike: "10.0000",
                    iv: "136.5",
                    delta: "0.0477",
                    theta: "-0.0064",
                    vega: "0.0012",
                    indic: "0.07523303866995967",
                  },
                ],
                put: [
                  {
                    strike: "7.0000",
                    iv: "104.1",
                    delta: "-0.3163",
                    theta: "-0.0176",
                    vega: "0.0043",
                    indic: "0.3460498244011956",
                  },
                  {
                    strike: "6.5000",
                    iv: "111.6",
                    delta: "-0.1634",
                    theta: "-0.0131",
                    vega: "0.0030",
                    indic: "0.1961715858559525",
                  },
                  {
                    strike: "6.0000",
                    iv: "123.8",
                    delta: "-0.0791",
                    theta: "-0.0086",
                    vega: "0.0018",
                    indic: "0.11071552602417667",
                  },
                  {
                    strike: "5.5000",
                    iv: "138.9",
                    delta: "-0.0382",
                    theta: "-0.0055",
                    vega: "0.0010",
                    indic: "0.06289736284716474",
                  },
                  {
                    strike: "5.0000",
                    iv: "147.4",
                    delta: "-0.0188",
                    theta: "-0.0034",
                    vega: "0.0006",
                    indic: "0.02818611082310568",
                  },
                  {
                    strike: "4.5000",
                    iv: "178.9",
                    delta: "-0.0095",
                    theta: "-0.0021",
                    vega: "0.0003",
                    indic: "0.022258054916534653",
                  },
                  {
                    strike: "4.0000",
                    iv: "221.1",
                    delta: "-0.0048",
                    theta: "-0.0013",
                    vega: "0.0002",
                    indic: "0.01960208544978745",
                  },
                ],
              },
              monthly: {
                call: [
                  {
                    strike: "7.0000",
                    iv: "125.6",
                    delta: "0.6361",
                    theta: "-0.0144",
                    vega: "0.0071",
                    indic: "1.1382330192084025",
                  },
                  {
                    strike: "7.5000",
                    iv: "125.4",
                    delta: "0.5273",
                    theta: "-0.0152",
                    vega: "0.0075",
                    indic: "0.90669317172215",
                  },
                  {
                    strike: "8.0000",
                    iv: "127.2",
                    delta: "0.4257",
                    theta: "-0.0152",
                    vega: "0.0074",
                    indic: "0.7265059565991292",
                  },
                  {
                    strike: "8.5000",
                    iv: "130.2",
                    delta: "0.3394",
                    theta: "-0.0145",
                    vega: "0.0069",
                    indic: "0.5884446489012229",
                  },
                  {
                    strike: "9.0000",
                    iv: "134.0",
                    delta: "0.2702",
                    theta: "-0.0135",
                    vega: "0.0063",
                    indic: "0.482698762446784",
                  },
                  {
                    strike: "9.5000",
                    iv: "138.1",
                    delta: "0.2162",
                    theta: "-0.0124",
                    vega: "0.0055",
                    indic: "0.40101266390217627",
                  },
                  {
                    strike: "10.0000",
                    iv: "142.4",
                    delta: "0.1745",
                    theta: "-0.0112",
                    vega: "0.0049",
                    indic: "0.33712633091433575",
                  },
                ],
                put: [
                  {
                    strike: "7.0000",
                    iv: "125.6",
                    delta: "-0.3639",
                    theta: "-0.0144",
                    vega: "0.0071",
                    indic: "0.7389780063927214",
                  },
                  {
                    strike: "6.5000",
                    iv: "128.3",
                    delta: "-0.2611",
                    theta: "-0.0127",
                    vega: "0.0061",
                    indic: "0.526597865440682",
                  },
                  {
                    strike: "6.0000",
                    iv: "133.7",
                    delta: "-0.1764",
                    theta: "-0.0106",
                    vega: "0.0049",
                    indic: "0.36761612723598414",
                  },
                  {
                    strike: "5.5000",
                    iv: "141.7",
                    delta: "-0.1142",
                    theta: "-0.0083",
                    vega: "0.0037",
                    indic: "0.25329076335919143",
                  },
                  {
                    strike: "5.0000",
                    iv: "152.0",
                    delta: "-0.0720",
                    theta: "-0.0064",
                    vega: "0.0026",
                    indic: "0.1729473372465533",
                  },
                  {
                    strike: "4.5000",
                    iv: "164.7",
                    delta: "-0.0447",
                    theta: "-0.0047",
                    vega: "0.0018",
                    indic: "0.11709920855861966",
                  },
                  {
                    strike: "4.0000",
                    iv: "179.5",
                    delta: "-0.0274",
                    theta: "-0.0035",
                    vega: "0.0012",
                    indic: "0.07846931020151804",
                  },
                ],
              },
              next_month: {
                call: [
                  {
                    strike: "7.0000",
                    iv: "153.9",
                    delta: "0.6365",
                    theta: "-0.0120",
                    vega: "0.0105",
                    indic: "1.7965072498534393",
                  },
                  {
                    strike: "7.5000",
                    iv: "153.4",
                    delta: "0.5768",
                    theta: "-0.0124",
                    vega: "0.0109",
                    indic: "1.5896904782642194",
                  },
                  {
                    strike: "8.0000",
                    iv: "153.6",
                    delta: "0.5195",
                    theta: "-0.0127",
                    vega: "0.0111",
                    indic: "1.4113331499919397",
                  },
                  {
                    strike: "8.5000",
                    iv: "154.2",
                    delta: "0.4662",
                    theta: "-0.0127",
                    vega: "0.0111",
                    indic: "1.258006622501378",
                  },
                  {
                    strike: "9.0000",
                    iv: "155.3",
                    delta: "0.4175",
                    theta: "-0.0126",
                    vega: "0.0109",
                    indic: "1.126238423691575",
                  },
                  {
                    strike: "9.5000",
                    iv: "156.7",
                    delta: "0.3739",
                    theta: "-0.0123",
                    vega: "0.0106",
                    indic: "1.01279248506675",
                  },
                  {
                    strike: "10.0000",
                    iv: "158.2",
                    delta: "0.3350",
                    theta: "-0.0119",
                    vega: "0.0102",
                    indic: "0.9148054542507196",
                  },
                ],
                put: [
                  {
                    strike: "7.0000",
                    iv: "153.9",
                    delta: "-0.3635",
                    theta: "-0.0120",
                    vega: "0.0105",
                    indic: "1.3911646460996772",
                  },
                  {
                    strike: "6.5000",
                    iv: "155.4",
                    delta: "-0.3034",
                    theta: "-0.0112",
                    vega: "0.0097",
                    indic: "1.1293878966420006",
                  },
                  {
                    strike: "6.0000",
                    iv: "157.9",
                    delta: "-0.2455",
                    theta: "-0.0103",
                    vega: "0.0088",
                    indic: "0.9009532684713051",
                  },
                  {
                    strike: "5.5000",
                    iv: "161.8",
                    delta: "-0.1925",
                    theta: "-0.0092",
                    vega: "0.0076",
                    indic: "0.7063298499304986",
                  },
                  {
                    strike: "5.0000",
                    iv: "167.1",
                    delta: "-0.1462",
                    theta: "-0.0079",
                    vega: "0.0064",
                    indic: "0.5443426958322239",
                  },
                  {
                    strike: "4.5000",
                    iv: "142.5",
                    delta: "-0.1077",
                    theta: "-0.0067",
                    vega: "0.0052",
                    indic: "0.2775568526801234",
                  },
                  {
                    strike: "4.0000",
                    iv: "150.4",
                    delta: "-0.0770",
                    theta: "-0.0055",
                    vega: "0.0040",
                    indic: "0.19953578321380383",
                  },
                ],
              },
            },
          },
          {
            underlyer: "SOL",
            spot_ref: "59.9600",
            prices: {
              weekly: {
                call: [
                  {
                    strike: "59.5000",
                    iv: "102.8",
                    delta: "0.5606",
                    theta: "-0.2861",
                    vega: "0.0209",
                    indic: "2.560012858848282",
                  },
                  {
                    strike: "60.0000",
                    iv: "102.3",
                    delta: "0.5122",
                    theta: "-0.2879",
                    vega: "0.0211",
                    indic: "2.3032600308402884",
                  },
                  {
                    strike: "60.5000",
                    iv: "102.0",
                    delta: "0.4637",
                    theta: "-0.2860",
                    vega: "0.0210",
                    indic: "2.0674762654920613",
                  },
                  {
                    strike: "61.0000",
                    iv: "101.9",
                    delta: "0.4162",
                    theta: "-0.2807",
                    vega: "0.0207",
                    indic: "1.8524205731870538",
                  },
                  {
                    strike: "61.5000",
                    iv: "102.1",
                    delta: "0.3705",
                    theta: "-0.2723",
                    vega: "0.0200",
                    indic: "1.6574888880948713",
                  },
                  {
                    strike: "62.0000",
                    iv: "102.5",
                    delta: "0.3274",
                    theta: "-0.2613",
                    vega: "0.0191",
                    indic: "1.4817631007622936",
                  },
                  {
                    strike: "62.5000",
                    iv: "103.1",
                    delta: "0.2876",
                    theta: "-0.2482",
                    vega: "0.0181",
                    indic: "1.324081636114748",
                  },
                ],
                put: [
                  {
                    strike: "59.5000",
                    iv: "102.8",
                    delta: "-0.4394",
                    theta: "-0.2861",
                    vega: "0.0209",
                    indic: "2.0746672477654293",
                  },
                  {
                    strike: "59.0000",
                    iv: "103.5",
                    delta: "-0.3922",
                    theta: "-0.2809",
                    vega: "0.0203",
                    indic: "1.8522489204293002",
                  },
                  {
                    strike: "58.5000",
                    iv: "104.5",
                    delta: "-0.3470",
                    theta: "-0.2725",
                    vega: "0.0196",
                    indic: "1.6501540967144983",
                  },
                  {
                    strike: "58.0000",
                    iv: "105.7",
                    delta: "-0.3047",
                    theta: "-0.2614",
                    vega: "0.0185",
                    indic: "1.4675587714126799",
                  },
                  {
                    strike: "57.5000",
                    iv: "107.2",
                    delta: "-0.2657",
                    theta: "-0.2482",
                    vega: "0.0174",
                    indic: "1.303387622290078",
                  },
                  {
                    strike: "57.0000",
                    iv: "108.8",
                    delta: "-0.2304",
                    theta: "-0.2334",
                    vega: "0.0161",
                    indic: "1.156390387966443",
                  },
                  {
                    strike: "56.5000",
                    iv: "110.6",
                    delta: "-0.1988",
                    theta: "-0.2178",
                    vega: "0.0148",
                    indic: "1.0252164299660986",
                  },
                ],
              },
              biweekly: {
                call: [
                  {
                    strike: "59.5000",
                    iv: "115.6",
                    delta: "0.5542",
                    theta: "-0.1908",
                    vega: "0.0390",
                    indic: "4.823261765344228",
                  },
                  {
                    strike: "60.0000",
                    iv: "115.6",
                    delta: "0.5332",
                    theta: "-0.1918",
                    vega: "0.0392",
                    indic: "4.585378112800374",
                  },
                  {
                    strike: "60.5000",
                    iv: "115.6",
                    delta: "0.5123",
                    theta: "-0.1924",
                    vega: "0.0393",
                    indic: "4.358393720354197",
                  },
                  {
                    strike: "61.0000",
                    iv: "115.6",
                    delta: "0.4916",
                    theta: "-0.1926",
                    vega: "0.0393",
                    indic: "4.142136135868576",
                  },
                  {
                    strike: "61.5000",
                    iv: "115.8",
                    delta: "0.4712",
                    theta: "-0.1923",
                    vega: "0.0392",
                    indic: "3.936385222445427",
                  },
                  {
                    strike: "62.0000",
                    iv: "116.0",
                    delta: "0.4511",
                    theta: "-0.1917",
                    vega: "0.0390",
                    indic: "3.740878518518169",
                  },
                  {
                    strike: "62.5000",
                    iv: "116.2",
                    delta: "0.4314",
                    theta: "-0.1907",
                    vega: "0.0387",
                    indic: "3.555317197624273",
                  },
                ],
                put: [
                  {
                    strike: "59.5000",
                    iv: "115.6",
                    delta: "-0.4458",
                    theta: "-0.1908",
                    vega: "0.0390",
                    indic: "4.28247452786206",
                  },
                  {
                    strike: "59.0000",
                    iv: "115.7",
                    delta: "-0.4248",
                    theta: "-0.1893",
                    vega: "0.0386",
                    indic: "4.03137772544866",
                  },
                  {
                    strike: "58.5000",
                    iv: "115.9",
                    delta: "-0.4039",
                    theta: "-0.1875",
                    vega: "0.0382",
                    indic: "3.7913652322328346",
                  },
                  {
                    strike: "58.0000",
                    iv: "116.2",
                    delta: "-0.3832",
                    theta: "-0.1852",
                    vega: "0.0376",
                    indic: "3.5624442671225225",
                  },
                  {
                    strike: "57.5000",
                    iv: "116.6",
                    delta: "-0.3628",
                    theta: "-0.1825",
                    vega: "0.0370",
                    indic: "3.344563937204928",
                  },
                  {
                    strike: "57.0000",
                    iv: "117.0",
                    delta: "-0.3428",
                    theta: "-0.1795",
                    vega: "0.0362",
                    indic: "3.1376161720953313",
                  },
                  {
                    strike: "56.5000",
                    iv: "117.5",
                    delta: "-0.3233",
                    theta: "-0.1761",
                    vega: "0.0354",
                    indic: "2.941438111372644",
                  },
                ],
              },
              monthly: {
                call: [
                  {
                    strike: "59.5000",
                    iv: "155.4",
                    delta: "0.5786",
                    theta: "-0.1633",
                    vega: "0.0601",
                    indic: "9.387871818656148",
                  },
                  {
                    strike: "60.0000",
                    iv: "155.3",
                    delta: "0.5686",
                    theta: "-0.1640",
                    vega: "0.0604",
                    indic: "9.170014632460312",
                  },
                  {
                    strike: "60.5000",
                    iv: "155.3",
                    delta: "0.5587",
                    theta: "-0.1647",
                    vega: "0.0606",
                    indic: "8.957556773838341",
                  },
                  {
                    strike: "61.0000",
                    iv: "155.3",
                    delta: "0.5489",
                    theta: "-0.1652",
                    vega: "0.0609",
                    indic: "8.75042307066176",
                  },
                  {
                    strike: "61.5000",
                    iv: "155.3",
                    delta: "0.5391",
                    theta: "-0.1657",
                    vega: "0.0610",
                    indic: "8.548533510773318",
                  },
                  {
                    strike: "62.0000",
                    iv: "155.3",
                    delta: "0.5294",
                    theta: "-0.1661",
                    vega: "0.0611",
                    indic: "8.351803709321228",
                  },
                  {
                    strike: "62.5000",
                    iv: "155.4",
                    delta: "0.5198",
                    theta: "-0.1664",
                    vega: "0.0612",
                    indic: "8.160145377775436",
                  },
                ],
                put: [
                  {
                    strike: "59.5000",
                    iv: "155.4",
                    delta: "-0.4214",
                    theta: "-0.1633",
                    vega: "0.0601",
                    indic: "8.738292702162841",
                  },
                  {
                    strike: "59.0000",
                    iv: "155.4",
                    delta: "-0.4115",
                    theta: "-0.1625",
                    vega: "0.0598",
                    indic: "8.461619087502946",
                  },
                  {
                    strike: "58.5000",
                    iv: "155.5",
                    delta: "-0.4015",
                    theta: "-0.1616",
                    vega: "0.0594",
                    indic: "8.190478794716569",
                  },
                  {
                    strike: "58.0000",
                    iv: "155.6",
                    delta: "-0.3915",
                    theta: "-0.1606",
                    vega: "0.0590",
                    indic: "7.9249297672988135",
                  },
                  {
                    strike: "57.5000",
                    iv: "155.8",
                    delta: "-0.3815",
                    theta: "-0.1596",
                    vega: "0.0586",
                    indic: "7.66502336277177",
                  },
                  {
                    strike: "57.0000",
                    iv: "155.9",
                    delta: "-0.3715",
                    theta: "-0.1584",
                    vega: "0.0581",
                    indic: "7.410803975344198",
                  },
                  {
                    strike: "56.5000",
                    iv: "156.1",
                    delta: "-0.3615",
                    theta: "-0.1572",
                    vega: "0.0576",
                    indic: "7.162308693574968",
                  },
                ],
              },
              next_month: {
                call: [
                  {
                    strike: "59.5000",
                    iv: "169.1",
                    delta: "0.6140",
                    theta: "-0.1181",
                    vega: "0.0869",
                    indic: "14.651545875331774",
                  },
                  {
                    strike: "60.0000",
                    iv: "169.0",
                    delta: "0.6079",
                    theta: "-0.1186",
                    vega: "0.0873",
                    indic: "14.451097584243819",
                  },
                  {
                    strike: "60.5000",
                    iv: "168.9",
                    delta: "0.6018",
                    theta: "-0.1190",
                    vega: "0.0876",
                    indic: "14.253896934435595",
                  },
                  {
                    strike: "61.0000",
                    iv: "168.8",
                    delta: "0.5957",
                    theta: "-0.1194",
                    vega: "0.0880",
                    indic: "14.059904189556473",
                  },
                  {
                    strike: "61.5000",
                    iv: "168.7",
                    delta: "0.5897",
                    theta: "-0.1198",
                    vega: "0.0883",
                    indic: "13.869078966565912",
                  },
                  {
                    strike: "62.0000",
                    iv: "168.6",
                    delta: "0.5837",
                    theta: "-0.1202",
                    vega: "0.0886",
                    indic: "13.681380309818685",
                  },
                  {
                    strike: "62.5000",
                    iv: "168.6",
                    delta: "0.5777",
                    theta: "-0.1205",
                    vega: "0.0889",
                    indic: "13.49676676312669",
                  },
                ],
                put: [
                  {
                    strike: "59.5000",
                    iv: "169.1",
                    delta: "-0.3860",
                    theta: "-0.1181",
                    vega: "0.0869",
                    indic: "13.87955217064458",
                  },
                  {
                    strike: "59.0000",
                    iv: "169.2",
                    delta: "-0.3799",
                    theta: "-0.1176",
                    vega: "0.0865",
                    indic: "13.583287116743275",
                  },
                  {
                    strike: "58.5000",
                    iv: "169.3",
                    delta: "-0.3738",
                    theta: "-0.1171",
                    vega: "0.0860",
                    indic: "13.290346931394627",
                  },
                  {
                    strike: "58.0000",
                    iv: "169.4",
                    delta: "-0.3676",
                    theta: "-0.1166",
                    vega: "0.0856",
                    indic: "13.000768948961074",
                  },
                  {
                    strike: "57.5000",
                    iv: "169.6",
                    delta: "-0.3614",
                    theta: "-0.1160",
                    vega: "0.0851",
                    indic: "12.71458954446874",
                  },
                  {
                    strike: "57.0000",
                    iv: "169.7",
                    delta: "-0.3553",
                    theta: "-0.1154",
                    vega: "0.0846",
                    indic: "12.431844052552993",
                  },
                  {
                    strike: "56.5000",
                    iv: "169.9",
                    delta: "-0.3491",
                    theta: "-0.1148",
                    vega: "0.0840",
                    indic: "12.152566685799904",
                  },
                ],
              },
            },
          },
          {
            underlyer: "SUI",
            spot_ref: "0.62",
            prices: {
              weekly: {
                call: [
                  {
                    strike: "0.60",
                    iv: "175.4",
                    delta: "0.6713",
                    theta: "-0.0037",
                    vega: "0.0002",
                    indic: "0.0493278856627134",
                  },
                  {
                    strike: "0.65",
                    iv: "170.4",
                    delta: "0.3381",
                    theta: "-0.0037",
                    vega: "0.0002",
                    indic: "0.02556418404621577",
                  },
                  {
                    strike: "0.70",
                    iv: "181.5",
                    delta: "0.1264",
                    theta: "-0.0022",
                    vega: "0.0001",
                    indic: "0.013162741523266741",
                  },
                  {
                    strike: "0.75",
                    iv: "199.0",
                    delta: "0.0467",
                    theta: "-0.0011",
                    vega: "0.0001",
                    indic: "0.007130481417084833",
                  },
                  {
                    strike: "0.80",
                    iv: "261.0",
                    delta: "0.0190",
                    theta: "-0.0006",
                    vega: "0.0000",
                    indic: "0.00741711616088124",
                  },
                  {
                    strike: "0.85",
                    iv: "343.4",
                    delta: "0.0085",
                    theta: "-0.0003",
                    vega: "0.0000",
                    indic: "0.009201732095310583",
                  },
                  {
                    strike: "0.90",
                    iv: "445.3",
                    delta: "0.0042",
                    theta: "-0.0002",
                    vega: "0.0000",
                    indic: "0.01222258387763761",
                  },
                ],
                put: [
                  {
                    strike: "0.60",
                    iv: "175.4",
                    delta: "-0.3287",
                    theta: "-0.0037",
                    vega: "0.0002",
                    indic: "0.026421578295208636",
                  },
                  {
                    strike: "0.55",
                    iv: "198.6",
                    delta: "-0.1119",
                    theta: "-0.0022",
                    vega: "0.0001",
                    indic: "0.01225089179605357",
                  },
                  {
                    strike: "0.50",
                    iv: "232.4",
                    delta: "-0.0346",
                    theta: "-0.0011",
                    vega: "0.0000",
                    indic: "0.005546512705683175",
                  },
                  {
                    strike: "0.45",
                    iv: "343.8",
                    delta: "-0.0111",
                    theta: "-0.0005",
                    vega: "0.0000",
                    indic: "0.005201308046065847",
                  },
                  {
                    strike: "0.40",
                    iv: "612.9",
                    delta: "-0.0037",
                    theta: "-0.0002",
                    vega: "0.0000",
                    indic: "0.009554703188007926",
                  },
                  {
                    strike: "0.35",
                    iv: "1082.1",
                    delta: "-0.0013",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.01757405625020283",
                  },
                  {
                    strike: "0.30",
                    iv: "1861.4",
                    delta: "-0.0005",
                    theta: "-0.0000",
                    vega: "0.0000",
                    indic: "0.029682852054187484",
                  },
                ],
              },
              biweekly: {
                call: [
                  {
                    strike: "0.60",
                    iv: "177.0",
                    delta: "0.6138",
                    theta: "-0.0025",
                    vega: "0.0004",
                    indic: "0.07890322257306082",
                  },
                  {
                    strike: "0.65",
                    iv: "176.1",
                    delta: "0.4610",
                    theta: "-0.0026",
                    vega: "0.0004",
                    indic: "0.05649375182916827",
                  },
                  {
                    strike: "0.70",
                    iv: "179.9",
                    delta: "0.3290",
                    theta: "-0.0024",
                    vega: "0.0004",
                    indic: "0.04079602408124333",
                  },
                  {
                    strike: "0.75",
                    iv: "186.4",
                    delta: "0.2311",
                    theta: "-0.0021",
                    vega: "0.0003",
                    indic: "0.030067143157036014",
                  },
                  {
                    strike: "0.80",
                    iv: "194.3",
                    delta: "0.1635",
                    theta: "-0.0018",
                    vega: "0.0003",
                    indic: "0.022684324184533977",
                  },
                  {
                    strike: "0.85",
                    iv: "155.9",
                    delta: "0.1178",
                    theta: "-0.0015",
                    vega: "0.0002",
                    indic: "0.00939748624879265",
                  },
                  {
                    strike: "0.90",
                    iv: "154.7",
                    delta: "0.0867",
                    theta: "-0.0012",
                    vega: "0.0002",
                    indic: "0.006037203977696415",
                  },
                ],
                put: [
                  {
                    strike: "0.60",
                    iv: "177.0",
                    delta: "-0.3862",
                    theta: "-0.0025",
                    vega: "0.0004",
                    indic: "0.05536254206978636",
                  },
                  {
                    strike: "0.55",
                    iv: "184.1",
                    delta: "-0.2454",
                    theta: "-0.0022",
                    vega: "0.0003",
                    indic: "0.035300790371992075",
                  },
                  {
                    strike: "0.50",
                    iv: "197.6",
                    delta: "-0.1426",
                    theta: "-0.0017",
                    vega: "0.0002",
                    indic: "0.02192308736367353",
                  },
                  {
                    strike: "0.45",
                    iv: "216.4",
                    delta: "-0.0790",
                    theta: "-0.0012",
                    vega: "0.0002",
                    indic: "0.013434795831907917",
                  },
                  {
                    strike: "0.40",
                    iv: "233.6",
                    delta: "-0.0428",
                    theta: "-0.0008",
                    vega: "0.0001",
                    indic: "0.007643800551477009",
                  },
                  {
                    strike: "0.35",
                    iv: "296.4",
                    delta: "-0.0230",
                    theta: "-0.0005",
                    vega: "0.0001",
                    indic: "0.006411261128893772",
                  },
                  {
                    strike: "0.30",
                    iv: "389.0",
                    delta: "-0.0122",
                    theta: "-0.0004",
                    vega: "0.0000",
                    indic: "0.005944794720866318",
                  },
                ],
              },
              monthly: {
                call: [
                  {
                    strike: "0.60",
                    iv: "171.2",
                    delta: "0.6124",
                    theta: "-0.0016",
                    vega: "0.0006",
                    indic: "0.11339327077819833",
                  },
                  {
                    strike: "0.65",
                    iv: "171.0",
                    delta: "0.5116",
                    theta: "-0.0016",
                    vega: "0.0006",
                    indic: "0.09244641611474314",
                  },
                  {
                    strike: "0.70",
                    iv: "173.0",
                    delta: "0.4198",
                    theta: "-0.0016",
                    vega: "0.0006",
                    indic: "0.07599641237161342",
                  },
                  {
                    strike: "0.75",
                    iv: "176.4",
                    delta: "0.3421",
                    theta: "-0.0016",
                    vega: "0.0006",
                    indic: "0.06316985887733212",
                  },
                  {
                    strike: "0.80",
                    iv: "180.5",
                    delta: "0.2790",
                    theta: "-0.0015",
                    vega: "0.0005",
                    indic: "0.053130194793385876",
                  },
                  {
                    strike: "0.85",
                    iv: "185.1",
                    delta: "0.2288",
                    theta: "-0.0013",
                    vega: "0.0005",
                    indic: "0.04519493415941875",
                  },
                  {
                    strike: "0.90",
                    iv: "189.7",
                    delta: "0.1891",
                    theta: "-0.0012",
                    vega: "0.0004",
                    indic: "0.038846591278506315",
                  },
                ],
                put: [
                  {
                    strike: "0.60",
                    iv: "171.2",
                    delta: "-0.3876",
                    theta: "-0.0016",
                    vega: "0.0006",
                    indic: "0.08866688179799312",
                  },
                  {
                    strike: "0.55",
                    iv: "174.2",
                    delta: "-0.2879",
                    theta: "-0.0014",
                    vega: "0.0005",
                    indic: "0.0647499400629438",
                  },
                  {
                    strike: "0.50",
                    iv: "180.7",
                    delta: "-0.2004",
                    theta: "-0.0012",
                    vega: "0.0004",
                    indic: "0.046066157574709804",
                  },
                  {
                    strike: "0.45",
                    iv: "191.0",
                    delta: "-0.1323",
                    theta: "-0.0010",
                    vega: "0.0003",
                    indic: "0.03210131986975165",
                  },
                  {
                    strike: "0.40",
                    iv: "205.0",
                    delta: "-0.0840",
                    theta: "-0.0008",
                    vega: "0.0002",
                    indic: "0.02197917241359014",
                  },
                  {
                    strike: "0.35",
                    iv: "222.7",
                    delta: "-0.0517",
                    theta: "-0.0006",
                    vega: "0.0002",
                    indic: "0.014780282374497591",
                  },
                  {
                    strike: "0.30",
                    iv: "244.5",
                    delta: "-0.0310",
                    theta: "-0.0004",
                    vega: "0.0001",
                    indic: "0.009722720062504235",
                  },
                ],
              },
              next_month: {
                call: [
                  {
                    strike: "0.60",
                    iv: "184.3",
                    delta: "0.6315",
                    theta: "-0.0011",
                    vega: "0.0009",
                    indic: "0.16922476786613969",
                  },
                  {
                    strike: "0.65",
                    iv: "183.5",
                    delta: "0.5687",
                    theta: "-0.0012",
                    vega: "0.0009",
                    indic: "0.1499565396544086",
                  },
                  {
                    strike: "0.70",
                    iv: "183.6",
                    delta: "0.5093",
                    theta: "-0.0012",
                    vega: "0.0009",
                    indic: "0.13347569591348066",
                  },
                  {
                    strike: "0.75",
                    iv: "184.4",
                    delta: "0.4547",
                    theta: "-0.0012",
                    vega: "0.0009",
                    indic: "0.11939442677512149",
                  },
                  {
                    strike: "0.80",
                    iv: "185.6",
                    delta: "0.4056",
                    theta: "-0.0012",
                    vega: "0.0009",
                    indic: "0.10734215756446303",
                  },
                  {
                    strike: "0.85",
                    iv: "187.2",
                    delta: "0.3619",
                    theta: "-0.0011",
                    vega: "0.0009",
                    indic: "0.09698870598472503",
                  },
                  {
                    strike: "0.90",
                    iv: "188.9",
                    delta: "0.3235",
                    theta: "-0.0011",
                    vega: "0.0008",
                    indic: "0.0880519998866644",
                  },
                ],
                put: [
                  {
                    strike: "0.60",
                    iv: "184.3",
                    delta: "-0.3685",
                    theta: "-0.0011",
                    vega: "0.0009",
                    indic: "0.14253427521679687",
                  },
                  {
                    strike: "0.55",
                    iv: "186.3",
                    delta: "-0.3047",
                    theta: "-0.0011",
                    vega: "0.0008",
                    indic: "0.11495110363419583",
                  },
                  {
                    strike: "0.50",
                    iv: "189.9",
                    delta: "-0.2428",
                    theta: "-0.0010",
                    vega: "0.0007",
                    indic: "0.09078926903765769",
                  },
                  {
                    strike: "0.45",
                    iv: "195.4",
                    delta: "-0.1860",
                    theta: "-0.0009",
                    vega: "0.0006",
                    indic: "0.07017013962790408",
                  },
                  {
                    strike: "0.40",
                    iv: "203.2",
                    delta: "-0.1369",
                    theta: "-0.0007",
                    vega: "0.0005",
                    indic: "0.05302775123883886",
                  },
                  {
                    strike: "0.35",
                    iv: "166.1",
                    delta: "-0.0967",
                    theta: "-0.0006",
                    vega: "0.0004",
                    indic: "0.024984993695358504",
                  },
                  {
                    strike: "0.30",
                    iv: "177.3",
                    delta: "-0.0656",
                    theta: "-0.0005",
                    vega: "0.0003",
                    indic: "0.017182325602376772",
                  },
                ],
              },
            },
          },
          {
            underlyer: "AVAX",
            spot_ref: "21.9200",
            prices: {
              weekly: {
                call: [
                  {
                    strike: "21.5000",
                    iv: "138.3",
                    delta: "0.6110",
                    theta: "-0.1221",
                    vega: "0.0074",
                    indic: "1.3097278110208972",
                  },
                  {
                    strike: "22.0000",
                    iv: "136.3",
                    delta: "0.5009",
                    theta: "-0.1252",
                    vega: "0.0077",
                    indic: "1.0484691324177877",
                  },
                  {
                    strike: "22.5000",
                    iv: "136.0",
                    delta: "0.3918",
                    theta: "-0.1203",
                    vega: "0.0074",
                    indic: "0.8322239503947699",
                  },
                  {
                    strike: "23.0000",
                    iv: "137.2",
                    delta: "0.2948",
                    theta: "-0.1090",
                    vega: "0.0067",
                    indic: "0.6582315171075148",
                  },
                  {
                    strike: "23.5000",
                    iv: "139.7",
                    delta: "0.2159",
                    theta: "-0.0942",
                    vega: "0.0057",
                    indic: "0.5209515018479065",
                  },
                  {
                    strike: "24.0000",
                    iv: "143.1",
                    delta: "0.1558",
                    theta: "-0.0788",
                    vega: "0.0046",
                    indic: "0.41376979442827544",
                  },
                  {
                    strike: "24.5000",
                    iv: "147.2",
                    delta: "0.1120",
                    theta: "-0.0645",
                    vega: "0.0037",
                    indic: "0.33036765197988593",
                  },
                ],
                put: [
                  {
                    strike: "21.5000",
                    iv: "138.3",
                    delta: "-0.3890",
                    theta: "-0.1221",
                    vega: "0.0074",
                    indic: "0.8782682713311765",
                  },
                  {
                    strike: "21.0000",
                    iv: "141.9",
                    delta: "-0.2897",
                    theta: "-0.1118",
                    vega: "0.0066",
                    indic: "0.6835271024848932",
                  },
                  {
                    strike: "20.5000",
                    iv: "147.0",
                    delta: "-0.2082",
                    theta: "-0.0971",
                    vega: "0.0056",
                    indic: "0.5284876467634367",
                  },
                  {
                    strike: "20.0000",
                    iv: "153.3",
                    delta: "-0.1463",
                    theta: "-0.0809",
                    vega: "0.0044",
                    indic: "0.4070860357050261",
                  },
                  {
                    strike: "19.5000",
                    iv: "160.6",
                    delta: "-0.1014",
                    theta: "-0.0656",
                    vega: "0.0034",
                    indic: "0.3129826599703689",
                  },
                  {
                    strike: "19.0000",
                    iv: "168.5",
                    delta: "-0.0700",
                    theta: "-0.0521",
                    vega: "0.0026",
                    indic: "0.24045081139600777",
                  },
                  {
                    strike: "18.5000",
                    iv: "177.1",
                    delta: "-0.0483",
                    theta: "-0.0409",
                    vega: "0.0019",
                    indic: "0.18470579594272962",
                  },
                ],
              },
              biweekly: {
                call: [
                  {
                    strike: "21.5000",
                    iv: "130.4",
                    delta: "0.5825",
                    theta: "-0.0717",
                    vega: "0.0141",
                    indic: "2.0849853274907577",
                  },
                  {
                    strike: "22.0000",
                    iv: "130.1",
                    delta: "0.5273",
                    theta: "-0.0730",
                    vega: "0.0143",
                    indic: "1.8444823934488177",
                  },
                  {
                    strike: "22.5000",
                    iv: "130.4",
                    delta: "0.4729",
                    theta: "-0.0731",
                    vega: "0.0143",
                    indic: "1.630821176083046",
                  },
                  {
                    strike: "23.0000",
                    iv: "131.1",
                    delta: "0.4210",
                    theta: "-0.0722",
                    vega: "0.0141",
                    indic: "1.4425528802455219",
                  },
                  {
                    strike: "23.5000",
                    iv: "132.2",
                    delta: "0.3725",
                    theta: "-0.0705",
                    vega: "0.0136",
                    indic: "1.2776584652395364",
                  },
                  {
                    strike: "24.0000",
                    iv: "133.6",
                    delta: "0.3283",
                    theta: "-0.0680",
                    vega: "0.0130",
                    indic: "1.13380069199832",
                  },
                  {
                    strike: "24.5000",
                    iv: "135.4",
                    delta: "0.2887",
                    theta: "-0.0651",
                    vega: "0.0123",
                    indic: "1.0085499140313425",
                  },
                ],
                put: [
                  {
                    strike: "21.5000",
                    iv: "130.4",
                    delta: "-0.4175",
                    theta: "-0.0717",
                    vega: "0.0141",
                    indic: "1.6346797610771624",
                  },
                  {
                    strike: "21.0000",
                    iv: "131.3",
                    delta: "-0.3632",
                    theta: "-0.0694",
                    vega: "0.0135",
                    indic: "1.402685116178759",
                  },
                  {
                    strike: "20.5000",
                    iv: "132.8",
                    delta: "-0.3115",
                    theta: "-0.0661",
                    vega: "0.0127",
                    indic: "1.1979505958346897",
                  },
                  {
                    strike: "20.0000",
                    iv: "134.8",
                    delta: "-0.2638",
                    theta: "-0.0621",
                    vega: "0.0118",
                    indic: "1.019360991332217",
                  },
                  {
                    strike: "19.5000",
                    iv: "137.4",
                    delta: "-0.2210",
                    theta: "-0.0575",
                    vega: "0.0107",
                    indic: "0.8650930758008908",
                  },
                  {
                    strike: "19.0000",
                    iv: "140.5",
                    delta: "-0.1835",
                    theta: "-0.0526",
                    vega: "0.0096",
                    indic: "0.7328589525009024",
                  },
                  {
                    strike: "18.5000",
                    iv: "144.1",
                    delta: "-0.1514",
                    theta: "-0.0476",
                    vega: "0.0085",
                    indic: "0.6201577814222023",
                  },
                ],
              },
              monthly: {
                call: [
                  {
                    strike: "21.5000",
                    iv: "181.2",
                    delta: "0.5953",
                    theta: "-0.0637",
                    vega: "0.0218",
                    indic: "3.9739058885161445",
                  },
                  {
                    strike: "22.0000",
                    iv: "180.9",
                    delta: "0.5700",
                    theta: "-0.0644",
                    vega: "0.0221",
                    indic: "3.757783231130693",
                  },
                  {
                    strike: "22.5000",
                    iv: "180.9",
                    delta: "0.5450",
                    theta: "-0.0650",
                    vega: "0.0223",
                    indic: "3.5547222998760475",
                  },
                  {
                    strike: "23.0000",
                    iv: "181.0",
                    delta: "0.5205",
                    theta: "-0.0654",
                    vega: "0.0224",
                    indic: "3.364209518915846",
                  },
                  {
                    strike: "23.5000",
                    iv: "181.2",
                    delta: "0.4965",
                    theta: "-0.0655",
                    vega: "0.0224",
                    indic: "3.1856761180301216",
                  },
                  {
                    strike: "24.0000",
                    iv: "181.5",
                    delta: "0.4733",
                    theta: "-0.0655",
                    vega: "0.0224",
                    indic: "3.018515559811476",
                  },
                  {
                    strike: "24.5000",
                    iv: "182.0",
                    delta: "0.4509",
                    theta: "-0.0653",
                    vega: "0.0222",
                    indic: "2.862099575879486",
                  },
                ],
                put: [
                  {
                    strike: "21.5000",
                    iv: "181.2",
                    delta: "-0.4047",
                    theta: "-0.0637",
                    vega: "0.0218",
                    indic: "3.485717359200791",
                  },
                  {
                    strike: "21.0000",
                    iv: "181.6",
                    delta: "-0.3793",
                    theta: "-0.0627",
                    vega: "0.0214",
                    indic: "3.215342207218656",
                  },
                  {
                    strike: "20.5000",
                    iv: "182.2",
                    delta: "-0.3539",
                    theta: "-0.0615",
                    vega: "0.0209",
                    indic: "2.9588193656732633",
                  },
                  {
                    strike: "20.0000",
                    iv: "183.1",
                    delta: "-0.3288",
                    theta: "-0.0600",
                    vega: "0.0203",
                    indic: "2.716393406416282",
                  },
                  {
                    strike: "19.5000",
                    iv: "184.1",
                    delta: "-0.3041",
                    theta: "-0.0584",
                    vega: "0.0197",
                    indic: "2.4881915395946486",
                  },
                  {
                    strike: "19.0000",
                    iv: "185.4",
                    delta: "-0.2799",
                    theta: "-0.0566",
                    vega: "0.0189",
                    indic: "2.274216362786624",
                  },
                  {
                    strike: "18.5000",
                    iv: "187.0",
                    delta: "-0.2566",
                    theta: "-0.0546",
                    vega: "0.0181",
                    indic: "2.0743439635726224",
                  },
                ],
              },
              next_month: {
                call: [
                  {
                    strike: "21.5000",
                    iv: "176.0",
                    delta: "0.6199",
                    theta: "-0.0413",
                    vega: "0.0316",
                    indic: "5.609452844824663",
                  },
                  {
                    strike: "22.0000",
                    iv: "175.7",
                    delta: "0.6024",
                    theta: "-0.0418",
                    vega: "0.0321",
                    indic: "5.409615717568716",
                  },
                  {
                    strike: "22.5000",
                    iv: "175.5",
                    delta: "0.5852",
                    theta: "-0.0422",
                    vega: "0.0324",
                    indic: "5.218476812333067",
                  },
                  {
                    strike: "23.0000",
                    iv: "175.4",
                    delta: "0.5682",
                    theta: "-0.0425",
                    vega: "0.0327",
                    indic: "5.035731111318938",
                  },
                  {
                    strike: "23.5000",
                    iv: "175.3",
                    delta: "0.5514",
                    theta: "-0.0428",
                    vega: "0.0329",
                    indic: "4.861064188186248",
                  },
                  {
                    strike: "24.0000",
                    iv: "175.3",
                    delta: "0.5350",
                    theta: "-0.0430",
                    vega: "0.0330",
                    indic: "4.694156027912028",
                  },
                  {
                    strike: "24.5000",
                    iv: "175.4",
                    delta: "0.5189",
                    theta: "-0.0431",
                    vega: "0.0331",
                    indic: "4.53468444438047",
                  },
                ],
                put: [
                  {
                    strike: "21.5000",
                    iv: "176.0",
                    delta: "-0.3801",
                    theta: "-0.0413",
                    vega: "0.0316",
                    indic: "5.052133792917897",
                  },
                  {
                    strike: "21.0000",
                    iv: "176.3",
                    delta: "-0.3626",
                    theta: "-0.0408",
                    vega: "0.0312",
                    indic: "4.7609605785792",
                  },
                  {
                    strike: "20.5000",
                    iv: "176.8",
                    delta: "-0.3449",
                    theta: "-0.0402",
                    vega: "0.0306",
                    indic: "4.479050428558224",
                  },
                  {
                    strike: "20.0000",
                    iv: "177.3",
                    delta: "-0.3273",
                    theta: "-0.0395",
                    vega: "0.0300",
                    indic: "4.206654098871575",
                  },
                  {
                    strike: "19.5000",
                    iv: "178.0",
                    delta: "-0.3097",
                    theta: "-0.0387",
                    vega: "0.0293",
                    indic: "3.9439950397763095",
                  },
                  {
                    strike: "19.0000",
                    iv: "178.7",
                    delta: "-0.2922",
                    theta: "-0.0379",
                    vega: "0.0285",
                    indic: "3.691264861754896",
                  },
                  {
                    strike: "18.5000",
                    iv: "179.6",
                    delta: "-0.2749",
                    theta: "-0.0370",
                    vega: "0.0277",
                    indic: "3.4486190990893757",
                  },
                ],
              },
            },
          },
          {
            underlyer: "XRP",
            spot_ref: "0.6095",
            prices: {
              weekly: {
                call: [
                  {
                    strike: "0.6000",
                    iv: "96.9",
                    delta: "0.6204",
                    theta: "-0.0024",
                    vega: "0.0002",
                    indic: "0.029301239259403145",
                  },
                  {
                    strike: "0.6500",
                    iv: "100.9",
                    delta: "0.1512",
                    theta: "-0.0015",
                    vega: "0.0001",
                    indic: "0.010446377301895876",
                  },
                  {
                    strike: "0.7000",
                    iv: "119.0",
                    delta: "0.0282",
                    theta: "-0.0005",
                    vega: "0.0000",
                    indic: "0.0039829386666446615",
                  },
                  {
                    strike: "0.7500",
                    iv: "138.2",
                    delta: "0.0068",
                    theta: "-0.0002",
                    vega: "0.0000",
                    indic: "0.0016939547050947718",
                  },
                  {
                    strike: "0.8000",
                    iv: "193.0",
                    delta: "0.0021",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0024872793674083",
                  },
                  {
                    strike: "0.8500",
                    iv: "253.4",
                    delta: "0.0008",
                    theta: "-0.0000",
                    vega: "0.0000",
                    indic: "0.0033615281042511658",
                  },
                  {
                    strike: "0.9000",
                    iv: "328.0",
                    delta: "0.0003",
                    theta: "-0.0000",
                    vega: "0.0000",
                    indic: "0.004886902329135176",
                  },
                ],
                put: [
                  {
                    strike: "0.6000",
                    iv: "96.9",
                    delta: "-0.3796",
                    theta: "-0.0024",
                    vega: "0.0002",
                    indic: "0.019583343166164002",
                  },
                  {
                    strike: "0.5500",
                    iv: "117.8",
                    delta: "-0.0668",
                    theta: "-0.0010",
                    vega: "0.0001",
                    indic: "0.0063505626109625485",
                  },
                  {
                    strike: "0.5000",
                    iv: "148.7",
                    delta: "-0.0114",
                    theta: "-0.0003",
                    vega: "0.0000",
                    indic: "0.0020491726401646385",
                  },
                  {
                    strike: "0.4500",
                    iv: "183.3",
                    delta: "-0.0023",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0006685611227158026",
                  },
                  {
                    strike: "0.4000",
                    iv: "367.0",
                    delta: "-0.0006",
                    theta: "-0.0000",
                    vega: "0.0000",
                    indic: "0.0028379723335943755",
                  },
                  {
                    strike: "0.3500",
                    iv: "648.2",
                    delta: "-0.0001",
                    theta: "-0.0000",
                    vega: "0.0000",
                    indic: "0.006234463133404727",
                  },
                  {
                    strike: "0.3000",
                    iv: "1124.2",
                    delta: "-0.0000",
                    theta: "-0.0000",
                    vega: "0.0000",
                    indic: "0.012223331953899352",
                  },
                ],
              },
              biweekly: {
                call: [
                  {
                    strike: "0.6000",
                    iv: "72.1",
                    delta: "0.5914",
                    theta: "-0.0011",
                    vega: "0.0004",
                    indic: "0.039191805529560086",
                  },
                  {
                    strike: "0.6500",
                    iv: "76.0",
                    delta: "0.2708",
                    theta: "-0.0010",
                    vega: "0.0003",
                    indic: "0.019965019354236585",
                  },
                  {
                    strike: "0.7000",
                    iv: "85.7",
                    delta: "0.1124",
                    theta: "-0.0006",
                    vega: "0.0002",
                    indic: "0.010871292452094672",
                  },
                  {
                    strike: "0.7500",
                    iv: "96.4",
                    delta: "0.0512",
                    theta: "-0.0004",
                    vega: "0.0001",
                    indic: "0.0063894932855203534",
                  },
                  {
                    strike: "0.8000",
                    iv: "106.6",
                    delta: "0.0259",
                    theta: "-0.0003",
                    vega: "0.0001",
                    indic: "0.00399176873446172",
                  },
                  {
                    strike: "0.8500",
                    iv: "93.4",
                    delta: "0.0143",
                    theta: "-0.0002",
                    vega: "0.0000",
                    indic: "0.0006257803581099213",
                  },
                  {
                    strike: "0.9000",
                    iv: "96.0",
                    delta: "0.0085",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0002608618723289645",
                  },
                ],
                put: [
                  {
                    strike: "0.6000",
                    iv: "72.1",
                    delta: "-0.4086",
                    theta: "-0.0011",
                    vega: "0.0004",
                    indic: "0.028963793097983248",
                  },
                  {
                    strike: "0.5500",
                    iv: "79.7",
                    delta: "-0.1393",
                    theta: "-0.0007",
                    vega: "0.0002",
                    indic: "0.012521591828229983",
                  },
                  {
                    strike: "0.5000",
                    iv: "94.8",
                    delta: "-0.0424",
                    theta: "-0.0003",
                    vega: "0.0001",
                    indic: "0.0054547191848300924",
                  },
                  {
                    strike: "0.4500",
                    iv: "113.4",
                    delta: "-0.0140",
                    theta: "-0.0002",
                    vega: "0.0000",
                    indic: "0.002433587650738041",
                  },
                  {
                    strike: "0.4000",
                    iv: "129.5",
                    delta: "-0.0050",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0008828392461039565",
                  },
                  {
                    strike: "0.3500",
                    iv: "168.3",
                    delta: "-0.0019",
                    theta: "-0.0000",
                    vega: "0.0000",
                    indic: "0.0007475424151334618",
                  },
                  {
                    strike: "0.3000",
                    iv: "223.6",
                    delta: "-0.0008",
                    theta: "-0.0000",
                    vega: "0.0000",
                    indic: "0.0007430710836716222",
                  },
                ],
              },
              monthly: {
                call: [
                  {
                    strike: "0.6000",
                    iv: "92.2",
                    delta: "0.5769",
                    theta: "-0.0009",
                    vega: "0.0006",
                    indic: "0.0679608940149361",
                  },
                  {
                    strike: "0.6500",
                    iv: "94.4",
                    delta: "0.4061",
                    theta: "-0.0009",
                    vega: "0.0006",
                    indic: "0.04822842321863796",
                  },
                  {
                    strike: "0.7000",
                    iv: "99.2",
                    delta: "0.2751",
                    theta: "-0.0008",
                    vega: "0.0005",
                    indic: "0.03504580197838775",
                  },
                  {
                    strike: "0.7500",
                    iv: "105.0",
                    delta: "0.1874",
                    theta: "-0.0007",
                    vega: "0.0004",
                    indic: "0.026207248062750793",
                  },
                  {
                    strike: "0.8000",
                    iv: "111.0",
                    delta: "0.1306",
                    theta: "-0.0006",
                    vega: "0.0003",
                    indic: "0.020120919869985945",
                  },
                  {
                    strike: "0.8500",
                    iv: "117.0",
                    delta: "0.0936",
                    theta: "-0.0005",
                    vega: "0.0003",
                    indic: "0.015801143824426572",
                  },
                  {
                    strike: "0.9000",
                    iv: "122.6",
                    delta: "0.0689",
                    theta: "-0.0004",
                    vega: "0.0002",
                    indic: "0.012648906014297662",
                  },
                ],
                put: [
                  {
                    strike: "0.6000",
                    iv: "92.2",
                    delta: "-0.4231",
                    theta: "-0.0009",
                    vega: "0.0006",
                    indic: "0.05660739723174446",
                  },
                  {
                    strike: "0.5500",
                    iv: "94.2",
                    delta: "-0.2535",
                    theta: "-0.0008",
                    vega: "0.0005",
                    indic: "0.03465241882271536",
                  },
                  {
                    strike: "0.5000",
                    iv: "101.1",
                    delta: "-0.1332",
                    theta: "-0.0005",
                    vega: "0.0003",
                    indic: "0.020546921061125475",
                  },
                  {
                    strike: "0.4500",
                    iv: "111.8",
                    delta: "-0.0662",
                    theta: "-0.0004",
                    vega: "0.0002",
                    indic: "0.01205426399960434",
                  },
                  {
                    strike: "0.4000",
                    iv: "125.5",
                    delta: "-0.0326",
                    theta: "-0.0002",
                    vega: "0.0001",
                    indic: "0.007028928633730835",
                  },
                  {
                    strike: "0.3500",
                    iv: "141.8",
                    delta: "-0.0162",
                    theta: "-0.0001",
                    vega: "0.0001",
                    indic: "0.0040575375681630235",
                  },
                  {
                    strike: "0.3000",
                    iv: "161.0",
                    delta: "-0.0080",
                    theta: "-0.0001",
                    vega: "0.0000",
                    indic: "0.0023002418985123233",
                  },
                ],
              },
              next_month: {
                call: [
                  {
                    strike: "0.6000",
                    iv: "108.9",
                    delta: "0.5894",
                    theta: "-0.0007",
                    vega: "0.0009",
                    indic: "0.10960886493086702",
                  },
                  {
                    strike: "0.6500",
                    iv: "109.8",
                    delta: "0.4901",
                    theta: "-0.0008",
                    vega: "0.0009",
                    indic: "0.09031117424782062",
                  },
                  {
                    strike: "0.7000",
                    iv: "111.7",
                    delta: "0.4021",
                    theta: "-0.0007",
                    vega: "0.0009",
                    indic: "0.07509157346911696",
                  },
                  {
                    strike: "0.7500",
                    iv: "114.1",
                    delta: "0.3285",
                    theta: "-0.0007",
                    vega: "0.0008",
                    indic: "0.06310079196522536",
                  },
                  {
                    strike: "0.8000",
                    iv: "116.9",
                    delta: "0.2689",
                    theta: "-0.0007",
                    vega: "0.0008",
                    indic: "0.05359201642391084",
                  },
                  {
                    strike: "0.8500",
                    iv: "119.8",
                    delta: "0.2214",
                    theta: "-0.0006",
                    vega: "0.0007",
                    indic: "0.04597467379020845",
                  },
                  {
                    strike: "0.9000",
                    iv: "122.7",
                    delta: "0.1836",
                    theta: "-0.0006",
                    vega: "0.0006",
                    indic: "0.03980293762022491",
                  },
                ],
                put: [
                  {
                    strike: "0.6000",
                    iv: "108.9",
                    delta: "-0.4106",
                    theta: "-0.0007",
                    vega: "0.0009",
                    indic: "0.09654692427902309",
                  },
                  {
                    strike: "0.5500",
                    iv: "109.6",
                    delta: "-0.3083",
                    theta: "-0.0007",
                    vega: "0.0008",
                    indic: "0.07069152312340238",
                  },
                  {
                    strike: "0.5000",
                    iv: "112.3",
                    delta: "-0.2150",
                    theta: "-0.0006",
                    vega: "0.0007",
                    indic: "0.05006990865249902",
                  },
                  {
                    strike: "0.4500",
                    iv: "117.2",
                    delta: "-0.1400",
                    theta: "-0.0004",
                    vega: "0.0005",
                    indic: "0.03446689140157366",
                  },
                  {
                    strike: "0.4000",
                    iv: "124.6",
                    delta: "-0.0864",
                    theta: "-0.0003",
                    vega: "0.0004",
                    indic: "0.023151806128254057",
                  },
                  {
                    strike: "0.3500",
                    iv: "110.2",
                    delta: "-0.0512",
                    theta: "-0.0002",
                    vega: "0.0002",
                    indic: "0.0076878356021866795",
                  },
                  {
                    strike: "0.3000",
                    iv: "120.8",
                    delta: "-0.0293",
                    theta: "-0.0002",
                    vega: "0.0002",
                    indic: "0.004608876265046542",
                  },
                ],
              },
            },
          },
          {
            underlyer: "MKR",
            spot_ref: "1448.00",
            prices: {
              weekly: {
                call: [
                  {
                    strike: "1400.00",
                    iv: "66.5",
                    delta: "0.8120",
                    theta: "-2.7282",
                    vega: "0.3448",
                    indic: "75.05931648284991",
                  },
                  {
                    strike: "1450.00",
                    iv: "61.4",
                    delta: "0.4996",
                    theta: "-3.7260",
                    vega: "0.5102",
                    indic: "45.07142162293508",
                  },
                  {
                    strike: "1500.00",
                    iv: "64.0",
                    delta: "0.1877",
                    theta: "-2.6225",
                    vega: "0.3444",
                    indic: "26.08593002106204",
                  },
                  {
                    strike: "1550.00",
                    iv: "71.4",
                    delta: "0.0593",
                    theta: "-1.2816",
                    vega: "0.1510",
                    indic: "15.409450755949536",
                  },
                  {
                    strike: "1600.00",
                    iv: "80.0",
                    delta: "0.0202",
                    theta: "-0.5939",
                    vega: "0.0624",
                    indic: "9.364876745861636",
                  },
                  {
                    strike: "1650.00",
                    iv: "88.9",
                    delta: "0.0077",
                    theta: "-0.2871",
                    vega: "0.0272",
                    indic: "5.839353480955623",
                  },
                  {
                    strike: "1700.00",
                    iv: "97.4",
                    delta: "0.0033",
                    theta: "-0.1472",
                    vega: "0.0127",
                    indic: "3.727098289728744",
                  },
                ],
                put: [
                  {
                    strike: "1400.00",
                    iv: "66.5",
                    delta: "-0.1880",
                    theta: "-2.7282",
                    vega: "0.3448",
                    indic: "26.091639548029377",
                  },
                  {
                    strike: "1350.00",
                    iv: "76.6",
                    delta: "-0.0583",
                    theta: "-1.3565",
                    vega: "0.1489",
                    indic: "14.657992809890857",
                  },
                  {
                    strike: "1300.00",
                    iv: "88.5",
                    delta: "-0.0188",
                    theta: "-0.6196",
                    vega: "0.0588",
                    indic: "8.240556876342737",
                  },
                  {
                    strike: "1250.00",
                    iv: "101.2",
                    delta: "-0.0067",
                    theta: "-0.2874",
                    vega: "0.0239",
                    indic: "4.636799818032358",
                  },
                  {
                    strike: "1200.00",
                    iv: "114.2",
                    delta: "-0.0026",
                    theta: "-0.1386",
                    vega: "0.0102",
                    indic: "2.6122517016446025",
                  },
                  {
                    strike: "1150.00",
                    iv: "127.6",
                    delta: "-0.0011",
                    theta: "-0.0696",
                    vega: "0.0046",
                    indic: "1.4740859110664886",
                  },
                  {
                    strike: "1100.00",
                    iv: "141.3",
                    delta: "-0.0005",
                    theta: "-0.0362",
                    vega: "0.0022",
                    indic: "0.8333348583442302",
                  },
                ],
              },
              biweekly: {
                call: [
                  {
                    strike: "1400.00",
                    iv: "72.6",
                    delta: "0.6670",
                    theta: "-2.4576",
                    vega: "0.8655",
                    indic: "107.82801026264542",
                  },
                  {
                    strike: "1450.00",
                    iv: "71.9",
                    delta: "0.5194",
                    theta: "-2.6660",
                    vega: "0.9489",
                    indic: "81.1678829876663",
                  },
                  {
                    strike: "1500.00",
                    iv: "73.2",
                    delta: "0.3757",
                    theta: "-2.5870",
                    vega: "0.9035",
                    indic: "60.908494483792424",
                  },
                  {
                    strike: "1550.00",
                    iv: "76.2",
                    delta: "0.2605",
                    theta: "-2.3033",
                    vega: "0.7732",
                    indic: "46.103201712179214",
                  },
                  {
                    strike: "1600.00",
                    iv: "80.1",
                    delta: "0.1788",
                    theta: "-1.9477",
                    vega: "0.6223",
                    indic: "35.38476409551811",
                  },
                  {
                    strike: "1650.00",
                    iv: "84.4",
                    delta: "0.1238",
                    theta: "-1.6061",
                    vega: "0.4869",
                    indic: "27.562744804724332",
                  },
                  {
                    strike: "1700.00",
                    iv: "88.9",
                    delta: "0.0872",
                    theta: "-1.3118",
                    vega: "0.3776",
                    indic: "21.77206564835282",
                  },
                ],
                put: [
                  {
                    strike: "1400.00",
                    iv: "72.6",
                    delta: "-0.3330",
                    theta: "-2.4576",
                    vega: "0.8655",
                    indic: "57.38307602810028",
                  },
                  {
                    strike: "1350.00",
                    iv: "75.6",
                    delta: "-0.2120",
                    theta: "-2.0398",
                    vega: "0.6902",
                    indic: "40.26628068223937",
                  },
                  {
                    strike: "1300.00",
                    iv: "80.3",
                    delta: "-0.1292",
                    theta: "-1.5750",
                    vega: "0.5016",
                    indic: "28.208632810283632",
                  },
                  {
                    strike: "1250.00",
                    iv: "86.3",
                    delta: "-0.0779",
                    theta: "-1.1698",
                    vega: "0.3469",
                    indic: "19.829930871625947",
                  },
                  {
                    strike: "1200.00",
                    iv: "93.1",
                    delta: "-0.0473",
                    theta: "-0.8545",
                    vega: "0.2349",
                    indic: "14.006020177836945",
                  },
                  {
                    strike: "1150.00",
                    iv: "100.4",
                    delta: "-0.0292",
                    theta: "-0.6214",
                    vega: "0.1583",
                    indic: "9.937263259167239",
                  },
                  {
                    strike: "1100.00",
                    iv: "108.3",
                    delta: "-0.0183",
                    theta: "-0.4525",
                    vega: "0.1069",
                    indic: "7.077545427885852",
                  },
                ],
              },
              monthly: {
                call: [
                  {
                    strike: "1400.00",
                    iv: "87.9",
                    delta: "0.6182",
                    theta: "-2.0070",
                    vega: "1.4154",
                    indic: "169.475681808996",
                  },
                  {
                    strike: "1450.00",
                    iv: "88.0",
                    delta: "0.5389",
                    theta: "-2.0929",
                    vega: "1.4739",
                    indic: "145.55240721825953",
                  },
                  {
                    strike: "1500.00",
                    iv: "88.9",
                    delta: "0.4619",
                    theta: "-2.1143",
                    vega: "1.4742",
                    indic: "125.22931764907639",
                  },
                  {
                    strike: "1550.00",
                    iv: "90.3",
                    delta: "0.3913",
                    theta: "-2.0788",
                    vega: "1.4256",
                    indic: "108.14566688517402",
                  },
                  {
                    strike: "1600.00",
                    iv: "92.3",
                    delta: "0.3295",
                    theta: "-2.0007",
                    vega: "1.3435",
                    indic: "93.84982737106793",
                  },
                  {
                    strike: "1650.00",
                    iv: "94.5",
                    delta: "0.2768",
                    theta: "-1.8952",
                    vega: "1.2426",
                    indic: "81.8854003242829",
                  },
                  {
                    strike: "1700.00",
                    iv: "96.9",
                    delta: "0.2328",
                    theta: "-1.7752",
                    vega: "1.1349",
                    indic: "71.84077608355199",
                  },
                ],
                put: [
                  {
                    strike: "1400.00",
                    iv: "87.9",
                    delta: "-0.3818",
                    theta: "-2.0070",
                    vega: "1.4154",
                    indic: "116.64759107816053",
                  },
                  {
                    strike: "1350.00",
                    iv: "88.6",
                    delta: "-0.3055",
                    theta: "-1.8607",
                    vega: "1.3012",
                    indic: "94.34103227013247",
                  },
                  {
                    strike: "1300.00",
                    iv: "90.2",
                    delta: "-0.2372",
                    theta: "-1.6702",
                    vega: "1.1466",
                    indic: "75.72375928333935",
                  },
                  {
                    strike: "1250.00",
                    iv: "92.8",
                    delta: "-0.1799",
                    theta: "-1.4581",
                    vega: "0.9737",
                    indic: "60.48645593060638",
                  },
                  {
                    strike: "1200.00",
                    iv: "96.1",
                    delta: "-0.1342",
                    theta: "-1.2454",
                    vega: "0.8028",
                    indic: "48.18100328569966",
                  },
                  {
                    strike: "1150.00",
                    iv: "100.1",
                    delta: "-0.0992",
                    theta: "-1.0467",
                    vega: "0.6477",
                    indic: "38.320959265620075",
                  },
                  {
                    strike: "1100.00",
                    iv: "104.8",
                    delta: "-0.0730",
                    theta: "-0.8700",
                    vega: "0.5145",
                    indic: "30.450997534965722",
                  },
                ],
              },
              next_month: {
                call: [
                  {
                    strike: "1400.00",
                    iv: "106.4",
                    delta: "0.6117",
                    theta: "-1.6620",
                    vega: "2.1036",
                    indic: "268.01738762722835",
                  },
                  {
                    strike: "1450.00",
                    iv: "106.5",
                    delta: "0.5675",
                    theta: "-1.7068",
                    vega: "2.1586",
                    indic: "246.29716157218968",
                  },
                  {
                    strike: "1500.00",
                    iv: "106.9",
                    delta: "0.5244",
                    theta: "-1.7341",
                    vega: "2.1860",
                    indic: "226.60641895526123",
                  },
                  {
                    strike: "1550.00",
                    iv: "107.4",
                    delta: "0.4829",
                    theta: "-1.7448",
                    vega: "2.1880",
                    indic: "208.80187496762858",
                  },
                  {
                    strike: "1600.00",
                    iv: "108.2",
                    delta: "0.4436",
                    theta: "-1.7407",
                    vega: "2.1681",
                    indic: "192.72664956296546",
                  },
                  {
                    strike: "1650.00",
                    iv: "109.0",
                    delta: "0.4069",
                    theta: "-1.7241",
                    vega: "2.1301",
                    indic: "178.22024503777465",
                  },
                  {
                    strike: "1700.00",
                    iv: "110.0",
                    delta: "0.3729",
                    theta: "-1.6971",
                    vega: "2.0779",
                    indic: "165.12600306913708",
                  },
                ],
                put: [
                  {
                    strike: "1400.00",
                    iv: "106.4",
                    delta: "-0.3883",
                    theta: "-1.6620",
                    vega: "2.1036",
                    indic: "210.94796893163016",
                  },
                  {
                    strike: "1350.00",
                    iv: "106.6",
                    delta: "-0.3440",
                    theta: "-1.5994",
                    vega: "2.0205",
                    indic: "184.81560293193166",
                  },
                  {
                    strike: "1300.00",
                    iv: "107.2",
                    delta: "-0.3007",
                    theta: "-1.5201",
                    vega: "1.9106",
                    indic: "160.91129449569007",
                  },
                  {
                    strike: "1250.00",
                    iv: "108.1",
                    delta: "-0.2591",
                    theta: "-1.4263",
                    vega: "1.7776",
                    indic: "139.26870971742954",
                  },
                  {
                    strike: "1200.00",
                    iv: "109.4",
                    delta: "-0.2204",
                    theta: "-1.3213",
                    vega: "1.6272",
                    indic: "119.8694194992737",
                  },
                  {
                    strike: "1150.00",
                    iv: "111.1",
                    delta: "-0.1851",
                    theta: "-1.2091",
                    vega: "1.4659",
                    indic: "102.64385043218192",
                  },
                  {
                    strike: "1100.00",
                    iv: "113.2",
                    delta: "-0.1537",
                    theta: "-1.0935",
                    vega: "1.3009",
                    indic: "87.47814140741076",
                  },
                ],
              },
            },
          },
        ],
      } as unknown as PreloadedStrikes,
    } as PreloadedStrikesResponse);
  }, [preloadedStrikes, swrError]);

  // same api call but can only be called when user presses refresh button
  const getPreloadedStrikes = async () => {
    try {
      setError(false);
      const response = await axios.get(apiURL);
      setData({
        loading: false,
        preloadedStrikes: response.data as PreloadedStrikes,
      } as PreloadedStrikesResponse);
    } catch (e) {
      setError(true);
    }
  };

  useEffect(() => {
    fetchPreloadedStrikes();
  }, [fetchPreloadedStrikes]);

  return { error, data, getPreloadedStrikes };
};

export default useFetchPreloadedStrikes;
