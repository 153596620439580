import styled from "styled-components";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";

export const CollateralBalanceContainer = styled.div`
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${COMPONENTS.collateralBalanceContainerHeight}px;
  background-color: ${LAYER_COLORS.three};
  > *:not(:first-child) {
    margin-top: ${SPACING.two}px;
  }
`;

export const CollateralBalanceText = styled.div`
  ${FONT_CSS.label.three}
  color: ${TEXT_COLORS.three};
`;

export const CollateralBalanceAmount = styled.div`
  ${FONT_CSS.data.one}
  color: ${TEXT_COLORS.one};
`;

export const ButtonsContainer = styled.div`
  margin-top: ${SPACING.three}px;
  display: flex;
  width: 100%;
  > * {
    &:not(:last-child) {
      margin-right: ${SPACING.two}px;
    }
  }
`;

export const InfoRow = styled.div<{ paddingTop?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${({ paddingTop }) => (paddingTop ?? SPACING.three)}px;
  font-size: ${FONT_SIZE.oneHalf};
  span:first-child {
    color: ${TEXT_COLORS.three};
  }
`;

export const InfoDetail = styled.span<{ color?: string }>`
  ${({ color }) => color && `color: ${color};`}
`;

export const TopUpText = styled.span`
  margin-top: ${SPACING.two}px;
  color: ${COLORS.negative.one};
  text-align: center;
  ${FONT_CSS.label.two}
`;
