/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { BigNumber } from "ethers";
import { useEffect, useState } from "react";
import { MinimalForwarder__factory, MinimalForwarder } from "../../../codegen";
import addresses from "../../../constants/addresses/addresses.json";
import useWallet from "../../wallet/useWallet";
import { useWeb3Context } from "../../../contexts/Web3Context";

const contractAddress = addresses.mainnet.contracts.minimalForwarder;

export const getMinimalForwarder = (provider: any, useSigner = true): MinimalForwarder | undefined => {
  const signerOrProvider = useSigner ? provider?.getSigner() : provider;
  if (signerOrProvider && contractAddress) {
    return MinimalForwarder__factory.connect(contractAddress, signerOrProvider);
  }

  return undefined;
};

const useMinimalForwarder = () => {
  const { account, active, provider, chainId } = useWallet();
  const { baseProvider } = useWeb3Context();
  const [nonce, setNonce] = useState<BigNumber>();
  const [contract, setContract] = useState<MinimalForwarder>();

  useEffect(() => {
    setContract(getMinimalForwarder(provider || baseProvider, active));
  }, [chainId, provider, baseProvider, active]);

  // Retrieve token balances if not available
  useEffect(() => {
    if (account && contract) {
      contract
        .getNonce(account)
        .then((accountNonce) => setNonce(accountNonce))
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
  }, [account, contract, setNonce]);

  return {
    contract,
    nonce
  };
};

export default useMinimalForwarder;
