import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import useRoutes from "../../../../hooks/routes/useRoutes";
import {
  MobileFooterWrapper,
  Container,
  LinkText,
  MobileMenuButton,
  MobileSvgContainer,
} from "../style";
import { MobilePendingOrdersFooter } from "../../../PendingOrders/MobilePendingOrdersFooter";
import { PageEndpointEnum } from "../../../../enums/endpoint";
import { PageEnum } from "../../../../enums/pages";
import MobileNavigationMenu from "../../../MobileNavigationMenu";
import { COLORS, ICON_COLORS } from "../../../../constants/design/colors";
import { messageSquare as MessageSquare } from "../../../../assets/styledSvg/messageSquare";

interface ILinkInfo {
  to: PageEndpointEnum;
  isActive: boolean;
  title: string;
}

function MobileFooter() {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [showTelegramModal, setShowTelegramModal] = useState<boolean>(false);
  const { currentPage, isMMPage } = useRoutes();

  const links: ILinkInfo[] = useMemo(
    () => (isMMPage
      ? [
        {
          to: PageEndpointEnum.REQUESTS,
          isActive: currentPage === PageEnum.Requests,
          title: "Requests",
        },
        {
          to: PageEndpointEnum.MMPORTFOLIO,
          isActive: currentPage === PageEnum.MMPortfolio,
          title: "Portfolio",
        },
      ]
      : [
        {
          to: PageEndpointEnum.TRADE,
          isActive: currentPage === PageEnum.Trade,
          title: "Trade",
        },
        {
          to: PageEndpointEnum.PORTFOLIO,
          isActive: currentPage === PageEnum.Portfolio,
          title: "Portfolio",
        },
      ]),
    [currentPage, isMMPage]
  );
  return (
    <>
      <MobileNavigationMenu
        open={showMobileNav}
        onClose={() => setShowMobileNav(false)}
      />
      <Container>
        <MobileFooterWrapper>
          {isMMPage ? (
            <MobileSvgContainer
              isOpen={showTelegramModal}
              onClick={() => setShowTelegramModal(!showTelegramModal)}
            >
              <MessageSquare
                color={showTelegramModal ? COLORS.blue.one : ICON_COLORS.two}
              />
            </MobileSvgContainer>
          ) : (
            <MobilePendingOrdersFooter />
          )}
          {links.map((linkInfo) => (
            <Link key={linkInfo.title} to={linkInfo.to}>
              <LinkText isActive={linkInfo.isActive}>{linkInfo.title}</LinkText>
            </Link>
          ))}
          <MobileMenuButton onClick={() => setShowMobileNav(true)} />
        </MobileFooterWrapper>
      </Container>
    </>
  );
}

export default MobileFooter;
