import { getAssetLogo, Assets } from "../../../utils/assets";
import LogoContainer from "../../shared/LogoContainer";

interface IMarketIconProps {
  asset: Assets;
}
function MarketIcon({ asset }: IMarketIconProps) {
  const logo = getAssetLogo(asset);
  return <LogoContainer logo={logo} />;
}

export default MarketIcon;
