/* eslint-disable no-console */
import { BigNumber } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import { useCallback, useEffect, useState } from "react";
import {
  MarginRequirement__factory,
  MarginRequirement,
} from "../../../codegen";
import { calculateHash } from "../../../utils/hash";
import useWallet from "../../wallet/useWallet";
import addresses from "../../../constants/addresses/addresses.json";
import { UNDERLYER_ADDRESSES, Assets } from "../../../utils/assets";
import useOracle from "../oracle/useOracle";
import useOtcWrapper from "../otcWrapper/useOtcWrapper";
import { oracleDecimals } from "../../../constants/decimals/decimals";
import useUSDC from "../usdc/useUSDC";
import useSetOraclePrice from "../../api/prices/useSetOraclePrice";
import { calculateInitialMarginBigNumber } from "../../../utils/math";
import useController from "../controller/useController";
import useAssetPriceData from "../../api/prices/useAssetPriceData";
import { useWeb3Context } from "../../../contexts/Web3Context";

export interface IUseMarginRequirementProps {
  underlyerAsset?: Assets;
  collateralAsset?: Assets;
  isPut?: boolean;
  contracts?: number | string;
  orderId?: number;
  withdrawAmount?: string;
}

export interface IMarginRequirementResponse {
  loading: boolean;
  totalFees?: BigNumber;
  initialMargin?: BigNumber;
  initialMarginFromOracle?: BigNumber;
  maintenanceMargin?: BigNumber;
  checkWithdrawCollateral: boolean;
}

export interface IMarginRequirementData {
  contract?: MarginRequirement;
  data: IMarginRequirementResponse;
}

const contractAddress = addresses.mainnet.contracts.marginRequirement;

export const getMarginRequirement = (
  provider: any,
  useSigner = true
): MarginRequirement | undefined => {
  const signerOrProvider = useSigner ? provider?.getSigner() : provider;
  if (signerOrProvider && contractAddress) {
    return MarginRequirement__factory.connect(
      contractAddress,
      signerOrProvider
    );
  }
  return undefined;
};

const defaultMarginRequirementResponse = {
  loading: true,
  totalFees: undefined,
  initialMargin: undefined,
} as IMarginRequirementResponse;

const useMarginRequirement = ({
  underlyerAsset,
  collateralAsset,
  isPut,
  contracts,
  orderId,
  withdrawAmount,
}: IUseMarginRequirementProps) => {
  const { account, active, provider, chainId } = useWallet();
  const { baseProvider } = useWeb3Context();
  const [data, setData] = useState<IMarginRequirementResponse>(
    defaultMarginRequirementResponse
  );
  const [contract, setContract] = useState<MarginRequirement>();
  const usdc = useUSDC();
  const { oraclePrices } = useOracle();
  const { isPriceDeviationTooHigh } = useSetOraclePrice();
  const {
    data: { fees, orderDetails },
  } = useOtcWrapper(orderId, undefined, undefined, true);
  const {
    data: { vault }
  } = useController(orderDetails?.vaultID);
  const { useAssetPrice } = useAssetPriceData();
  const { price: coingeckoUnderlyerPrice } = useAssetPrice({ asset: underlyerAsset || "ETH" });

  const underlyerAddress = underlyerAsset
    ? UNDERLYER_ADDRESSES[underlyerAsset]
    : undefined;
  const collateralAddress = collateralAsset
    ? UNDERLYER_ADDRESSES[collateralAsset]
    : undefined;

  useEffect(() => {
    const getContract = async () => {
      setContract(getMarginRequirement(provider || baseProvider, active));
    };
    getContract();
  }, [chainId, baseProvider, provider, active]);

  // uses either oracle or coingecko price, for visuals
  const getOraclePrice = useCallback(async () => {
    // if there is price deviation do not set initialMarginFromOracle
    if (
      !coingeckoUnderlyerPrice
      || !underlyerAsset
      || !oraclePrices
      || !oraclePrices[underlyerAsset]
    ) {
      return undefined;
    }
    const hasPriceDeviation = isPriceDeviationTooHigh(
      coingeckoUnderlyerPrice,
      underlyerAsset
    );
    return !hasPriceDeviation ? oraclePrices[underlyerAsset] : undefined;
  }, [
    coingeckoUnderlyerPrice,
    isPriceDeviationTooHigh,
    oraclePrices,
    underlyerAsset,
  ]);

  // uses either oracle or coingecko price, for visuals
  const getMinMargin = useCallback(async () => {
    try {
      if (
        !contract
        || !underlyerAddress
        || !collateralAddress
        || isPut === undefined
        || !account
      ) {
        return undefined;
      }
      const getInitialMargin = await contract.initialMargin(
        calculateHash(underlyerAddress, collateralAddress, isPut),
        account
      );

      return getInitialMargin.isZero() ? undefined : getInitialMargin;
    } catch (error) {
      return undefined;
    }
  }, [account, collateralAddress, contract, isPut, underlyerAddress]);

  // uses either oracle or coingecko price, for visuals
  const checkWithdrawCollateral = useCallback(
    async () => {
      try {
        if (!contract || !orderDetails || !account || !vault || !withdrawAmount || !oraclePrices || !oraclePrices.USDC) {
          return false;
        }
        const {
          notional,
          oToken,
          vaultID
        } = orderDetails;
        const withdrawAmountBigNumber = parseUnits(withdrawAmount, usdc.decimals);
        const withdrawAmountInUSDC = withdrawAmountBigNumber.mul(BigNumber.from(10).pow(oracleDecimals)).div(oraclePrices.USDC);
        const checkWithdrawCollateralCall = await contract.checkWithdrawCollateral(account, notional, withdrawAmountInUSDC, oToken, vaultID, vault);
        return checkWithdrawCollateralCall;
      } catch (error) {
        return false;
      }
    },
    [account, contract, oraclePrices, orderDetails, usdc.decimals, vault, withdrawAmount]
  );

  const calculateTotalFees = useCallback(
    async () => {
      if (
        !oraclePrices
        || !underlyerAsset
        || !contracts
        || !fees
        || !coingeckoUnderlyerPrice
      ) {
        return undefined;
      }

      const coingeckoUnderlyerPriceBigNumber = parseUnits(
        String(coingeckoUnderlyerPrice),
        oracleDecimals
      );

      // if the oraclePrice is not valid, use the coingecko price instead
      const price
        = oraclePrices[underlyerAsset] || coingeckoUnderlyerPriceBigNumber;

      // price is 10 ** 8
      // fees is 10 ** 6. together is 10 ** 14
      // to get usdc decimals (6) we divide by 10 ** 8
      const totalFees = price
        .mul(BigNumber.from(String(Number(contracts) * 10))) // multiply by 10 because contracts can be 1 d.p
        .div(BigNumber.from(10)) // then divide back by 10
        .mul(fees[underlyerAsset])
        .div(BigNumber.from(10).pow(8));

      return totalFees;
    },
    [coingeckoUnderlyerPrice, contracts, fees, oraclePrices, underlyerAsset]
  );

  // uses either oracle or coingecko price, for visuals
  const calculateInitialMargin = useCallback(
    async (minMargin?: BigNumber) => {
      try {
        if (
          !oraclePrices
          || !minMargin
          || !underlyerAsset
          || !contracts
          || !fees
          || !coingeckoUnderlyerPrice
        ) {
          return undefined;
        }
        if (!oraclePrices.USDC) {
          return undefined;
        }
        const coingeckoUnderlyerPriceBigNumber = parseUnits(
          String(coingeckoUnderlyerPrice),
          oracleDecimals
        );

        // if the oraclePrice is not valid, use the coingecko price instead
        const underlyerPrice
          = oraclePrices[underlyerAsset] || coingeckoUnderlyerPriceBigNumber;

        const usdcPrice = oraclePrices.USDC;

        return calculateInitialMarginBigNumber(
          minMargin,
          underlyerPrice,
          usdcPrice,
          contracts,
          usdc.decimals
        );
      } catch (error) {
        return undefined;
      }
    },
    [
      coingeckoUnderlyerPrice,
      contracts,
      fees,
      oraclePrices,
      underlyerAsset,
      usdc.decimals,
    ]
  );

  // uses oracle price, actual amount
  const calculateInitialMarginFromOracle = useCallback(
    async (oraclePrice?: BigNumber) => {
      try {
        if (
          !oraclePrices
          || !underlyerAsset
          || !collateralAddress
          || !underlyerAddress
          || !contracts
          || !fees
          || !contract
          || !account
          || !oraclePrice
        ) {
          return undefined;
        }
        if (!oraclePrices.USDC) {
          return undefined;
        }
        const usdcPrice = oraclePrices.USDC;

        const getInitialMargin = await contract.initialMargin(
          calculateHash(underlyerAddress, collateralAddress, isPut!!),
          account
        );

        const minMargin = getInitialMargin.isZero()
          ? undefined
          : getInitialMargin;

        if (!minMargin) {
          return undefined;
        }
        return calculateInitialMarginBigNumber(
          minMargin,
          oraclePrices[underlyerAsset],
          usdcPrice,
          contracts,
          usdc.decimals
        );
      } catch (error) {
        return undefined;
      }
    },
    [
      account,
      collateralAddress,
      contract,
      contracts,
      fees,
      isPut,
      oraclePrices,
      underlyerAddress,
      underlyerAsset,
      usdc.decimals,
    ]
  );

  const fetchMarginRequirementData = useCallback(async () => {
    try {
      if (contract) {
        const minMargin = await getMinMargin();
        const oraclePrice = await getOraclePrice();

        const vaultID = orderDetails?.vaultID;
        setData({
          loading: false,
          totalFees: await calculateTotalFees(),
          initialMargin: await calculateInitialMargin(minMargin),
          initialMarginFromOracle: await calculateInitialMarginFromOracle(
            oraclePrice
          ),
          maintenanceMargin: vaultID ? await contract.maintenanceMargin(vaultID) : undefined,
          checkWithdrawCollateral: await checkWithdrawCollateral()
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [contract, getMinMargin, getOraclePrice, orderDetails?.vaultID, calculateTotalFees, calculateInitialMargin, calculateInitialMarginFromOracle, checkWithdrawCollateral]);

  useEffect(() => {
    fetchMarginRequirementData();
  }, [fetchMarginRequirementData]);

  return {
    contract,
    data,
    calculateInitialMarginFromOracle,
  };
};

export default useMarginRequirement;
