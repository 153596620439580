// Shared styles

import { css } from "styled-components";
import { BACKGROUND_COLORS } from "../../constants/design/colors";

export const frostedGlassBg = (bgColor: string, fallbackBgColor?: string, blur?: number) => css`
  -webkit-backdrop-filter: blur(${blur ?? 2}px);
  backdrop-filter: blur(${blur ?? 2}px);

  /* Only give frosted glass effect if supported. If not, use a darker bgcolor*/
  background-color: ${fallbackBgColor ?? BACKGROUND_COLORS.seven};
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: ${bgColor};
  }
`;
