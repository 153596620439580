import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import {
  BACKGROUND_COLORS,
  COLORS,
  PRIMARY_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";
import { WalletEnum } from "../../../utils/wallet/connectors";

export const PageContentContainer = styled(motion.div)`
  transition: all 0.2s ease-in-out;
`;

export const Header = styled.div``;

export const WalletButton = styled.button<{
  isActive?: boolean;
  walletType?: WalletEnum;
}>`
  width: 100%;
  height: 64px;
  color: ${TEXT_COLORS.one};
  padding: 0 ${SPACING.three}px;
  display: flex;
  align-items: center;

  font-size: ${FONT_SIZE.three};
  transition: all 0.4s ease-in-out;

  img {
    margin-right: ${SPACING.two}px;
    width: 40px;
    height: 40px;
  }

  span {
    margin: auto 0;
    font-size: ${FONT_SIZE.two};
  }

  background-color: ${({ isActive }) => (isActive ? PRIMARY_COLORS.six : "transparent")};
  ${({ isActive }) => {
    if (isActive) {
      return css`
        box-shadow: 0px 0px 0px 1px ${PRIMARY_COLORS.one} inset;
      `;
    }
    return "";
  }}
  border-radius: ${({ isActive }) => (isActive ? "8px" : "0")};
`;

export const Checkbox = styled.div<{ isActive: boolean }>`
  height: 16px;
  width: 16px;
  border: 1px solid ${BACKGROUND_COLORS.three};
  margin: 0;
  transition: 0.2s ease-in-out;
`;

export const RememberCheckbox = styled.div<{ isActive: boolean }>`
  display: flex;
  margin-top: ${SPACING.three}px;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  svg {
    position: absolute;
  }

  div {
    color: ${TEXT_COLORS.two};
    font-size: ${FONT_SIZE.two};
    border-radius: 4px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;

    &:not(:last-child) {
      margin-right: ${SPACING.two}px;
    }
  }
`;

export const VideoView = styled.div`
  height: 200px;
  position: relative;
`;

export const ExtrasContainer = styled.div`
  padding: ${SPACING.three}px;
`;

export const ExtrasText = styled.span<{ type: "error" | "default" }>`
  color: ${({ type }) => (type === "error" ? COLORS.negative.one : TEXT_COLORS.one)};
  font-size: ${FONT_SIZE.two};
`;

export const AevoLogoContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url("https://media.giphy.com/media/wKrnOQYpjPatYlG8zl/giphy.gif");
  background-repeat: no-repeat;
  background-size: cover;

  svg {
    position: absolute;
  }
`;

export const Padding = styled.div`
  padding: 0 ${SPACING.three}px;
`;

export const SuccessMessage = styled.div`
  padding-top: ${SPACING.three}px;
  font-size: ${FONT_SIZE.xs};
  color: ${COLORS.positive.one};
`;
