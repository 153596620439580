import styled from "styled-components";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";

export const SectionContainer = styled.div<{ isMobileScreen: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;
  padding: ${SPACING.three}px;
  width: ${({ isMobileScreen }) => (isMobileScreen ? "100%" : `${COMPONENTS.tableWidth}px`)};
`;

export const InnerContainer = styled.div`
  flex: 1;
  height: 100%;
  padding-bottom: ${SPACING.three}px;
  max-width: 100%;
`;
