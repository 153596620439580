import { useCallback, useContext } from "react";
import { OptionsDataContext } from "../../contexts/OptionsDataContext";
import { IOrder, IOrderType } from "../../interfaces/Orders";

export const useOrder = () => {
  const {
    userPositions: { orders: userPositions },
    pendingOrders: { orders: pendingOrders },
    userTradeHistory: { orders: userTradeHistory },
    userUnfilledOrders: { orders: userUnfilledOrders },
    mmPositions: { orders: mmPositions },
    mmTradeHistory: { orders: mmTradeHistory },
    liveRequests: { orders: liveRequests },
    unmatchedRequests: { orders: unmatchedRequests },
  } = useContext(OptionsDataContext);

  const hasOrder = (orders: IOrder[], orderId: number) => orders.some((order) => order.orderId === orderId);

  const getOrder = useCallback(
    (orderId: number): IOrder | undefined => {
      const orderArrays = [
        userPositions,
        pendingOrders,
        userTradeHistory,
        userUnfilledOrders,
        liveRequests,
        unmatchedRequests,
        mmPositions,
        mmTradeHistory,
      ];

      return orderArrays
        .flatMap((orderArray) => orderArray)
        .find((order) => order.orderId === orderId);
    },
    [
      liveRequests,
      mmPositions,
      mmTradeHistory,
      pendingOrders,
      unmatchedRequests,
      userPositions,
      userTradeHistory,
      userUnfilledOrders,
    ]
  );

  const getOrderType = useCallback(
    (orderId: number): IOrderType | undefined => {
      if (hasOrder(userPositions, orderId)) {
        return "AccountPositions" as IOrderType;
      }
      if (hasOrder(pendingOrders, orderId)) {
        return "PendingOrders" as IOrderType;
      }
      if (hasOrder(userTradeHistory, orderId)) {
        return "TradeHistory" as IOrderType;
      }
      if (hasOrder(userUnfilledOrders, orderId)) {
        return "UnfilledOrders" as IOrderType;
      }
      if (hasOrder(liveRequests, orderId)) {
        return "LiveRequests" as IOrderType;
      }
      if (hasOrder(unmatchedRequests, orderId)) {
        return "UnmatchedRequests" as IOrderType;
      }
      if (hasOrder(mmPositions, orderId)) {
        return "MMPositions" as IOrderType;
      }
      if (hasOrder(mmTradeHistory, orderId)) {
        return "MMTradeHistory" as IOrderType;
      }
      return undefined;
    },
    [
      liveRequests,
      mmPositions,
      mmTradeHistory,
      pendingOrders,
      unmatchedRequests,
      userPositions,
      userTradeHistory,
      userUnfilledOrders,
    ]
  );
  return {
    getOrder,
    getOrderType
  };
};
