import { css } from "styled-components";

export const FONT_SIZE = {
  xxxs: "9px",
  xxs: "10px",
  xs: "11px",
  one: "12px",
  oneHalf: "13px",
  two: "14px",
  three: "16px",
  four: "22px",
  five: "28px",
} as const;

export const FONT_STYLE = {
  header: {
    one: {
      fontSize: "96px",
      lineHeight: "112px",
    },
    two: {
      fontSize: "60px",
      lineHeight: "72px",
    },
    three: {
      fontSize: "36px",
      lineHeight: "44px",
    },
    four: {
      fontSize: "22px",
      lineHeight: "28px",
    },
    five: {
      fontSize: "16px",
      lineHeight: "20px",
    },
    six: {
      fontSize: "14px",
      lineHeight: "20px",
    },
    seven: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  body: {
    one: {
      fontSize: "16px",
      lineHeight: "24px",
    },
    two: {
      fontSize: "14px",
      lineHeight: "20px",
    },
    three: {
      fontSize: "13px",
      lineHeight: "16px",
    },
    four: {
      fontSize: "12px",
      lineHeight: "16px",
    },
    five: {
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
  data: {
    one: {
      fontSize: "28px",
      lineHeight: "32px",
    },
    two: {
      fontSize: "16px",
      lineHeight: "20px",
    },
    three: {
      fontSize: "13px",
      lineHeight: "16px",
    },
    four: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  label: {
    one: {
      fontSize: "13px",
      lineHeight: "16px",
    },
    two: {
      fontSize: "12px",
      lineHeight: "16px",
    },
    three: {
      fontSize: "11px",
      lineHeight: "12px",
    },
    four: {
      fontSize: "10px",
      lineHeight: "12px",
    },
  }
} as const;

//  CSS
export const FONT_CSS = {
  header: {
    one: css`
      font-size: ${FONT_STYLE.header.one.fontSize};
      line-height: ${FONT_STYLE.header.one.lineHeight};
    `,
    two: css`
      font-size: ${FONT_STYLE.header.two.fontSize};
      line-height: ${FONT_STYLE.header.two.lineHeight};
    `,
    three: css`
      font-size: ${FONT_STYLE.header.three.fontSize};
      line-height: ${FONT_STYLE.header.three.lineHeight};
    `,
    four: css`
      font-size: ${FONT_STYLE.header.four.fontSize};
      line-height: ${FONT_STYLE.header.four.lineHeight};
    `,
    five: css`
      font-size: ${FONT_STYLE.header.five.fontSize};
      line-height: ${FONT_STYLE.header.five.lineHeight};
    `,
    six: css`
      font-size: ${FONT_STYLE.header.six.fontSize};
      line-height: ${FONT_STYLE.header.six.lineHeight};
    `,
    seven: css`
      font-size: ${FONT_STYLE.header.seven.fontSize};
      line-height: ${FONT_STYLE.header.seven.lineHeight};
    `,
  },
  body: {
    one: css`
      font-size: ${FONT_STYLE.body.one.fontSize};
      line-height: ${FONT_STYLE.body.one.lineHeight};
    `,
    two: css`
      font-size: ${FONT_STYLE.body.two.fontSize};
      line-height: ${FONT_STYLE.body.two.lineHeight};
    `,
    three: css`
      font-size: ${FONT_STYLE.body.three.fontSize};
      line-height: ${FONT_STYLE.body.three.lineHeight};
    `,
    four: css`
      font-size: ${FONT_STYLE.body.four.fontSize};
      line-height: ${FONT_STYLE.body.four.lineHeight};
    `,
    five: css`
      font-size: ${FONT_STYLE.body.five.fontSize};
      line-height: ${FONT_STYLE.body.five.lineHeight};
    `,
  },
  data: {
    one: css`
      font-size: ${FONT_STYLE.data.one.fontSize};
      line-height: ${FONT_STYLE.data.one.lineHeight};
    `,
    two: css`
      font-size: ${FONT_STYLE.data.two.fontSize};
      line-height: ${FONT_STYLE.data.two.lineHeight};
    `,
    three: css`
      font-size: ${FONT_STYLE.data.three.fontSize};
      line-height: ${FONT_STYLE.data.three.lineHeight};
    `,
    four: css`
      font-size: ${FONT_STYLE.data.four.fontSize};
      line-height: ${FONT_STYLE.data.four.lineHeight};
    `,
  },
  label: {
    one: css`
      font-size: ${FONT_STYLE.label.one.fontSize};
      line-height: ${FONT_STYLE.label.one.lineHeight};
    `,
    two: css`
      font-size: ${FONT_STYLE.label.two.fontSize};
      line-height: ${FONT_STYLE.label.two.lineHeight};
    `,
    three: css`
      font-size: ${FONT_STYLE.label.three.fontSize};
      line-height: ${FONT_STYLE.label.three.lineHeight};
    `,
    four: css`
    font-size: ${FONT_STYLE.label.four.fontSize};
    line-height: ${FONT_STYLE.label.four.lineHeight};
  `,
  }
} as const;
