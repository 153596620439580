import { useMemo } from "react";
import currency from "currency.js";
import {
  HeaderText,
  SublabelWrapper,
} from "../../TradeSelectionDropdown/style";
import { SublabelText } from "../Label/style";
import useAssetPriceData from "../../../hooks/api/prices/useAssetPriceData";
import {
  getAssetLogo,
  Assets,
  getAssetIndicPrecision,
} from "../../../utils/assets";
import { getNumberColor } from "../../../utils/math";
import { SPACING } from "../../../constants/design/spacing";
import { COLORS } from "../../../constants/design/colors";
import LogoContainer from "../LogoContainer";

interface IActiveAsset {
  logo: string | undefined;
  title: string | undefined;
  price: string | undefined;
  percentChange: string | undefined;
  priceColor: string | undefined;
}

interface IRequestModalProps {
  asset: Assets;
  priceAtExpiry?: string;
}
function AssetWrapper({ asset, priceAtExpiry }: IRequestModalProps) {
  const { useAssetsPrice } = useAssetPriceData();
  const { prices } = useAssetsPrice();

  const activeAsset: IActiveAsset = useMemo(() => {
    const { loading, price, percentChange } = prices[asset];

    return {
      logo: getAssetLogo(asset),
      title: `${asset}-USD`,
      price: loading ? "-" : String(price),
      percentChange: loading
        ? ""
        : `${percentChange > 0 ? "+" : ""}${percentChange.toFixed(2)}%`,
      priceColor: getNumberColor(percentChange),
    };
  }, [asset, prices]);

  return (
    <div>
      <LogoContainer logo={activeAsset.logo} />
      <div>
        <HeaderText>{activeAsset?.title}</HeaderText>
        {priceAtExpiry ? (
          <SublabelWrapper>
            <SublabelText>Expiry Price: </SublabelText>
            <SublabelText marginLeft={SPACING.one} color={COLORS.white.one}>
              {currency(priceAtExpiry, {
                precision: getAssetIndicPrecision(asset),
              }).format()}
            </SublabelText>
          </SublabelWrapper>
        ) : (
          <SublabelWrapper>
            <SublabelText>{activeAsset?.price}</SublabelText>
            <SublabelText
              marginLeft={SPACING.two}
              color={activeAsset?.priceColor}
            >
              {activeAsset?.percentChange}
            </SublabelText>
          </SublabelWrapper>
        )}
      </div>
    </div>
  );
}
export default AssetWrapper;
