import { SwitchLabel, SwitchLine, ToggleContainer } from "./style";

interface IToggleProps {
  isOn: boolean;
  onToggle: () => void;
}

function Toggle({ isOn, onToggle }: IToggleProps) {
  return (
    <ToggleContainer isOn={isOn} onClick={onToggle}>
      <div>
        <SwitchLine isOn={isOn} />
        <SwitchLabel isOn={isOn}>
          {isOn ? "On" : "Off"}
        </SwitchLabel>
      </div>
    </ToggleContainer>
  );
}

export default Toggle;
