import { ChainIdEnum } from "../enums/chain";

type ChainIdToBlockExplorerURL = {
  [chainId in ChainIdEnum]: string;
};

export const CHAIN_EXPLORER_URLS: ChainIdToBlockExplorerURL = {
  [ChainIdEnum.NONE]: "",
  [ChainIdEnum.ETH_MAINNET]: "https://etherscan.io",
};

interface IAddEthereumChainParameter {
  chainId: string; // A 0x-prefixed hexadecimal string
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string; // 2-6 characters long
    decimals: 18;
  };
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  iconUrls?: string[]; // Currently ignored.
}

type ChainIdToChainParam = {
  [chainId in ChainIdEnum]: IAddEthereumChainParameter | undefined;
};

export const CHAIN_PARAMS: ChainIdToChainParam = {
  [ChainIdEnum.NONE]: undefined,
  [ChainIdEnum.ETH_MAINNET]: undefined,
};
