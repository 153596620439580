import { motion } from "framer-motion";
import styled, { css, keyframes } from "styled-components";
import {
  BACKGROUND_COLORS,
  COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_SIZE, FONT_STYLE } from "../../constants/design/fontSize";
import { FONT_WEIGHT } from "../../constants/design/fontWeight";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";

export const Container = styled.div`
  position: absolute;
  z-index: 2;
  left: ${SPACING.three}px;
  right: ${SPACING.three}px;
  margin: ${SPACING.three}px 0 0;
`;

const flash = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

export const Tab = styled.button<{
  isActive?: boolean;
  flashing?: boolean;
}>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${SPACING.two}px 0;
  text-transform: capitalize;
  height: ${COMPONENTS.tertiaryTabs - 2 * SPACING.two}px;
  font-size: ${FONT_SIZE.one};
  color: ${({ isActive }) => (isActive ? COLORS.blue.one : TEXT_COLORS.one)};
  opacity: ${({ isActive }) => (isActive ? 1 : 0.64)};

  svg {
    margin-left: ${SPACING.one}px;
    stroke: ${({ isActive }) => (isActive ? COLORS.blue.one : TEXT_COLORS.one)};
  }

  ${({ flashing }) => {
    if (flashing) {
      return css`
        opacity: 0;
        animation: ${flash} 1.2s ease-in-out infinite;
      `;
    }
    return "";
  }}
`;

export const Header = styled.div`
  background-color: ${BACKGROUND_COLORS.eight};
  border-radius: ${SPACING.two}px;
  display: flex;

  ${Tab} {
    &:not(:last-child) {
      border-right: 1px solid ${COLORS.white.two};
    }
  }
`;

export const FilterContent = styled(motion.div)`
  position: absolute;
  left: 0px;
  right: 0px;
  margin-top: ${SPACING.three}px;
  z-index: 1;
  background-color: ${BACKGROUND_COLORS.eight};
  border-radius: ${SPACING.two}px;
  font-size: ${FONT_SIZE.one};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ButtonsContainer = styled.div`
  color: ${TEXT_COLORS.two};
  border-radius: 8px;
  position: absolute;
  bottom: ${SPACING.three}px;
  left: ${SPACING.three}px;
  right: ${SPACING.three}px;
`;

export const Title = styled.div`
  padding: ${SPACING.three}px;
  height: ${COMPONENTS.titleHeight}px;
  font-weight: ${FONT_WEIGHT.medium};
  border-bottom: 1px solid ${COLORS.white.five};
`;

export const FilterSelections = styled.div`
  // Extra padding bottom leave space for cancel button
  margin-bottom: ${SPACING.three}px;
  padding-bottom: ${SPACING.six}px;
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: scroll;
`;

export const ExpiryTag = styled.div`
  border-radius: 4px;
  margin-left: ${SPACING.two}px;
  padding: 2px 4px;
`;

export const FilterRow = styled.button<{
  selected?: boolean;
}>`
  color: ${({ selected }) => (selected ? COLORS.blue.one : TEXT_COLORS.two)};
  padding: ${SPACING.twoHalf}px ${SPACING.two}px;
  height: 40px;
  margin: 0 ${SPACING.two}px;
  text-align: left;
  border-radius: 8px;
  text-transform: capitalize;
  display: flex;
  align-items: center;

  &:hover {
    color: ${({ selected }) => (selected ? COLORS.blue.one : TEXT_COLORS.one)};
    background-color: ${({ selected }) => (selected ? COLORS.blue.four : BACKGROUND_COLORS.four)};
  }

  ${ExpiryTag} {
    background-color: ${({ selected }) => (selected ? COLORS.blue.five : BACKGROUND_COLORS.four)};
  }
`;

export const CurrentSpotRow = styled.div`
  background-color: ${BACKGROUND_COLORS.four};
  padding: ${SPACING.two}px;
  text-align: center;
  color: ${TEXT_COLORS.three};
`;

export const CurrentPrice = styled.span`
  color: ${TEXT_COLORS.one};
  padding: ${SPACING.two}px;
`;

export const ChangePercent = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  font-size: ${FONT_SIZE.one};
`;

export const SelectStrikeWarning = styled.span`
  text-align: center;
  display: inline-block;
  width: 100%;
  color: ${COLORS.blue.one};
  font-size: ${FONT_SIZE.one};
  font-weight: ${FONT_WEIGHT.medium};
  padding-bottom: ${SPACING.twoHalf}px;
`;

export const StrikeButtonsWrapper = styled.div`
  display: flex;
`;

export const CustomStrikeContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: ${SPACING.four}px 0;
`;

export const CustomStrikeContentText = styled.div`
  font-size: ${FONT_SIZE.two}px;
  color: ${TEXT_COLORS.four};
`;

export const CustomStrikeContentErrorText = styled.div`
  font-size: ${FONT_SIZE.two}px;
  color: ${COLORS.negative.one};
`;

export const CustomStrikeContentPercentageText = styled.div<{ color: string }>`
  font-size: ${FONT_SIZE.oneHalf}px;
  line-height: ${FONT_STYLE.header.seven.lineHeight};
  color: ${({ color }) => color};
`;

export const CustomExpiryWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const RdpWrapper = styled.div`
  .rdp {
    --rdp-cell-size: 24px;
    --rdp-caption-font-size: 14px;
    --rdp-accent-color: #0000ff;
    --rdp-background-color: #000000;
    --rdp-accent-color-dark: #3003e1;
    --rdp-background-color-dark: #180270;
    --rdp-outline: 2px solid var(--rdp-accent-color);
    --rdp-outline-selected: 3px solid var(--rdp-accent-color);

    margin: 1em;
  }
`;

export const ErrorText = styled.div`
  color: ${COLORS.negative.one};
`;
