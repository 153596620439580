import React from "react";

type SVGProps = React.SVGAttributes<SVGElement>;

export const tradeHistory = ({
  color,
  ...props
}: SVGProps & { color: string }): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="M7.95 14C6.29444 14 4.88889 13.4139 3.73333 12.2417C2.57778 11.0694 2 9.65 2 7.98333H3C3 9.37222 3.47778 10.5556 4.43333 11.5333C5.38889 12.5111 6.56111 13 7.95 13C9.36111 13 10.5556 12.5056 11.5333 11.5167C12.5111 10.5278 13 9.32778 13 7.91667C13 6.53889 12.5056 5.375 11.5167 4.425C10.5278 3.475 9.33889 3 7.95 3C7.19444 3 6.48611 3.17222 5.825 3.51667C5.16389 3.86111 4.58889 4.31667 4.1 4.88333H5.85V5.88333H2.36667V2.41667H3.36667V4.18333C3.94444 3.50556 4.63056 2.97222 5.425 2.58333C6.21944 2.19444 7.06111 2 7.95 2C8.78333 2 9.56667 2.15556 10.3 2.46667C11.0333 2.77778 11.675 3.20278 12.225 3.74167C12.775 4.28056 13.2083 4.91111 13.525 5.63333C13.8417 6.35556 14 7.13333 14 7.96667C14 8.8 13.8417 9.58333 13.525 10.3167C13.2083 11.05 12.775 11.6889 12.225 12.2333C11.675 12.7778 11.0333 13.2083 10.3 13.525C9.56667 13.8417 8.78333 14 7.95 14ZM10.0833 10.7167L7.51667 8.18333V4.61667H8.51667V7.76667L10.8 10L10.0833 10.7167Z"
        fill={color || "white"}
      />
    </g>
  </svg>
);
