/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import currency from "currency.js";
import { useCallback, useMemo } from "react";
import { COLORS } from "../../constants/design/colors";
import { useOrderTimes } from "../time/useOrderTimes";
import useWallet from "../wallet/useWallet";
import { IOrder, IOrderType, ReasonText } from "../../interfaces/Orders";
import { ITableColumn } from "../../interfaces/Table/TableColumn";
import { getTimeAgo } from "../../utils/date";
import { Align } from "../../components/shared/Table/style";
import MarketCell from "../../components/OrdersTable/Table/MarketCell";
import PerformanceCell from "../../components/OrdersTable/Table/PerformanceCell";
import { getAssetIndicPrecision } from "../../utils/assets";
import { getProfitTextColor } from "../../utils/strings";
import MobileMarketCell from "../../components/OrdersTable/Table/MobileMarketCell";
import { IInfoRowCol } from "../../interfaces/InfoRow";
import MarginCell from "../../components/OrdersTable/Table/MarginCell";

interface IOrderColumnsProps {
  orderType: IOrderType;
}

function useOrderColumns({ orderType }: IOrderColumnsProps) {
  const { account } = useWallet();
  const { timeArgs } = useOrderTimes();

  const makeInfoCols = useCallback(
    (orderObj: IOrder) => {
      const {
        asset,
        contracts,
        expiry,
        performance,
        reason,
        indicativePrice,
        offerPrice,
        orderStartTime,
        orderOfferPeriodEnd,
        orderEndTime,
        expiredAt,
        marginPosted,
        entryPrice,
        imAmount,
      } = orderObj;
      const { timeToExpiry, orderExpired, isOrderConfirmationTimeWindow }
        = timeArgs(
          Number(orderStartTime),
          Number(orderOfferPeriodEnd),
          Number(orderEndTime)
        );
      const rowColor = () => {
        if (isOrderConfirmationTimeWindow) {
          return COLORS.positive.one;
        }
        if (orderExpired) {
          return COLORS.negative.one;
        }
        return "inherit";
      };
      const cols: IInfoRowCol[] = [];
      if (orderType === "AccountPositions") {
        cols.push({
          title: "Contract",
          detail: contracts,
        });
        cols.push({
          title: "Entry Price",
          detail: entryPrice
            ? currency(parseFloat(entryPrice), {
              precision: getAssetIndicPrecision(asset),
            }).format()
            : "-",
        });
      }
      if (orderType === "TradeHistory") {
        cols.push({
          title: "PnL",
          detail: performance ? currency(performance).format() : "-",
          color: performance
            ? getProfitTextColor(Number(performance))
            : undefined,
        });
        cols.push({
          title: "Status",
          detail: reason ? ReasonText[reason] : "-",
          color:
            reason === "UNCLAIMED" || reason === "PENDING"
              ? COLORS.blue.one
              : undefined,
        });
      }
      if (orderType === "UnfilledOrders") {
        cols.push({
          title: "Contract",
          detail: contracts,
        });
        cols.push({
          title: "Indicative Price",
          detail: indicativePrice
            ? currency(parseFloat(indicativePrice)).format()
            : "-",
        });
      }
      if (orderType === "LiveRequests") {
        cols.push({
          title: "Contract",
          detail: contracts,
        });
        cols.push({
          title: "Offer/Bid Price",
          detail: offerPrice
            ? currency(parseFloat(offerPrice), {
              precision: getAssetIndicPrecision(asset),
            }).format()
            : "No Offer",
          color: offerPrice ? COLORS.blue.one : undefined,
        });
        cols.push({
          title: "Time Left",
          detail: timeToExpiry,
          color: rowColor(),
        });
      }
      if (orderType === "UnmatchedRequests") {
        cols.push({
          title: "Contract",
          detail: contracts,
        });
        cols.push({
          title: "Offer/Bid Price",
          detail: offerPrice
            ? currency(parseFloat(offerPrice), {
              precision: getAssetIndicPrecision(asset),
            }).format()
            : "No Offer",
          color: offerPrice ? COLORS.blue.one : COLORS.negative.one,
        });
        cols.push({
          title: "Time",
          detail: expiredAt ? getTimeAgo(Number(expiredAt) * 1000) : "-",
        });
      }
      if (orderType === "MMPositions") {
        cols.push({
          title: "Initial Margin",
          detail: imAmount ? currency(imAmount).format() : "-",
        });
        cols.push({
          title: "Margin Posted",
          detail: marginPosted ? currency(marginPosted).format() : "-",
        });
        cols.push({
          title: "Unrealized PnL",
          detail: performance ? currency(performance).format() : "-",
          color: performance
            ? getProfitTextColor(Number(performance))
            : undefined,
        });
      }
      if (orderType === "MMTradeHistory") {
        cols.push({
          title: "Realized PnL",
          detail: performance ? currency(performance).format() : "-",
          color: performance
            ? getProfitTextColor(Number(performance))
            : undefined,
        });
        cols.push({
          title: "Status",
          detail: reason ? ReasonText[reason] : "-",
          color:
            reason === "UNCLAIMED" || reason === "PENDING"
              ? COLORS.blue.one
              : undefined,
        });
        cols.push({
          title: "Time",
          detail: getTimeAgo(Number(expiry) * 1000),
        });
      }

      return cols;
    },
    [orderType, timeArgs]
  );

  const columns: ITableColumn<IOrder>[] = useMemo(() => {
    const cols: ITableColumn<IOrder>[] = [
      {
        title: "Market",
        accessor: "instrumentName",
        Cell: ({ row }) => {
          const {
            instrumentName,
            asset,
            contracts,
            offerPrice,
            orderStartTime,
            orderOfferPeriodEnd,
            orderEndTime,
            entryPrice,
            isUnwindOrder,
          } = row.original;
          const { isOrderConfirmationTimeWindow } = timeArgs(
            Number(orderStartTime),
            Number(orderOfferPeriodEnd),
            Number(orderEndTime)
          );
          return (
            <MarketCell
              instrumentName={instrumentName}
              asset={asset}
              isOrderConfirmationTimeWindow={isOrderConfirmationTimeWindow}
              price={offerPrice || entryPrice || ""}
              contracts={contracts}
              isUnwindOrder={Boolean(isUnwindOrder)}
              hasSecondaryText={
                orderType === "MMPositions"
                || orderType === "TradeHistory"
                || orderType === "MMTradeHistory"
              }
            />
          );
        },
      },
    ];
    const otherCols: ITableColumn<IOrder>[] = [];

    if (orderType === "AccountPositions") {
      otherCols.push({
        title: "Contracts",
        accessor: "contracts",
        align: "right",
      });
      otherCols.push({
        title: "Entry Price",
        accessor: "entryPrice",
        align: "right",
        Cell: ({ row }) => {
          const { entryPrice, asset } = row.original;
          return (
            <Align align="right">
              {entryPrice
                ? `${currency(parseFloat(entryPrice), {
                  precision: getAssetIndicPrecision(asset),
                }).format()}`
                : "-"}
            </Align>
          );
        },
      });
    }
    if (orderType === "TradeHistory") {
      otherCols.push({
        title: "Performance",
        accessor: "performance",
        align: "right",
        Cell: ({ row }) => {
          const { performance, roi } = row.original;
          const color = getProfitTextColor(
            performance ? Number(performance) : 0
          );
          return (
            <Align align="right" color={color}>
              <PerformanceCell
                performance={performance || ""}
                roi={roi || ""}
              />
            </Align>
          );
        },
      });
      otherCols.push({
        title: "Status",
        accessor: "reason",
        align: "right",
        Cell: ({ value }) => {
          const color
            = value === "UNCLAIMED" || value === "PENDING"
              ? COLORS.blue.one
              : undefined;
          return (
            <Align
              align="right"
              color={color}
              style={{ textTransform: "none" }}
            >
              {value ? ReasonText[value] : "-"}
            </Align>
          );
        },
      });
      otherCols.push({
        title: "Time",
        accessor: "expiredAt",
        align: "right",
        Cell: ({ value }) => {
          const time = getTimeAgo(Number(value) * 1000);
          return (
            <Align align="right" style={{ textTransform: "lowercase" }}>
              {time}
            </Align>
          );
        },
      });
    }
    if (orderType === "UnfilledOrders") {
      otherCols.push({
        title: "Contracts",
        accessor: "contracts",
        align: "right",
      });
      otherCols.push({
        title: "Indicative Price",
        accessor: "indicativePrice",
        align: "right",
        Cell: ({ value }) => (
          <Align align="right">
            {value ? `${currency(parseFloat(value)).format()}` : "-"}
          </Align>
        ),
      });
      otherCols.push({
        title: "Time",
        accessor: "expiredAt",
        align: "right",
        Cell: ({ value }) => {
          const time = getTimeAgo(Number(value) * 1000);
          return (
            <Align align="right" style={{ textTransform: "lowercase" }}>
              {time}
            </Align>
          );
        },
      });
    }
    if (orderType === "LiveRequests") {
      otherCols.push({
        title: "Contracts",
        accessor: "contracts",
        align: "right",
      });
      if (account) {
        otherCols.push({
          title: "Offer/Bid Price",
          accessor: "offerPrice",
          align: "right",
          Cell: ({ row }) => {
            const { offerPrice, asset } = row.original;
            return (
              <Align
                align="right"
                style={{
                  ...(!offerPrice ? { color: COLORS.blue.one } : {}),
                }}
              >
                {offerPrice
                  ? `${currency(parseFloat(offerPrice), {
                    precision: getAssetIndicPrecision(asset),
                  }).format()}`
                  : "No Offer"}
              </Align>
            );
          },
        });
      }
      otherCols.push({
        title: "Time Left",
        accessor: "orderEndTime",
        align: "right",
        Cell: ({ row }) => {
          const { orderStartTime, orderOfferPeriodEnd, orderEndTime }
            = row.original;
          const { timeToExpiry, orderExpired, isOrderConfirmationTimeWindow }
            = timeArgs(
              Number(orderStartTime),
              Number(orderOfferPeriodEnd),
              Number(orderEndTime)
            );
          const rowColor = () => {
            if (isOrderConfirmationTimeWindow) {
              return COLORS.positive.one;
            }
            if (orderExpired) {
              return COLORS.negative.one;
            }
            return "inherit";
          };
          return (
            <Align
              align="right"
              style={{
                color: rowColor(),
              }}
            >
              {timeToExpiry}
            </Align>
          );
        },
      });
    }
    if (orderType === "UnmatchedRequests") {
      otherCols.push({
        title: "Contracts",
        accessor: "contracts",
        align: "right",
      });
      if (account) {
        otherCols.push({
          title: "Offer/Bid Price",
          accessor: "offerPrice",
          align: "right",
          Cell: ({ row }) => {
            const { offerPrice, asset } = row.original;
            return (
              <Align
                align="right"
                style={{
                  ...(!offerPrice ? { color: COLORS.negative.one } : {}),
                }}
              >
                {offerPrice
                  ? `${currency(parseFloat(offerPrice), {
                    precision: getAssetIndicPrecision(asset),
                  }).format()}`
                  : "No Offer"}
              </Align>
            );
          },
        });
      }
      otherCols.push({
        title: "Time",
        accessor: "expiredAt",
        align: "right",
        Cell: ({ value }) => {
          const time = getTimeAgo(Number(value) * 1000);
          return (
            <Align align="right" style={{ textTransform: "lowercase" }}>
              {time}
            </Align>
          );
        },
      });
    }
    if (orderType === "MMPositions") {
      otherCols.push({
        title: "Initial Margin",
        accessor: "imAmount",
        align: "right",
        Cell: ({ row }) => {
          const { imAmount } = row.original;
          return (
            <Align align="right">
              {imAmount ? `${currency(imAmount).format()}` : "-"}
            </Align>
          );
        },
      });
      otherCols.push({
        title: "Margin Posted",
        accessor: "marginPosted",
        align: "right",
        Cell: ({ row }) => {
          const { marginPosted, requireTopUp } = row.original;
          return (
            <Align align="right">
              <MarginCell
                marginPosted={marginPosted}
                requireTopUp={Boolean(requireTopUp)}
              />
            </Align>
          );
        },
      });
      otherCols.push({
        title: "Unrealized PnL",
        accessor: "performance",
        align: "right",
        Cell: ({ row }) => {
          const { performance, roi } = row.original;
          const color = getProfitTextColor(
            performance ? Number(performance) : 0
          );
          return (
            <Align align="right" color={color}>
              <PerformanceCell
                performance={performance || ""}
                roi={roi || ""}
              />
            </Align>
          );
        },
      });
    }
    if (orderType === "MMTradeHistory") {
      otherCols.push({
        title: "Realized PnL",
        accessor: "performance",
        align: "right",
        Cell: ({ row }) => {
          const { performance } = row.original;
          const color = getProfitTextColor(
            performance ? Number(performance) : 0
          );
          return (
            <Align align="right" color={color}>
              <PerformanceCell performance={performance} />
            </Align>
          );
        },
      });
      otherCols.push({
        title: "Status",
        accessor: "reason",
        align: "right",
        Cell: ({ value }) => {
          const color
            = value === "UNCLAIMED" || value === "PENDING"
              ? COLORS.blue.one
              : undefined;
          return (
            <Align
              align="right"
              color={color}
              style={{ textTransform: "none" }}
            >
              {value ? ReasonText[value] : "-"}
            </Align>
          );
        },
      });
      otherCols.push({
        title: "Time",
        accessor: "expiry",
        align: "right",
        Cell: ({ value }) => {
          const time = getTimeAgo(Number(value) * 1000);
          return (
            <Align align="right" style={{ textTransform: "lowercase" }}>
              {time}
            </Align>
          );
        },
      });
    }
    return [...cols, ...otherCols];
  }, [account, orderType, timeArgs]);

  const mobileColumns: ITableColumn<IOrder>[] = useMemo(() => {
    const cols: ITableColumn<IOrder>[] = [
      {
        title: "Market",
        accessor: "instrumentName",
        Cell: ({ row }) => {
          const {
            instrumentName,
            asset,
            orderStartTime,
            orderOfferPeriodEnd,
            orderEndTime,
            isUnwindOrder,
          } = row.original;
          const { isOrderConfirmationTimeWindow } = timeArgs(
            Number(orderStartTime),
            Number(orderOfferPeriodEnd),
            Number(orderEndTime)
          );
          const infoCols = makeInfoCols(row.original);
          return (
            <MobileMarketCell
              instrumentName={instrumentName}
              asset={asset}
              isOrderConfirmationTimeWindow={isOrderConfirmationTimeWindow}
              isUnwindOrder={Boolean(isUnwindOrder)}
              infoCols={infoCols}
            />
          );
        },
      },
    ];
    return cols;
  }, [makeInfoCols, timeArgs]);

  return {
    columns,
    mobileColumns,
  };
}

export default useOrderColumns;
