import useSWR from "swr";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { GET_MM_NAMES_URL } from "../../../../constants/endpoints/endpoints";

const useFetchMMNames = () => {
  const [data, setData] = useState<string[]>([]);

  const apiURL = GET_MM_NAMES_URL;
  const fetcher = (url: string) => axios.get(url).then((res) => res.data);

  const swr = useSWR<string[]>(apiURL, fetcher);
  const { data: swrData, error: swrError } = swr;

  const fetchMMNames = useCallback(async () => {
    if (!swrData || swrError) {
      // API call hasn't completed yet or error, return early
      return;
    }

    setData(swrData);
  }, [swrData, swrError]);

  useEffect(() => {
    fetchMMNames();
  }, [fetchMMNames]);

  return {
    data,
  };
};

export default useFetchMMNames;
