import React from "react";

type SVGProps = React.SVGAttributes<SVGElement>;

export const globe = ({
  color,
  ...props
}: SVGProps & { color: string }): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0013 18.3334C14.6037 18.3334 18.3346 14.6024 18.3346 10C18.3346 5.39765 14.6037 1.66669 10.0013 1.66669C5.39893 1.66669 1.66797 5.39765 1.66797 10C1.66797 14.6024 5.39893 18.3334 10.0013 18.3334Z"
      stroke={color || "white"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66797 10H18.3346"
      stroke={color || "white"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0013 1.66669C12.0857 3.94865 13.2703 6.91005 13.3346 10C13.2703 13.09 12.0857 16.0514 10.0013 18.3334C7.9169 16.0514 6.73234 13.09 6.66797 10C6.73234 6.91005 7.9169 3.94865 10.0013 1.66669Z"
      stroke={color || "white"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
