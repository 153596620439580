import styled from "styled-components";
import {
  BORDER_COLORS,
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { FONT_SIZE } from "../../constants/design/fontSize";
import { glow } from "../Buttons/styles";

export const SubmitButton = styled.button`
  height: ${COMPONENTS.largeButtonHeight}px;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out;
  display: flex;
  width: 100%;
  border-radius: 6px;
  color: ${TEXT_COLORS.two};
  font-size: ${FONT_SIZE.two};
  padding: ${SPACING.three}px ${SPACING.two}px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const ExtraContentWrapper = styled.div`
  display: flex;
  margin-top: ${SPACING.three}px;
`;

export const PrimaryButton = styled(SubmitButton)<{ requireTopUp?: boolean }>`
  flex: 1;
  background: ${LAYER_COLORS.one};
  height: ${COMPONENTS.xlButtonHeight}px;
  color: ${TEXT_COLORS.one};
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 12px;
  opacity: 0.6;
  &:hover {
    ${({ disabled }) => !disabled && "opacity: 1;"}
  }
  ${({ disabled }) => !disabled && glow(TEXT_COLORS.one)}
  ${({ requireTopUp }) => requireTopUp!! && glow(COLORS.negative.one)}
  ${({ requireTopUp }) => requireTopUp!!
    && `
    color: ${COLORS.negative.one};
    background: ${COLORS.negative.five};
    border: none;
  `}
`;

export const CloseButton = styled(SubmitButton)`
  flex: 1;
  background: ${LAYER_COLORS.highlightDark};
  height: ${COMPONENTS.xlButtonHeight}px;
  color: ${COLORS.blue.one};
  border: 1px solid ${LAYER_COLORS.highlightDark};
  border-radius: 12px;
  ${glow(COLORS.blue.one)}
`;

export const InfoButton = styled(SubmitButton)`
  width: ${COMPONENTS.xlButtonHeight}px;
  height: ${COMPONENTS.xlButtonHeight}px;
  display: flex;
  align-items: center;
  margin-left: ${SPACING.three}px;
  background: ${LAYER_COLORS.one};
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 12px;
  opacity: 0.6;
  &:hover {
    ${({ disabled }) => !disabled && "opacity: 1;"}
  }
  ${({ disabled }) => !disabled && glow(TEXT_COLORS.one)}
`;

export const PrimaryButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING.two}px;
  margin-left: ${SPACING.two}px;
`;
