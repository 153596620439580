/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import currency from "currency.js";
import { useForm, useWatch } from "react-hook-form";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { ReactComponent as Close } from "../../../../../assets/svg/close.svg";
import {
  InfoRow,
  HeaderContainer,
  AssetHeaderWrapper,
  InfoDetail,
  SmartContractRowWrapper,
  Form,
  RowsWrapper,
  SegmentedControlWrapper,
  InputContainer,
  InputError,
  InputHeaderWrapper,
  InputLabel,
  ButtonContainer,
} from "../../style";
import { IOrder, OptionSide } from "../../../../../interfaces/Orders";
import {
  LargeModalButtonV2,
  ModalButtonV2,
  ReverseChevronButton,
} from "../../../../Buttons/styles";
import AssetWrapper from "../../../../shared/AssetWrapper";
import { fullExpiryTime } from "../../../../../utils/date";
import {
  BACKGROUND_COLORS,
  COLORS,
  TEXT_COLORS,
} from "../../../../../constants/design/colors";
import { shortenAddress, strToTitle } from "../../../../../utils/strings";
import { ChainIdEnum } from "../../../../../enums/chain";
import { CHAIN_EXPLORER_URLS } from "../../../../../utils/chain";
import { ReactComponent as ArrowOut } from "../../../../../assets/svg/arrow-up-right.svg";
import addresses from "../../../../../constants/addresses/addresses.json";
import {
  UNDERLYER_ADDRESSES,
  getAssetIndicPrecision,
} from "../../../../../utils/assets";
import { IInfoRowCol } from "../../../../../interfaces/InfoRow";
import { FONT_SIZE } from "../../../../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../../../../constants/design/spacing";
import SegmentedControl from "../../../../shared/SegmentedControl";
import { ModalContent } from "../../ModalContentSegmentControl";
import useIndicativePriceData from "../../../../../hooks/api/strikes/useIndicativePriceData";
import { Input } from "../../../../shared/Input";
import { ToastEnum, ToastStatusEnum } from "../../../../../utils/toast";
import { useToast } from "../../../../../hooks/toast";
import useUSDC from "../../../../../hooks/contracts/usdc/useUSDC";
import {
  CounterpartyButton,
  FlashingText,
  PermitButton,
  PlaceOrderButton,
} from "../../../../TradeForm/style";
import usePlaceOrder, {
  IPlaceOrderBody,
} from "../../../../../hooks/api/order/user/usePlaceOrder";
import useWallet from "../../../../../hooks/wallet/useWallet";
import useOtcWrapper from "../../../../../hooks/contracts/otcWrapper/useOtcWrapper";
import useMinimalForwarder from "../../../../../hooks/contracts/minimalForwarder/useMinimalForwarder";
import useSignSignature from "../../../../../hooks/contracts/usdc/useSignSignature";
import { TradeInstrumentContext } from "../../../../../contexts/TradeInstrumentContext";
import { OptionsDataContext } from "../../../../../contexts/OptionsDataContext";
import { permitDuration } from "../../../../../constants/time/getTimestamps";
import useOToken from "../../../../../hooks/contracts/oToken/useOToken";
import { isProduction } from "../../../../../utils/env";
import { useApproval } from "../../../../../hooks/approval/useApproval";
import { oracleDecimals } from "../../../../../constants/decimals/decimals";
import { Chevron } from "../../../../shared/Chevron/style";
import useUnwindPermit from "../../../../../hooks/contracts/unwindPermit/useUnwindPermit";
import SelectCounterpartyModal from "../../../SelectCounterpartyModal";

interface IAccountPositionModalProps {
  order: IOrder;
  setShowModal: (show: boolean) => void;
  modalRef?: MutableRefObject<null>;
}

export interface IUnwindPermitDetails {
  permitAmount: string;
  v: number;
  r: string;
  s: string;
  permitExpiry: number;
}

function AccountPositionModal({
  order,
  setShowModal,
  modalRef,
}: IAccountPositionModalProps) {
  const {
    contracts,
    optionType,
    expiry,
    strike,
    asset,
    totalPremiumPaid,
    entryPrice,
    markPrice,
    greeks,
    orderId,
  } = order;

  const { account } = useWallet();
  const { selectedValues, setAsset } = useContext(TradeInstrumentContext);
  const [indicativePrice, setIndicativePrice] = useState<string>("");
  const [selectedCounterparty, setSelectedCounterparty] = useState<string>();
  const [showSelectCounterpartyModal, setShowSelectCounterpartyModal]
    = useState(false);
  const [confirming, setConfirming] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState(false);
  const { addToast, addErrorToast } = useToast();
  const { getIndicativePrice } = useIndicativePriceData();
  const {
    pendingOrders: { orders: userPendingOrders },
  } = useContext(OptionsDataContext);
  const {
    data: { unwindFees },
  } = useOtcWrapper(undefined, undefined, true);
  const [unwindPermitDetails, setUnwindPermitDetails]
    = useState<IUnwindPermitDetails>();
  const [isUnwindOrder, setIsUnwindOrder] = useState(false);
  const [permitting, setPermitting] = useState(false);
  const usdc = useUSDC();
  useEffect(() => {
    setIsUnwindOrder(
      userPendingOrders.some((obj: IOrder) => obj.orderIdToUnwind === orderId)
    );
  }, [orderId, userPendingOrders]);

  useEffect(() => {
    setAsset(asset);
  }, [asset, setAsset]);

  const {
    register,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
  });

  const amount: string | undefined = useWatch({ control, name: "amount" });

  const [modalContent, setModalContent] = useState<ModalContent>(
    ModalContent.Details
  );

  const { handleSignUnwindSignature } = useSignSignature();
  const { placeBuyOrder } = usePlaceOrder();
  const { contract: otcWrapperContract } = useOtcWrapper();

  const { contract: minimalForwarderContract, nonce } = useMinimalForwarder();

  const { whitelistedUsers } = useContext(OptionsDataContext);

  const { approvedAmount, balance, isApproved } = useApproval(
    orderId,
    account || undefined
  );

  const GetIndicativePriceCallback = useCallback(async () => {
    try {
      const { data } = await getIndicativePrice(
        {
          underlyer: asset,
          expiry,
          strike,
          type: optionType,
          add_charges: true,
          side: OptionSide.Sell,
        },
        false
      );
      if (data.indic) {
        const indic = Number(data.indic).toFixed(getAssetIndicPrecision(asset));
        setValue("amount", indic);
        setIndicativePrice(indic);
      }
    } catch (error: any) {
      console.log(error);
    }
  }, [asset, expiry, getIndicativePrice, optionType, setValue, strike]);

  const [bidValue, bidFees] = useMemo(() => {
    if (!amount) {
      return [undefined, undefined];
    }

    const feePercent = unwindFees
      ? Number(formatUnits(unwindFees[asset], 6))
      : 0;
    const bidValueCalc = (Number(amount) * Number(contracts)).toFixed(
      usdc.decimals
    );
    const feesCalc = (Number(amount) * Number(contracts) * feePercent).toFixed(
      usdc.decimals
    );
    return [bidValueCalc, feesCalc];
  }, [amount, asset, contracts, unwindFees, usdc.decimals]);

  const placeOrderBody: IPlaceOrderBody | undefined = useMemo(() => {
    if (!account || !unwindPermitDetails) {
      return undefined;
    }
    return {
      size: String(contracts),
      type: optionType,
      strike,
      underlyer: asset,
      expiry,
      address: account,
      indic: amount || indicativePrice,
      spot_ref: selectedValues?.spotRef,
      permit_v: unwindPermitDetails.v,
      permit_r: unwindPermitDetails.r,
      permit_s: unwindPermitDetails.s,
      permit_amount: unwindPermitDetails.permitAmount,
      permit_expiry: String(unwindPermitDetails.permitExpiry),
      delta: greeks?.delta,
      theta: greeks?.theta,
      vega: greeks?.vega,
      gamma: greeks?.gamma,
      order_id_to_unwind: orderId,
      ...(selectedCounterparty && { counterparty: selectedCounterparty }),
    } as IPlaceOrderBody;
  }, [
    account,
    unwindPermitDetails,
    contracts,
    optionType,
    strike,
    asset,
    expiry,
    amount,
    indicativePrice,
    selectedValues?.spotRef,
    greeks?.delta,
    greeks?.theta,
    greeks?.vega,
    greeks?.gamma,
    orderId,
    selectedCounterparty,
  ]);

  useEffect(() => {
    GetIndicativePriceCallback(); // Call immediately when the component renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contractAddress = addresses.mainnet.contracts.otcWrapper;

  const onHide = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const openBlockExplorer = useCallback(() => {
    window.open(
      `${
        CHAIN_EXPLORER_URLS[ChainIdEnum.ETH_MAINNET]
      }/address/${contractAddress}`
    );
  }, [contractAddress]);

  const infoRows: IInfoRowCol[] = useMemo(() => {
    const rows = [];
    rows.push({
      title: "Option Type",
      detail: `European ${optionType.replace(/^\w/, (c) => c.toUpperCase())}`,
    });
    rows.push({
      title: "Expiry",
      detail: `${fullExpiryTime(expiry)}`,
    });
    rows.push({
      title: "Strike",
      detail: `${strike}`,
    });
    rows.push({
      title: "Contracts",
      detail: `${contracts}`,
    });
    rows.push({
      title: "Total Premium Paid",
      detail: `${currency(totalPremiumPaid).format()}`,
    });
    rows.push({
      title: "Entry Price",
      detail: entryPrice
        ? `${currency(parseFloat(entryPrice), {
          precision: getAssetIndicPrecision(asset),
        }).format()}`
        : "-",
    });
    rows.push({
      title: "Mark Price",
      detail: markPrice
        ? `${currency(parseFloat(markPrice), {
          precision: getAssetIndicPrecision(asset),
        }).format()}`
        : "-",
      color: COLORS.positive.one,
    });
    rows.push({
      title: "Smart Contract",
      detail: (
        <SmartContractRowWrapper onClick={openBlockExplorer}>
          <span>{shortenAddress(contractAddress)}</span>
          <ArrowOut />
        </SmartContractRowWrapper>
      ),
    });
    return rows;
  }, [
    optionType,
    expiry,
    strike,
    contracts,
    totalPremiumPaid,
    entryPrice,
    asset,
    markPrice,
    openBlockExplorer,
    contractAddress,
  ]);

  const greeksRows: IInfoRowCol[] = useMemo(() => {
    const rows = [];
    if (greeks) {
      rows.push({
        title: "Gamma",
        detail: `${greeks.gamma}`,
      });
      rows.push({
        title: "Theta",
        detail: `${greeks.theta}`,
      });
      rows.push({
        title: "Vega",
        detail: `${greeks.vega}`,
      });
      rows.push({
        title: "Delta",
        detail: `${greeks.delta}`,
      });
    }
    return rows;
  }, [greeks]);

  const unwindRows: IInfoRowCol[] = useMemo(() => {
    const rows = [];
    rows.push({
      title: "Suggested Unwind Price",
      detail: indicativePrice
        ? `${currency(indicativePrice, {
          precision: getAssetIndicPrecision(asset),
        }).format()}`
        : "---",
      color: COLORS.blue.one,
    });
    rows.push({
      title: "Unwind Amount",
      detail: bidValue
        ? `${currency(Number(bidValue), {
          precision: getAssetIndicPrecision(asset),
        }).format()}`
        : "---",
    });
    rows.push({
      title: "Unwind Fee",
      detail: bidFees
        ? `${currency(bidFees, {
          precision: getAssetIndicPrecision(asset),
        }).format()}`
        : "---",
    });
    rows.push({
      title: "Approval oToken Amount",
      detail: balance
        ? `${currency(formatUnits(balance, oracleDecimals)).format()}`
        : "---",
    });
    rows.push({
      title: "Approved oToken Amount",
      detail: approvedAmount
        ? `${currency(formatUnits(approvedAmount, oracleDecimals)).format()}`
        : "---",
      color: !approvedAmount
        ? COLORS.white.one
        : isApproved
          ? COLORS.positive.one
          : COLORS.negative.one,
    });
    if (isUnwindOrder) {
      rows.push({
        title: "Position is currently being unwound",
        detail: "",
      });
    }
    return rows;
  }, [
    approvedAmount,
    asset,
    balance,
    bidFees,
    bidValue,
    indicativePrice,
    isApproved,
    isUnwindOrder,
  ]);

  const headerContent = useMemo(
    () => (
      <>
        <AssetHeaderWrapper>
          <AssetWrapper asset={asset} />
        </AssetHeaderWrapper>
        <LargeModalButtonV2 type={"button"} onClick={onHide}>
          <Close />
        </LargeModalButtonV2>
      </>
    ),
    [onHide, asset]
  );

  const placeOrderText = useMemo(() => {
    if (!confirmed) {
      return "Request Unwind";
    }
    return "Transaction Pending";
  }, [confirmed]);

  const resetStates = useCallback(() => {
    setValue("amount", undefined);
    reset();
    setConfirmed(false);
    setConfirming(false);
    setPermitting(false);
    setUnwindPermitDetails(undefined);
  }, [reset, setValue]);

  const { contract: oTokenContract } = useOToken(orderId);
  const { nonce: unwindNonce } = useUnwindPermit();
  const handleApprovals = useCallback(async () => {
    try {
      setPermitting(true);
      if (!unwindPermitDetails) {
        const deadline = Math.round(Date.now() / 1000 + permitDuration);
        const { v, r, s } = await handleSignUnwindSignature(
          bidValue!,
          orderId,
          deadline,
          Number(unwindNonce!)
        );
        setUnwindPermitDetails({
          v,
          r,
          s,
          permitAmount: String(parseUnits(bidValue!, usdc.decimals)),
          permitExpiry: deadline,
        } as IUnwindPermitDetails);
      } else if (isProduction()) {
        if (
          !oTokenContract
          || !otcWrapperContract
          || !account
          || !balance
        ) {
          throw Error;
        }
        await oTokenContract.approve(
          otcWrapperContract.address,
          balance
        );
      }
      setPermitting(false);
    } catch (error) {
      console.log(error);
      resetStates();
      addErrorToast("Something went wrong", "Please try again");
    }
  }, [account, addErrorToast, balance, bidValue, handleSignUnwindSignature, oTokenContract, orderId, otcWrapperContract, resetStates, unwindNonce, unwindPermitDetails, usdc.decimals]);

  const handlePlaceOrder = useCallback(async () => {
    try {
      if (
        placeOrderBody
        && account
        && otcWrapperContract
        && nonce
        && minimalForwarderContract
        && UNDERLYER_ADDRESSES[asset]
      ) {
        setConfirmed(true);
        if (placeOrderBody) {
          const responseOrderId = await placeBuyOrder(placeOrderBody);

          if (responseOrderId) {
            onHide();
            addToast(
              {
                type: ToastEnum.SIMPLE,
                header: "Unwind Request Sent",
                status: ToastStatusEnum.SUCCESS,
              },
              10000
            );
          }
        } else {
          throw Error;
        }
      }
    } catch (err) {
      console.log(err);
      resetStates();
      addErrorToast("Something went wrong", "Please try again");
    } finally {
      resetStates();
    }
  }, [
    placeOrderBody,
    account,
    otcWrapperContract,
    nonce,
    minimalForwarderContract,
    asset,
    placeBuyOrder,
    addToast,
    onHide,
    resetStates,
    addErrorToast,
  ]);

  const submitButtonContent = useMemo(() => {
    if (isUnwindOrder || modalContent !== ModalContent.Unwind) {
      return undefined;
    }
    return (
      <ButtonContainer>
        <ReverseChevronButton
          role="button"
          size={48}
          onClick={() => {
            setModalContent(ModalContent.Details);
          }}
        >
          <Chevron direction="left" />
        </ReverseChevronButton>
        {!isApproved || !unwindPermitDetails ? (
          <PermitButton
            disabled={
              !contracts
              || permitting
              || !amount
              || !bidValue
              || !unwindNonce
              || Boolean(errors?.amount?.type)
            }
            type="button"
            onClick={() => handleApprovals()}
          >
            <FlashingText flashing={permitting}>
              {permitting
                ? unwindPermitDetails
                  ? "Confirm approval in your wallet"
                  : "Confirm permit in your wallet"
                : unwindPermitDetails
                  ? "Approve oTokens"
                  : "Permit USDC for Unwind"}
            </FlashingText>
          </PermitButton>
        ) : (
          <PlaceOrderButton
            disabled={!placeOrderBody || confirming || confirmed}
            style={{ flex: 1 }}
            type="button"
            onClick={() => handlePlaceOrder()}
          >
            <FlashingText flashing={confirming || confirmed}>
              {placeOrderText}
            </FlashingText>
          </PlaceOrderButton>
        )}
      </ButtonContainer>
    );
  }, [
    isUnwindOrder,
    modalContent,
    isApproved,
    unwindPermitDetails,
    contracts,
    permitting,
    amount,
    bidValue,
    unwindNonce,
    errors?.amount?.type,
    placeOrderBody,
    confirming,
    confirmed,
    placeOrderText,
    handleApprovals,
    handlePlaceOrder,
  ]);

  const displayedRows = useMemo(() => {
    switch (modalContent) {
      case ModalContent.Details:
        return infoRows;
      case ModalContent.Greeks:
        return greeksRows;
      case ModalContent.Unwind:
        return unwindRows;
      default:
        return [];
    }
  }, [greeksRows, infoRows, modalContent, unwindRows]);

  return (
    <div>
      <SelectCounterpartyModal
        show={showSelectCounterpartyModal}
        modalRef={modalRef}
        setSelectedCounterparty={setSelectedCounterparty}
        onHide={() => setShowSelectCounterpartyModal(false)}
      />
      <Form>
        <HeaderContainer>{headerContent}</HeaderContainer>
        {!isUnwindOrder && modalContent === ModalContent.Unwind && (
          <InputContainer>
            <InputHeaderWrapper>
              <InputLabel>Minimum Unwind Price</InputLabel>
            </InputHeaderWrapper>
            <Input
              placeholder="0.00"
              type="number"
              disabled={false}
              {...register("amount", {
                required: true,
                validate: {
                  moreThanZero: (v) => parseFloat(v) > 0,
                },
              })}
              error={Boolean(Object.keys(errors).length)}
            />
            {indicativePrice && Number(amount) > Number(indicativePrice) && (
              <InputError>
                Warning: unwind price provided is above the suggested unwind
                price. The order will likely not be filled.
              </InputError>
            )}
            {errors.amount?.type === "required" && (
              <InputError>Amount is required</InputError>
            )}
            {errors.amount?.type === "moreThanZero" && (
              <InputError>Amount must be more than zero</InputError>
            )}
          </InputContainer>
        )}
        {/* ensures height stays constant when switching modal content */}
        <RowsWrapper height={displayedRows.length * COMPONENTS.infoRowHeight}>
          {displayedRows.map((infoRow) => (
            <InfoRow key={infoRow.title}>
              <span>{infoRow.title}</span>
              <InfoDetail color={infoRow.color}>{infoRow.detail}</InfoDetail>
            </InfoRow>
          ))}
        </RowsWrapper>
        {modalContent === ModalContent.Unwind && whitelistedUsers.includes(account || "") && (
          <CounterpartyButton
            onClick={() => setShowSelectCounterpartyModal(true)}
          >
            {selectedCounterparty
              ? `${strToTitle(selectedCounterparty)} selected`
              : "Select counterparty"}
          </CounterpartyButton>
        )}
        <div style={{ marginTop: `${SPACING.two}px` }}>
          {submitButtonContent}
        </div>
        {modalContent !== ModalContent.Unwind && (
          <ModalButtonV2
            type={"button"}
            style={{ width: "100%", marginTop: SPACING.two }}
            onClick={() => setModalContent(ModalContent.Unwind)}
          >
            Early Unwind
          </ModalButtonV2>
        )}
        {modalContent !== ModalContent.Unwind && (
          <SegmentedControlWrapper>
            <SegmentedControl
              segments={[
                {
                  value: ModalContent.Details,
                  display: ModalContent.Details,
                  textColor:
                    modalContent === ModalContent.Details
                      ? TEXT_COLORS.one
                      : TEXT_COLORS.three,
                },
                {
                  value: ModalContent.Greeks,
                  display: ModalContent.Greeks,
                  textColor:
                    modalContent === ModalContent.Greeks
                      ? TEXT_COLORS.one
                      : TEXT_COLORS.three,
                },
              ]}
              value={modalContent}
              onSelect={(value) => {
                setModalContent(value as ModalContent);
              }}
              config={{
                theme: "outline",
                color:
                  modalContent === ModalContent.Details
                    ? TEXT_COLORS.one
                    : TEXT_COLORS.two,
                widthType: "fullWidth",
                backgroundColor: BACKGROUND_COLORS.eight,
                activeBackgroundColor:
                  modalContent === ModalContent.Details
                    ? BACKGROUND_COLORS.four
                    : BACKGROUND_COLORS.fifteen,
                borderRadius: "10px",
                button: {
                  height: 48,
                  fontSize: FONT_SIZE.two,
                },
              }}
            />
          </SegmentedControlWrapper>
        )}
      </Form>
    </div>
  );
}

export default AccountPositionModal;
