/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { useCallback, useMemo, useState } from "react";
import addresses from "../../../constants/addresses/addresses.json";
import { permitDuration } from "../../../constants/time/getTimestamps";
import { IExtraValues } from "../../../contexts/TradeInstrumentContext";
import { ISignature } from "../../../interfaces/Signing";
import useWallet from "../../wallet/useWallet";
import useUSDC from "./useUSDC";

export interface IUseSignatureProps {
  amount: string;
  extraValues?: IExtraValues;
  setConfirmedExtraValues?: (confirmedExtraValues?: IExtraValues) => void;
  setConfirmedMinMargin?: (confirmedMinMargin?: string) => void;
}
function useSignature({
  amount,
  extraValues,
  setConfirmedExtraValues,
  setConfirmedMinMargin,
}: IUseSignatureProps) {
  // states
  const [permitting, setPermitting] = useState(false);
  const [approvedAmountAndSignature, setApprovedAmountAndSignature] = useState<{
    approvedAmount: string | undefined;
    signature: ISignature | undefined;
    deadline: number;
  }>({
    approvedAmount: undefined,
    signature: undefined,
    deadline: 0,
  });

  const { provider, account } = useWallet();

  const usdc = useUSDC();

  const resetSignature = useCallback(() => {
    setApprovedAmountAndSignature({
      approvedAmount: undefined,
      signature: undefined,
      deadline: 0,
    });
    setPermitting(false);
  }, []);

  // permit handling
  const hasValidSignature = useMemo(() => {
    if (
      amount === approvedAmountAndSignature.approvedAmount
      && approvedAmountAndSignature.signature
    ) {
      return true;
    }
    return false;
  }, [
    amount,
    approvedAmountAndSignature.approvedAmount,
    approvedAmountAndSignature.signature,
  ]);

  const handlePermit = useCallback(async () => {
    try {
      if (usdc.getAssetApprovalSignature && provider && account && amount) {
        if (setConfirmedExtraValues && extraValues) {
          setConfirmedExtraValues(extraValues); // saves the current extra values
        }
        if (setConfirmedMinMargin && amount) {
          setConfirmedMinMargin(amount); // saves the current min margin
        }
        setPermitting(true);
        const deadline = Math.round(Date.now() / 1000 + permitDuration);
        const signature = await usdc.getAssetApprovalSignature(
          addresses.mainnet.contracts.otcWrapper,
          String(amount),
          deadline
        );

        setApprovedAmountAndSignature({
          signature,
          deadline,
          approvedAmount: String(amount),
        });
      } else {
        throw Error;
      }
    } catch (error: any) {
      if (setConfirmedExtraValues) {
        setConfirmedExtraValues(undefined); // saves the current extra values
      }
      if (setConfirmedMinMargin) {
        setConfirmedMinMargin(undefined); // saves the current extra values
      }
    } finally {
      setPermitting(false);
    }
  }, [
    account,
    amount,
    extraValues,
    provider,
    setConfirmedExtraValues,
    setConfirmedMinMargin,
    usdc,
  ]);

  return {
    permitting,
    setPermitting,
    approvedAmountAndSignature,
    setApprovedAmountAndSignature,
    resetSignature,
    handlePermit,
    hasValidSignature,
  };
}

export default useSignature;
