import { motion } from "framer-motion";
import styled from "styled-components";
import { BORDER_COLORS, COLORS, MODAL_COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_SIZE } from "../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { frostedGlassBg } from "../shared/style";

export const ModalWrapper = styled.div<{ show?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalBackground = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  ${frostedGlassBg(COLORS.black.two, COLORS.black.two)}
`;

export const ModalBody = styled(motion.div)`
  position: relative;
`;

export const ModalFrame = styled(motion.div)`
  position: absolute;
  top: -${SPACING.four}px;
  left: -${SPACING.four}px;
  right: -${SPACING.four}px;
  bottom: -${SPACING.four}px;
  z-index: -1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const PageIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled(motion.div)`
  display: flex;
  height: 100%;
  flex-direction: column;
  color: ${TEXT_COLORS.one};
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 8px;
  ${frostedGlassBg(MODAL_COLORS.one, MODAL_COLORS.one)}
  overflow: hidden;
  transition: all 0.2s ease-in-out;
`;

export const Content = styled.div<{noPadding?: boolean}>`
  display: flex;
  flex-direction: column;
  padding: ${({ noPadding }) => (noPadding ? 0 : `${SPACING.three}px`)};
`;

export const ModalHeader = styled.div`
  height: ${COMPONENTS.modalHeaderHeight}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${BORDER_COLORS.one};
`;

export const CloseButton = styled.button`
  height: ${COMPONENTS.modalHeaderHeight}px;
  width: ${COMPONENTS.modalHeaderHeight}px;
  border-left: 1px solid ${BORDER_COLORS.one};
`;

export const Title = styled(motion.div)`
  font-size: ${FONT_SIZE.two};
  padding-left: ${SPACING.three}px;
`;
