/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */

import { BigNumber, ethers } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import { useCallback } from "react";
import addresses from "../../../constants/addresses/addresses.json";
import { oracleDecimals } from "../../../constants/decimals/decimals";
import { placeOrderGasPrice } from "../../../constants/placeholders/placeholders";
import {
  OptionResponse,
  OptionTypeResponse,
} from "../../../interfaces/PreloadedStrikes";
import { Assets, UNDERLYER_ADDRESSES } from "../../../utils/assets";
import useMinimalForwarder from "../minimalForwarder/useMinimalForwarder";
import useOtcWrapper from "../otcWrapper/useOtcWrapper";
import useWallet from "../../wallet/useWallet";
import useUSDC from "./useUSDC";

export const types = {
  ForwardRequest: [
    { name: "from", type: "address" },
    { name: "to", type: "address" },
    { name: "value", type: "uint256" },
    { name: "gas", type: "uint256" },
    { name: "nonce", type: "uint256" },
    { name: "data", type: "bytes" },
  ],
};

export const unwindTypes = {
  UnwindPermit: [
    { name: "owner", type: "address" },
    { name: "orderID", type: "uint256" },
    { name: "value", type: "uint256" },
    { name: "nonce", type: "uint256" },
    { name: "deadline", type: "uint256" },
  ],
};

type UnwindPermitStruct = {
  owner: string;
  orderID: number;
  value: number;
  nonce: number;
  deadline: number;
};

export interface IRequest {
  from: string;
  to: string;
  value: BigNumber | number | string;
  gas: BigNumber | number | string;
  nonce: BigNumber | number | string;
  data: any;
}

export interface IUnwindSignature {
  v: number;
  r: string;
  s: string;
}
export interface ISignSignature {
  isVerified: boolean;
  forwardRequest: IRequest;
  signature: string;
}

function useSignSignature() {
  const { account, provider } = useWallet();
  const minimalForwarderAddress = addresses.mainnet.contracts.minimalForwarder;
  const unwindPermitAddress = addresses.mainnet.contracts.unwindPermit;
  const otcWrapperAddress = addresses.mainnet.contracts.otcWrapper;
  const { contract: minimalForwarderContract, nonce } = useMinimalForwarder();
  const { contract: otcWrapperContract } = useOtcWrapper();
  const usdc = useUSDC();

  const handleSignSignature = useCallback(
    async (
      approvedAmount: string,
      underlyerAsset: Assets,
      optionType: OptionTypeResponse,
      strike: string,
      expiry: string,
      contracts: string,
      collateralDecimals: number
    ) => {
      if (!otcWrapperContract || !minimalForwarderContract || !provider) {
        throw Error;
      }
      const domain = {
        name: "MinimalForwarder",
        version: "0.0.1",
        chainId: 1,
        verifyingContract: minimalForwarderAddress,
      };

      const transaction = await otcWrapperContract.populateTransaction.placeOrder(
        UNDERLYER_ADDRESSES[underlyerAsset]!,
        optionType === OptionResponse.Put,
        parseUnits(strike, oracleDecimals),
        String(expiry),
        parseUnits(approvedAmount, collateralDecimals),
        parseUnits(contracts, oracleDecimals)
      );

      const transactionData = transaction.data;

      const gasPrice = placeOrderGasPrice;
      const request = {
        from: account,
        to: otcWrapperAddress,
        value: ethers.constants.Zero,
        gas: gasPrice,
        nonce,
        data: transactionData!,
      } as IRequest;

      const signer = provider.getSigner();
      // eslint-disable-next-line no-underscore-dangle
      const orderSignature = await signer._signTypedData(
        domain,
        types,
        request
      );

      const isVerified = await minimalForwarderContract.verify(
        request,
        orderSignature
      );

      const forwardRequest = {
        from: account,
        to: otcWrapperAddress,
        value: Number(ethers.constants.Zero),
        gas: Number(gasPrice),
        nonce: Number(nonce),
        data: transactionData!,
      } as IRequest;

      if (!isVerified) {
        throw Error;
      }

      return {
        isVerified,
        forwardRequest,
        signature: orderSignature,
      } as ISignSignature;
    },
    [
      account,
      minimalForwarderAddress,
      minimalForwarderContract,
      nonce,
      otcWrapperAddress,
      otcWrapperContract,
      provider,
    ]
  );

  const handleSignUnwindSignature = useCallback(
    async (approvedAmount: string, orderID: number, deadline: number, unwindNonce: number) => {
      if (!provider) {
        throw Error;
      }
      const approvedAmountInUSDC = Number(parseUnits(approvedAmount, usdc.decimals));
      const domain = {
        name: "OTCWrapperPermit",
        version: "1",
        chainId: 1,
        verifyingContract: unwindPermitAddress,
      };

      const request = {
        owner: String(account),
        orderID,
        value: approvedAmountInUSDC,
        nonce: Number(unwindNonce),
        deadline,
      } as UnwindPermitStruct;

      const signer = provider.getSigner();
      // eslint-disable-next-line no-underscore-dangle
      const orderSignature = await signer._signTypedData(
        domain,
        unwindTypes,
        request
      );

      // Parse the signature
      const sig = ethers.utils.splitSignature(orderSignature);

      // Extract v, r, and s
      const { v, r, s } = sig;

      return {
        v,
        r,
        s
      } as IUnwindSignature;
    },
    [account, provider, unwindPermitAddress, usdc.decimals]
  );

  return {
    handleSignSignature,
    handleSignUnwindSignature,
  };
}

export default useSignSignature;
