import { useCallback, useContext } from "react";
import useSound from "use-sound";
import { SettingsContext, SFXSettings } from "../../contexts/SettingsContext";
import orderFilledSFX from "../../assets/audio/order_filled.wav";
import orderPlacedSFX from "../../assets/audio/order_placed.wav";
import orderCancelledSFX from "../../assets/audio/order_cancelled.wav";
import errorSFX from "../../assets/audio/error.wav";

export interface IPlayOptions {
  id?: string;
  forceSoundEnabled?: boolean;
  playbackRate?: number;
}

export declare type PlayFunction = (options?: IPlayOptions) => void;

export const useSFX = () => {
  const [orderFilled] = useSound(orderFilledSFX);
  const [orderPlaced] = useSound(orderPlacedSFX);
  const [orderCancelled] = useSound(orderCancelledSFX);
  const [error] = useSound(errorSFX);

  const getSFX = useCallback(
    (sfx: SFXSettings) => {
      switch (sfx) {
        case "order_filled":
          return orderFilled;
        case "order_placed":
          return orderPlaced;
        case "order_cancelled":
          return orderCancelled;
        case "error":
          return error;
        default:
          return undefined;
      }
    },
    [error, orderCancelled, orderFilled, orderPlaced]
  );

  const { sfx: userSettings } = useContext(SettingsContext);

  const playSound = useCallback(
    (sound: SFXSettings, force?: boolean) => {
      if (force || userSettings.has(sound)) {
        getSFX(sound)?.();
      }
    },
    [getSFX, userSettings]
  );

  return {
    playSound,
  };
};
