export const BACKGROUND_COLORS = {
  one: "#ffffff",
  two: "rgba(255, 255, 255, 0.08)",
  three: "#5e5d6a",
  four: "rgba(255,255,255,0.04)",
  five: "rgba(6, 6, 12, 1)",
  six: "rgba(21, 21, 27, 0.72)",
  seven: "#0D0D13",
  eight: "#16161C",
  nine: "rgba(21, 21, 27, 0.4)",
  eleven: "#15151B",
  twelve: "#1B1B20",
  thirteen: "rgba(6, 6, 12, 0.72)",
  fourteen: "rgba(255,255,255,0.03)",
  fifteen: "rgba(255,255,255,0.02)",

  tooltip: "rgba(21, 21, 27, 0.96)",
  black: "#06060C",
  // Components bg color
  component: "#0E0E14",
} as const;

export const TEXT_COLORS = {
  one: "#ffffff",
  two: "#A5A5A8",
  three: "#6A6A6D",

  // This should not be text color
  four: "#424246",
  five: "rgba(255,255,255, 0.18)",
} as const;

export const ICON_COLORS = {
  one: "#ffffff",
  two: "#A5A5A8",
  three: "#6A6A6D"
};

export const LAYER_COLORS = {
  one: "#0E0E14",
  two: "#131319",
  three: "#17171D",
  four: "#212127",
  highlightDark: "#090E15",
  highlightLight: "#0E1F28",
};

export const PRIMARY_COLORS = {
  one: "rgba(70, 0, 255, 1)",
  two: "rgba(70, 0, 255, 0.40)",
  three: "rgba(70, 0, 255, 0.24)",
  four: "rgba(70, 0, 255, 0.12)",
  five: "rgba(70, 0, 255, 0.08)",
  six: "rgba(70, 0, 255, 0.04)",
} as const;

export const NEGATIVE_COLORS = {
  one: "rgba(252, 10, 82, 1)",
  two: "rgba(252, 10, 82, 0.40)",
  three: "rgba(252, 10, 82, 0.24)",
  four: "rgba(252, 10, 82, 0.12)",
  five: "rgba(252, 10, 82, 0.08)",
  six: "rgba(252, 10, 82, 0.04)",
} as const;

export const COLORS = {
  positive: {
    one: "rgba(0, 225, 0, 1)",
    two: "rgba(0, 225, 0, 0.40)",
    three: "rgba(0, 225, 0, 0.24)",
    four: "rgba(0, 225, 0, 0.12)",
    five: "rgba(0, 225, 0, 0.08)",
    six: "rgba(0, 225, 0, 0.04)",
  },
  negative: {
    one: "rgba(252, 10, 82, 1)",
    two: "rgba(252, 10, 84, 0.40)",
    three: "rgba(252, 10, 84, 0.24)",
    four: "rgba(252, 10, 84, 0.12)",
    five: "rgba(252, 10, 84, 0.08)",
    six: "rgba(252, 10, 84, 0.04)",
  },
  orange: {
    one: "rgba(255, 122, 0, 1)",
    two: "rgba(255, 122, 0, 0.40)",
    three: "rgba(255, 122, 0, 0.24)",
    four: "rgba(255, 122, 0, 0.12)",
    five: "rgba(255, 122, 0, 0.08)",
    six: "rgba(255, 122, 0, 0.06)",
  },
  blue: {
    one: "rgba(76, 215, 244, 1)",
    two: "rgba(76, 215, 244, 0.08)",
    // two, but solid. TODO: - Confirm with vp
    twoSolid: "#0D171F",
    three: "rgba(76, 215, 244, 0.12)",
    four: "rgba(76, 215, 244, 0.03)",
    five: "rgba(76, 215, 244, 0.04)",
    six: "rgba(76, 215, 244, 0.04)"
  },
  white: {
    one: "#fff",
    two: "rgba(255, 255, 255, 0.08)",
    three: "rgba(255, 255, 255, 0.16)",
    four: "rgba(255, 255, 255, 0.24)",
    five: "rgba(255, 255, 255, 0.04)",
  },
  black: {
    one: "rgba(0,0,0,1)",
    two: "rgba(0,0,0,0.72)",
    three: "rgba(22, 22, 28, 1)",
    four: "rgba(0,0,0,0.24)",
    five: "rgba(17, 17, 19, 1)",
  },
} as const;

export const BORDER_COLORS = {
  one: "#15151B",
  two: "#1A1A1F",
  three: "#2A2A30",
  four: "#A5A5A8",
  five: "#FFFFFF",
} as const;

export const MODAL_COLORS = {
  one: "rgba(14, 14, 20, 0.92)",
};

export const ASSET_COLORS = {
  usdc: {
    one: "rgba(62, 115, 196, 1)",
    two: "rgba(62, 115, 196, 0.64)",
    three: "rgba(62, 115, 196, 0.24)",
    four: "rgba(62, 115, 196, 0.12)",
  },
};
