/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { BigNumber } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { Oracle, Oracle__factory } from "../../../codegen";
import addresses from "../../../constants/addresses/addresses.json";
import { Assets, AssetsList, UNDERLYER_ADDRESSES } from "../../../utils/assets";
import useWallet from "../../wallet/useWallet";
import { useWeb3Context } from "../../../contexts/Web3Context";

const contractAddress = addresses.mainnet.contracts.oracle;

export const getOracle = (provider: any, useSigner = true): Oracle | undefined => {
  const signerOrProvider = useSigner ? provider?.getSigner() : provider;
  if (signerOrProvider && contractAddress) {
    return Oracle__factory.connect(contractAddress, signerOrProvider);
  }

  return undefined;
};

const useOracle = () => {
  const { active, provider, chainId } = useWallet();
  const { baseProvider } = useWeb3Context();
  const [contract, setContract] = useState<Oracle>();
  const [oraclePrices, setOraclePrices] = useState<Record<Assets, BigNumber>>();

  useEffect(() => {
    setContract(getOracle(provider || baseProvider, active));
  }, [chainId, provider, baseProvider, active]);

  const getPriceRecord = useCallback(async () => {
    const promises = AssetsList.map(async (asset) => {
      const assetAddress = UNDERLYER_ADDRESSES[asset];
      if (assetAddress && contract) {
        try {
          const price = await contract.getPrice(assetAddress);
          return { [asset]: price };
        } catch (err) {
          return { [asset]: undefined };
        }
      } else {
        return { [asset]: undefined };
      }
    });
    const results = await Promise.all(promises);
    const priceRecord = Object.assign({}, ...results) as Record<
      Assets,
      BigNumber
    >;
    setOraclePrices(priceRecord);
  }, [contract]);

  useEffect(() => {
    getPriceRecord();
  }, [getPriceRecord]);

  return {
    contract,
    oraclePrices,
  };
};

export default useOracle;
