import useSWR from "swr";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Assets } from "../../../../utils/assets";
import { IOrder, OrdersResponse, Reason } from "../../../../interfaces/Orders";
import { OptionTypeResponse } from "../../../../interfaces/PreloadedStrikes";
import { getInstrumentName } from "../../../../utils/instruments";
import { defaultOrdersResponse } from "../../../../contexts/OptionsDataContext";
import useWallet from "../../../wallet/useWallet";
import { GET_MM_TRADE_HISTORY_URL } from "../../../../constants/endpoints/endpoints";

export interface IMMTradeHistory {
  order_id: number;
  underlyer: Assets;
  type: OptionTypeResponse;
  expiry: string;
  strike: string;
  size: string;
  offer: string;
  underlyer_price_at_expiry?: string;
  total_margin?: string;
  performance?: string;
  roi?: string;
  reason?: Reason;
  unwind_price?: string;
}

// Get the current account
const useFetchMMTradeHistory = () => {
  const { account } = useWallet();
  const [mmTradeHistoryData, setMmTradeHistoryData] = useState<OrdersResponse>(
    defaultOrdersResponse
  );

  const mmTradeHistoryApiURL = GET_MM_TRADE_HISTORY_URL;

  const fetcher = (params: any) => (url: string) => {
    if (params.address !== undefined) {
      return axios.get(url, { params }).then((res) => res.data);
    }
    return Promise.resolve(null); // Return a resolved Promise with a null value if address is undefined
  };

  const mmTradeHistorySwr = useSWR<IMMTradeHistory[]>(
    mmTradeHistoryApiURL,
    fetcher({
      address: account,
    }),
    {
      refreshInterval: 5000,
    }
  );

  const { data: mmTradeHistoryDataSwr, error: mmTradeHistorySwrError }
    = mmTradeHistorySwr;

  const fetchMmTradeHistory = useCallback(async () => {
    if (!mmTradeHistoryDataSwr || mmTradeHistorySwrError || !account) {
      // API call hasn't completed yet or error, return early
      return;
    }

    const mmTradeHistory = mmTradeHistoryDataSwr.map((tradeHistoryData) => ({
      instrumentName: getInstrumentName(
        tradeHistoryData.underlyer,
        tradeHistoryData.expiry,
        tradeHistoryData.strike,
        tradeHistoryData.type
      ),
      orderId: tradeHistoryData.order_id,
      optionType: tradeHistoryData.type,
      asset: tradeHistoryData.underlyer,
      expiry: tradeHistoryData.expiry,
      strike: tradeHistoryData.strike,
      contracts: tradeHistoryData.size,
      totalPremiumPaid: tradeHistoryData.offer
        ? String(parseFloat(tradeHistoryData.size) * parseFloat(tradeHistoryData.offer))
        : "0",
      offerPrice: tradeHistoryData.offer,
      performance: tradeHistoryData.performance,
      priceAtExpiry: tradeHistoryData.underlyer_price_at_expiry,
      totalMargin: tradeHistoryData.total_margin,
      reason: tradeHistoryData.reason,
      unwindPrice: tradeHistoryData.unwind_price,
    })) as IOrder[];
    setMmTradeHistoryData({
      loading: false,
      orders: mmTradeHistory,
    } as OrdersResponse);
  }, [mmTradeHistoryDataSwr, mmTradeHistorySwrError, account]);

  useEffect(() => {
    fetchMmTradeHistory();
  }, [fetchMmTradeHistory]);

  return {
    mmTradeHistoryData,
  };
};

export default useFetchMMTradeHistory;
