import React, { ReactElement, useState } from "react";

interface IImpersonateContextType {
  impersonateAddress: string;
  setImpersonateAddress: (impersonateAddress: string) => void;
}

interface IImpersonateContextProviderProps {
  children: ReactElement;
}

export const ImpersonateContext = React.createContext<IImpersonateContextType>({
  impersonateAddress: "",
  setImpersonateAddress: () => {},
});

export function ImpersonateContextProvider({
  children,
}: IImpersonateContextProviderProps) {
  const [impersonateAddress, setImpersonateAddress] = useState<string>("");

  return (
    <ImpersonateContext.Provider
      value={{
        impersonateAddress,
        setImpersonateAddress,
      }}
    >
      {children}
    </ImpersonateContext.Provider>
  );
}
