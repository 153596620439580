/* eslint-disable no-console */
import { useCallback, useEffect, useState } from "react";
import { BigNumber } from "ethers";
import {
  Controller__factory,
  Controller,
} from "../../../codegen";
import useWallet from "../../wallet/useWallet";
import addresses from "../../../constants/addresses/addresses.json";
import { MarginVault } from "../../../codegen/Controller";
import { useWeb3Context } from "../../../contexts/Web3Context";

export interface IControllerResponse {
  loading: boolean;
  vault?: MarginVault.VaultStructOutput;
}

export interface IControllerData {
  contract?: Controller;
  data: IControllerResponse;
}

const contractAddress = addresses.mainnet.contracts.controller;
const otcWrapperAddress = addresses.mainnet.contracts.otcWrapper;

export const getController = (
  provider: any,
  useSigner = true
): Controller | undefined => {
  const signerOrProvider = useSigner ? provider?.getSigner() : provider;
  if (signerOrProvider && contractAddress) {
    return Controller__factory.connect(
      contractAddress,
      signerOrProvider
    );
  }
  return undefined;
};

const defaultControllerResponse = {
  loading: true,
  vault: undefined
} as IControllerResponse;

const useController = (vaultId?: BigNumber) => {
  const { provider, active, chainId } = useWallet();
  const { baseProvider } = useWeb3Context();
  const [data, setData] = useState<IControllerResponse>(
    defaultControllerResponse
  );
  const [contract, setContract] = useState<Controller>();

  useEffect(() => {
    const getContract = async () => {
      setContract(getController(provider || baseProvider, active));
    };
    getContract();
  }, [chainId, provider, baseProvider, active]);

  const fetchControllerData = useCallback(async () => {
    try {
      if (contract) {
        const vault = vaultId ? await contract.getVault(otcWrapperAddress, vaultId) : undefined;
        setData({
          loading: false,
          vault
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [contract, vaultId]);

  useEffect(() => {
    fetchControllerData();
  }, [fetchControllerData]);

  return {
    contract,
    data,
  };
};

export default useController;
