import useSWR from "swr";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Assets } from "../../../../utils/assets";
import {
  IOrder,
  IPermitDetails,
  Reason,
  OrdersResponse,
  OptionSideResponse,
} from "../../../../interfaces/Orders";
import { OptionTypeResponse } from "../../../../interfaces/PreloadedStrikes";
import { getInstrumentName } from "../../../../utils/instruments";
import { defaultOrdersResponse } from "../../../../contexts/OptionsDataContext";
import useWallet from "../../../wallet/useWallet";
import { permitDuration } from "../../../../constants/time/getTimestamps";
import {
  GET_LIVE_REQUESTS_URL,
  GET_UNMATCHED_REQUESTS_URL,
} from "../../../../constants/endpoints/endpoints";

export interface ILiveRequestData {
  order_id: number;
  underlyer: Assets;
  type: OptionTypeResponse;
  side: OptionSideResponse;
  expiry: string;
  strike: string;
  offer: string;
  permit_address?: string;
  permit_v?: number;
  permit_r?: string;
  permit_s?: string;
  permit_amount?: string;
  order_start_time: string;
  order_offer_period_end: string;
  order_end_time: string;
  size: string;
  pending_filled: string;
  indic?: string;
  is_unwind_order?: "true" | "false";
  order_id_to_unwind?: number;
  is_original_mm?: "true" | "false";
}

export interface IUnmatchedRequestData {
  order_id: number;
  underlyer: Assets;
  type: OptionTypeResponse;
  expiry: string;
  strike: string;
  offer: string;
  winning_offer: string;
  permit_expiry: string;
  size: string;
  reason: Reason;
  is_unwind_order?: "true" | "false";
}

// Get the current account
const useFetchRequests = () => {
  const { account } = useWallet();
  const [error, setError] = useState<boolean>(false);
  const [liveRequestsData, setLiveRequestsData] = useState<OrdersResponse>(
    defaultOrdersResponse
  );
  const [unmatchedRequestsData, setUnmatchedRequestsData]
    = useState<OrdersResponse>(defaultOrdersResponse);
  const liveRequestsApiURL = GET_LIVE_REQUESTS_URL;
  const unmatchedRequestsApiURL = GET_UNMATCHED_REQUESTS_URL;

  const fetcher = (params: any) => (url: string) => {
    if (params.address !== undefined) {
      return axios.get(url, { params }).then((res) => res.data);
    }
    return Promise.resolve(null); // Return a resolved Promise with a null value if address is undefined
  };

  const liveRequestsSwr = useSWR<ILiveRequestData[]>(
    liveRequestsApiURL,
    fetcher({
      address: account,
    }),
    {
      refreshInterval: 5000,
    }
  );

  const unmatchedRequestsSwr = useSWR<IUnmatchedRequestData[]>(
    unmatchedRequestsApiURL,
    fetcher({
      address: account,
    }),
    {
      refreshInterval: 5000,
    }
  );
  const { data: liveRequestsDataSwr, error: liveRequestsSwrError }
    = liveRequestsSwr;
  const { data: unmatchedRequestsDataSwr, error: unmatchedRequestsSwrError }
    = unmatchedRequestsSwr;

  const makeIOrder = (liveRequestDatas: ILiveRequestData[]) => {
    if (liveRequestDatas.length > 0) {
      return liveRequestDatas.map((requestData: ILiveRequestData) => ({
        instrumentName: getInstrumentName(
          requestData.underlyer,
          requestData.expiry,
          requestData.strike,
          requestData.type
        ),
        orderId: requestData.order_id,
        optionType: requestData.type,
        asset: requestData.underlyer,
        optionSide: requestData.side,
        expiry: requestData.expiry,
        strike: requestData.strike,
        contracts: requestData.size,
        pendingFilled: requestData.pending_filled === "true",
        offerPrice: requestData.offer,
        orderStartTime: requestData.order_start_time,
        orderOfferPeriodEnd: requestData.order_offer_period_end,
        orderEndTime: requestData.order_end_time,
        indicativePrice: requestData.indic,
        isUnwindOrder: requestData.is_unwind_order === "true",
        orderIdToUnwind: requestData.order_id_to_unwind,
        isOriginalMm: requestData.is_original_mm === "true",
        userPermit: requestData.permit_address
          ? ({
            permitAddress: requestData.permit_address,
            permitV: String(requestData.permit_v),
            permitR: requestData.permit_r,
            permitS: requestData.permit_s,
            permitAmount: requestData.permit_amount,
            permitDeadline: String(
              Number(requestData.order_start_time) + permitDuration
            ),
          } as IPermitDetails)
          : undefined,
      })) as IOrder[];
    }
    return [];
  };

  const fetchLiveRequests = useCallback(async () => {
    if (!liveRequestsDataSwr || liveRequestsSwrError || !account) {
      // API call hasn't completed yet or error, return early
      return;
    }
    const requests = makeIOrder(liveRequestsDataSwr);
    setLiveRequestsData({
      loading: false,
      orders: requests,
    } as OrdersResponse);
  }, [liveRequestsDataSwr, liveRequestsSwrError, account]);

  // same api call but can only be called when user presses refresh button
  const getLiveRequests = async (accountAddress: string) => {
    try {
      setError(false);
      setLiveRequestsData(defaultOrdersResponse);
      const response = await axios.get(liveRequestsApiURL, {
        params: {
          address: accountAddress,
        },
      });
      const requests = makeIOrder(response.data);
      setLiveRequestsData({
        loading: false,
        orders: requests,
      } as OrdersResponse);
    } catch (e) {
      setError(true);
    }
  };

  const fetchUnmatchedRequests = useCallback(async () => {
    if (!unmatchedRequestsDataSwr || unmatchedRequestsSwrError || !account) {
      // API call hasn't completed yet or error, return early
      return;
    }
    const requests = unmatchedRequestsDataSwr.map((requestData) => ({
      instrumentName: getInstrumentName(
        requestData.underlyer,
        requestData.expiry,
        requestData.strike,
        requestData.type
      ),
      orderId: requestData.order_id,
      optionType: requestData.type,
      expiredAt: requestData.permit_expiry,
      asset: requestData.underlyer,
      expiry: requestData.expiry,
      strike: requestData.strike,
      contracts: requestData.size,
      offerPrice: requestData.offer,
      winningOffer: requestData.winning_offer,
      reason: requestData.reason,
      isUnwindOrder: requestData.is_unwind_order === "true",
    })) as IOrder[];
    setUnmatchedRequestsData({
      loading: false,
      orders: requests,
    } as OrdersResponse);
  }, [unmatchedRequestsDataSwr, unmatchedRequestsSwrError, account]);

  useEffect(() => {
    fetchLiveRequests();
  }, [fetchLiveRequests]);

  useEffect(() => {
    fetchUnmatchedRequests();
  }, [fetchUnmatchedRequests]);

  return {
    error,
    liveRequestsData,
    getLiveRequests,
    unmatchedRequestsData,
  };
};

export default useFetchRequests;
