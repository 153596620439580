import React, { ReactElement, useEffect, useState } from "react";
import {
  ExpiryTexts,
  PreloadedStrikes,
  PreloadedStrikesResponse,
} from "../../interfaces/PreloadedStrikes";
import useFetchPreloadedStrikes from "../../hooks/api/strikes/useFetchPreloadedStrikes";
import { OrdersResponse } from "../../interfaces/Orders";
import useFetchLiveRequests from "../../hooks/api/order/mm/useFetchRequests";
import useFetchOrders from "../../hooks/api/order/user/useFetchOrders";
import useFetchUserPositions from "../../hooks/api/order/user/useFetchUserPositions";
import useFetchUserTradeHistory from "../../hooks/api/order/user/useFetchUserTradeHistory";
import useFetchUserUnfilledOrders from "../../hooks/api/order/user/useFetchUserUnfilledOrders";
import useFetchMMPositions from "../../hooks/api/order/mm/useFetchMMPositions";
import useFetchMMTradeHistory from "../../hooks/api/order/mm/useFetchMMTradeHistory";
import useFetchWhitelistedUsers from "../../hooks/api/order/user/useWhitelistedUsers";
import useFetchMMNames from "../../hooks/api/order/mm/useFetchMMNames";

export type OptionsDataContextType = {
  preloadedStrikes: PreloadedStrikesResponse;
  updatePreloadedStrikes: () => void;
  liveRequests: OrdersResponse;
  unmatchedRequests: OrdersResponse;
  pendingOrders: OrdersResponse;
  userPositions: OrdersResponse;
  userTradeHistory: OrdersResponse;
  userUnfilledOrders: OrdersResponse;
  mmPositions: OrdersResponse;
  mmTradeHistory: OrdersResponse;
  whitelistedUsers: string[];
  mmNames: string[];
};

interface IOptionsDataContextProviderProps {
  children: ReactElement;
}

export const defaultPreloadedStrikes: PreloadedStrikesResponse = {
  loading: true,
  preloadedStrikes: {
    expiries: {
      [ExpiryTexts.Weekly]: "",
      [ExpiryTexts.Biweekly]: "",
      [ExpiryTexts.Triweekly]: "",
      [ExpiryTexts.Monthly]: "",
      [ExpiryTexts.NextMonth]: "",
    },
    data: [],
  } as PreloadedStrikes,
};

export const defaultOrdersResponse: OrdersResponse = {
  loading: true,
  orders: [],
};

export const defaultOptionsData: OptionsDataContextType = {
  preloadedStrikes: defaultPreloadedStrikes,
  updatePreloadedStrikes: () => {},
  liveRequests: defaultOrdersResponse,
  unmatchedRequests: defaultOrdersResponse,
  pendingOrders: defaultOrdersResponse,
  userPositions: defaultOrdersResponse,
  userTradeHistory: defaultOrdersResponse,
  userUnfilledOrders: defaultOrdersResponse,
  mmPositions: defaultOrdersResponse,
  mmTradeHistory: defaultOrdersResponse,
  whitelistedUsers: [],
  mmNames: []
};

export const OptionsDataContext
  = React.createContext<OptionsDataContextType>(defaultOptionsData);

export function OptionsDataContextProvider({
  children,
}: IOptionsDataContextProviderProps) {
  const { data, getPreloadedStrikes } = useFetchPreloadedStrikes();
  const { liveRequestsData, unmatchedRequestsData }
    = useFetchLiveRequests();
  const { pendingOrdersData } = useFetchOrders();
  const { userPositionsData } = useFetchUserPositions();
  const { userTradeHistoryData } = useFetchUserTradeHistory();
  const { userUnfilledOrdersData } = useFetchUserUnfilledOrders();
  const { mmPositionsData } = useFetchMMPositions();
  const { mmTradeHistoryData } = useFetchMMTradeHistory();
  const { data: whitelistedUsersData } = useFetchWhitelistedUsers();
  const { data: mmNamesData } = useFetchMMNames();
  const [preloadedStrikes, setPreloadedStrikes]
    = useState<PreloadedStrikesResponse>(data);

  // whenever data gets changed set the preloaded strikes
  useEffect(() => {
    setPreloadedStrikes(data);
  }, [data]);
  // whenever data gets changed set the preloaded strikes

  const updatePreloadedStrikes = async () => {
    await getPreloadedStrikes();
    setPreloadedStrikes(data);
  };

  return (
    <OptionsDataContext.Provider
      value={{
        preloadedStrikes,
        updatePreloadedStrikes,
        liveRequests: liveRequestsData,
        unmatchedRequests: unmatchedRequestsData,
        pendingOrders: pendingOrdersData,
        userPositions: userPositionsData,
        userTradeHistory: userTradeHistoryData,
        userUnfilledOrders: userUnfilledOrdersData,
        mmPositions: mmPositionsData,
        mmTradeHistory: mmTradeHistoryData,
        whitelistedUsers: whitelistedUsersData,
        mmNames: mmNamesData,
      }}
    >
      {children}
    </OptionsDataContext.Provider>
  );
}
