/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import { useCallback, useContext } from "react";
import { useSortBy, useTable } from "react-table";
import { useOrderTimes } from "../../../hooks/time/useOrderTimes";
import { IOrder, IOrderType } from "../../../interfaces/Orders";
import { Spinner } from "../../shared/Spinner";
import { DefaultCellForColumn } from "../../shared/Table/DefaultCellForColumn";
import { DefaultHeaderForColumn } from "../../shared/Table/DefaultHeaderForColumn";
import { StyledTableRow, TableHeaderCell } from "../../shared/Table/style";
import { EmptyContent, PortfolioTableWrapper } from "../Table/style";
import { ProgressBar } from "../../shared/ProgressBar";
import { RequestPositionContext } from "../../../contexts/RequestPositionContext";
import useOrderColumns from "../../../hooks/table/useOrderColumns";
import useScreenSize from "../../../hooks/screenSize/useScreenSize";
import useWallet from "../../../hooks/wallet/useWallet";

interface IOrdersProps {
  orders: IOrder[];
  orderType: IOrderType;
  loading?: boolean;
}

function OrderRows({ orders, orderType, loading }: IOrdersProps) {
  const {
    setOrder,
    setOrderType,
    showRequestPositionModal,
    setShowRequestPositionModal,
  } = useContext(RequestPositionContext);
  const { timeArgs } = useOrderTimes();
  const { account } = useWallet();
  const { columns, mobileColumns } = useOrderColumns({ orderType });
  const { isMobileScreen } = useScreenSize();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow }
    = useTable(
      {
        columns: isMobileScreen ? mobileColumns : columns,
        data: orders,
        defaultColumn: {
          Header: DefaultHeaderForColumn,
          Cell: DefaultCellForColumn,
        },
        autoResetSortBy: false,
      } as any,
      useSortBy
    );

  const handleRowClick = useCallback(
    (rowData: IOrder) => {
      setOrder(rowData);
      setOrderType(orderType);
      setShowRequestPositionModal(!showRequestPositionModal);
    },
    [
      setOrder,
      orderType,
      showRequestPositionModal,
      setOrderType,
      setShowRequestPositionModal,
    ]
  );

  if (!account) {
    return <EmptyContent>Connect your wallet</EmptyContent>;
  }
  if (loading || !orders.length) {
    return <EmptyContent>{loading ? <Spinner /> : "No orders"}</EmptyContent>;
  }
  return (
    <PortfolioTableWrapper firstColSticky>
      <table {...getTableProps()}>
        {!isMobileScreen && (
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableHeaderCell
                    // We apply the sort properties to each column
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                  </TableHeaderCell>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const rowData = row.original as IOrder;
            const { orderStartTime, orderOfferPeriodEnd, orderEndTime }
              = rowData;
            const { isOrderConfirmationTimeWindow, percentage } = timeArgs(
              Number(orderStartTime),
              Number(orderOfferPeriodEnd),
              Number(orderEndTime)
            );
            return (
              // eslint-disable-next-line react/jsx-key
              <StyledTableRow
                onClick={() => handleRowClick(rowData)}
                isOrderConfirmationTimeWindow={isOrderConfirmationTimeWindow}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  // eslint-disable-next-line react/jsx-key
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
                {/* last table data element is position absolute progressbar */}
                <td>
                  <ProgressBar
                    percentage={orderType !== "LiveRequests" ? 0 : percentage}
                  />
                </td>
              </StyledTableRow>
            );
          })}
        </tbody>
      </table>
    </PortfolioTableWrapper>
  );
}

export default OrderRows;
