export enum ChainEnum {
  NotSelected = "Unknown",
  Ethereum = "Ethereum Mainnet",
}

export enum ChainIdEnum {
  NONE = -1,
  ETH_MAINNET = 1,
}

export const AllChainIdEnum: ChainIdEnum[] = Object.values(ChainIdEnum).filter(
  (v) => !Number.isNaN(Number(v))
) as ChainIdEnum[];
