import { useCallback, useEffect, useMemo, useState } from "react";
import currency from "currency.js";
import { Link } from "react-router-dom";
import { ReactComponent as Close } from "../../../../../assets/svg/close.svg";
import CircularProgressBar from "../../../../shared/CircularProgressBar";
import {
  InfoRow,
  HeaderContainer,
  AssetHeaderWrapper,
  InfoDetail,
  ButtonsContainer,
  SmartContractRowWrapper,
  Form,
  BottomText,
} from "../../style";
import { IOrder } from "../../../../../interfaces/Orders";
import { LargeModalButtonV2, ModalButtonV2 } from "../../../../Buttons/styles";
import AssetWrapper from "../../../../shared/AssetWrapper";
import { fullExpiryTime } from "../../../../../utils/date";
import { COLORS, TEXT_COLORS } from "../../../../../constants/design/colors";
import { shortenAddress } from "../../../../../utils/strings";
import { ChainIdEnum } from "../../../../../enums/chain";
import { CHAIN_EXPLORER_URLS } from "../../../../../utils/chain";
import { ReactComponent as ArrowOut } from "../../../../../assets/svg/arrow-up-right.svg";
import addresses from "../../../../../constants/addresses/addresses.json";
import { ToastEnum, ToastStatusEnum } from "../../../../../utils/toast";
import {
  getAssetLogo,
  getAssetIndicPrecision,
} from "../../../../../utils/assets";
import { useToast } from "../../../../../hooks/toast";
import { useOrderTimes } from "../../../../../hooks/time/useOrderTimes";
import { formatBigNumber } from "../../../../../utils/format";
import useUSDC from "../../../../../hooks/contracts/usdc/useUSDC";
import useOrder, {
  IOrderBody,
} from "../../../../../hooks/api/order/user/useOrder";
import { Spinner } from "../../../../shared/Spinner";
import { IInfoRowCol } from "../../../../../interfaces/InfoRow";
import useOtcWrapper from "../../../../../hooks/contracts/otcWrapper/useOtcWrapper";
import { isProduction } from "../../../../../utils/env";
import { useSFX } from "../../../../../hooks/useSFX";
import { TELEGRAM_SUPPORT_URL } from "../../../../../constants/endpoints/endpoints";
import { SPACING } from "../../../../../constants/design/spacing";

interface IPendingOrderModalProps {
  order: IOrder;
  setShowModal: (show: boolean) => void;
}

function PendingOrderModal({ order, setShowModal }: IPendingOrderModalProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { addToast, addErrorToast } = useToast();
  const {
    orderId,
    instrumentName,
    contracts,
    optionType,
    totalPremiumPaid,
    expiry,
    strike,
    asset,
    orderStartTime,
    orderEndTime,
    iv,
    indicativePrice,
    customPrice,
    orderIdToUnwind,
  } = order;

  const contractAddress = addresses.mainnet.contracts.otcWrapper;
  const { contract } = useOtcWrapper();
  const { cancelOrder } = useOrder();
  const usdc = useUSDC();
  const { timeNow } = useOrderTimes();
  const { playSound } = useSFX();
  const { timeArgs } = useOrderTimes();
  const { orderExpired } = timeArgs(
    Number(orderStartTime),
    Number(orderEndTime),
    Number(orderEndTime)
  );

  const onHide = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  // hide modal if order has expired
  useEffect(() => {
    if (orderExpired) {
      onHide();
    }
  }, [onHide, orderExpired]);

  const openBlockExplorer = useCallback(() => {
    window.open(
      `${
        CHAIN_EXPLORER_URLS[ChainIdEnum.ETH_MAINNET]
      }/address/${contractAddress}`
    );
  }, [contractAddress]);

  const infoRows: IInfoRowCol[] = useMemo(() => {
    const rows = [];
    rows.push({
      title: "Order Status",
      detail: "Sent to market makers",
      color: COLORS.blue.one,
    });
    if (orderIdToUnwind) {
      rows.push({
        title: "Order Type",
        detail: "Unwind Order",
        color: COLORS.blue.one,
      });
    }
    rows.push({
      title: "Option Type",
      detail: `European ${optionType.replace(/^\w/, (c) => c.toUpperCase())}`,
    });
    rows.push({
      title: "Expiry",
      detail: `${fullExpiryTime(expiry)}`,
    });
    rows.push({
      title: "Strike",
      detail: `${strike}`,
    });
    if (indicativePrice) {
      rows.push({
        title: "Indicative Price",
        detail: `${currency(parseFloat(indicativePrice), {
          precision: getAssetIndicPrecision(asset),
        }).format()}`,
        color: COLORS.positive.one,
      });
    }
    if (iv) {
      rows.push({
        title: "Indicative IV",
        detail: `${iv}%`,
        color: COLORS.positive.one,
      });
    }
    if (customPrice) {
      rows.push({
        title: "Custom Price",
        detail: `${currency(parseFloat(customPrice), {
          precision: getAssetIndicPrecision(asset),
        }).format()}`,
        color: COLORS.positive.one,
      });
    }
    rows.push({
      title: "Contracts",
      detail: `${contracts}`,
    });
    rows.push({
      title: "Total Premium Limit",
      detail: `${currency(
        formatBigNumber(totalPremiumPaid, usdc.decimals)
      ).format()}`,
    });
    rows.push({
      title: "Smart Contract",
      detail: (
        <SmartContractRowWrapper onClick={openBlockExplorer}>
          <span>{shortenAddress(contractAddress)}</span>
          <ArrowOut />
        </SmartContractRowWrapper>
      ),
    });
    return rows;
  }, [
    orderIdToUnwind,
    optionType,
    expiry,
    strike,
    indicativePrice,
    iv,
    customPrice,
    contracts,
    totalPremiumPaid,
    usdc.decimals,
    openBlockExplorer,
    contractAddress,
    asset,
  ]);

  const headerContent = useMemo(
    () => (
      <>
        <AssetHeaderWrapper>
          <AssetWrapper asset={asset} />
        </AssetHeaderWrapper>
        <LargeModalButtonV2 type={"button"} onClick={onHide}>
          <Close />
        </LargeModalButtonV2>
      </>
    ),
    [onHide, asset]
  );

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      if (contract) {
        if (isProduction() && !orderIdToUnwind) {
          await contract.undoOrder(String(orderId));
        }
        await cancelOrder({
          order_id: Number(orderId),
          is_unwind_order: String(Boolean(orderIdToUnwind)),
        } as IOrderBody);
        playSound("order_cancelled");
        addToast(
          {
            type: ToastEnum.INFO,
            icon: getAssetLogo(asset) as string,
            header: <p>{instrumentName}</p>,
            subheader: (
              <span style={{ color: COLORS.blue.one }}>Order Cancelled</span>
            ),
            stats: [
              {
                label: "Contracts",
                value: Number(contracts).toFixed(2),
              },
              {
                label: "Indicative Price",
                value: currency(parseFloat(indicativePrice || "0"), {
                  precision: getAssetIndicPrecision(asset),
                }).format(),
              },
            ],
            status: ToastStatusEnum.SUCCESS,
          },
          10000
        );
      } else {
        throw Error;
      }
      setLoading(false);
    } catch (error: any) {
      addErrorToast("Something went wrong", "Please try again");
      onHide();
      setLoading(false);
    }
    onHide();
  }, [
    onHide,
    contract,
    orderIdToUnwind,
    orderId,
    cancelOrder,
    playSound,
    addToast,
    asset,
    instrumentName,
    contracts,
    indicativePrice,
    addErrorToast,
  ]);

  const telegramSupportButton = useMemo(
    () => (
      <BottomText style={{ padding: `0 ${SPACING.two}px` }}>
        <p>
          <Link style={{ textDecoration: "underline" }} target="_blank" rel="noreferrer" to={TELEGRAM_SUPPORT_URL}>
            Get in touch
          </Link>{" "}
          for optimal execution and guaranteed fills
        </p>
      </BottomText>
    ),
    []
  );

  const submitButtonContent = useMemo(
    () => (
      <ButtonsContainer>
        <ModalButtonV2
          type="button"
          disabled={loading}
          style={{ flex: 1 }}
          onClick={() => onSubmit()}
        >
          {loading ? <Spinner color={TEXT_COLORS.two} /> : "Cancel order"}
        </ModalButtonV2>
      </ButtonsContainer>
    ),
    [loading, onSubmit]
  );
  return (
    <Form onSubmit={() => {}}>
      <HeaderContainer>{headerContent}</HeaderContainer>
      <CircularProgressBar
        timeNow={timeNow}
        startTime={Number(orderStartTime)}
        endTime={Number(orderEndTime)}
        isOrderConfirmationTimeWindow={false}
        size={160}
        strokeWidth={4}
        detail={"Time till order confirmation"}
        type={"pending"}
      />
      {infoRows.map((infoRow) => (
        <InfoRow key={infoRow.title}>
          <span>{infoRow.title}</span>
          <InfoDetail color={infoRow.color}>{infoRow.detail}</InfoDetail>
        </InfoRow>
      ))}
      {submitButtonContent}
      {telegramSupportButton}
    </Form>
  );
}

export default PendingOrderModal;
