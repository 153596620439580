import useSWR from "swr";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Assets } from "../../../../utils/assets";
import { IOrder, OrdersResponse } from "../../../../interfaces/Orders";
import { OptionTypeResponse } from "../../../../interfaces/PreloadedStrikes";
import { getInstrumentName } from "../../../../utils/instruments";
import { defaultOrdersResponse } from "../../../../contexts/OptionsDataContext";
import useWallet from "../../../wallet/useWallet";
import { GET_MM_LIVE_POSITIONS_URL } from "../../../../constants/endpoints/endpoints";

export interface IMMPositions {
  order_id: number;
  underlyer: Assets;
  type: OptionTypeResponse;
  expiry: string;
  strike: string;
  size: string;
  offer: string;
  mark_price: string;
  total_premiums: string;
  performance: string;
  im_amount: string;
  margin_requirement: string;
  margin_posted: string;
  withdrawable_collateral: string;
  mm_utilization: string;
  delta: string;
  theta: string;
  vega: string;
  gamma: string;
}

// Get the current account
const useFetchMMPositions = () => {
  const { account } = useWallet();
  const [mmPositionsData, setMMPositionsData] = useState<OrdersResponse>(
    defaultOrdersResponse
  );
  const mmPositionsApiURL = GET_MM_LIVE_POSITIONS_URL;

  const fetcher = (params: any) => (url: string) => {
    if (params.address !== undefined) {
      return axios.get(url, { params }).then((res) => res.data);
    }
    return Promise.resolve(null); // Return a resolved Promise with a null value if address is undefined
  };

  const mmPositionsSwr = useSWR<IMMPositions[]>(
    mmPositionsApiURL,
    fetcher({
      address: account,
    }),
    {
      refreshInterval: 5000,
    }
  );

  const { data: mmPositionsDataSwr, error: mmPositionsSwrError }
    = mmPositionsSwr;

  const fetchMMPositions = useCallback(async () => {
    if (!mmPositionsDataSwr || mmPositionsSwrError || !account) {
      // API call hasn't completed yet or error, return early
      return;
    }

    const mmPositions = mmPositionsDataSwr.map((positionData) => ({
      instrumentName: getInstrumentName(
        positionData.underlyer,
        positionData.expiry,
        positionData.strike,
        positionData.type
      ),
      orderId: positionData.order_id,
      optionType: positionData.type,
      asset: positionData.underlyer,
      expiry: positionData.expiry,
      strike: positionData.strike,
      contracts: positionData.size,
      totalPremiumPaid: positionData.total_premiums,
      offerPrice: positionData.offer,
      markPrice: positionData.mark_price,
      performance: positionData.performance,
      imAmount: positionData.im_amount,
      marginRequirement: positionData.margin_requirement,
      marginPosted: positionData.margin_posted,
      requireTopUp:
        Number(positionData.margin_requirement)
        > Number(positionData.margin_posted),
      withdrawableCollateral: positionData.withdrawable_collateral,
      mmUtilization: positionData.mm_utilization,
      greeks: {
        delta: positionData.delta,
        theta: positionData.theta,
        vega: positionData.vega,
        gamma: positionData.gamma,
      },
    })) as IOrder[];
    setMMPositionsData({
      loading: false,
      orders: mmPositions,
    } as OrdersResponse);
  }, [mmPositionsDataSwr, mmPositionsSwrError, account]);

  useEffect(() => {
    fetchMMPositions();
  }, [fetchMMPositions]);

  return {
    mmPositionsData,
  };
};

export default useFetchMMPositions;
