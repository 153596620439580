import styled from "styled-components";
import {
  BORDER_COLORS,
  COLORS,
  LAYER_COLORS,
  PRIMARY_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { FONT_WEIGHT } from "../../../constants/design/fontWeight";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: ${COLORS.blue.one};
    margin-right: ${SPACING.two}px;
  }
`;

export const Content = styled.div`
  width: 312px;
`;

export const ExchangeBalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${LAYER_COLORS.two};
  border-radius: 12px;
  margin: ${SPACING.three}px;
  padding: ${SPACING.two}px;
  color: ${TEXT_COLORS.three};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${TEXT_COLORS.three};
  }

  img {
    width: ${SPACING.five}px;
    height: ${SPACING.five}px;
  }

  div {
    display: flex;
    flex-direction: column;
    font-size: ${FONT_SIZE.xs};
    padding-left: ${SPACING.two}px;
  }
`;

export const BalanceValue = styled.span`
  font-size: ${FONT_SIZE.oneHalf};
  color: ${TEXT_COLORS.one};
  display: flex;
  align-items: center;
`;

export const USDCLabel = styled.span`
  font-size: ${FONT_SIZE.xxxs};
  margin-left: ${SPACING.one}px;
  color: ${TEXT_COLORS.three};
`;

export const MarginLivePerformanceWrapper = styled.div`
  padding: 0 ${SPACING.three}px 0;
  color: ${TEXT_COLORS.three};
`;

export const MarginPnLRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${SPACING.three}px;
  font-size: ${FONT_SIZE.one};
`;

export const AccountItemRow = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  height: ${COMPONENTS.accountModalRowHeight}px;
  padding: 0 ${SPACING.three}px;
  color: ${TEXT_COLORS.one};
  font-size: ${FONT_SIZE.two};

  span {
    padding: 0 ${SPACING.two}px;
  }
  opacity: 0.48;
  &:hover {
    opacity: 1;
  }

  svg:first-child {
    width: 40px;
    height: 40px;
    padding: ${SPACING.twoHalf}px;
    border-radius: 10px;
    background-color: ${BORDER_COLORS.one};
  }
`;

export const DepositWithdrawSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${SPACING.three}px;
`;

export const DepositWithdrawButton = styled.button`
  height: 48px;
  color: ${TEXT_COLORS.one};
  background-color: ${COLORS.white.five};
  border-radius: 8px;

  &:hover {
    opacity: 0.6;
  }

  &:first-child {
    margin-bottom: ${SPACING.three}px;
  }
`;

export const EnableTradingButton = styled.div`
  cursor: pointer;
  color: ${TEXT_COLORS.one};
  background-color: ${PRIMARY_COLORS.four};
  margin: ${SPACING.three}px;
  padding: ${SPACING.three}px;
  text-align: center;
  border-radius: 8px;
  font-weight: ${FONT_WEIGHT.medium};
  font-size: ${FONT_SIZE.two};

  transition: opacity 0.2s ease-in-out;
  &:hover {
    outline: 1px solid ${PRIMARY_COLORS.one};
  }
`;
