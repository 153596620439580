import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { Assets } from "../../../utils/assets";
import MarketIcon from "./MarketIcon";
import {
  BottomContent,
  InfoContent,
  InfoTitle,
  MobileMarketCellContainer,
  TertiaryText,
  TextContainer,
  TopContent,
} from "./style";
import { IInfoRowCol } from "../../../interfaces/InfoRow";

interface IMarketCellProps {
  instrumentName: string;
  asset: Assets;
  isOrderConfirmationTimeWindow: boolean;
  isUnwindOrder: boolean;
  infoCols: IInfoRowCol[];
}

function MobileMarketCell({
  instrumentName,
  asset,
  isOrderConfirmationTimeWindow,
  isUnwindOrder,
  infoCols,
}: IMarketCellProps) {
  return (
    <MobileMarketCellContainer>
      <TopContent>
        <MarketIcon asset={asset} />
        <TextContainer>
          {instrumentName}
          {isOrderConfirmationTimeWindow && !isUnwindOrder && (
            <TertiaryText color={COLORS.positive.one}>
              Post initial margin
            </TertiaryText>
          )}
        </TextContainer>
      </TopContent>
      <BottomContent>
        {infoCols.map((infoCol) => (
          <TextContainer numRows={infoCols.length} key={infoCol.title}>
            <InfoTitle color={TEXT_COLORS.three}>{infoCol.title} </InfoTitle>
            <InfoContent color={infoCol.color || TEXT_COLORS.two}>
              {infoCol.detail}
            </InfoContent>
          </TextContainer>
        ))}
      </BottomContent>
    </MobileMarketCellContainer>
  );
}

export default MobileMarketCell;
