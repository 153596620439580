import { Assets } from "../../utils/assets";
import { OptionTypeResponse } from "../PreloadedStrikes";

export const NotificationType = {
  Filled: "filled",
  Expired: "expired",
  PostMargin: "postMargin",
} as const;

export type NotificationTypeResponse =
  (typeof NotificationType)[keyof typeof NotificationType];

export interface INotification {
  instrumentName: string;
  type: NotificationTypeResponse;
  orderId: number;
  optionType: OptionTypeResponse;
  asset: Assets;
  expiry: string;
  contracts: string;
  strike: string;
  offerPrice?: string;
  entryPrice?: string;
  marginRequirement?: string;
  isRead: boolean;
  timestamp: string;
}
