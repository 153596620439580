import { CSSProperties } from "react";
import { Container, Label, StepButton, StepButtonContainer } from "./style";
import { ReactComponent as ChevronRight } from "../../assets/svg/chevron-outline.svg";
import { ReactComponent as Info } from "../../assets/svg/info.svg";
import { ModalButton } from "../Buttons/styles";

interface IStepButtonsProps {
  rightTitle?: string | JSX.Element;
  onClickRight?: () => void;
  onClickLeft?: () => void;
  info?: {
    onInfoClick: () => void;
    disabled?: boolean;
  };
  style?: CSSProperties;
}

function StepButtons({
  rightTitle,
  onClickRight,
  onClickLeft,
  info,
  style,
}: IStepButtonsProps) {
  return (
    <Container style={style}>
      <StepButtonContainer>
        {onClickLeft && (
          <StepButton
            direction="left"
            disabled={!onClickLeft}
            onClick={onClickLeft}
          >
            <ChevronRight />
          </StepButton>
        )}
        <StepButton
          direction="right"
          disabled={!onClickRight}
          onClick={onClickRight}
        >
          {rightTitle && <Label>{rightTitle}</Label>}
          <ChevronRight />
        </StepButton>
      </StepButtonContainer>
      {info && (
        <ModalButton
          disabled={info.disabled}
          onClick={info.onInfoClick}
          style={{
            marginRight: 0,
          }}
        >
          <Info />
        </ModalButton>
      )}
    </Container>
  );
}

export default StepButtons;
