import React, { ReactElement, useState } from "react";

interface IConnectWalletContextType {
  showConnectModal: boolean;
  setShowConnectModal: (show: boolean) => void;
}

interface IConnectWalletContextProviderProps {
  children: ReactElement;
}

export const ConnectWalletContext = React.createContext<IConnectWalletContextType>({
  showConnectModal: false,
  setShowConnectModal: () => {},
});

export function ConnectWalletContextProvider({ children }: IConnectWalletContextProviderProps) {
  const [showConnectModal, setShowConnectModal] = useState(false);
  return (
    <ConnectWalletContext.Provider value={{ showConnectModal, setShowConnectModal }}>
      {children}
    </ConnectWalletContext.Provider>
  );
}
