import { useCallback, useEffect, useMemo, useState } from "react";
import { ethers } from "ethers";
import currency from "currency.js";
import useWallet from "../../../hooks/wallet/useWallet";
import { copyStringToClipboard, shortenAddress } from "../../../utils/strings";
import { BaseModal, IBaseModalProps } from "../../BaseModal";
import {
  Content,
  Title,
  AccountItemRow,
  ExchangeBalanceWrapper,
  BalanceValue,
} from "./style";
import { ReactComponent as Disconnect } from "../../../assets/svg/power.svg";
import { ReactComponent as Copy } from "../../../assets/svg/copy.svg";
import { ReactComponent as ArrowOut } from "../../../assets/svg/arrow-up-right.svg";
import { ReactComponent as Etherscan } from "../../../assets/svg/etherscan-logo-circle.svg";
import { Divider } from "../../shared/Divider";
import { CHAIN_EXPLORER_URLS } from "../../../utils/chain";
import { ChainIdEnum } from "../../../enums/chain";
import { SPACING } from "../../../constants/design/spacing";
import useUSDC from "../../../hooks/contracts/usdc/useUSDC";
import { getAssetLogo } from "../../../utils/assets";

function AccountModal({ onHide, ...others }: IBaseModalProps) {
  const [showCopied, setShowCopied] = useState(false);
  const { account, deactivate } = useWallet();

  const usdc = useUSDC();
  const usdcBalance = useMemo(
    () => (usdc.userBalance
      ? ethers.utils.formatUnits(usdc.userBalance, usdc.decimals)
      : undefined),
    [usdc.decimals, usdc.userBalance]
  );

  const logo = getAssetLogo("USDC");
  const openBlockExplorer = useCallback(() => {
    window.open(
      `${CHAIN_EXPLORER_URLS[ChainIdEnum.ETH_MAINNET]}/address/${account}`
    );
  }, [account]);

  useEffect(() => {
    if (showCopied) {
      const timeout = setTimeout(() => {
        setShowCopied(!showCopied);
      }, 800);
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [showCopied]);

  const onDisconnect = useCallback(() => {
    deactivate();
    onHide?.();
  }, [deactivate, onHide]);

  const onCopyAddress = useCallback(() => {
    if (account) {
      copyStringToClipboard(account);
      setShowCopied(true);
    }
  }, [account]);

  return (
    <BaseModal
      {...others}
      onHide={onHide}
      noContentPadding
      title={<Title>{shortenAddress(account || "")}</Title>}
    >
      <Content>
        <ExchangeBalanceWrapper>
          <img src={logo} alt={"USDC"} />
          <div>
            <span>Wallet Balance (USDC)</span>
            <BalanceValue>
              {usdcBalance
                ? currency(usdcBalance, {
                  symbol: "",
                }).format()
                : "-"}
            </BalanceValue>
          </div>
        </ExchangeBalanceWrapper>
        <Divider direction="horizontal" size="100%" />
        <AccountItemRow onClick={openBlockExplorer}>
          <Etherscan />
          <span>Open Etherscan</span>
          <ArrowOut />
        </AccountItemRow>
        <Divider direction="horizontal" size="100%" />
        <AccountItemRow onClick={onCopyAddress}>
          <Copy style={{ marginRight: SPACING.two }} />
          {showCopied ? "Address Copied" : "Copy Address"}
        </AccountItemRow>
        <Divider direction="horizontal" size="100%" />
        <AccountItemRow onClick={onDisconnect}>
          <Disconnect />
          <span>Disconnect</span>
        </AccountItemRow>
      </Content>
    </BaseModal>
  );
}

export default AccountModal;
