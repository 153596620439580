import { useContext, useMemo } from "react";
import { TradeSection } from "./style";
import OptionsTradeForm from "../../../components/TradeForm/OptionsTradeForm";
import { ConfirmOrderContextProvider } from "../../../contexts/ConfirmOrderContext";
import { OptionsDataContext } from "../../../contexts/OptionsDataContext";
import useWallet from "../../../hooks/wallet/useWallet";
import ClientOptionsTradeForm from "../../../components/TradeForm/ClientOptionsTradeForm";

function CreateTradeTab() {
  const { account } = useWallet();
  const { whitelistedUsers } = useContext(OptionsDataContext);

  const isClientAddress = useMemo(
    () => (account ? whitelistedUsers.includes(account) : false),
    [account, whitelistedUsers]
  );

  return (
    <ConfirmOrderContextProvider>
      <TradeSection>
        {isClientAddress ? <ClientOptionsTradeForm /> : <OptionsTradeForm />}
      </TradeSection>
    </ConfirmOrderContextProvider>
  );
}

export default CreateTradeTab;
