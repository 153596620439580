import axios from "axios";
import { CANCEL_ORDER_URL, UPDATE_REDEEMED_URL } from "../../../../constants/endpoints/endpoints";

export interface IOrderBody {
  order_id: number;
  is_unwind_order?: "true" | "false";
}

export type Entity = "user" | "mm";
export interface IUpdateRedeemedBody extends IOrderBody {
  entity: Entity;
}

const useOrder = () => {
  const cancelOrderApiURL = CANCEL_ORDER_URL;
  const updateRedeemedApiURL = UPDATE_REDEEMED_URL;
  const cancelOrder = async (body: IOrderBody) => {
    try {
      await axios.patch(cancelOrderApiURL, body);
    } catch (e) {
      throw Error();
    }
  };

  const updateRedeemed = async (body: IUpdateRedeemedBody) => {
    try {
      await axios.patch(updateRedeemedApiURL, body);
    } catch (e) {
      throw Error();
    }
  };
  return { cancelOrder, updateRedeemed };
};

export default useOrder;
