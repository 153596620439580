import useSWR from "swr";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Assets } from "../../../../utils/assets";
import { IOrder, OrdersResponse } from "../../../../interfaces/Orders";
import { OptionTypeResponse } from "../../../../interfaces/PreloadedStrikes";
import { getInstrumentName } from "../../../../utils/instruments";
import { defaultOrdersResponse } from "../../../../contexts/OptionsDataContext";
import useWallet from "../../../wallet/useWallet";
import { GET_USER_LIVE_POSITIONS_URL } from "../../../../constants/endpoints/endpoints";

export interface IUserPositions {
  order_id: number;
  underlyer: Assets;
  type: OptionTypeResponse;
  expiry: string;
  strike: string;
  size: string;
  entry_price?: string;
  mark_price?: string;
  performance?: string;
  roi?: string;
  delta: string;
  theta: string;
  vega: string;
  gamma: string;
}

// Get the current account
const useFetchUserPositions = () => {
  const { account } = useWallet();
  const [userPositionsData, setUserPositionsData] = useState<OrdersResponse>(
    defaultOrdersResponse
  );
  const userPositionsApiURL = GET_USER_LIVE_POSITIONS_URL;

  const fetcher = (params: any) => (url: string) => {
    if (params.address !== undefined) {
      return axios.get(url, { params }).then((res) => res.data);
    }
    return Promise.resolve(null); // Return a resolved Promise with a null value if address is undefined
  };

  const userPositionsSwr = useSWR<IUserPositions[]>(
    userPositionsApiURL,
    fetcher({
      address: account,
    }),
    {
      refreshInterval: 5000,
    }
  );

  const { data: userPositionsDataSwr, error: userPositionsSwrError }
    = userPositionsSwr;

  const fetchUserPositions = useCallback(async () => {
    if (!userPositionsDataSwr || userPositionsSwrError || !account) {
      // API call hasn't completed yet or error, return early
      return;
    }

    const userPositions = userPositionsDataSwr.map((positionData) => ({
      instrumentName: getInstrumentName(
        positionData.underlyer,
        positionData.expiry,
        positionData.strike,
        positionData.type
      ),
      orderId: positionData.order_id,
      optionType: positionData.type,
      asset: positionData.underlyer,
      expiry: positionData.expiry,
      strike: positionData.strike,
      contracts: positionData.size,
      totalPremiumPaid: positionData.entry_price
        ? String(parseFloat(positionData.size) * parseFloat(positionData.entry_price))
        : "0",
      entryPrice: positionData.entry_price,
      markPrice: positionData.mark_price,
      performance: positionData.performance,
      roi: positionData.roi,
      greeks: {
        delta: positionData.delta,
        theta: positionData.theta,
        vega: positionData.vega,
        gamma: positionData.gamma,
      },
    })) as IOrder[];
    setUserPositionsData({
      loading: false,
      orders: userPositions,
    } as OrdersResponse);
  }, [userPositionsDataSwr, userPositionsSwrError, account]);

  useEffect(() => {
    fetchUserPositions();
  }, [fetchUserPositions]);

  return {
    userPositionsData,
  };
};

export default useFetchUserPositions;
