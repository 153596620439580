export enum PortfolioMarketFilterEnum {
  ALL = "All",
  CALLS = "Calls",
  PUTS = "Puts",
}

export enum RequestsFilterEnum {
  ALL = "All",
  OFFERSPLACED = "Offers Placed",
  NOOFFERS = "No Offers",
}
