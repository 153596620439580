import styled, { css } from "styled-components";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  COLORS,
  TEXT_COLORS,
  PRIMARY_COLORS,
  LAYER_COLORS,
} from "../../constants/design/colors";
import { FONT_SIZE, FONT_STYLE } from "../../constants/design/fontSize";
import { FONT_WEIGHT } from "../../constants/design/fontWeight";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { frostedGlassBg } from "../shared/style";
import { Chevron } from "../shared/Chevron/style";

export const FullWidthButton = styled.button<{
  border?: {
    color?: string;
    hoverColor?: string;
  };
  bg?: {
    color?: string;
    hoverColor?: string;
  };
}>`
  background-color: ${({ bg }) => bg?.color || BACKGROUND_COLORS.four};
  color: ${TEXT_COLORS.one};
  border-radius: 8px;
  height: 48px;
  font-size: ${FONT_SIZE.two};
  border-radius: 8px;
  margin-right: ${SPACING.two}px;
  width: 100%;
  border: 1px solid transparent;
  transition: 0.2s all ease-in-out;
  outline: 1px solid transparent;

  &:hover {
    outline: 1px solid ${PRIMARY_COLORS.one};
    background-color: ${({ bg }) => bg?.hoverColor || PRIMARY_COLORS.six};
  }

  &:disabled {
    opacity: 0.64;
    transition: none;
    &:hover {
      outline: none;
      background-color: ${COLORS.white.five};
    }
  }
`;

/**
 * CSS for components that glows on hover
 * @param color Color, hex or rgba()
 * @returns css
 */
export const glow = (color: string) => css`
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 0px 1px ${color} inset;
  }
`;

export const ConnectWalletButton = styled.button.attrs<{
  fullWidth?: boolean;
  isError?: boolean;
  disableHoverAnimation?: boolean;
}>({
  type: "button",
})<{
  fullWidth?: boolean;
  isError?: boolean;
  disableHoverAnimation?: boolean;
}>`
  border-radius: 6px;
  color: ${TEXT_COLORS.one};
  padding: ${SPACING.two}px ${SPACING.three}px;
  white-space: nowrap;
  font-size: ${FONT_SIZE.two};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : undefined)};
  ${({ disableHoverAnimation }) => !disableHoverAnimation && glow(PRIMARY_COLORS.one)}

  ${({ isError, disableHoverAnimation }) => {
    if (isError) {
      return css`
        color: ${COLORS.negative.one};
        background-color: ${COLORS.negative.five};
        ${!disableHoverAnimation ? glow(COLORS.negative.one) : ""}
      `;
    }
    return css`
      background-color: ${PRIMARY_COLORS.four};
    `;
  }}
`;

export const ModalButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${BORDER_COLORS.two};
  color: ${TEXT_COLORS.one};
  font-weight: ${FONT_WEIGHT.medium};
  border-radius: 8px;
  padding: ${SPACING.three}px;
  min-width: 48px;
  min-height: 48px;
  font-size: ${FONT_SIZE.two};
  line-height: ${FONT_SIZE.three};

  transition: all 0.4s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 0px 1px ${PRIMARY_COLORS.one} inset;
    background-color: ${PRIMARY_COLORS.four};
  }

  &:disabled {
    opacity: 0.48;

    &:hover {
      box-shadow: none;
      background-color: ${BORDER_COLORS.two};
    }
  }
`;

export const ModalButtonV2 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${BORDER_COLORS.two};
  color: ${TEXT_COLORS.one};
  font-weight: ${FONT_WEIGHT.medium};
  border-radius: 8px;
  padding: ${SPACING.three}px;
  width: 48px;
  height: 48px;
  font-size: ${FONT_SIZE.two};
  line-height: ${FONT_SIZE.three};
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${BORDER_COLORS.five};
  }

  &:disabled {
    opacity: 0.48;
    border: none;
  }
`;

export const LargeModalButtonV2 = styled(ModalButtonV2)`
  background: ${LAYER_COLORS.two};
  min-height: ${COMPONENTS.xlButtonHeight}px;
  min-width: ${COMPONENTS.xlButtonHeight}px;
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${BORDER_COLORS.two};
  color: ${TEXT_COLORS.one};
  font-weight: ${FONT_WEIGHT.medium};
  border-radius: 8px;
  padding: ${SPACING.three}px;
  min-width: 48px;
  min-height: 48px;
  font-size: ${FONT_SIZE.two};
  line-height: ${FONT_SIZE.three};

  transition: all 0.4s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 0px 1px ${PRIMARY_COLORS.one} inset;
    background-color: ${PRIMARY_COLORS.four};
  }

  &:disabled {
    opacity: 0.48;

    &:hover {
      box-shadow: none;
      background-color: ${BORDER_COLORS.two};
    }
  }
`;

export const BaseButton = styled.button`
  color: ${TEXT_COLORS.two};
  height: ${COMPONENTS.baseButtonHeight}px;
  padding: ${SPACING.two}px;
  border-radius: 8px;

  text-align: center;
  ${frostedGlassBg(BACKGROUND_COLORS.four, BACKGROUND_COLORS.twelve)};

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;

export const ReverseChevronButton = styled(BaseButton)<{ size?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${({ size }) => (size ?? COMPONENTS.baseButtonHeight)}px;
  min-height: ${({ size }) => (size ?? COMPONENTS.baseButtonHeight)}px;
  margin-right: ${SPACING.three}px;
  ${Chevron} {
    opacity: 0.6;
  }
  &:hover {
    opacity: 1;
    border: 1px solid ${COLORS.white.one};
  }
`;

export const ConfirmButton = styled(BaseButton)<{ disabled?: boolean }>`
  width: 100%;
  flex: 1;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  &:hover {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    ${({ disabled }) => !disabled
      && `opacity: 1;
          border: 1px solid ${COLORS.white.one};`}
  }
  font-size: ${FONT_STYLE.header.seven.fontSize};
  line-height: ${FONT_STYLE.header.seven.lineHeight};
`;
