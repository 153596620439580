import React, { ReactElement } from "react";

import { Assets, AssetsList } from "../../utils/assets";
import useFetchAssetsPrice from "../../hooks/api/prices/useFetchAssetsPrice";

export type AssetsPriceData = {
  [asset in Assets]: {
    loading: boolean;
    latestPrice: number;
    percentChange: number;
  };
};

interface IPricesContextProviderProps {
  children: ReactElement;
}
export const defaultAssetsPriceData = Object.fromEntries(
  AssetsList.map((asset) => [
    asset,
    {
      loading: true,
      latestPrice: 0,
      percentChange: 0,
    },
  ])
) as AssetsPriceData;

export const defaultPrices: AssetsPriceData = defaultAssetsPriceData;

export const PricesContext
  = React.createContext<AssetsPriceData>(defaultPrices);

export function PricesContextProvider({
  children,
}: IPricesContextProviderProps) {
  const { data: assetsPrice } = useFetchAssetsPrice();
  return (
    <PricesContext.Provider value={assetsPrice}>
      {children}
    </PricesContext.Provider>
  );
}
