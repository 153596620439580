import { useState, useEffect, useMemo, useRef, createRef } from "react";

import {
  ActiveBackground,
  SegmentControlButton,
  SegmentControlButtonText,
  SegmentControlContainer,
  WidthType,
} from "./style";

export interface ISegmentedControlConfig {
  theme?: "outline";
  color?: string;
  backgroundColor?: string;
  activeBackgroundColor?: string;
  widthType?: WidthType;
  button?: {
    px?: number;
    py?: number;
    height?: number;
    fontSize: string;
  };
  borderRadius?: string;
  margin?: string;
}

interface ISegmentControlProps {
  segments: {
    value: string;
    display: string | JSX.Element;
    textColor?: string;
    disabled?: boolean;
  }[];
  value: string;
  onSelect: (value: string) => void;
  config?: {
    theme?: "outline";
    color?: string;
    backgroundColor?: string;
    activeBackgroundColor?: string;
    widthType?: WidthType;
    button?: {
      px?: number;
      py?: number;
      width?: number;
      height?: number;
      fontSize: string;
    };
    flexDirection?: string;
    borderRadius?: string;
  };
}

function SegmentControl({
  segments,
  value,
  onSelect,
  config: {
    theme,
    color,
    backgroundColor,
    activeBackgroundColor,
    widthType = "maxContent",
    button = { px: 16, py: 12, fontSize: "14px" },
    borderRadius,
    flexDirection,
  } = {},
}: ISegmentControlProps) {
  const controlRefs = useMemo(
    () => segments.reduce<any>((acc, curr) => {
      acc[curr.value] = createRef();
      return acc;
    }, {}),
    [segments]
  );
  const [activeBackgroundState, setActiveBackgroundState] = useState<
    object | boolean
  >(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentRef = controlRefs[value]?.current;

    if (!currentRef) {
      return () => {};
    }

    const handleResize = () => {
      setActiveBackgroundState({
        left: currentRef.offsetLeft,
        top: currentRef.offsetTop,
        height: currentRef.clientHeight,
        width: currentRef.clientWidth - 1,
      });
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [value, controlRefs, theme]);

  return (
    <SegmentControlContainer
      ref={containerRef}
      color={color}
      backgroundColor={backgroundColor}
      widthType={widthType}
      flexDirection={flexDirection}
      style={{ borderRadius }}
    >
      {segments.map((segment) => (
        <SegmentControlButton
          key={segment.value}
          role="button"
          onClick={() => !segment.disabled && onSelect(segment.value)}
          ref={controlRefs[segment.value]}
          widthType={widthType}
          px={button.px}
          py={button.py}
          width={button.width}
          height={button.height}
          backgroundColor={
            theme === "outline" && value === segment.value
              ? activeBackgroundColor
              : "transparent"
          }
          disabled={segment.disabled}
        >
          <SegmentControlButtonText
            color={segment.textColor ?? color}
            style={{
              fontSize: button.fontSize,
            }}
          >
            {segment.display}
          </SegmentControlButtonText>
        </SegmentControlButton>
      ))}
      <ActiveBackground
        transition={{
          type: "keyframes",
          ease: "easeOut",
        }}
        initial={{
          height: "100%",
          width: `calc(100% / ${segments.length})`,
        }}
        animate={activeBackgroundState}
        theme={theme}
        color={theme === "outline" ? color : activeBackgroundColor}
        style={{ borderRadius }}
      />
    </SegmentControlContainer>
  );
}

export default SegmentControl;
