import { BigNumber } from "ethers";
import React, { PropsWithChildren, useCallback, useState } from "react";

// Mapping of token address to the user's balance.
// eg. ITokenBalance["0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8"] = BigNumber(1000)
export type ITokenBalance = {
  [tokenAddress: string]: BigNumber | undefined;
}

// Mapping of token approvals for the user
// eg. To check how much USDC (0xf8439...354) the current user has
// approved to the Account contract (0x23C21...4dC), we do
// ITokenApprovals["0xf8439...354"]["0x23C21...4dC"] = BigNumber(1000)
export type ITokenApprovals = {
  [tokenAddress: string]: {
    [contractAddress: string]: BigNumber | undefined;
  };
}

interface IContractContextType {
  currentAccount: string;
  setCurrentAccount: React.Dispatch<React.SetStateAction<string>>;
  tokenBalances: ITokenBalance;
  setTokenBalances: React.Dispatch<React.SetStateAction<ITokenBalance>>;
  tokenApprovals: ITokenApprovals;
  updateTokenApproval: (tokenAddress: string, spenderAddress: string, amount: BigNumber | undefined) => void;
}

export const ContractContext = React.createContext<IContractContextType>({
  currentAccount: "",
  setCurrentAccount: () => {},
  tokenBalances: {},
  setTokenBalances: () => {},
  tokenApprovals: {},
  updateTokenApproval: () => {},
});

/**
 * This contracts stores common values that requires contract calls,
 * eg. Token balances, token approval limits, etc
 * This is to prevent unnecessary contract calls
 * Any contract hooks (eg. `useUSDC`) can make use of this context to decide
 * when to retrieve data.
 *
 * Take `useUSDC` for example. It checks if a balance exists in tokenBalances,
 * if it does, return that value immediately. If not, it does a contract call to fetch.
 */
export function ContractContextProvider({ children }: PropsWithChildren<{}>) {
  const [currentAccount, setCurrentAccount] = useState("");
  const [tokenBalances, setTokenBalances] = useState<ITokenBalance>({});
  const [tokenApprovals, setTokenApprovals] = useState<ITokenApprovals>({});

  const updateTokenApproval = useCallback((tokenAddress: string, spenderAddress: string, amount?: BigNumber) => {
    setTokenApprovals((prev) => {
      // If already exist, and is the same amount, return
      if (prev[tokenAddress] && prev[tokenAddress][spenderAddress]) {
        const sameAmount = Boolean(amount && prev[tokenAddress][spenderAddress]?.eq(amount));
        if (sameAmount) {
          return prev;
        }
      }
      return {
        ...prev,
        [tokenAddress]: {
          ...(prev[tokenAddress] || {}),
          [spenderAddress]: amount,
        },
      };
    });
  }, []);

  return (
    <ContractContext.Provider
      value={{
        tokenBalances,
        setTokenBalances,
        tokenApprovals,
        updateTokenApproval,
        currentAccount,
        setCurrentAccount,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
}
