import { useContext, useMemo, useState } from "react";
import { RequestsFilterEnum } from "../../../enums/tableFilters";
import OrdersTable from "../../../components/OrdersTable";
import { OptionsDataContext } from "../../../contexts/OptionsDataContext";

function UnmatchedRequestsTab() {
  const [filter, setFilter] = useState(RequestsFilterEnum.ALL);
  const { unmatchedRequests: { loading, orders: unmatchedRequests } } = useContext(OptionsDataContext);

  const filteredRequests = useMemo(() => {
    if (filter === RequestsFilterEnum.OFFERSPLACED) {
      return unmatchedRequests?.filter((r) => r.offerPrice);
    }
    if (filter === RequestsFilterEnum.NOOFFERS) {
      return unmatchedRequests?.filter((r) => !r.offerPrice);
    }
    return unmatchedRequests;
  }, [filter, unmatchedRequests]);

  return (
    <OrdersTable<RequestsFilterEnum, typeof RequestsFilterEnum>
      title="Unmatched Requests"
      filter={filter}
      onSetFilter={setFilter}
      filterEnum={RequestsFilterEnum}
      filteredOrders={filteredRequests}
      orderType="UnmatchedRequests"
      loading={loading}
    />
  );
}

export default UnmatchedRequestsTab;
