import { useContext, useMemo } from "react";
import {
  SettingsContext,
  SFXSettings,
} from "../../../contexts/SettingsContext";
import { SettingsAudioPlayer } from "../../SettingsAudioPlayer";
import { BaseModal, IBaseModalProps } from "../../BaseModal";
import Toggle from "../../shared/Toggle";
import { SFX_LIST } from "./settings";
import { SettingHeader, SettingRow, SFXTitle } from "./style";

interface ISetting {
  title: string;
  setting: SFXSettings;
  isOn: boolean;
  onClick: () => void;
}

export function SettingsModal({ show, onHide }: IBaseModalProps) {
  const { sfx, addSfx, removeSfx } = useContext(SettingsContext);

  const sfxSettings: ISetting[] = useMemo(
    () => SFX_LIST.map((o) => ({
      title: o.key,
      setting: o.value,
      isOn: sfx.has(o.value),
      onClick: () => {
        if (sfx.has(o.value)) {
          removeSfx(o.value);
        } else {
          addSfx(o.value);
        }
      },
    })),
    [sfx, addSfx, removeSfx]
  );

  const settingsTitle = useMemo(
    () => "Customise sound effects for specific events",
    []
  );

  const settingsList = useMemo(() => sfxSettings, [sfxSettings]);

  return (
    <BaseModal
      title="Sound Effects"
      show={show}
      onHide={onHide}
      style={{
        width: 310,
      }}
    >
      <SettingHeader>{settingsTitle}</SettingHeader>
      {settingsList.map((o, i) => (
        <SettingRow
          key={o.setting}
          transition={{
            duration: 0.4,
            delay: (i + 1) * 0.05,
            ease: "easeInOut",
          }}
          initial={{
            opacity: 0,
            transform: "translateY(8px)",
          }}
          animate={{
            opacity: 1,
            transform: "translateY(0px)",
          }}
        >
          <SFXTitle $isOn={o.isOn}>
            <SettingsAudioPlayer sfx={o.setting as SFXSettings} isOn={o.isOn} />
            <p>{o.title}</p>
          </SFXTitle>
          <Toggle isOn={o.isOn} onToggle={o.onClick} />
        </SettingRow>
      ))}
    </BaseModal>
  );
}
