import moment from "moment";
import { TimePeriodEnum } from "../interfaces/TimePeriod";

/**
 * Given a time period, returns the number of seconds for that period
 * @param timePeriod
 */
export const secondsFromTimePeriod = (timePeriod: TimePeriodEnum): number => {
  switch (timePeriod) {
    case TimePeriodEnum.HOURLY:
      return 60 * 60;
    case TimePeriodEnum.DAILY:
      return secondsFromTimePeriod(TimePeriodEnum.HOURLY) * 24;
    case TimePeriodEnum.WEEKLY:
      return secondsFromTimePeriod(TimePeriodEnum.DAILY) * 7;
    case TimePeriodEnum.MONTHLY:
      return secondsFromTimePeriod(TimePeriodEnum.DAILY) * 30;
    case TimePeriodEnum.THREE_MONTHS:
      return secondsFromTimePeriod(TimePeriodEnum.MONTHLY) * 3;
    case TimePeriodEnum.SIX_MONTHS:
      return secondsFromTimePeriod(TimePeriodEnum.MONTHLY) * 6;
    case TimePeriodEnum.YEARLY:
      return secondsFromTimePeriod(TimePeriodEnum.DAILY) * 365;
    default:
      return 0;
  }
};

/**
 * @param nanos Nanoseconds in string
 * @returns Number of seconds
 */
export const nanosToSeconds = (nanos: string | number) => Number(String(nanos).slice(0, -9));

/**
 * @param seconds to nanos in string
 * @returns Number of nanoseconds
 */
export const secondsToNanos = (seconds: string | number) => Number(seconds) * 1e9;

/**
 * Given a time period, return the appropriate resolution (interval) in seconds
 * @param timePeriod Time period
 */
export const getResolutionSecondsForTimePeriod = (
  timePeriod: TimePeriodEnum
): number => {
  switch (timePeriod) {
    case TimePeriodEnum.HOURLY:
      // 30 seconds
      return 30;
    case TimePeriodEnum.DAILY:
      // Every minute
      return 60;
    case TimePeriodEnum.WEEKLY:
      // Every 30 mins
      return 60 * 30;
    case TimePeriodEnum.MONTHLY:
      // Every hour
      return 60 * 60;
    case TimePeriodEnum.THREE_MONTHS:
      // Every 2 hours
      return 60 * 60 * 2;
    case TimePeriodEnum.SIX_MONTHS:
      // Every 4 hours
      return 60 * 60 * 4;
    case TimePeriodEnum.YEARLY:
      // Every Day
      return 60 * 60 * 24;
    default:
      return 0;
  }
};

/**
 * Given an expiry, returns the remaining time to expiry in weeks, days, hours, minutes, and seconds
 * @param expiry UNIX timestamp
 */
export const timeLeftToExpiry = (
  expiry: number | string,
  currentDate?: Date
) => {
  const exp = moment.unix(Number(expiry));
  const duration = moment.duration(exp.diff(currentDate ?? new Date()));

  // Get weeks and subtract from duration
  let weeks = duration.weeks();
  // Max is weeks, so if duration is >1 month
  // we use asWeeks() to convert it all into weeks
  if (duration.asWeeks() > 4) {
    weeks = Math.floor(duration.asWeeks());
  }
  duration.subtract(weeks, "weeks");

  // Get Days and subtract from duration
  const days = duration.days();
  duration.subtract(days, "days");

  // Get hours and subtract from duration
  const hours = duration.hours();
  duration.subtract(hours, "hours");

  // Get Minutes and subtract from duration
  const minutes = duration.minutes();
  duration.subtract(minutes, "minutes");

  const seconds = duration.seconds();

  // Never negative
  return {
    weeks: Math.max(0, weeks),
    days: Math.max(0, days),
    hours: Math.max(0, hours),
    minutes: Math.max(0, minutes),
    seconds: Math.max(0, seconds),
  };
};

/**
 * eg. "1D", "20H", etc.
 * @param expiry UNIX timestamp
 */
export const shorthandTimeLeftToExpiry = (
  expiry: number | string,
  currentDate?: Date
) => {
  const { weeks, days, hours, minutes, seconds } = timeLeftToExpiry(
    expiry,
    currentDate
  );

  if (weeks) {
    return `${weeks}W`;
  }
  if (days) {
    return `${days}D`;
  }
  if (hours) {
    return `${hours}H`;
  }
  if (minutes) {
    return `${minutes}m`;
  }

  // If a few seconds still remaining, return 1M
  if (seconds) {
    return "1m";
  }
  return "Expired";
};
/**
 * eg. "1W 2D 5H 26M"
 * @param expiry UNIX timestamp
 */
export const longhandTimeLeftToExpiry = (
  expiry: number | string,
  currentDate?: Date
) => {
  const { weeks, days, hours, minutes, seconds } = timeLeftToExpiry(
    expiry,
    currentDate
  );
  const timeLeft: string[] = [];
  if (weeks) {
    timeLeft.push(`${weeks}W`);
  }
  if (days) {
    timeLeft.push(`${days}D`);
  }
  if (hours) {
    timeLeft.push(`${hours}H`);
  }
  if (minutes) {
    timeLeft.push(`${minutes}m`);
    timeLeft.push(`${seconds}s`);
  } else if (seconds) {
    // If a few seconds still remaining, return 1M
    timeLeft.push(`${seconds}s`);
  }

  if (!timeLeft.length) {
    return "Expired";
  }

  // Only show the first 4 values
  return timeLeft.slice(0, 4).join(" ");
};

export const getTimeAgo = (pastDate: moment.MomentInput) => {
  const compareToDate = moment(pastDate);
  const now = moment();
  const diff = now.diff(compareToDate);

  // Date must be in the past, else is "Just now"
  if (diff >= 0) {
    const duration = moment.duration(diff);
    const oneMonthAgo = now.clone().subtract(1, "month");
    if (compareToDate <= oneMonthAgo) {
      const value = Math.round(duration.asMonths());
      return `${value} ${value > 1 ? "months" : "month"} ago`;
    }

    const oneWeekAgo = now.clone().subtract(1, "week");
    if (compareToDate <= oneWeekAgo) {
      const value = Math.round(duration.asWeeks());
      return `${value} ${value > 1 ? "weeks" : "week"} ago`;
    }

    const oneDayAgo = now.clone().subtract(1, "day");
    if (compareToDate <= oneDayAgo) {
      const value = Math.round(duration.asDays());
      return `${value} ${value > 1 ? "days" : "day"} ago`;
    }

    const oneHourAgo = now.clone().subtract(1, "hour");
    if (compareToDate <= oneHourAgo) {
      const value = Math.round(duration.asHours());
      return `${value} ${value > 1 ? "hours" : "hour"} ago`;
    }

    const oneMinuteAgo = now.clone().subtract(1, "minute");
    if (compareToDate <= oneMinuteAgo) {
      const value = Math.round(duration.asMinutes());
      return `${value} ${value > 1 ? "mins" : "min"} ago`;
    }
  }

  return "Just now";
};

export const shortExpiryTime = (expiry: number | string) => {
  const date = moment.utc(Number(expiry) * 1000);
  return date.format("DD MMM YY");
};

export const fullExpiryTime = (expiry: number | string) => {
  const date = moment.utc(Number(expiry) * 1000);
  return date.format("DD MMM YYYY - HH:mm [UTC]");
};
