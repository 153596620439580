import { useCallback, useMemo } from "react";
import { placeholderNotifications } from "../../../constants/placeholders/placeholders";
import {
  INotification,
  NotificationType,
  NotificationTypeResponse,
} from "../../../interfaces/Notification";
import { isProduction } from "../../../utils/env";

export const useNotifications = () => {
  //   const { apiKey, apiSecret } = useContext(AuthContext);
  //   const fetcher = authApi(apiKey, apiSecret);

  //   const swr = useSWR<GetNotifications200Response[], AxiosError>(
  //     apiKey ? [APIEndpointEnum.NOTIFICATIONS] : undefined,
  //     async () => (await (await fetcher.getNotifications())()).data,
  //     {
  //       use: [useLoggerMiddleware],
  //       refreshInterval: pollingInterval[APIEndpointEnum.NOTIFICATIONS],
  //     },
  //   );

  const markAsRead = useCallback(async () => {
    try {
      //   if (swr.data) {
      //     const updatedNotifs = swr.data.map((n) => ({
      //       ...n,
      //       is_read: true,
      //     }));
      //     swr.mutate(updatedNotifs, { revalidate: false });
      //   }
      //   await (await fetcher.postMarkAsRead())();
    } catch (error) {
      //   const genericResponseAxiosError = error as AxiosError<GenericErrorResponse>;
      //   throw Error(genericResponseAxiosError.response?.data?.error || "Error Posting Mark As Read");
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const supportedNotificationTypes: NotificationTypeResponse[] = [
    NotificationType.Filled,
    NotificationType.Expired,
    NotificationType.PostMargin,
  ];

  const unreadNotifications: INotification[] = useMemo(
    () => placeholderNotifications.filter(
      (n: INotification) => !n.isRead && supportedNotificationTypes.includes(n.type)
    ),
    [supportedNotificationTypes]
  );

  return {
    notifications: isProduction() ? [] : placeholderNotifications,
    markAsRead,
    unreadNotifications: isProduction() ? [] : unreadNotifications,
  };
};
