import axios from "axios";
import { useCallback } from "react";
import { Assets, isManualPricerAsset } from "../../../utils/assets";
import useOracle from "../../contracts/oracle/useOracle";
import useFetchAssetsPrice from "./useFetchAssetsPrice";
import { formatBigNumber } from "../../../utils/format";
import { isProduction } from "../../../utils/env";
import { SET_ORACLE_PRICE_URL } from "../../../constants/endpoints/endpoints";
import { oracleDecimals } from "../../../constants/decimals/decimals";

export interface ISetOraclePriceBody {
  underlyer: Assets;
}

// if spot price and oracle price percentage difference is 0.02, change oracle price
const priceDeviationBoundary = 0.02;

const useSetOraclePrice = () => {
  const apiURL = SET_ORACLE_PRICE_URL;
  const { oraclePrices } = useOracle();
  const { getAssetPrice } = useFetchAssetsPrice();
  const setOraclePrice = useCallback(
    async (body: ISetOraclePriceBody) => {
      try {
        await axios.post(apiURL, body);
      } catch (e) {
        throw Error();
      }
    },
    [apiURL]
  );

  // checks if the percentage difference between oracle price and coingecko price is above 0.02
  const isPriceDeviationTooHigh = useCallback(
    (assetPrice: number, asset: Assets) => {
      // set the Oracle price if it is invalid
      if (!isManualPricerAsset(asset) || !isProduction() || !oraclePrices) {
        return false;
      }
      if (!oraclePrices[asset]) {
        return true;
      }
      const oraclePriceToNumber = formatBigNumber(
        oraclePrices[asset],
        oracleDecimals
      );
      return (
        Math.abs(assetPrice - oraclePriceToNumber) / oraclePriceToNumber
        > priceDeviationBoundary
      );
    },
    [oraclePrices]
  );

  const handleSetOraclePrice = useCallback(
    async (asset: Assets) => {
      // if there is price deviation between coingeckoPrice and oraclePrice
      // set oraclePrice before executeOrder
      // only set for assets that require manual pricer and on prod
      try {
        if (isManualPricerAsset(asset) && isProduction()) {
          const assetPrice = await getAssetPrice(asset);
          if (assetPrice) {
            if (isPriceDeviationTooHigh(Number(assetPrice), asset)) {
              await setOraclePrice({
                underlyer: asset,
              } as ISetOraclePriceBody);
            }
          }
        }
      } catch (e) {
        throw Error;
      }
    },
    [getAssetPrice, isPriceDeviationTooHigh, setOraclePrice]
  );

  return { isPriceDeviationTooHigh, handleSetOraclePrice };
};

export default useSetOraclePrice;
