import { useMemo, useState } from "react";
import { PageType } from "../TradeForm/OptionsTradeForm";
import {
  ExtraContentWrapper,
  InfoButton,
  CloseButton,
  PrimaryButtonContent,
  PrimaryButton,
} from "./style";
import { ReactComponent as Info } from "../../assets/svg/info.svg";
import { ReactComponent as BarChart } from "../../assets/svg/bar-chart.svg";
import { marginHealth as MarginHealth } from "../../assets/styledSvg/marginHealth";
import { COLORS } from "../../constants/design/colors";
import TradingGuideModal from "../Modals/TradingGuideModal";

export type ContentType = "Payoff" | "MarginHealth";
interface IModalFooterContentProps {
  contentType: ContentType;
  showExtraModal?: boolean;
  setShowExtraModal?: (showExtraModal: boolean) => void;
  pageType?: PageType;
  setPage?: (pageType: PageType) => void;
  requireTopUp?: boolean;
  buttonsDisabled?: boolean;
}

export function ModalFooterContent({
  contentType,
  showExtraModal,
  setShowExtraModal,
  pageType,
  setPage,
  requireTopUp,
  buttonsDisabled,
}: IModalFooterContentProps) {
  const [showTradingGuideModal, setShowTradingGuideModal] = useState(false);
  const payoffContent = useMemo(
    () => (pageType === PageType.Trade ? (
      <PrimaryButton
        type="button"
        disabled={buttonsDisabled}
        onClick={() => {
          if (setPage) {
            setPage(PageType.Payoff);
          }
        }}
      >
        <PrimaryButtonContent>
          <BarChart />
          Payoff and Greeks
        </PrimaryButtonContent>
      </PrimaryButton>
    ) : (
      pageType === PageType.Payoff && (
      <CloseButton
        type="button"
        disabled={buttonsDisabled}
        onClick={() => {
          if (setPage) {
            setPage(PageType.Trade);
          }
        }}
      >
        Close
      </CloseButton>
      )
    )),
    [buttonsDisabled, pageType, setPage]
  );

  const marginHealthContent = useMemo(
    () => (!showExtraModal!! ? (
      <PrimaryButton
        type="button"
        requireTopUp={Boolean(requireTopUp)}
        onClick={() => {
          if (setShowExtraModal) {
            setShowExtraModal(true);
          }
        }}
      >
        <PrimaryButtonContent>
          <MarginHealth
            color={requireTopUp!! ? COLORS.negative.one : undefined}
          />
          Margin Health
        </PrimaryButtonContent>
      </PrimaryButton>
    ) : (
      <CloseButton
        type="button"
        onClick={() => {
          if (setShowExtraModal) {
            setShowExtraModal(false);
          }
        }}
      >
        Close
      </CloseButton>
    )),
    [requireTopUp, setShowExtraModal, showExtraModal]
  );
  return (
    <>
      <TradingGuideModal
        show={showTradingGuideModal}
        onHide={() => setShowTradingGuideModal(false)}
      />
      <ExtraContentWrapper>
        {contentType === "Payoff" ? payoffContent : marginHealthContent}
        <InfoButton
          type={"button"}
          disabled={buttonsDisabled}
          onClick={() => setShowTradingGuideModal(true)}
        >
          <Info />
        </InfoButton>
      </ExtraContentWrapper>
    </>
  );
}
