import {
  SegmentedControlWrapper,
} from "./style";
import {
  TEXT_COLORS,
  BACKGROUND_COLORS,
} from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import SegmentedControl from "../../shared/SegmentedControl";

export enum ModalContent {
  Details = "Details",
  Greeks = "Greeks",
  Unwind = "Unwind"
}

interface IModalContentSegmentControlProps {
  modalContent: ModalContent;
  setModalContent: (modalContent: ModalContent) => void;
}

function ModalContentSegmentControl({
  modalContent,
  setModalContent,
}: IModalContentSegmentControlProps) {
  return (
    <SegmentedControlWrapper>
      <SegmentedControl
        segments={[
          {
            value: ModalContent.Details,
            display: ModalContent.Details,
            textColor:
              modalContent === ModalContent.Details
                ? TEXT_COLORS.one
                : TEXT_COLORS.three,
          },
          {
            value: ModalContent.Greeks,
            display: ModalContent.Greeks,
            textColor:
              modalContent === ModalContent.Greeks
                ? TEXT_COLORS.one
                : TEXT_COLORS.three,
          },
        ]}
        value={modalContent}
        onSelect={(value) => {
          setModalContent(value as ModalContent);
        }}
        config={{
          theme: "outline",
          color:
            modalContent === ModalContent.Details
              ? TEXT_COLORS.one
              : TEXT_COLORS.two,
          widthType: "fullWidth",
          backgroundColor: BACKGROUND_COLORS.eight,
          activeBackgroundColor:
            modalContent === ModalContent.Details
              ? BACKGROUND_COLORS.four
              : BACKGROUND_COLORS.fifteen,
          borderRadius: "10px",
          button: {
            height: 48,
            fontSize: FONT_SIZE.two,
          },
        }}
      />
    </SegmentedControlWrapper>
  );
}

export default ModalContentSegmentControl;
