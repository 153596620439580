import { DividerWrapper } from "./style";

interface IDividerProps {
  direction: "vertical" | "horizontal";

  // If direction is horizontal, size is referring to height
  // If direction is vertical, size is referring to width
  size: string;
}

export function Divider({ direction, size }: IDividerProps) {
  return (
    <DividerWrapper direction={direction} size={size} />
  );
}
