import { CSSProperties } from "styled-components";
import { OptionWrapper, SelectButton, SelectWrapper, Tag } from "./style";

export interface ISelectOption {
  label: string;
  onClick?: () => void;
  isActive: boolean;
  // Highlighted text
  isDecorated?: boolean;
  css?: any;
  additionalTags?: string[];
}

export interface ISelectProps {
  options: ISelectOption[];
  wrapperStyle?: CSSProperties;
  isRound?: boolean;
  isDisabled?: boolean;
  buttonSpacing?: number;
}

export function Select({
  options,
  wrapperStyle,
  isRound,
  isDisabled = false,
  buttonSpacing,
}: ISelectProps) {
  return (
    <SelectWrapper style={wrapperStyle}>
      {options.map((option) => (
        <OptionWrapper key={option.label} spacing={buttonSpacing}>
          <SelectButton
            disabled={isDisabled}
            css={option.css}
            onClick={() => !isDisabled && option.onClick?.()}
            isActive={option.isActive}
            isHighlighted={option.isDecorated}
            isRound={isRound}
          >
            {option.label}
            {
              option.additionalTags && option.additionalTags.map(
                (tag) => <Tag key={tag}>{tag}</Tag>,
              )
            }
          </SelectButton>
        </OptionWrapper>
      ))}
    </SelectWrapper>
  );
}
