import styled from "styled-components";
import { COMPONENTS } from "./constants/design/spacing";

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

export const BodyContainer = styled.div<{ isMobileScreen: boolean }>`
  height: ${({ isMobileScreen }) => (isMobileScreen
    ? `calc(100vh - ${
      COMPONENTS.header.main
          + COMPONENTS.footer.sub
          + COMPONENTS.mobileNavigatorHeight
    }px)`
    : `calc(100vh - ${
      COMPONENTS.header.main
          + COMPONENTS.footer.main
          + COMPONENTS.marqueeHeight
    }px)`)};
  overflow: auto;
`;

export const FloatingBackgroundContainer = styled.div<{
  backgroundColor?: string;
}>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100%;
  width: 100vw;
  opacity: 0.08;
  overflow: hidden;
  ${(props) => (props.backgroundColor ? `background: ${props.backgroundColor};` : "")};
`;
