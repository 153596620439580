import styled from "styled-components";
import { COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";

export const BadgeWrapper = styled.span<{ backgroundColor?: string; color?: string; size?: number }>`
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: ${FONT_SIZE.xs};
  border-radius: 50%;
  line-height: 1.5;
  color: ${({ color }) => color ?? COLORS.negative.one};
  background-color: ${({ backgroundColor }) => backgroundColor ?? COLORS.negative.four};
  ${({ size }) => `
        width: ${size ?? 24}px;
        height: ${size ?? 24}px; 
    `}
`;
