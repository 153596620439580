import React from "react";
import ReactDOM from "react-dom/client";
import buffer from "buffer";
import App from "./App";
import { GlobalStyle } from "./style";
import "./i18n/config";

window.Buffer = window.Buffer || buffer.Buffer;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>,
);
