/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useContext } from "react";
import currency from "currency.js";
import {
  Content,
  Icon,
  IconWrapper,
  MarginBar,
  NoPendingOrdersWrapper,
  PendingOrderWrapper,
  TextWrapper,
  Timestamp,
  Title,
} from "./style";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { IOrder } from "../../../interfaces/Orders";
import { shorthandTimeLeftToExpiry } from "../../../utils/date";
import { getAssetLogo, getAssetIndicPrecision } from "../../../utils/assets";
import { useOrderTimes } from "../../../hooks/time/useOrderTimes";
import { RequestPositionContext } from "../../../contexts/RequestPositionContext";
import { Spinner } from "../../shared/Spinner";

interface IPendingOrdersPanelProps {
  loading: boolean;
  pendingOrders: IOrder[];
}

export function PendingOrdersPanel({
  loading,
  pendingOrders,
}: IPendingOrdersPanelProps) {
  const {
    setOrder,
    setOrderType,
    showRequestPositionModal,
    setShowRequestPositionModal,
  } = useContext(RequestPositionContext);
  const marginBar = useCallback(
    (percentage: number) => <MarginBar style={{ width: `${percentage}%` }} />,
    []
  );

  const { timeArgs } = useOrderTimes();
  // temp
  const noPendingOrders = pendingOrders.length === 0;
  if (loading) {
    return (
      <PendingOrderWrapper>
        <NoPendingOrdersWrapper>
          <Spinner color={TEXT_COLORS.two} />
        </NoPendingOrdersWrapper>
      </PendingOrderWrapper>
    );
  }
  if (noPendingOrders) {
    return (
      <PendingOrderWrapper>
        <NoPendingOrdersWrapper>No pending orders</NoPendingOrdersWrapper>
      </PendingOrderWrapper>
    );
  }

  return (
    <div>
      {noPendingOrders ? (
        <PendingOrderWrapper key={""}>
          <NoPendingOrdersWrapper>No pending orders</NoPendingOrdersWrapper>
        </PendingOrderWrapper>
      ) : (
        pendingOrders.map((pendingOrder) => {
          const { percentage } = timeArgs(
            Number(pendingOrder.orderStartTime),
            Number(pendingOrder.orderStartTime),
            Number(pendingOrder.orderEndTime)
          );
          const timeLeft = shorthandTimeLeftToExpiry(Number(pendingOrder.orderEndTime));
          const hasExpired = shorthandTimeLeftToExpiry(Number(pendingOrder.orderEndTime)) === "Expired";
          return (
            <PendingOrderWrapper
              role="button"
              onClick={() => {
                setOrder(pendingOrder);
                setOrderType("PendingOrders");
                setShowRequestPositionModal(!showRequestPositionModal);
              }}
              key={pendingOrder.orderId}
              isRead={false}
            >
              {marginBar(percentage)}
              <IconWrapper>
                <Icon
                  src={getAssetLogo(pendingOrder.asset)}
                  style={{ padding: 0 }}
                />
              </IconWrapper>
              <Content>
                <div>
                  <Title>{pendingOrder.instrumentName}</Title>
                  <TextWrapper>
                    <div>{pendingOrder.contracts}</div>
                    <div style={{ color: TEXT_COLORS.three }}>contracts at</div>
                    <div>
                      {currency(Number(pendingOrder.indicativePrice || pendingOrder.customPrice), {
                        precision: getAssetIndicPrecision(pendingOrder.asset),
                      }).format()}
                    </div>
                    {pendingOrder.isUnwindOrder && <div style={{ color: COLORS.blue.one }}>Unwind order</div>}
                  </TextWrapper>
                </div>
                <Timestamp>
                  {hasExpired ? "Expired" : `${timeLeft} till order confirmation`}
                </Timestamp>
              </Content>
            </PendingOrderWrapper>
          );
        })
      )}
    </div>
  );
}
