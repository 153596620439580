import metamask from "../../../assets/svg/metamask.svg";
import walletConnect from "../../../assets/svg/wallet-connect.svg";
import coinbaseWallet from "../../../assets/svg/coinbase-wallet.svg";
import { WalletButton } from "./style";
import { Divider } from "../../shared/Divider";
import { WalletEnum } from "../../../utils/wallet/connectors";

interface IConnectWalletProps {
  selectedWallet?: WalletEnum;
  setWallet: (wallet: WalletEnum) => void;
}

function ConnectWallet({ selectedWallet, setWallet }: IConnectWalletProps) {
  return (
    <>
      <WalletButton
        onClick={() => setWallet(WalletEnum.METAMASK)}
        isActive={selectedWallet === WalletEnum.METAMASK}
        walletType={WalletEnum.METAMASK}
      >
        <img src={metamask} alt="metamask" />
        <span>Metamask</span>
      </WalletButton>
      <Divider direction="horizontal" size="100%" />
      <WalletButton
        onClick={() => setWallet(WalletEnum.WALLETCONNECT)}
        isActive={selectedWallet === WalletEnum.WALLETCONNECT}
        walletType={WalletEnum.WALLETCONNECT}
      >
        <img src={walletConnect} alt="walletconnect" />
        <span>Wallet Connect</span>
      </WalletButton>
      <Divider direction="horizontal" size="100%" />
      <WalletButton
        onClick={() => setWallet(WalletEnum.COINBASE)}
        isActive={selectedWallet === WalletEnum.COINBASE}
        walletType={WalletEnum.COINBASE}
      >
        <img src={coinbaseWallet} alt="coinbase wallet" />
        <span>Coinbase Wallet</span>
      </WalletButton>
      <Divider direction="horizontal" size="100%" />
    </>
  );
}

export default ConnectWallet;
