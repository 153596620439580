import { motion } from "framer-motion";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SegmentedControl from "../SegmentedControl";
import { BACKGROUND_COLORS, COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import {
  Container,
  SideNavigatorWrapper,
  TextContainer,
} from "./style";
import { TabLinkWrapper } from "../../../pages/style";
import useRoutes from "../../../hooks/routes/useRoutes";
import { IRoute } from "../../../enums/pages";
import { COMPONENTS } from "../../../constants/design/spacing";

function SideNavigator() {
  const navigate = useNavigate();
  const { pages, currentTab, currentPage, setCurrentTab } = useRoutes();

  const location = useLocation();

  // if specific route found go to that page
  // gelse o to the first tab when page changed
  useEffect(() => {
    const findMatch = pages.find((page) => location.pathname === page.redirect);
    setCurrentTab(findMatch ?? (pages[0] as IRoute));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <SideNavigatorWrapper>
      <SegmentedControl
        segments={pages.map((page) => ({
          value: page.title,
          display: page.img,
          textColor: COLORS.positive.one,
        }))}
        value={currentTab?.title || pages[0].title}
        onSelect={(value) => {
          const getPage = pages.find((page) => page.title === value);
          if (getPage) navigate(getPage.redirect);
          setCurrentTab(getPage);
        }}
        config={{
          theme: "outline",
          color: COLORS.blue.one,
          backgroundColor: BACKGROUND_COLORS.four,
          activeBackgroundColor: COLORS.blue.six,
          borderRadius: "10px",
          button: {
            px: 0,
            py: 0,
            width: 56,
            height: COMPONENTS.sideNavigatorHeight,
            fontSize: FONT_SIZE.two,
          },
          flexDirection: "column",
        }}
      />
      <Container>
        {pages.map((page, i) => (
          <motion.div
            key={page.title}
            transition={{
              delay: (pages.length - (i + 1)) * 0.1, // reverse order
              duration: 0.25,
              ease: "easeInOut",
            }}
            initial={{
              transform: "translateY(-8px)",
              opacity: 0,
            }}
            animate={{
              transform: "translateY(0px)",
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
          >
            <TabLinkWrapper
              role="button"
              onClick={() => setCurrentTab(page as IRoute)}
              to={page.redirect}
              $isActive={page.isActive}
            >
              <TextContainer
                isActive={currentTab?.title === page.title}
                key={page.title}
              >
                {page.title}
              </TextContainer>
            </TabLinkWrapper>
          </motion.div>
        ))}
      </Container>
    </SideNavigatorWrapper>
  );
}

export default SideNavigator;
