import { AnchorHTMLAttributes, CSSProperties, PropsWithChildren } from "react";
import { TextWrapper } from "./styles";
import { ReactComponent as Arrow } from "../../assets/svg/arrow-up-right.svg";

interface IExternalLinkTextProps extends PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>> {
  hideArrow?: boolean;
  wrapperStyle?: CSSProperties;
  color?: string;
  hoverColor?: string;
}

function ExternalLinkText({
  children,
  hideArrow,
  wrapperStyle,
  color,
  hoverColor,
  ...others
}: IExternalLinkTextProps) {
  return (
    <TextWrapper style={wrapperStyle} color={color} hoverColor={hoverColor}>
      <a {...others}>
        {children}
        {!hideArrow && <Arrow color={color} />}
      </a>
    </TextWrapper>
  );
}

export default ExternalLinkText;
