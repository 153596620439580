import { CSSProperties, PropsWithChildren } from "react";
import { SectionContainer, InnerContainer } from "./style";
import useScreenSize from "../../../hooks/screenSize/useScreenSize";

interface ISectionProps extends PropsWithChildren {
  style?: CSSProperties;
}

export function Section({ children, style }: ISectionProps) {
  const { isMobileScreen } = useScreenSize();
  return (
    <SectionContainer style={style} isMobileScreen={isMobileScreen}>
      <InnerContainer>{children}</InnerContainer>
    </SectionContainer>
  );
}

export default Section;
