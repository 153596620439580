import { ethers } from "ethers";

export const calculateHash = (
  underlyingAsset: string,
  collateralAsset: string,
  isPut: boolean
) => {
  const encoded = ethers.utils.defaultAbiCoder.encode(
    ["address", "address", "bool"],
    [underlyingAsset, collateralAsset, isPut]
  );
  const hash = ethers.utils.keccak256(encoded);
  return hash;
};
