import { AnimatePresence, motion } from "framer-motion";
import Marquee from "react-fast-marquee";
import { Assets } from "../../utils/assets";
import useAssetPriceData from "../../hooks/api/prices/useAssetPriceData";
import { getNumberColor } from "../../utils/math";
import {
  MarqueeWrapper,
  PriceMarqueeWrapper,
  TickerChange,
  TickerContainer,
  TickerPrice,
  TickerTitle,
} from "./style";

function PriceMarquee() {
  const { useAssetsPrice } = useAssetPriceData();
  const { prices } = useAssetsPrice();

  return (
    <PriceMarqueeWrapper>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          transition={{
            delay: 1,
            duration: 0.25,
            type: "keyframes",
            ease: "easeOut",
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          <MarqueeWrapper>
            <Marquee pauseOnHover gradient={false} speed={40} delay={1}>
              {Object.keys(prices).map((asset) => {
                const { price, percentChange } = prices[asset as Assets];
                return (
                  <div key={asset}>
                    <TickerContainer>
                      <TickerTitle>{`${asset}-USD`}</TickerTitle>
                      <TickerPrice>{price.toFixed(2)}</TickerPrice>
                      <TickerChange color={getNumberColor(percentChange)}>
                        {percentChange > 0 && "+"}
                        {percentChange.toFixed(2)}%
                      </TickerChange>
                    </TickerContainer>
                  </div>
                );
              })}
            </Marquee>
          </MarqueeWrapper>
        </motion.div>
      </AnimatePresence>
    </PriceMarqueeWrapper>
  );
}

export default PriceMarquee;
