// This hook is solely responsible for the management of toasts rendered on a global level
// A toast is added to a queue with varying intervals to be removed
// We hash each toast and clear it from the queue based on its hash

import { ethers } from "ethers";
import { useCallback, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ToastContext } from "../../contexts/ToastContext";
import { IToast } from "../../interfaces/Toast";
import { ToastEnum, ToastStatusEnum } from "../../utils/toast";
import { useSFX } from "../useSFX";

function generateToastHash(): string {
  // Generate toast hash and pass into queueing toast
  return ethers.utils.id(new Date().getMilliseconds().toString());
}

export function useToast() {
  // Attach intervals and remove based on toast hash after timeout
  const { toasts, setToasts } = useContext(ToastContext);
  const { playSound } = useSFX();
  const { t } = useTranslation();

  // We use a reference to toasts so that it will not trigger re-renders
  const toastsRef = useRef<IToast[]>([]);
  toastsRef.current = toasts;

  const removeToast = useCallback((id?: string) => {
    if (id) {
      setToasts(toastsRef.current.filter((toast) => toast.hash !== id));
    }
  }, [setToasts]);

  const addToast = useCallback((toast: IToast, interval?: number | undefined) => {
    const toastHash = generateToastHash();

    const newToast = {
      ...toast,
      interval,
      hash: toastHash,
    };
    setToasts([newToast, ...toasts]);
  }, [setToasts, toasts]);

  /**
   * Given an error code, displays the related error message in an error toast
   * @param errorCode Error code returned from API. Should be one of src/i18n/en/errors.json. If doesnt exist, show error code as is
   */
  const addErrorToast = useCallback((
    header: string | JSX.Element,
    errorCode: string,
  ) => {
    const error = t(`apiErrors:${errorCode}`, {
      defaultValue: errorCode,
    });
    playSound("error");
    addToast(
      {
        type: ToastEnum.SIMPLE,
        header,
        subheader: error,
        status: ToastStatusEnum.ERROR,
      },
      10000,
    );
  }, [addToast, playSound, t]);

  return {
    toasts,
    addToast,
    removeToast,
    addErrorToast,
  };
}
