import { useCallback, useMemo } from "react";
import currency from "currency.js";
import { ReactComponent as Close } from "../../../../../assets/svg/close.svg";
import {
  InfoRow,
  HeaderContainer,
  AssetHeaderWrapper,
  InfoDetail,
  SmartContractRowWrapper,
  Form,
} from "../../style";
import { IOrder } from "../../../../../interfaces/Orders";
import { LargeModalButtonV2 } from "../../../../Buttons/styles";
import AssetWrapper from "../../../../shared/AssetWrapper";
import { fullExpiryTime } from "../../../../../utils/date";
import { COLORS } from "../../../../../constants/design/colors";
import { shortenAddress } from "../../../../../utils/strings";
import { ChainIdEnum } from "../../../../../enums/chain";
import { CHAIN_EXPLORER_URLS } from "../../../../../utils/chain";
import { ReactComponent as ArrowOut } from "../../../../../assets/svg/arrow-up-right.svg";
import addresses from "../../../../../constants/addresses/addresses.json";
import { getAssetIndicPrecision } from "../../../../../utils/assets";
import CircularProgressBar from "../../../../shared/CircularProgressBar";
import { IInfoRowCol } from "../../../../../interfaces/InfoRow";

interface IUnfilledOrderModalProps {
  order: IOrder;
  setShowModal: (show: boolean) => void;
}

function UnfilledOrderModal({ order, setShowModal }: IUnfilledOrderModalProps) {
  const { optionType, expiry, strike, asset, indicativePrice, iv, isUnwindOrder } = order;

  const contractAddress = addresses.mainnet.contracts.otcWrapper;

  const onHide = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const openBlockExplorer = useCallback(() => {
    window.open(
      `${
        CHAIN_EXPLORER_URLS[ChainIdEnum.ETH_MAINNET]
      }/address/${contractAddress}`
    );
  }, [contractAddress]);

  const infoRows: IInfoRowCol[] = useMemo(() => {
    const rows = [];
    rows.push({
      title: "Order Status",
      detail: "Order not filled",
      color: COLORS.negative.one,
    });
    if (isUnwindOrder) {
      rows.push({
        title: "Order Type",
        detail: "Unwind",
      });
    }
    rows.push({
      title: "Option Type",
      detail: `European ${optionType.replace(/^\w/, (c) => c.toUpperCase())}`,
    });
    rows.push({
      title: "Expiry",
      detail: `${fullExpiryTime(expiry)}`,
    });
    rows.push({
      title: "Strike",
      detail: `${strike}`,
    });
    if (indicativePrice) {
      rows.push({
        title: "Indicative Price",
        detail: `${currency(parseFloat(indicativePrice), {
          precision: getAssetIndicPrecision(asset),
        }).format()}`,
        color: COLORS.positive.one,
      });
    }
    if (iv) {
      rows.push({
        title: "Indicative IV",
        detail: `${iv}%`,
        color: COLORS.positive.one,
      });
    }
    rows.push({
      title: "Smart Contract",
      detail: (
        <SmartContractRowWrapper onClick={openBlockExplorer}>
          <span>{shortenAddress(contractAddress)}</span>
          <ArrowOut />
        </SmartContractRowWrapper>
      ),
    });
    return rows;
  }, [isUnwindOrder, optionType, expiry, strike, indicativePrice, iv, openBlockExplorer, contractAddress, asset]);

  const headerContent = useMemo(
    () => (
      <>
        <AssetHeaderWrapper>
          <AssetWrapper asset={asset} />
        </AssetHeaderWrapper>
        <LargeModalButtonV2 type={"button"} onClick={onHide}>
          <Close />
        </LargeModalButtonV2>
      </>
    ),
    [onHide, asset]
  );

  return (
    <Form onSubmit={() => {}}>
      <HeaderContainer>{headerContent}</HeaderContainer>
      <CircularProgressBar
        size={160}
        strokeWidth={4}
        detail={"Time till order confirmation"}
        type={"failure"}
      />
      {infoRows.map((infoRow) => (
        <InfoRow key={infoRow.title}>
          <span>{infoRow.title}</span>
          <InfoDetail color={infoRow.color}>{infoRow.detail}</InfoDetail>
        </InfoRow>
      ))}
    </Form>
  );
}

export default UnfilledOrderModal;
