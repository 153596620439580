import { MutableRefObject, useContext, useState } from "react";
import { BaseModal } from "../../BaseModal";
import {
  Title,
  SelectCounterpartyModalContainer,
  CounterpartyDetails,
} from "./style";
import { ModalButtonV2 } from "../../Buttons/styles";
import { OptionsDataContext } from "../../../contexts/OptionsDataContext";

interface ISelectCounterpartyModalProps {
  show: boolean;
  selectedCounterparty?: string;
  modalRef?: MutableRefObject<null>;
  setSelectedCounterparty: (counterparty?: string) => void;
  onHide: () => void;
}

function SelectCounterpartyModal({
  show,
  selectedCounterparty,
  setSelectedCounterparty,
  modalRef,
  onHide,
}: ISelectCounterpartyModalProps) {
  const { mmNames } = useContext(OptionsDataContext);
  const noCounterpartyText = "NO COUNTERPARTY";
  const fullMMNames = [...mmNames, noCounterpartyText];
  const [modalSelectedCounterparty, setModalSelectedCounterparty] = useState<string>(selectedCounterparty!!);
  return (
    <BaseModal show={show} modalRef={modalRef} onHide={onHide} title="Select Counterparty">
      <SelectCounterpartyModalContainer>
        {fullMMNames.map((cp) => (
          <CounterpartyDetails
            key={cp}
            onClick={() => setModalSelectedCounterparty(cp)}
            isSelected={cp === modalSelectedCounterparty}
          >
            <Title>{cp}</Title>
          </CounterpartyDetails>
        ))}
        <ModalButtonV2
          type={"button"}
          onClick={() => {
            onHide();
            setSelectedCounterparty(modalSelectedCounterparty === noCounterpartyText ? undefined : modalSelectedCounterparty);
          }}
          disabled={!modalSelectedCounterparty}
          style={{ width: "100%" }}
        >
          Confirm Counterparty
        </ModalButtonV2>
      </SelectCounterpartyModalContainer>
    </BaseModal>
  );
}

export default SelectCounterpartyModal;
