import { isProduction } from "../../utils/env";

export const GET_PRELOADED_STRIKES_URL = isProduction() ? process.env.REACT_APP_GET_PRELOADED_STRIKES_URL || "" : "https://6tmaisvngfn2m74sgjjnprcfcq0ydoct.lambda-url.ap-southeast-1.on.aws/";

export const GET_INDICATIVE_PRICE_URL = isProduction() ? process.env.REACT_APP_GET_INDICATIVE_PRICE_URL || "" : "https://irvyrpc6ba6ir3f3xtem36u3by0pnolg.lambda-url.ap-southeast-1.on.aws/";

export const SET_ORACLE_PRICE_URL = process.env.REACT_APP_SET_ORACLE_PRICE_URL || "";

export const MM_PLACE_ORDER_URL = process.env.REACT_APP_MM_PLACE_ORDER_URL || "";

export const PLACE_ORDER_URL = isProduction()
  ? process.env.REACT_APP_PLACE_ORDER_URL || ""
  : "https://3ag2qybcg6iilv5xcgv3hmrgsi0uqegb.lambda-url.ap-southeast-1.on.aws/";

export const OFFER_URL = isProduction()
  ? process.env.REACT_APP_OFFER_URL || ""
  : "https://n4yjed6tdip2fpe6tj3hx2olzi0fsugk.lambda-url.ap-southeast-1.on.aws/";

export const UPDATE_OFFER_AFTER_EXECUTION_URL = isProduction()
  ? process.env.REACT_APP_UPDATE_OFFER_AFTER_EXECUTION_URL || ""
  : "https://gjvxj2odufsqsrdqpnu7axq4jq0eriut.lambda-url.ap-southeast-1.on.aws/";

export const GET_LIVE_REQUESTS_URL = isProduction()
  ? process.env.REACT_APP_GET_LIVE_REQUESTS_URL || ""
  : "https://ugzdtbnuvw4ylr7encxczwnziy0gciwl.lambda-url.ap-southeast-1.on.aws/";

export const GET_UNMATCHED_REQUESTS_URL = isProduction()
  ? process.env.REACT_APP_GET_UNMATCHED_REQUESTS_URL || ""
  : "https://i4kicsltyalhy577smntvd5pra0isxye.lambda-url.ap-southeast-1.on.aws/";

export const GET_PENDING_ORDERS_URL = isProduction()
  ? process.env.REACT_APP_GET_PENDING_ORDERS_URL || ""
  : "https://sdb5hrhkxjpn633vewp2pac6am0rtpqv.lambda-url.ap-southeast-1.on.aws/";

export const CANCEL_ORDER_URL = isProduction()
  ? process.env.REACT_APP_CANCEL_ORDER_URL || ""
  : "https://q4xvqpptofzt5q4hynivti4jxq0amsxx.lambda-url.ap-southeast-1.on.aws/";

export const GET_USER_LIVE_POSITIONS_URL = isProduction()
  ? process.env.REACT_APP_GET_USER_LIVE_POSITIONS_URL || ""
  : "https://4rmmof4lhmcx6zuayp7wrkcxnu0pfbow.lambda-url.ap-southeast-1.on.aws/";

export const GET_USER_TRADE_HISTORY_URL = isProduction()
  ? process.env.REACT_APP_GET_USER_TRADE_HISTORY_URL || ""
  : "https://kc5tmjwvridnuiitdffn4sj3cy0wymjd.lambda-url.ap-southeast-1.on.aws/";

export const GET_USER_UNFILLED_ORDERS_URL = isProduction()
  ? process.env.REACT_APP_GET_USER_UNFILLED_ORDERS_URL || ""
  : "https://qt4364imf5wqgee4mb7q7kwbma0nktll.lambda-url.ap-southeast-1.on.aws/";

export const UPDATE_REDEEMED_URL = isProduction()
  ? process.env.REACT_APP_UPDATE_REDEEMED_URL || ""
  : "https://qjn4rj4ceypupbwwzbrxbhglc40xowcj.lambda-url.ap-southeast-1.on.aws/";

export const UPDATE_MM_COLLATERAL_URL = isProduction()
  ? process.env.REACT_APP_UPDATE_MM_COLLATERAL_URL || ""
  : "https://4tm2rew3kip74uqrexkxyjga4a0jtrwd.lambda-url.ap-southeast-1.on.aws/";

export const GET_MM_LIVE_POSITIONS_URL = isProduction()
  ? process.env.REACT_APP_GET_MM_LIVE_POSITIONS_URL || ""
  : "https://ng4tihgkuhvudy6qwao4nn3odq0jxwhy.lambda-url.ap-southeast-1.on.aws/";

export const GET_MM_TRADE_HISTORY_URL = isProduction()
  ? process.env.REACT_APP_GET_MM_TRADE_HISTORY_URL || ""
  : "https://s3muuw45kdwjhjxij3fwobxuhy0xiwyg.lambda-url.ap-southeast-1.on.aws/";

export const GET_WHITELISTED_USERS_URL = isProduction()
  ? process.env.REACT_APP_GET_WHITELISTED_USERS_URL || ""
  : "https://d3iuxgrununqizrockh7llhg5u0kjfpl.lambda-url.ap-southeast-1.on.aws/";

export const GET_MM_NAMES_URL = isProduction()
  ? process.env.REACT_APP_GET_MM_NAMES_URL || ""
  : "https://vtr7rw7uga3gbwudjmnm5amgj40tvqhi.lambda-url.ap-southeast-1.on.aws/";

export const POST_ERROR_MESSAGE_URL = isProduction()
  ? process.env.REACT_APP_POST_ERROR_MESSAGE_URL || ""
  : "https://skfwau3cckap3oesdz7l7mug3i0tpeod.lambda-url.ap-southeast-1.on.aws/";

export const TELEGRAM_SUPPORT_URL = process.env.REACT_APP_TELEGRAM_SUPPORT_URL || "";
export const PERMIT_DURATION = isProduction()
  ? process.env.REACT_APP_PERMIT_DURATION || 0
  : process.env.REACT_APP_DEV_PERMIT_DURATION || 0;

export const MINIMUM_TOTAL_PREMIUM = Number(process.env.REACT_APP_MINIMUM_TOTAL_PREMIUM || 3000);
