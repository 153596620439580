/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as MobileLogo } from "../../../../assets/logo/logo_white.svg";
import { ReactComponent as Bell } from "../../../../assets/svg/bell.svg";
import { SPACING } from "../../../../constants/design/spacing";
import { PageEndpointEnum } from "../../../../enums/endpoint";
import AccountDropdown from "../../AccountDropdown";
import MobileNavigationMenu from "../../../MobileNavigationMenu";
import {
  BellWrapper,
  Divider,
  LeftHeaderWrapper,
  MainHeaderWrapper,
  NotificationButton,
  RightHeaderComponent,
  RightHeaderWrapper,
} from "../style";
import {
  COLORS,
} from "../../../../constants/design/colors";
import { Badge } from "../../Badge";
import { NotificationContext } from "../../../../contexts/NotificationContext";
import { useNotifications } from "../../../../hooks/api/notifications/useNotifications";
import useOutsideAlerter from "../../../../hooks/outsideAlerter/useOutsideAlerter";
import useKeydownHandler, {
  KeydownHandlerKeyEnum,
} from "../../../../hooks/useKeydownHandler";
import useWallet from "../../../../hooks/wallet/useWallet";
import { NotificationPanel } from "../../NotificationPanel";
import { isProduction } from "../../../../utils/env";
import { ImpersonateInput } from "../../../ImpersonateInput";

function MobileHeader() {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const { account } = useWallet();
  const { markAsRead, unreadNotifications } = useNotifications();
  const { showPanel, setShowPanel } = useContext(NotificationContext);
  const modalRef = useRef(null);
  const notifButtonRef = useRef(null);

  const onHide = useCallback(() => {
    if (unreadNotifications.length > 0) markAsRead();
    setShowPanel(false);
  }, [markAsRead, setShowPanel, unreadNotifications.length]);
  useOutsideAlerter([modalRef, notifButtonRef], onHide);
  useKeydownHandler(KeydownHandlerKeyEnum.ESC, onHide);

  const notificationsBadgeColor = useMemo(
    () => (unreadNotifications.length > 0 ? [COLORS.blue.one, COLORS.blue.two] : []),
    // }
    [unreadNotifications.length]
  );

  const onTriggerPanel = useCallback(() => {
    if (showPanel && unreadNotifications.length > 0) {
      // Only mark notifications as read when the panel is closed
      markAsRead();
    }

    setShowPanel(!showPanel);
  }, [markAsRead, setShowPanel, showPanel, unreadNotifications.length]);

  return (
    <>
      <MobileNavigationMenu
        open={showMobileNav}
        onClose={() => setShowMobileNav(false)}
      />
      <NotificationPanel modalRef={modalRef} />
      <MainHeaderWrapper>
        <LeftHeaderWrapper>
          <>
            <Link to={PageEndpointEnum.TRADE}>
              <MobileLogo height={32} width={32} />
            </Link>
            <Divider
              horizontalMargin={`${SPACING.two}px`}
            />
          </>
        </LeftHeaderWrapper>
        <RightHeaderWrapper>
          <ImpersonateInput />
          {account && !isProduction() && (
            <NotificationButton
              onClick={() => onTriggerPanel()}
              ref={notifButtonRef}
            >
              <BellWrapper color={notificationsBadgeColor[0]}>
                <Bell />
                {unreadNotifications.length > 0 && (
                  <Badge
                    color={notificationsBadgeColor[0]}
                    backgroundColor={notificationsBadgeColor[1]}
                  >
                    {unreadNotifications.length}
                  </Badge>
                )}
              </BellWrapper>
            </NotificationButton>
          )}
          <Divider
            height="0px"
            horizontalMargin={`${SPACING.three}px`}
          />
          <RightHeaderComponent>
            <AccountDropdown />
          </RightHeaderComponent>
        </RightHeaderWrapper>
      </MainHeaderWrapper>
    </>
  );
}

export default MobileHeader;
