/* eslint-disable no-console */
import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useForm, useWatch } from "react-hook-form";
import { Alert } from "react-bootstrap";
import {
  OptionTypeWrapper,
  InfoRow,
  Title,
  Value,
  OrderInfoWrapper,
  ChartContainer,
  GreekInfoRow,
  GreekInfoWrapper,
  GreekIconWrapper,
  GreekValuesWrapper,
  PlaceholderContent,
  SegmentControlWrapper,
} from "./style";
import {
  BACKGROUND_COLORS,
  COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { TradeInstrumentContext } from "../../contexts/TradeInstrumentContext";
import useAssetPriceData from "../../hooks/api/prices/useAssetPriceData";
import { IOrderInfo } from "../../interfaces/OrderInfo";
import { OptionResponse } from "../../interfaces/PreloadedStrikes";
import { calculateProfitPerOption } from "../../utils/instruments";
import ProfitChart from "../ProfitChart/ProfitChart";
import { ExpectedExpiryInput } from "../TradeForm/OptionsTradeForm/form";
import { FONT_SIZE } from "../../constants/design/fontSize";
import SegmentedControl from "../shared/SegmentedControl";
import useScreenSize from "../../hooks/screenSize/useScreenSize";
import { ModalButtonV2 } from "../Buttons/styles";
import { PageType } from "../TradeForm/OptionsTradeForm";

export enum PayoffType {
  Payoff = "Payoff",
  Greeks = "Greeks",
}

interface IPayoffContentProps {
  premium: number;
  numContracts?: number;
  setPage: (pageType: PageType) => void;
  additionalHeight: number;
}

function PayoffContent({
  premium,
  numContracts,
  setPage,
  additionalHeight,
}: PropsWithChildren<IPayoffContentProps>) {
  const {
    register,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const { selectedValues } = useContext(TradeInstrumentContext);
  const { isMediumScreen } = useScreenSize();
  const { useAssetPrice } = useAssetPriceData();
  const { price: currentPrice } = useAssetPrice({
    asset: selectedValues.asset,
  });
  const [payoffType, setPayoffType] = useState<PayoffType>(PayoffType.Payoff);
  const [assetPrice, setAssetPrice] = useState<number>(currentPrice);

  const expiryPrice = useWatch({ control, name: "expiryPrice" });

  // set to custom input expiry price whenever it changes
  useEffect(() => {
    setAssetPrice(expiryPrice);
  }, [expiryPrice]);

  // set back spot price whenever api values change
  useEffect(() => {
    setAssetPrice(currentPrice);
  }, [currentPrice, selectedValues]);

  const chartContainerHeight = useMemo(
    () => additionalHeight
      + (isMediumScreen
        ? COMPONENTS.profitChartMobileHeight
        : COMPONENTS.profitChartHeight),
    [additionalHeight, isMediumScreen]
  );
  const expectedPayoff = useMemo(
    () => (numContracts
      ? calculateProfitPerOption(
        selectedValues.optionType === OptionResponse.Put,
        Number(assetPrice),
        Number(selectedValues.strike),
        Number(premium)
      ) * numContracts
      : 0),
    [
      assetPrice,
      numContracts,
      premium,
      selectedValues.optionType,
      selectedValues.strike,
    ]
  );

  const payoffInfo = useMemo<IOrderInfo[]>(
    () => [
      {
        title: "Expected Payoff at Maturity",
        value: expectedPayoff.toFixed(2),
      },
    ],
    [expectedPayoff]
  );

  const greekInfo = useMemo<IOrderInfo[]>(() => {
    const { extraValues } = selectedValues;
    const delta = extraValues?.delta || "-";
    const theta = extraValues?.theta || "-";
    const vega = extraValues?.vega || "-";

    return [
      {
        title: "Delta",
        icon: "Δ",
        value: delta,
      },
      {
        title: "Theta",
        icon: "θ",
        value: theta,
      },
      {
        title: "Vega",
        icon: "ν",
        value: vega,
      },
    ];
  }, [selectedValues]);

  const onChartHover = useCallback(
    (price: number | undefined) => {
      setAssetPrice(price || currentPrice);
    },
    [currentPrice]
  );

  const memoizedProfitChart = useMemo(
    () => (
      <ProfitChart
        key={
          `${selectedValues.strike}-${selectedValues.asset}-${assetPrice}-${premium}`
          || ""
        }
        price={assetPrice || currentPrice}
        strike={Number(selectedValues.strike)}
        premium={Number(premium)}
        isPut={selectedValues.optionType === OptionResponse.Put}
        asset={selectedValues.asset}
        onHover={onChartHover}
      />
    ),
    [
      assetPrice,
      currentPrice,
      onChartHover,
      premium,
      selectedValues.asset,
      selectedValues.optionType,
      selectedValues.strike,
    ]
  );
  return (
    <>
      {payoffType === PayoffType.Payoff ? (
        <>
          <ExpectedExpiryInput
            register={register("expiryPrice", {
              required: true,
              validate: {},
            })}
            errors={errors}
          />
          <ChartContainer height={chartContainerHeight}>
            {selectedValues.strike ? (
              memoizedProfitChart
            ) : (
              <PlaceholderContent>
                <span>Please select a strike price</span>
              </PlaceholderContent>
            )}
          </ChartContainer>
          <OrderInfoWrapper>
            {payoffInfo.map((info) => (
              <InfoRow
                key={`${info.title}-${info.value}}`}
                warningOrError={info.warningOrError}
              >
                <Title>
                  {info.title}
                  {Boolean(info.warningOrError && info.showErrorIcon) && (
                    <Alert
                      style={{
                        stroke:
                          info.warningOrError === "warning"
                            ? COLORS.orange.one
                            : COLORS.negative.one,
                        marginLeft: `${SPACING.one}px`,
                        marginTop: `-${SPACING.one / 2}px`,
                      }}
                    />
                  )}
                </Title>
                <Value>{info.value}</Value>
              </InfoRow>
            ))}
          </OrderInfoWrapper>
          <OptionTypeWrapper />
        </>
      ) : (
        <GreekInfoWrapper>
          {greekInfo.map((info) => (
            <GreekInfoRow key={`${info.title}-${info.value}}`}>
              <GreekIconWrapper>{info.icon}</GreekIconWrapper>
              <GreekValuesWrapper>
                <Title>{info.title}</Title>
                <Value>{info.value}</Value>
              </GreekValuesWrapper>
            </GreekInfoRow>
          ))}
        </GreekInfoWrapper>
      )}
      <SegmentControlWrapper>
        {isMediumScreen && (
          <ModalButtonV2
            type={"button"}
            onClick={() => setPage(PageType.Trade)}
          >
            <Close />
          </ModalButtonV2>
        )}
        <SegmentedControl
          segments={[
            {
              value: PayoffType.Payoff,
              display: PayoffType.Payoff,
              textColor:
                payoffType === PayoffType.Payoff
                  ? TEXT_COLORS.one
                  : TEXT_COLORS.three,
            },
            {
              value: PayoffType.Greeks,
              display: PayoffType.Greeks,
              textColor:
                payoffType === PayoffType.Greeks
                  ? TEXT_COLORS.one
                  : TEXT_COLORS.three,
            },
          ]}
          value={payoffType}
          onSelect={(value) => {
            setPayoffType(value as PayoffType);
          }}
          config={{
            theme: "outline",
            color:
              payoffType === PayoffType.Payoff
                ? TEXT_COLORS.one
                : TEXT_COLORS.two,
            widthType: "fullWidth",
            backgroundColor: BACKGROUND_COLORS.eight,
            activeBackgroundColor:
              payoffType === PayoffType.Payoff
                ? BACKGROUND_COLORS.four
                : BACKGROUND_COLORS.fifteen,
            borderRadius: "10px",
            button: {
              height: 48,
              fontSize: FONT_SIZE.two,
            },
          }}
        />
      </SegmentControlWrapper>
    </>
  );
}

export default PayoffContent;
