import moment, { Moment } from "moment";
import { PERMIT_DURATION } from "../endpoints/endpoints";

const convertToUnix = (momentValue: Moment) => momentValue.hours(8).minutes(0).seconds(0).unix() * 10 ** 9;

export const permitDuration = Number(PERMIT_DURATION);

// gets the timestamps of the fridays from 1W, 2W, 1M from now
export const getFridayTimestamps = () => {
  const now = moment().utc();
  let nextFridayMoment = now;

  switch (now.isoWeekday()) {
    case 1:
    case 2:
    case 3:
      nextFridayMoment = moment().utc().day(5);
      break;
    case 4:
    case 5:
    case 6:
    case 7:
      nextFridayMoment = moment().utc().day(5).add(1, "week");
      break;
    default:
  }

  const nextFriday = convertToUnix(nextFridayMoment);
  const fridayFromTwoWeeks = convertToUnix(nextFridayMoment.add(1, "weeks"));
  const fridayFromOneMonth = convertToUnix(nextFridayMoment.add(3, "weeks"));

  return [nextFriday, fridayFromTwoWeeks, fridayFromOneMonth];
};
