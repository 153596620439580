import { useCallback, useMemo, useState } from "react";
import currency from "currency.js";
import { ReactComponent as Close } from "../../../../../assets/svg/close.svg";
import {
  InfoRow,
  HeaderContainer,
  AssetHeaderWrapper,
  InfoDetail,
  SmartContractRowWrapper,
  Form,
  SegmentedControlWrapper,
  RowsWrapper,
} from "../../style";
import { IOrder } from "../../../../../interfaces/Orders";
import { LargeModalButtonV2 } from "../../../../Buttons/styles";
import AssetWrapper from "../../../../shared/AssetWrapper";
import { fullExpiryTime } from "../../../../../utils/date";
import {
  BACKGROUND_COLORS,
  COLORS,
  TEXT_COLORS,
} from "../../../../../constants/design/colors";
import { shortenAddress } from "../../../../../utils/strings";
import { ChainIdEnum } from "../../../../../enums/chain";
import { CHAIN_EXPLORER_URLS } from "../../../../../utils/chain";
import { ReactComponent as ArrowOut } from "../../../../../assets/svg/arrow-up-right.svg";
import addresses from "../../../../../constants/addresses/addresses.json";
import PerformanceContent from "../../PerformanceContent";
import { IInfoRowCol } from "../../../../../interfaces/InfoRow";
import { getAssetIndicPrecision } from "../../../../../utils/assets";
import SegmentedControl from "../../../../shared/SegmentedControl";
import { FONT_SIZE } from "../../../../../constants/design/fontSize";
import { COMPONENTS } from "../../../../../constants/design/spacing";
import { ModalContent } from "../../ModalContentSegmentControl";

interface IMMAccountPositionModalProps {
  order: IOrder;
  setShowModal: (show: boolean) => void;
}

function MMAccountPositionModal({
  order,
  setShowModal,
}: IMMAccountPositionModalProps) {
  const {
    contracts,
    optionType,
    performance,
    expiry,
    strike,
    asset,
    totalPremiumPaid,
    offerPrice,
    markPrice,
    imAmount,
    marginPosted,
    greeks,
  } = order;

  const [modalContent, setModalContent] = useState<ModalContent>(
    ModalContent.Details
  );

  const contractAddress = addresses.mainnet.contracts.otcWrapper;

  const onHide = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const openBlockExplorer = useCallback(() => {
    window.open(
      `${
        CHAIN_EXPLORER_URLS[ChainIdEnum.ETH_MAINNET]
      }/address/${contractAddress}`
    );
  }, [contractAddress]);

  const infoRows: IInfoRowCol[] = useMemo(() => {
    const rows = [];
    rows.push({
      title: "Option Type",
      detail: `European ${optionType.replace(/^\w/, (c) => c.toUpperCase())}`,
    });
    rows.push({
      title: "Expiry",
      detail: `${fullExpiryTime(expiry)}`,
    });
    rows.push({
      title: "Strike",
      detail: `${strike}`,
    });
    rows.push({
      title: "Contracts",
      detail: `${contracts}`,
    });
    rows.push({
      title: "Total Premiums",
      detail: `${currency(totalPremiumPaid).format()}`,
    });
    rows.push({
      title: "Offer Price",
      detail: offerPrice
        ? `${currency(parseFloat(offerPrice), {
          precision: getAssetIndicPrecision(asset),
        }).format()}`
        : "-",
    });
    rows.push({
      title: "Mark Price",
      detail: markPrice
        ? `${currency(parseFloat(markPrice), {
          precision: getAssetIndicPrecision(asset),
        }).format()}`
        : "-",
      color: COLORS.positive.one,
    });
    rows.push({
      title: "Initial Margin",
      detail: imAmount ? `${currency(imAmount).format()}` : "-",
    });
    rows.push({
      title: "Margin Posted",
      detail: marginPosted ? `${currency(marginPosted).format()}` : "-",
    });
    rows.push({
      title: "Smart Contract",
      detail: (
        <SmartContractRowWrapper onClick={openBlockExplorer}>
          <span>{shortenAddress(contractAddress)}</span>
          <ArrowOut />
        </SmartContractRowWrapper>
      ),
    });
    return rows;
  }, [optionType, expiry, strike, contracts, totalPremiumPaid, offerPrice, asset, markPrice, imAmount, marginPosted, openBlockExplorer, contractAddress]);

  const greeksRows: IInfoRowCol[] = useMemo(() => {
    const rows = [];
    if (greeks) {
      rows.push({
        title: "Gamma",
        detail: `${greeks.gamma}`,
      });
      rows.push({
        title: "Theta",
        detail: `${greeks.theta}`,
      });
      rows.push({
        title: "Vega",
        detail: `${greeks.vega}`,
      });
      rows.push({
        title: "Delta",
        detail: `${greeks.delta}`,
      });
    }
    return rows;
  }, [greeks]);

  const headerContent = useMemo(
    () => (
      <>
        <AssetHeaderWrapper>
          <AssetWrapper asset={asset} />
        </AssetHeaderWrapper>
        <LargeModalButtonV2 type={"button"} onClick={onHide}>
          <Close />
        </LargeModalButtonV2>
      </>
    ),
    [onHide, asset]
  );

  const displayedRows = modalContent === ModalContent.Details ? infoRows : greeksRows;
  return (
    <Form onSubmit={() => {}}>
      <HeaderContainer>{headerContent}</HeaderContainer>
      {performance && (
        <PerformanceContent
          title="Unrealized PnL"
          performance={Number(performance)}
        />
      )}
      {/* ensures height stays constant when switching modal content */}
      <RowsWrapper height={infoRows.length * COMPONENTS.infoRowHeight}>
        {displayedRows.map((infoRow) => (
          <InfoRow key={infoRow.title}>
            <span>{infoRow.title}</span>
            <InfoDetail color={infoRow.color}>{infoRow.detail}</InfoDetail>
          </InfoRow>
        ))}
      </RowsWrapper>
      <SegmentedControlWrapper>
        <SegmentedControl
          segments={[
            {
              value: ModalContent.Details,
              display: ModalContent.Details,
              textColor:
                modalContent === ModalContent.Details
                  ? TEXT_COLORS.one
                  : TEXT_COLORS.three,
            },
            {
              value: ModalContent.Greeks,
              display: ModalContent.Greeks,
              textColor:
                modalContent === ModalContent.Greeks
                  ? TEXT_COLORS.one
                  : TEXT_COLORS.three,
            },
          ]}
          value={modalContent}
          onSelect={(value) => {
            setModalContent(value as ModalContent);
          }}
          config={{
            theme: "outline",
            color:
              modalContent === ModalContent.Details
                ? TEXT_COLORS.one
                : TEXT_COLORS.two,
            widthType: "fullWidth",
            backgroundColor: BACKGROUND_COLORS.eight,
            activeBackgroundColor:
              modalContent === ModalContent.Details
                ? BACKGROUND_COLORS.four
                : BACKGROUND_COLORS.fifteen,
            borderRadius: "10px",
            button: {
              height: 48,
              fontSize: FONT_SIZE.two,
            },
          }}
        />
      </SegmentedControlWrapper>
    </Form>
  );
}

export default MMAccountPositionModal;
