import { PropsWithChildren } from "react";
import { Filter, IFilterProps } from "../OrderFilter";
import Section from "./Section";
import { Header } from "./Table/style";
import { TableContainerWrapper } from "../shared/Table/style";
import { IOrder, IOrderType } from "../../interfaces/Orders";
import OrderRows from "./OrderRows";

export interface IOrdersTableProps<
  FilterType extends string,
  FilterEnum extends Record<string, FilterType>
> extends IFilterProps<FilterType, FilterEnum> {
  orderType: IOrderType;
  filteredOrders: IOrder[];
  loading: boolean;
}

function OrdersTable<
  FilterType extends string,
  FilterEnum extends Record<string, FilterType>
>({
  title,
  orderType,
  filter,
  onSetFilter,
  filterEnum,
  filteredOrders,
  loading
}: PropsWithChildren<IOrdersTableProps<FilterType, FilterEnum>>) {
  return (
    <TableContainerWrapper>
      <Section>
        <Header>
          <Filter
            title={title}
            filter={filter}
            onSetFilter={onSetFilter}
            filterEnum={filterEnum}
          />
        </Header>
        <OrderRows
          orders={filteredOrders}
          orderType={orderType}
          loading={loading}
        />
      </Section>
    </TableContainerWrapper>
  );
}

export default OrdersTable;
