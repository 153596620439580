/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { ethers } from "ethers";
import { useCallback, useContext, useEffect, useState } from "react";
import { IERC20, IERC20__factory } from "../../../codegen";
import addresses from "../../../constants/addresses/addresses.json";
import {
  ContractContext,
  ITokenBalance,
} from "../../../contexts/ContractContext";
import { ChainIdEnum } from "../../../enums/chain";
import { IERC20Info } from "../../../interfaces/ERC20Info";
import { splitSignature } from "../../../utils/signing";
import useWallet from "../../wallet/useWallet";
import { useWeb3Context } from "../../../contexts/Web3Context";

const EIP2612_TYPE = [
  { name: "owner", type: "address" },
  { name: "spender", type: "address" },
  { name: "value", type: "uint256" },
  { name: "nonce", type: "uint256" },
  { name: "deadline", type: "uint256" },
];

const contractAddress = addresses.mainnet.assets.usdc;

export const getUSDC = (provider: any, useSigner = true): IERC20 | undefined => {
  const signerOrProvider = useSigner ? provider?.getSigner() : provider;
  if (signerOrProvider && contractAddress) {
    return IERC20__factory.connect(contractAddress, signerOrProvider);
  }

  return undefined;
};

const decimals = 6;

const useUSDC = (): IERC20Info => {
  const { account, active, provider, chainId } = useWallet();
  const { baseProvider } = useWeb3Context();
  const { tokenBalances, setTokenBalances } = useContext(ContractContext);
  const [contract, setContract] = useState<IERC20>();

  useEffect(() => {
    setContract(getUSDC(provider || baseProvider, active));
  }, [active, baseProvider, chainId, provider]);

  // Retrieve token balances if not available
  useEffect(() => {
    if (account && contract) {
      contract
        .balanceOf(account)
        .then((bal) => setTokenBalances((prev: ITokenBalance) => {
          const newTokenBalances: ITokenBalance = {
            ...prev,
            [contract.address]: bal,
          };
          return newTokenBalances;
        }))
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
  }, [account, contract, setTokenBalances]);

  const showApproveAssetSignature = useCallback(
    async (
      spender: string,
      // Amount of USDC
      amountUSDC: string,
      deadline: number
    ) => {
      if (!account || !contract || !provider || !provider.provider.sendAsync) {
        return undefined;
      }

      const domain = {
        name: "USD Coin",
        version: chainId === ChainIdEnum.ETH_MAINNET ? "2" : "1",
        verifyingContract: contract.address,
        chainId,
      };

      const approveMessage = {
        owner: account,
        spender,
        value: ethers.utils.parseUnits(amountUSDC, decimals).toString(),
        nonce: (await contract.nonces(account)).toNumber(),
        deadline,
      };

      // eslint-disable-next-line no-underscore-dangle
      const approveUSDCSignature = await provider
        .getSigner()
        ._signTypedData(domain, { Permit: EIP2612_TYPE }, approveMessage);

      if (approveUSDCSignature) {
        const splitted = splitSignature(approveUSDCSignature);
        return splitted;
      }
      return undefined;
    },
    [account, chainId, contract, provider]
  );

  return {
    contract,
    decimals,
    userBalance: tokenBalances[contract?.address || ""],
    getAssetApprovalSignature: showApproveAssetSignature,
  };
};

export default useUSDC;
