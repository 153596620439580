import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  MobileNavigatorContent,
  MobileNavigatorWrapper,
  TabLink,
} from "./style";
import { IRoute } from "../../enums/pages";
import useRoutes from "../../hooks/routes/useRoutes";

function MobileNavigator() {
  const { pages, currentTab, currentPage, setCurrentTab } = useRoutes();

  const location = useLocation();

  // if specific route found go to that page
  // else go to the first tab when page changed
  useEffect(() => {
    const findMatch = pages.find((page) => location.pathname === page.redirect);
    setCurrentTab(findMatch ?? (pages[0] as IRoute));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <MobileNavigatorWrapper>
      <AnimatePresence exitBeforeEnter>
        <MobileNavigatorContent>
          {pages.map((page, i) => (
            <motion.div
              key={page.title}
              transition={{
                delay: i * 0.2, // reverse order
                duration: 0.25,
                ease: "easeInOut",
              }}
              initial={{
                transform: "translateX(-8px)",
                opacity: 0,
              }}
              animate={{
                transform: "translateX(0px)",
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
            >
              <TabLink
                $isActive={currentTab?.title === page.title}
                key={page.title}
                to={page.redirect}
                onClick={() => setCurrentTab(page as IRoute)}
              >
                {page.title}
              </TabLink>
            </motion.div>
          ))}
        </MobileNavigatorContent>
      </AnimatePresence>
    </MobileNavigatorWrapper>
  );
}

export default MobileNavigator;
