import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React as useEVMWallet } from "@web3-react/core";
import { Connector } from "@web3-react/types";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ChainEnum, ChainIdEnum } from "../../enums/chain";
import { ChainCodeErrorEnum } from "../../enums/rpcErrorCodes";
import { CHAIN_EXPLORER_URLS } from "../../utils/chain";
import {
  allConnectors,
  supportedChainId,
  walletToConnector,
  WalletEnum,
  LAST_CONNECTED_WALLET_LOCAL_STORAGE_KEY,
} from "../../utils/wallet/connectors";
import { ImpersonateContext } from "../../contexts/ImpersonateContext";

interface IWallet {
  chainId: number | undefined;
  active: boolean;
  activate: (wallet: WalletEnum) => Promise<void>;
  deactivate: () => Promise<void>;
  account: string | null | undefined;
  connectingWallet: WalletEnum | undefined;
  connectedWallet: WalletEnum | undefined;
  provider: Web3Provider | undefined;
  connector?: Connector;
  isWrongNetwork: boolean;
  supportedChainId: ChainIdEnum;
  setChainToSwitch: Dispatch<SetStateAction<ChainIdEnum | null>>;
  explorerURL: string;
}

type ChainToId = {
  [key in ChainEnum]: ChainIdEnum;
};
export const CHAINS_TO_ID: ChainToId = {
  [ChainEnum.NotSelected]: ChainIdEnum.NONE,
  [ChainEnum.Ethereum]: ChainIdEnum.ETH_MAINNET,
};

type IdToChain = {
  [key in ChainIdEnum]: ChainEnum;
};
export const ID_TO_CHAINS: IdToChain = {
  [ChainIdEnum.NONE]: ChainEnum.NotSelected,
  [ChainIdEnum.ETH_MAINNET]: ChainEnum.Ethereum,
};

export const useWallet = (): IWallet => {
  const [chainToSwitch, setChainToSwitch] = useState<ChainIdEnum | null>(null);
  const [connectingWallet, setConnectingWallet] = useState<WalletEnum>();
  const [connectedWallet, setConnectedWallet] = useState<WalletEnum>();
  const { impersonateAddress } = useContext(ImpersonateContext);
  const {
    account: accountEth,
    connector: connectorEth,
    isActive: isActiveEth,
    chainId: activeChainId,
    provider,
  } = useEVMWallet();

  // This hook checks if there is an EVM chainId to switch to
  // If so, it will prompt switchChains only when a provider is available
  useEffect(() => {
    const onSwitchChains = async (id: ChainIdEnum) => {
      try {
        if (id === -1) {
          await connectorEth.activate();
        } else {
          await connectorEth.activate(id);
        }
      } catch (switchError: any) {
        if (switchError.code === ChainCodeErrorEnum.CANCELLED) {
          window.location.reload();
        }
      }
      setChainToSwitch(null);
    };

    if (chainToSwitch && chainToSwitch !== activeChainId && connectorEth) {
      onSwitchChains(chainToSwitch);
    }
  }, [activeChainId, chainToSwitch, connectorEth]);

  const deactivateEth = useCallback(async () => {
    try {
      // Deactivate all connectors
      const promises = allConnectors.map(async (c) => {
        const connector = c[0];
        await connector.deactivate?.();
        await connector.resetState();
      });
      await Promise.all(promises);
      // Remove last connected wallet from local storage so we don't eagerly connect to anything
      localStorage.removeItem(LAST_CONNECTED_WALLET_LOCAL_STORAGE_KEY);
    } catch (error) {
      console.log("Error deactivating", error);
    }
  }, []);

  const activate = useCallback(
    async (wallet: WalletEnum) => {
      setConnectingWallet(wallet);

      try {
        if (!isActiveEth) {
          // Connect to the selected connector
          const connector = walletToConnector[wallet as WalletEnum]();
          // If is wallet connect, we try to connect eagerly first
          // This is because activate() will do nothing if it
          // already has an active session
          if (connector instanceof WalletConnectV2) {
            // If theres an error here, we ignore and try to activate()
            await connector?.connectEagerly().catch(() => {});
          }
          await connector?.activate(supportedChainId);
          setConnectedWallet(wallet as WalletEnum);
          // Update local storage so we know which wallet to connect to when we connect eagerly
          localStorage.setItem(
            LAST_CONNECTED_WALLET_LOCAL_STORAGE_KEY,
            String(wallet)
          );
        }
        setConnectingWallet(undefined);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    [isActiveEth]
  );

  return {
    chainId: activeChainId,
    active: isActiveEth,
    activate,
    deactivate: deactivateEth,
    account: impersonateAddress || accountEth,
    connectingWallet,
    connectedWallet,
    provider: isActiveEth ? provider : undefined,
    connector: connectorEth,
    // If true, user is connected to an unsupported chain
    // Only enforces wrong network if not in development
    isWrongNetwork: activeChainId !== supportedChainId,
    supportedChainId,
    setChainToSwitch,
    explorerURL: CHAIN_EXPLORER_URLS[supportedChainId],
  };
};

export default useWallet;
