/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../../assets/logo/logo_text_white.svg";
import { ReactComponent as Bell } from "../../../../assets/svg/bell.svg";
import { ReactComponent as Blog } from "../../../../assets/svg/blog.svg";
import { ReactComponent as Discord } from "../../../../assets/svg/discord.svg";
import { ReactComponent as Github } from "../../../../assets/svg/github.svg";
import { ReactComponent as Twitter } from "../../../../assets/svg/twitter.svg";
import { SPACING } from "../../../../constants/design/spacing";
import { PageEndpointEnum } from "../../../../enums/endpoint";
import AccountDropdown from "../../AccountDropdown";
import useRoutes from "../../../../hooks/routes/useRoutes";
import { PageEnum } from "../../../../enums/pages";
import {
  BellWrapper,
  CommunityRow,
  CommunityRowLabel,
  Divider,
  DropdownWrapper,
  LeftHeaderWrapper,
  LinkText,
  MainHeaderWrapper,
  MiddleHeaderWrapper,
  NotificationButton,
  RightHeaderComponent,
  RightHeaderWrapper,
} from "../style";
import Dropdown from "../../Dropdown";
import { LINKS } from "../../../../constants/links";
import { Chevron } from "../../Chevron/style";
import { ReactComponent as Arrow } from "../../../../assets/svg/arrow-up-right.svg";
import {
  BORDER_COLORS,
  COLORS,
  TEXT_COLORS,
} from "../../../../constants/design/colors";
import { Badge } from "../../Badge";
import { NotificationContext } from "../../../../contexts/NotificationContext";
import { useNotifications } from "../../../../hooks/api/notifications/useNotifications";
import useOutsideAlerter from "../../../../hooks/outsideAlerter/useOutsideAlerter";
import useKeydownHandler, {
  KeydownHandlerKeyEnum,
} from "../../../../hooks/useKeydownHandler";
import useWallet from "../../../../hooks/wallet/useWallet";
import { NotificationPanel } from "../../NotificationPanel";
import ExternalLinkText from "../../../ExternalLinkText";
import { isProduction } from "../../../../utils/env";
import { ImpersonateInput } from "../../../ImpersonateInput";

interface ILinkInfo {
  to: PageEndpointEnum;
  isActive: boolean;
  title: string;
}
function DesktopHeader() {
  const [showCommunityDropdown, setShowCommunityDropdown] = useState(false);
  const { currentPage, isMMPage } = useRoutes();
  const { account } = useWallet();
  const { markAsRead, unreadNotifications } = useNotifications();
  const { showPanel, setShowPanel } = useContext(NotificationContext);
  const modalRef = useRef(null);
  const notifButtonRef = useRef(null);

  const onHide = useCallback(() => {
    if (unreadNotifications.length > 0) markAsRead();
    setShowPanel(false);
  }, [markAsRead, setShowPanel, unreadNotifications.length]);
  useOutsideAlerter([modalRef, notifButtonRef], onHide);
  useKeydownHandler(KeydownHandlerKeyEnum.ESC, onHide);

  const notificationsBadgeColor = useMemo(
    () => (unreadNotifications.length > 0 ? [COLORS.blue.one, COLORS.blue.two] : []),
    // }
    [unreadNotifications.length]
  );

  const onTriggerPanel = useCallback(() => {
    if (showPanel && unreadNotifications.length > 0) {
      // Only mark notifications as read when the panel is closed
      markAsRead();
    }

    setShowPanel(!showPanel);
  }, [markAsRead, setShowPanel, showPanel, unreadNotifications.length]);

  const links: ILinkInfo[] = useMemo(
    () => (isMMPage
      ? [
        {
          to: PageEndpointEnum.REQUESTS,
          isActive: currentPage === PageEnum.Requests,
          title: "Requests",
        },
        {
          to: PageEndpointEnum.MMPORTFOLIO,
          isActive: currentPage === PageEnum.MMPortfolio,
          title: "Portfolio",
        },
      ]
      : [
        {
          to: PageEndpointEnum.TRADE,
          isActive: currentPage === PageEnum.Trade,
          title: "Trade",
        },
        {
          to: PageEndpointEnum.PORTFOLIO,
          isActive: currentPage === PageEnum.Portfolio,
          title: "Portfolio",
        },
      ]),
    [currentPage, isMMPage]
  );

  return (
    <>
      <NotificationPanel modalRef={modalRef} />
      <MainHeaderWrapper>
        <LeftHeaderWrapper>
          <>
            <Link to={PageEndpointEnum.TRADE}>
              <Logo height={20} width={72} />
            </Link>
            <Divider horizontalMargin={"0px"} />
          </>
        </LeftHeaderWrapper>
        <MiddleHeaderWrapper>
          {links.map((linkInfo) => (
            <Link key={linkInfo.title} to={linkInfo.to}>
              <LinkText isActive={linkInfo.isActive}>{linkInfo.title}</LinkText>
            </Link>
          ))}
          <DropdownWrapper>
            <Dropdown
              onToggle={setShowCommunityDropdown}
              toggleStyles={{
                borderRadius: 100,
                paddingLeft: SPACING.twoHalf,
                paddingRight: SPACING.twoHalf,
                backgroundColor: "transparent",
              }}
              dropdownMenuContainerStyles={{
                overflow: "hidden",
                borderRadius: "8px",
                border: `1px solid ${BORDER_COLORS.one}`,
              }}
              title={
                <LinkText isActive={showCommunityDropdown}>
                  Community
                  <Chevron
                    style={{
                      marginLeft: SPACING.one,
                    }}
                    direction={showCommunityDropdown ? "up" : "down"}
                  />
                </LinkText>
              }
              items={[
                {
                  label: (
                    <CommunityRow href={LINKS.twitter}>
                      <Twitter />
                      <CommunityRowLabel>
                        Twitter
                        <Arrow />
                      </CommunityRowLabel>
                    </CommunityRow>
                  ),
                },
                {
                  label: (
                    <CommunityRow svgIsFill href={LINKS.discord}>
                      <Discord />
                      <CommunityRowLabel>
                        Discord
                        <Arrow />
                      </CommunityRowLabel>
                    </CommunityRow>
                  ),
                },
                {
                  label: (
                    <CommunityRow href={LINKS.github}>
                      <Github />
                      <CommunityRowLabel>
                        Github
                        <Arrow />
                      </CommunityRowLabel>
                    </CommunityRow>
                  ),
                },
                {
                  label: (
                    <CommunityRow svgIsFill href={LINKS.blog}>
                      <Blog />
                      <CommunityRowLabel>
                        Blog
                        <Arrow />
                      </CommunityRowLabel>
                    </CommunityRow>
                  ),
                },
              ]}
            />
          </DropdownWrapper>
          <ExternalLinkText
            color={TEXT_COLORS.three}
            hoverColor={TEXT_COLORS.one}
            href={LINKS.exchange}
            target="_blank"
            rel="noreferrer"
          >
            Exchange
          </ExternalLinkText>
        </MiddleHeaderWrapper>
        <RightHeaderWrapper>
          <ImpersonateInput />
          {account && !isProduction() && (
            <NotificationButton
              onClick={() => onTriggerPanel()}
              ref={notifButtonRef}
            >
              <BellWrapper color={notificationsBadgeColor[0]}>
                <Bell />
                {unreadNotifications.length > 0 && (
                  <Badge
                    color={notificationsBadgeColor[0]}
                    backgroundColor={notificationsBadgeColor[1]}
                  >
                    {unreadNotifications.length}
                  </Badge>
                )}
              </BellWrapper>
            </NotificationButton>
          )}
          <Divider horizontalMargin={`${SPACING.three}px`} />
          <RightHeaderComponent>
            <AccountDropdown />
          </RightHeaderComponent>
        </RightHeaderWrapper>
      </MainHeaderWrapper>
    </>
  );
}

export default DesktopHeader;
