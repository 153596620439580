import { Fragment } from "react";
import { BaseModal } from "../../BaseModal";
import { AboutModalContainer } from "./style";

interface IAboutAevoModalProps {
  show: boolean;
  onHide: () => void;
}

const lps = [
  {
    name: "GSR",
    link: "https://www.gsr.io/",
  },
  {
    name: "Orbit Markets",
    link: "https://www.orbitmarkets.io/",
  },
  {
    name: "Galaxy",
    link: "https://www.galaxy.com/",
  },
  {
    name: "Genesis",
    link: "https://genesistrading.com/",
  },
];

const vcs = [
  {
    name: "Paradigm",
    link: "https://www.paradigm.xyz/",
  },
  {
    name: "Dragonfly Capital",
    link: "https://www.dragonfly.xyz/",
  },
  {
    name: "Ethereal Ventures",
    link: "http://www.etherealventures.com/",
  },
  {
    name: "Coinbase Ventures",
    link: "https://www.coinbase.com/ventures",
  },
  {
    name: "Nascent",
    link: "https://www.nascent.xyz/",
  },
  {
    name: "Robot Ventures",
    link: "https://robvc.com/",
  },
  {
    name: "Scalar Capital",
    link: "https://scalar.capital/",
  },
  {
    name: "Alliance",
    link: "https://alliance.xyz/",
  },
];

function AboutAevoModal({ show, onHide }: IAboutAevoModalProps) {
  return (
    <BaseModal show={show} onHide={onHide} title="About Aevo OTC">
      <AboutModalContainer>
        <p>
          Aevo OTC is the world&apos;s first venue to offer OTC options,
          settled-on chain, with a focus on altcoin options. The exchange runs
          on Ethereum Mainnet. Aevo OTC operates using RFQs with on-chain
          settlements and dynamic margin requirements, enforced on-chain.{" "}
          {lps.map((vc, idx) => (
            <Fragment key={vc.name}>
              <a target="_blank" rel="noreferrer" href={vc.link}>
                {vc.name}
              </a>
              {idx !== vcs.length - 1 ? ", " : "."}
            </Fragment>
          ))}{" "}
          are amongst the institutional grade liquidity providers quoting on the
          exchange.
        </p>
        <p>
          Aevo is backed by{" "}
          {vcs.map((vc, idx) => (
            <Fragment key={vc.name}>
              <a target="_blank" rel="noreferrer" href={vc.link}>
                {vc.name}
              </a>
              {idx !== vcs.length - 1 ? ", " : "."}
            </Fragment>
          ))}
        </p>
      </AboutModalContainer>
    </BaseModal>
  );
}

export default AboutAevoModal;
