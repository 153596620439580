import { Assets } from "../../utils/assets";

export const COINGECKO_CURRENCIES: { [key in Assets]: string | undefined } = {
  ETH: "ethereum",
  BTC: "wrapped-bitcoin",
  USDC: "usd-coin",
  MATIC: "matic-network",
  BNB: "binancecoin",
  DOGE: "dogecoin",
  LDO: "lido-dao",
  APT: "aptos",
  SOL: "solana",
  OP: "optimism",
  ARB: "arbitrum",
  SUI: "sui",
  // PEPE: "pepe",
  LTC: "litecoin",
  // BLUR: "blur",
  // CRV: "curve-dao-token",
  // APE: "apecoin",
  // UNI: "uniswap"
  // XRP: "ripple",
  AVAX: "avalanche-2",
  // MKR: "maker",
};
