import React, { ReactElement, useState } from "react";
import { ChainEnum } from "../../enums/chain";

interface IChainContextType {
  chain: ChainEnum;
  setChain: (chain: ChainEnum) => void;
}

interface IChainContextProviderProps {
  children: ReactElement;
}

export const ChainContext = React.createContext<IChainContextType>({
  chain: ChainEnum.NotSelected,
  setChain: () => {},
});

export function ChainContextProvider({ children }: IChainContextProviderProps) {
  const [chain, setChain] = useState(ChainEnum.NotSelected);
  return <ChainContext.Provider value={{ chain, setChain }}>{children}</ChainContext.Provider>;
}
