import { useCallback, useEffect, useState } from "react";
import useSWR from "swr";
import axios from "axios";
import { Assets, AssetsList } from "../../../utils/assets";
import {
  AssetsPriceData,
  defaultAssetsPriceData,
} from "../../../contexts/PricesContext";
import { COINGECKO_CURRENCIES } from "../../../constants/currencies/coingeckoCurrencies";

interface ISimplePriceAPI {
  [key: string]: {
    current_price: number;
    price_change_24h: number;
  };
}

const COINGECKO_AWS_BASE_URL
  = process.env.REACT_APP_COINGECKO_AWS_BASE_URL || "";

const useFetchAssetsPrice = () => {
  const [data, setData] = useState<AssetsPriceData>(defaultAssetsPriceData);
  const apiURL = `${COINGECKO_AWS_BASE_URL}latestPrices`;
  const fetcher = (url: string) => axios.get(url).then((res) => res.data);
  const { data: latestPrices, error } = useSWR<ISimplePriceAPI>(
    apiURL,
    fetcher,
    {
      refreshInterval: 60000, // Refresh data every 120 seconds
    }
  );

  const fetchAssetsPrices = useCallback(async () => {
    if (!latestPrices || error) {
      // API call hasn't completed yet or error, return early
      return;
    }
    const assetsBarUSDC = [...AssetsList].filter((asset) => asset !== "USDC");

    // Default USDC price
    setData((prev) => ({
      ...prev,
      USDC: {
        loading: false,
        percentChange: 0,
        latestPrice: 1,
      },
    }));

    assetsBarUSDC.forEach((asset) => {
      const coinId = COINGECKO_CURRENCIES[asset];
      if (coinId) {
        setData((prev) => ({
          ...prev,
          [asset]: {
            loading: false,
            latestPrice: coinId ? latestPrices[coinId].current_price : 0,
            percentChange: coinId
              ? (latestPrices[coinId].price_change_24h
                  / latestPrices[coinId].current_price)
                * 100
              : 0,
          },
        }));
      }
    });
  }, [error, latestPrices]);

  const getAssetPrice = async (asset: Assets) => {
    try {
      const coinId = COINGECKO_CURRENCIES[asset];
      if (coinId) {
        const COINGECKO_BASE_URL = "https://api.coingecko.com/api/v3";
        const getAssetPriceApiURL = `${COINGECKO_BASE_URL}/simple/price?ids=${coinId}&vs_currencies=usd`;
        const response = await axios.get(getAssetPriceApiURL);
        const { data: responseData } = response;
        if (responseData[coinId]) {
          return responseData[coinId].usd;
        }
      }
      return undefined;
    } catch (e) {
      throw Error();
    }
  };
  useEffect(() => {
    fetchAssetsPrices();
  }, [fetchAssetsPrices]);

  return { data, getAssetPrice };
};

export default useFetchAssetsPrice;
