import { BigNumber } from "ethers";
import { IOrder } from "../../interfaces/Orders";
import { INotification } from "../../interfaces/Notification";

export const placeOrderGasPrice = BigNumber.from("400000");
export const placeholderAccountPositions: IOrder[] = [
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 1,
    optionType: "call",
    asset: "ETH",
    expiry: "1682208000",
    strike: "1600",
    contracts: "30",
    price: "100",
    markPrice: "88",
    performance: "-2.5",
    roi: "-0.16",
    totalPremiumPaid: "3600",
    greeks: {
      delta: "1",
      gamma: "1",
      theta: "1",
      vega: "1"
    }
  },
  {
    instrumentName: "SOL - 30 Jun 23 - 32,000 - P",
    orderId: 2,
    optionType: "put",
    asset: "SOL",
    expiry: "1682208000",
    strike: "20",
    contracts: "30",
    price: "100",
    markPrice: "88",
    performance: "2.5",
    roi: "0.16",
    totalPremiumPaid: "880",
    greeks: {
      delta: "1",
      gamma: "1",
      theta: "1",
      vega: "1"
    }
  },
];

export const placeholderPendingOrders = (): IOrder[] => [
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 2,
    optionType: "call",
    asset: "ETH",
    expiry: "1682208000",
    strike: "1600",
    contracts: "30",
    price: "100",
    markPrice: "88",
    totalPremiumPaid: "3600",
    orderIdToUnwind: 1,
  },
  {
    instrumentName: "SOL - 30 Jun 23 - 32,000 - P",
    orderId: 2,
    optionType: "put",
    asset: "SOL",
    expiry: "1682208000",
    strike: "20",
    contracts: "30",
    price: "100",
    markPrice: "88",
    totalPremiumPaid: "880",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 1,
    optionType: "call",
    asset: "ETH",
    expiry: "1682208000",
    strike: "1600",
    contracts: "30",
    price: "100",
    markPrice: "88",
    totalPremiumPaid: "3600",
  },
  {
    instrumentName: "SOL - 30 Jun 23 - 32,000 - P",
    orderId: 2,
    optionType: "put",
    asset: "SOL",
    expiry: "1682208000",
    strike: "20",
    contracts: "30",
    price: "100",
    markPrice: "88",
    totalPremiumPaid: "880",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 1,
    optionType: "call",
    asset: "ETH",
    expiry: "1682208000",
    strike: "1600",
    contracts: "30",
    price: "100",
    markPrice: "88",
    totalPremiumPaid: "3600",
  },
  {
    instrumentName: "SOL - 30 Jun 23 - 32,000 - P",
    orderId: 2,
    optionType: "put",
    asset: "SOL",
    expiry: "1682208000",
    strike: "20",
    contracts: "30",
    price: "100",
    markPrice: "88",
    totalPremiumPaid: "880",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 1,
    optionType: "call",
    asset: "ETH",
    expiry: "1682208000",
    strike: "1600",
    contracts: "30",
    price: "100",
    markPrice: "88",
    totalPremiumPaid: "3600",
  },
  {
    instrumentName: "SOL - 30 Jun 23 - 32,000 - P",
    orderId: 2,
    optionType: "put",
    asset: "SOL",
    expiry: "1682208000",
    strike: "20",
    contracts: "30",
    price: "100",
    markPrice: "88",
    totalPremiumPaid: "880",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 1,
    optionType: "call",
    asset: "ETH",
    expiry: "1682208000",
    strike: "1600",
    contracts: "30",
    price: "100",
    markPrice: "88",
    totalPremiumPaid: "3600",
  },
  {
    instrumentName: "SOL - 30 Jun 23 - 32,000 - P",
    orderId: 2,
    optionType: "put",
    asset: "SOL",
    expiry: "1682208000",
    strike: "20",
    contracts: "30",
    price: "100",
    markPrice: "88",
    totalPremiumPaid: "880",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 1,
    optionType: "call",
    asset: "ETH",
    expiry: "1682208000",
    strike: "1600",
    contracts: "30",
    price: "100",
    markPrice: "88",
    totalPremiumPaid: "3600",
  },
  {
    instrumentName: "SOL - 30 Jun 23 - 32,000 - P",
    orderId: 2,
    optionType: "put",
    asset: "SOL",
    expiry: "1682208000",
    strike: "20",
    contracts: "30",
    price: "100",
    markPrice: "88",
    totalPremiumPaid: "880",
  },
];

export const placeholderMMPositions: IOrder[] = [
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 44,
    optionType: "call",
    asset: "ETH",
    expiry: "1682208000",
    strike: "1600",
    contracts: "30",
    price: "100",
    markPrice: "88",
    imAmount: "88",
    mmUtilization: "88",
    marginPosted: "88",
    marginRequirement: "88",
    withdrawableCollateral: "88",
    totalPremiumPaid: "3600",
    offerPrice: "120",
    performance: "68",
    roi: "0.16",
  },
  {
    instrumentName: "DOGE - 30 Jun 23 - 32,000 - C",
    orderId: 45,
    optionType: "call",
    asset: "DOGE",
    expiry: "1682208000",
    strike: "1600",
    contracts: "30",
    price: "100",
    markPrice: "88",
    imAmount: "88",
    mmUtilization: "88",
    marginPosted: "88",
    marginRequirement: "88",
    withdrawableCollateral: "88",
    totalPremiumPaid: "3600",
    offerPrice: "120",
    performance: "-68",
    roi: "-0.16",
  },
];

export const placeholderLiveRequests: IOrder[] = [
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    totalPremiumPaid: "35",
    price: "25",
    contracts: "30",
    pendingFilled: true,
  },
];

export const placeholderUnmatchedRequests: IOrder[] = [
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    totalPremiumPaid: "35",
    price: "25",
    contracts: "30",
    reason: "LOSING",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    totalPremiumPaid: "35",
    price: "25",
    contracts: "30",
    reason: "NO_OFFER",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    totalPremiumPaid: "35",
    price: "25",
    contracts: "30",
    reason: "CANCEL",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    totalPremiumPaid: "35",
    price: "25",
    contracts: "30",
    reason: "CANCEL",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    totalPremiumPaid: "35",
    price: "25",
    contracts: "30",
    reason: "LOSING",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    totalPremiumPaid: "35",
    price: "25",
    contracts: "30",
    reason: "NO_OFFER",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    totalPremiumPaid: "35",
    price: "25",
    contracts: "30",
    reason: "CANCEL",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    totalPremiumPaid: "35",
    price: "25",
    contracts: "30",
    reason: "CANCEL",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    totalPremiumPaid: "35",
    price: "25",
    contracts: "30",
    reason: "LOSING",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    totalPremiumPaid: "35",
    price: "25",
    contracts: "30",
    reason: "NO_OFFER",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    totalPremiumPaid: "35",
    price: "25",
    contracts: "30",
    reason: "CANCEL",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    totalPremiumPaid: "35",
    price: "25",
    contracts: "30",
    reason: "CANCEL",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    totalPremiumPaid: "35",
    price: "25",
    contracts: "30",
    reason: "CANCEL",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    totalPremiumPaid: "35",
    price: "25",
    contracts: "30",
    reason: "CANCEL",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    totalPremiumPaid: "35",
    price: "25",
    contracts: "30",
    reason: "CANCEL",
  },
  {
    instrumentName: "SOL - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    totalPremiumPaid: "35",
    price: "25",
    contracts: "30",
    reason: "CANCEL",
  },
];

export const placeholderUserLivePositions: IOrder[] = [
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 1,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    entryPrice: "825.85",
    markPrice: "825.85",
    performance: "35",
    totalPremiumPaid: "383.9",
    roi: "0.01",
    contracts: "30",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 1,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    entryPrice: "825.85",
    markPrice: "825.85",
    performance: "35",
    totalPremiumPaid: "383.9",
    roi: "0.01",
    contracts: "30",
  },
];

export const placeholderUserTradeHistory: IOrder[] = [
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    entryPrice: "825.85",
    markPrice: "825.85",
    performance: "35",
    totalPremiumPaid: "383.9",
    roi: "0.01",
    contracts: "30",
    reason: "EXPIRED",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    entryPrice: "825.85",
    markPrice: "825.85",
    performance: "35",
    totalPremiumPaid: "383.9",
    roi: "0.01",
    contracts: "30",
    reason: "UNCLAIMED",
  },
];

export const placeholderUnfilledOrders: IOrder[] = [
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 1,
    optionType: "call",
    asset: "ETH",
    expiry: "1682208000",
    strike: "1600",
    contracts: "1.1",
    totalPremiumPaid: "0",
    indicativePrice: "1",
    iv: "1",
    expiredAt: "0",
  },
  {
    instrumentName: "SOL - 30 Jun 23 - 32,000 - P",
    orderId: 2,
    optionType: "put",
    asset: "SOL",
    expiry: "1682208000",
    strike: "20",
    contracts: "1.1",
    totalPremiumPaid: "0",
    indicativePrice: "1",
    iv: "1",
    expiredAt: "0",
  },
];

export const placeholderMMTradeHistory: IOrder[] = [
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    assetExpiryPrice: "2018.15",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    markPrice: "825.85",
    performance: "35",
    totalPremiumPaid: "383.9",
    roi: "0.01",
    contracts: "30",
    reason: "EXPIRED",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    assetExpiryPrice: "2018.15",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    markPrice: "825.85",
    performance: "35",
    totalPremiumPaid: "383.9",
    roi: "0.01",
    contracts: "30",
    reason: "UNCLAIMED",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    orderId: 4,
    asset: "ETH",
    assetExpiryPrice: "2018.15",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    markPrice: "825.85",
    totalPremiumPaid: "383.9",
    roi: "0.01",
    contracts: "30",
    reason: "PENDING",
  },
];

export const placeholderNotifications: INotification[] = [
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    type: "filled",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    contracts: "30",
    isRead: true,
    timestamp: "1679040000",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    type: "expired",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    contracts: "30",
    isRead: false,
    timestamp: "1679040000",
  },
  {
    instrumentName: "ETH - 30 Jun 23 - 32,000 - C",
    type: "postMargin",
    orderId: 4,
    asset: "ETH",
    optionType: "call",
    expiry: "1679040000",
    strike: "30.32",
    offerPrice: "825.85",
    contracts: "30",
    marginRequirement: "5000",
    isRead: false,
    timestamp: "1679040000",
  },
];
