import styled, { css, keyframes } from "styled-components";
import { motion } from "framer-motion";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import {
  FONT_CSS,
  FONT_SIZE,
  FONT_STYLE,
} from "../../constants/design/fontSize";
import { ConnectWalletButton, glow } from "../Buttons/styles";
import { foregroundComponent } from "../../style";
import { SelectButton } from "../shared/Select/style";
import { flash } from "../../constants/animations/animations";

export const ContentWrapper = styled.div<{
  height: number;
}>`
  ${foregroundComponent};
  width: 100%;
  height: ${({ height }) => height}px;
  background-color: ${LAYER_COLORS.one};
  position: relative;
`;

export const Padding = styled.div`
  padding: ${SPACING.three}px;
`;

export const Form = styled.form`
  position: relative;
  overflow: hidden;
  margin-top: ${SPACING.seven}px;
`;

export const Title = styled.div`
  color: ${TEXT_COLORS.three};
  display: flex;
  align-items: center;
  margin: auto 0;
  font-size: ${FONT_SIZE.one};
`;

export const Value = styled.p`
  color: ${TEXT_COLORS.one};
  font-size: ${FONT_SIZE.oneHalf};
  font-family: BananaGroteskMono;
`;

export const InputWrapper = styled.div``;

export const LabelContainer = styled.div`
  display: flex;
  text-transform: capitalize;
  justify-content: space-between;
  color: ${TEXT_COLORS.three};
  font-size: ${FONT_STYLE.header.seven.fontSize};
  line-height: ${FONT_STYLE.header.seven.lineHeight};
`;

export const Label = styled.span`
  text-transform: capitalize;
  color: ${TEXT_COLORS.three};
  font-size: ${FONT_STYLE.header.seven.fontSize};
  line-height: ${FONT_STYLE.header.seven.lineHeight};
`;

export const LabelValue = styled.span<{ color?: string }>`
  color: ${({ color }) => color ?? TEXT_COLORS.one};
`;

export const WarningLabel = styled.span<{ type?: "error" | "warning" }>`
  display: inline-block;
  text-transform: none;
  color: ${({ type }) => (type === "warning" ? COLORS.orange.one : COLORS.negative.one)};
  font-size: ${FONT_SIZE.one};
`;

export const SubmitButton = styled.button`
  height: ${COMPONENTS.largeButtonHeight}px;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out;
  display: flex;
  width: 100%;
  border-radius: 6px;
  color: ${TEXT_COLORS.two};
  font-size: ${FONT_SIZE.two};
  padding: ${SPACING.three}px ${SPACING.two}px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const PermitButton = styled(SubmitButton)`
  display: flex;
  flex: 1;
  background-color: ${LAYER_COLORS.three};
  color: ${TEXT_COLORS.one};
  ${({ disabled }) => !disabled && glow(TEXT_COLORS.one)}
`;

export const FlashingText = styled.div<{ flashing?: boolean }>`
  ${({ flashing }) => {
    if (flashing) {
      return css`
        opacity: 0;
        animation: ${flash} 1.2s ease-in-out infinite;
      `;
    }
    return "";
  }}
`;

export const ConnectWallet = styled(ConnectWalletButton)`
  padding: ${SPACING.three}px;
  width: 100%;
`;

export const PlaceOrderButton = styled(SubmitButton)`
  background-color: ${COLORS.positive.five};
  color: ${COLORS.positive.one};
  ${({ disabled }) => !disabled && glow(COLORS.positive.one)}
`;

export const InfoRow = styled.div<{ warningOrError?: "warning" | "error" }>`
  font-size: ${FONT_SIZE.one};
  line-height: calc(${FONT_STYLE.header.seven.lineHeight} - 2px);
  > * {
    color: ${({ warningOrError }) => {
    if (warningOrError === "warning") {
      return `${COLORS.orange.one} !important`;
    }
    if (warningOrError === "error") {
      return `${COLORS.negative.one} !important`;
    }
    return undefined;
  }};
  }
`;
export const OrderInfoWrapper = styled.div`
  margin: ${SPACING.three}px ${SPACING.two}px;

  > * {
    display: flex;
    justify-content: space-between;
  }

  p {
    margin: 0;
  }

  > ${InfoRow} {
    &:not(:first-child) {
      padding-top: ${SPACING.two}px;
    }
    &:not(:last-child) {
      padding-bottom: ${SPACING.two}px;
    }
  }
`;

export const IndicativePriceWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  padding: ${SPACING.two}px;
  height: ${COMPONENTS.indicativePriceWrapperHeight}px;
  border-radius: 6px;
  background: ${COLORS.positive.six};

  > * {
    display: flex;
    justify-content: space-between;
  }

  p {
    margin: 0;
  }
`;

export const AbsoluteBorderContainer = styled(motion.div)`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: ${SPACING.two}px;
  height: ${COMPONENTS.indicativePriceWrapperHeight}px;
  border-radius: 6px;
  > * {
    display: flex;
    justify-content: space-between;
  }

  p {
    margin: 0;
  }
`;

export const IndicativePriceValue = styled(motion.div)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  p {
    &:not(:last-child) {
      margin-right: ${SPACING.two}px;
    }
  }
`;

export const IndicativePriceText = styled.p`
  color: ${TEXT_COLORS.three};
  font-size: ${FONT_SIZE.one};
`;

export const IndicativePriceTextLarge = styled.p`
  color: ${COLORS.positive.one};
  font-size: ${FONT_SIZE.three};
  font-family: BananaGroteskMono;
`;

export const IndicativePriceTextSmall = styled.p`
  color: ${COLORS.positive.two};
  padding-top: 2px;
  font-size: ${FONT_SIZE.oneHalf};
  font-family: BananaGroteskMono;
  display: inline;
`;

export const RefreshButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${SPACING.three}px;
  background: ${COLORS.positive.six};
  border-radius: 6px;
  ${glow(COLORS.positive.one)};
`;

export const rotateForward = keyframes`
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
`;

export const RefreshSvgWrapper = styled.div<{ isRefreshing?: boolean }>`
  ${({ isRefreshing }) => isRefreshing
    && css`
      animation: ${rotateForward} infinite 0.5s linear;
    `};
`;

export const PositionInfoWrapper = styled.div`
  margin: ${SPACING.three}px 0;
  padding: ${SPACING.two}px;
  background: ${BACKGROUND_COLORS.four};
  border-radius: 6px;

  > * {
    display: flex;
    justify-content: space-between;
  }

  p {
    margin: 0;
  }

  > ${InfoRow} {
    &:not(:last-child) {
      padding-bottom: ${SPACING.two}px;
    }
  }
`;

export const OptionTypeWrapper = styled.div`
  margin-top: ${SPACING.three}px;
  ${SelectButton} {
    width: 100%;
    padding: ${SPACING.two}px;
    font-size: ${FONT_SIZE.three};
  }
`;

export const ExtraContentWrapper = styled.div`
  display: flex;
  margin-top: ${SPACING.three}px;
`;

export const PayoffButton = styled(SubmitButton)`
  flex: 1;
  background: ${LAYER_COLORS.one};
  height: ${COMPONENTS.xlButtonHeight}px;
  color: ${TEXT_COLORS.one};
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 12px;
  ${glow(TEXT_COLORS.one)}
`;

export const PayoffCloseButton = styled(SubmitButton)`
  flex: 1;
  background: ${LAYER_COLORS.highlightDark};
  height: ${COMPONENTS.xlButtonHeight}px;
  color: ${COLORS.blue.one};
  border: 1px solid ${LAYER_COLORS.highlightDark};
  border-radius: 12px;
  ${glow(COLORS.blue.one)}
`;

export const InfoButton = styled(SubmitButton)`
  width: ${COMPONENTS.xlButtonHeight}px;
  height: ${COMPONENTS.xlButtonHeight}px;
  display: flex;
  align-items: center;
  margin-left: ${SPACING.three}px;
  background: ${LAYER_COLORS.one};
  border: 1px solid ${BORDER_COLORS.one};
  border-radius: 12px;
  ${glow(TEXT_COLORS.one)}
`;

export const SegmentControlWrapper = styled.div`
  position: absolute;
  z-index: 1;
  left: ${SPACING.three}px;
  right: ${SPACING.three}px;
  bottom: ${SPACING.three}px;
`;

export const ChartContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${COMPONENTS.profitChartHeight}px;
`;

export const GreekInfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: ${FONT_SIZE.one};
`;

export const GreekInfoRow = styled.div`
  display: flex;
  margin-top: 4px;
  margin-bottom: ${SPACING.three}px;
  height: 32px;
  width: 50%;
`;

export const GreekIconWrapper = styled.div`
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${LAYER_COLORS.three};
  border-radius: 8px;
  color: ${TEXT_COLORS.two};
`;

export const GreekValuesWrapper = styled.div`
  margin-left: ${SPACING.two}px;
`;

export const PayoffButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING.two}px;
  margin-left: ${SPACING.two}px;
`;

export const PlaceholderContent = styled.div`
  color: ${TEXT_COLORS.three};
  display: flex;
  min-height: 600px;
  align-items: center;
  justify-content: center;
  font-size: ${FONT_SIZE.two};
`;

export const ButtonsContainer = styled.div`
  gap: ${SPACING.two}px;
  display: flex;
  width: 100%;
`;

export const CounterpartyButton = styled.div`
  display: flex;
  role: button;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid ${BORDER_COLORS.one};
  background: ${LAYER_COLORS.two};
  border-radius: 100px;
  height: 32px;
  margin: ${SPACING.three}px 0;
  color: ${TEXT_COLORS.one};
  text-align: center;
  cursor: pointer;
  ${FONT_CSS.body.three}
  &:hover {
    border-color: ${TEXT_COLORS.one};
  }
`;

export const BottomText = styled.div<{ isMobileScreen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isMobileScreen }) => !isMobileScreen && `margin-top: ${SPACING.three}px;`}
  text-align: center;
  color: ${TEXT_COLORS.two};
  ${FONT_CSS.label.two};
  width: 100%;

  p {
    margin: 0px;
  }

  p > a {
    color: ${TEXT_COLORS.one};
    text-decoration: none;
  }
`;

export const TransactionTypeWrapper = styled.div`
  margin-top: ${SPACING.three}px;

  ${SelectButton} {
    width: 50%;
    padding: ${SPACING.two}px;
    font-size: ${FONT_SIZE.three};
  }
`;

export const InputsWrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 6px;
  }
  margin-bottom: ${SPACING.three}px;
`;
