import axios from "axios";
import {
  OFFER_URL,
  UPDATE_OFFER_AFTER_EXECUTION_URL,
} from "../../../../constants/endpoints/endpoints";

export interface IOfferBody {
  order_id: number;
  offer?: string;
  address?: string;
  is_unwind_order?: "true" | "false";
}

export interface IOfferAfterExecutionBody extends IOfferBody {
  cancel: "true" | "false";
  fees: string;
  im_amount?: string;
  mm_address: string;
}

const useOffer = () => {
  const apiURL = OFFER_URL;
  const updateOfferAfterExecutionApiURL = UPDATE_OFFER_AFTER_EXECUTION_URL;
  const makeOffer = async (body: IOfferBody) => {
    try {
      await axios.post(apiURL, body);
    } catch (e) {
      throw Error();
    }
  };

  const editOffer = async (body: IOfferBody) => {
    try {
      await axios.patch(apiURL, body);
    } catch (e) {
      throw Error();
    }
  };

  const cancelOffer = async (body: IOfferBody) => {
    try {
      await axios.delete(apiURL, {
        data: body,
      });
    } catch (e) {
      throw Error();
    }
  };

  const updateOfferAfterExecution = async (body: IOfferAfterExecutionBody) => {
    try {
      await axios.patch(updateOfferAfterExecutionApiURL, body);
    } catch (e) {
      throw Error();
    }
  };

  return { makeOffer, editOffer, cancelOffer, updateOfferAfterExecution };
};

export default useOffer;
