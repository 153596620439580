import styled from "styled-components";
import { LAYER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";

export const TradingGuideModalContainer = styled.div`
  ${FONT_CSS.body.two}
  width: 280px;
  color: ${TEXT_COLORS.two};

  p::first-line {
    text-indent: 20px;
  }
  
  div {
    &:not(:last-child) {
      margin-bottom: ${SPACING.three}px;
    }
  }
  a {
    color: ${TEXT_COLORS.one};
  }
`;

export const Title = styled.p`
  display: inline;
  color: ${TEXT_COLORS.one};
`;

export const ImportantTextWrapper = styled.div`
  width: 100%;
  background: ${LAYER_COLORS.two};
  border-radius: 12px;
  ${FONT_CSS.body.four}
  padding: ${SPACING.two}px;
  p {
    margin: auto 0;
  }
`;
