/* eslint-disable no-console */
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import currency from "currency.js";
import { formatUnits, parseUnits } from "ethers/lib/utils";
import { useForm, useWatch } from "react-hook-form";
import { ethers } from "ethers";
import { ReactComponent as Info } from "../../../../../assets/svg/info.svg";
import { ReactComponent as Close } from "../../../../../assets/svg/close.svg";
import CircularProgressBar from "../../../../shared/CircularProgressBar";
import {
  InfoRow,
  HeaderContainer,
  AssetHeaderWrapper,
  InfoDetail,
  InputContainer,
  InputError,
  InputLabel,
  ButtonsContainer,
  SmartContractRowWrapper,
  InputHeaderWrapper,
  BottomText,
  Form,
} from "../../style";
import { IOrder } from "../../../../../interfaces/Orders";
import { LargeModalButtonV2, ModalButtonV2 } from "../../../../Buttons/styles";
import { Input } from "../../../../shared/Input";
import AssetWrapper from "../../../../shared/AssetWrapper";
import { fullExpiryTime } from "../../../../../utils/date";
import { CHAIN_EXPLORER_URLS } from "../../../../../utils/chain";
import { ChainIdEnum } from "../../../../../enums/chain";
import useWallet from "../../../../../hooks/wallet/useWallet";
import { shortenAddress } from "../../../../../utils/strings";
import { ReactComponent as ArrowOut } from "../../../../../assets/svg/arrow-up-right.svg";
import addresses from "../../../../../constants/addresses/addresses.json";
import useUSDC from "../../../../../hooks/contracts/usdc/useUSDC";
import { useToast } from "../../../../../hooks/toast";
import { ToastEnum, ToastStatusEnum } from "../../../../../utils/toast";
import { COLORS, TEXT_COLORS } from "../../../../../constants/design/colors";
import {
  getAssetIndicPrecision,
  getAssetLogo,
  getAssetPrecision,
} from "../../../../../utils/assets";
import useOffer, {
  IOfferAfterExecutionBody,
  IOfferBody,
} from "../../../../../hooks/api/order/mm/useOffer";
import { Spinner } from "../../../../shared/Spinner";
import useSignature from "../../../../../hooks/contracts/usdc/useSignature";
import { useOrderTimes } from "../../../../../hooks/time/useOrderTimes";
import useOtcWrapper from "../../../../../hooks/contracts/otcWrapper/useOtcWrapper";
import useMarginRequirement from "../../../../../hooks/contracts/marginRequirement/useMarginRequirement";
import { formatBigNumber } from "../../../../../utils/format";
import useSetOraclePrice from "../../../../../hooks/api/prices/useSetOraclePrice";
import { isProduction } from "../../../../../utils/env";
import { IInfoRowCol } from "../../../../../interfaces/InfoRow";
import { IPermitStruct } from "../../../../../interfaces/Signing";
import { OptionsDataContext } from "../../../../../contexts/OptionsDataContext";
import { useSFX } from "../../../../../hooks/useSFX";
import { RequestPositionContext } from "../../../../../contexts/RequestPositionContext";
import useOracle from "../../../../../hooks/contracts/oracle/useOracle";
import usePostErrorMessage from "../../../../../hooks/api/order/mm/usePostErrorMessage";

interface IRequestModalProps {
  order: IOrder;
  setOrder: (order: IOrder) => void;
  setShowModal: (show: boolean) => void;
}

type OfferType = "make" | "edit" | "cancel" | "filled";

function LiveBuyRequestModal({
  order,
  setOrder,
  setShowModal,
}: IRequestModalProps) {
  const {
    instrumentName,
    contracts,
    orderId,
    optionType,
    expiry,
    strike,
    asset,
    offerPrice,
    orderStartTime,
    orderOfferPeriodEnd,
    orderEndTime,
    userPermit,
    indicativePrice,
  } = order;

  const {
    register,
    trigger,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm({
    mode: "onChange",
  });
  const { account } = useWallet();
  const { addToast, addErrorToast } = useToast();
  const { makeOffer, cancelOffer, editOffer, updateOfferAfterExecution }
    = useOffer();
  const [updateOfferAfterExecutionBody, setUpdateOfferAfterExecutionBody]
    = useState<IOfferAfterExecutionBody>();
  const {
    contract,
    data: { loading: otcWrapperLoading, isAccountWhitelisted },
  } = useOtcWrapper();
  const usdc = useUSDC();
  const { playSound } = useSFX();
  const [offerType, setOfferType] = useState<OfferType>();
  const [executed, setExecuted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [handlingOraclePrice, setHandlingOraclePrice] = useState(false);
  const [executing, setExecuting] = useState(false);
  const [confirmedMinMargin, setConfirmedMinMargin] = useState<string>();
  const [executedOrderId, setExecutedOrderId] = useState<number>(); // the id of the order that was just executed
  const [executedOrder, setExecutedOrder] = useState<IOrder>(); // the order object that was just executed
  const [contractOrderStatus, setContractOrderStatus] = useState<number>();
  const { mmPositions } = useContext(OptionsDataContext);
  const { postErrorMessage } = usePostErrorMessage();
  const { setShowRequestPositionModal, setOrderType } = useContext(
    RequestPositionContext
  );
  const usdcContractAddress = addresses.mainnet.assets.usdc;
  const contractAddress = addresses.mainnet.contracts.otcWrapper;
  const usdcBalance = useMemo(
    () => (usdc.userBalance
      ? ethers.utils.formatUnits(usdc.userBalance, usdc.decimals)
      : undefined),
    [usdc.decimals, usdc.userBalance]
  );
  const {
    data: {
      loading: marginRequirementLoading,
      totalFees,
      initialMargin,
      initialMarginFromOracle,
    },
  } = useMarginRequirement({
    collateralAsset: "USDC",
    underlyerAsset: order.asset,
    isPut: order.optionType === "put",
    contracts: order.contracts,
  });

  const amount: string | undefined = useWatch({ control, name: "amount" });

  // need to repermit whenever amount changes
  useEffect(() => {
    if (amount) {
      trigger("amount");
    }
  }, [amount, trigger]);

  // keep polling for orderstatus on chain
  useEffect(() => {
    const fetchOrderStatus = async () => {
      if (executedOrderId && contract) {
        const getOrderStatus = await contract.orderStatus(executedOrderId);
        setContractOrderStatus(getOrderStatus);
      }
    };

    const interval = setInterval(() => {
      if (isProduction()) {
        fetchOrderStatus();
      } else {
        setContractOrderStatus(2);
      }
    }, 5000);
    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [contract, executedOrderId]);

  useEffect(() => {
    const waitExecuteOrder = async () => {
      if (executedOrderId && contract) {
        if (contractOrderStatus === 2) {
          if (updateOfferAfterExecutionBody) {
            const updateOfferAfterExecutionBodyTemp
              = updateOfferAfterExecutionBody;
            // sets state to undefined so there is no re-call of updateOfferAfterExecution
            setUpdateOfferAfterExecutionBody(undefined);
            await updateOfferAfterExecution(updateOfferAfterExecutionBodyTemp);
          }
          const position = mmPositions.orders.find(
            (obj) => obj.orderId === executedOrderId
          );
          if (position) {
            playSound("order_filled");
            setExecuting(false);
            setExecuted(true);
            setExecutedOrder(position);
            setExecutedOrderId(undefined);
          }
        }
      }
    };

    waitExecuteOrder();
  }, [
    contract,
    contractOrderStatus,
    executedOrderId,
    mmPositions.orders,
    playSound,
    updateOfferAfterExecution,
    updateOfferAfterExecutionBody,
  ]);

  const { timeNow, timeArgs } = useOrderTimes();
  const { handleSetOraclePrice } = useSetOraclePrice();
  const { oraclePrices } = useOracle();
  const { timeToExpiry, orderExpired, isOrderConfirmationTimeWindow }
    = timeArgs(
      Number(orderStartTime),
      Number(orderOfferPeriodEnd),
      Number(orderEndTime)
    );

  const hasOffer = useMemo(() => Boolean(offerPrice), [offerPrice]);

  const initialOfferType = useMemo(() => {
    if (isOrderConfirmationTimeWindow) {
      return "filled";
    }
    if (hasOffer) {
      return "cancel";
    }
    return "make";
  }, [hasOffer, isOrderConfirmationTimeWindow]);

  useEffect(() => {
    setOfferType(initialOfferType);
  }, [hasOffer, initialOfferType]);

  const totalPremium = useMemo(
    () => (amount || offerPrice
      ? String(Number(contracts) * Number(amount || offerPrice))
      : undefined),
    [amount, contracts, offerPrice]
  );

  const totalPremiumAfterFees = useMemo(() => {
    if (!totalPremium || !totalFees) {
      return undefined;
    }
    return (
      Number(contracts) * Number(amount || offerPrice)
      + formatBigNumber(totalFees, usdc.decimals)
    ).toFixed(usdc.decimals);
  }, [amount, contracts, offerPrice, totalFees, totalPremium, usdc.decimals]);

  const minMarginRequired = useMemo(() => {
    // dev amount
    if (!isProduction()) {
      return initialMargin
        ? formatBigNumber(initialMargin, usdc.decimals).toFixed(usdc.decimals)
        : "0";
    }
    if (!initialMargin) {
      return undefined;
    }
    return formatBigNumber(initialMargin, usdc.decimals).toFixed(usdc.decimals);
  }, [initialMargin, usdc.decimals]);

  const permitAmount = useMemo(
    () => (initialMarginFromOracle
      ? formatUnits(initialMarginFromOracle, usdc.decimals)
      : "0"),
    [initialMarginFromOracle, usdc.decimals]
  );

  const {
    permitting,
    setPermitting,
    resetSignature,
    handlePermit,
    approvedAmountAndSignature,
    hasValidSignature,
  } = useSignature({
    amount: permitAmount,
    setConfirmedMinMargin,
  });

  const sufficientBalance = useMemo(() => {
    const initialMarginRequired = initialMarginFromOracle
      ? formatUnits(initialMarginFromOracle, usdc.decimals)
      : "0";
    return Number(usdcBalance) >= Number(initialMarginRequired);
  }, [initialMarginFromOracle, usdc.decimals, usdcBalance]);

  const resetRequestModal = useCallback(() => {
    setExecuted(false);
    setExecuting(false);
    reset();
    setOfferType(initialOfferType);
    setConfirmedMinMargin(undefined);
    resetSignature();
  }, [initialOfferType, reset, resetSignature]);

  const onHide = useCallback(() => {
    setShowModal(false);
    resetRequestModal();
  }, [setShowModal, resetRequestModal]);

  // hide modal if orderOfferPeriod has expired or orderExpired
  useEffect(() => {
    if (Math.round(timeNow) === Number(orderOfferPeriodEnd)) {
      onHide();
    }
    if (orderExpired) {
      onHide();
    }
  }, [onHide, orderExpired, orderOfferPeriodEnd, timeNow]);

  const openBlockExplorer = useCallback(() => {
    window.open(
      `${
        CHAIN_EXPLORER_URLS[ChainIdEnum.ETH_MAINNET]
      }/address/${contractAddress}`
    );
  }, [contractAddress]);

  const [orderStatus, orderStatusColor] = useMemo(() => {
    if (executed) {
      return ["Trade Successful", COLORS.positive.one];
    }
    // eslint-disable-next-line no-extra-boolean-cast
    if (isOrderConfirmationTimeWindow) {
      return ["Awaiting Confirmation", COLORS.positive.one];
    }
    return ["Submitted", COLORS.blue.one];
  }, [executed, isOrderConfirmationTimeWindow]);

  const infoRows: IInfoRowCol[] = useMemo(() => {
    const rows = [];
    if (offerPrice) {
      rows.push({
        title: "Order Status",
        detail: orderStatus,
        color: orderStatusColor,
      });
      rows.push({
        title: "Offer Price",
        detail: `${currency(parseFloat(offerPrice), {
          precision: getAssetIndicPrecision(asset),
        }).format()}`,
        color: COLORS.positive.one,
      });
    }
    if (indicativePrice) {
      rows.push({
        title: "Indicative Price",
        detail: `${currency(parseFloat(indicativePrice), {
          precision: getAssetIndicPrecision(asset),
        }).format()}`,
        color: COLORS.positive.one,
      });
    }
    rows.push({
      title: "Option Type",
      detail: `European ${optionType.replace(/^\w/, (c) => c.toUpperCase())}`,
    });
    rows.push({
      title: "Expiry",
      detail: `${fullExpiryTime(expiry)}`,
    });
    rows.push({
      title: "Strike",
      detail: `${strike}`,
    });
    rows.push({
      title: "Contracts",
      detail: `${contracts}`,
    });
    rows.push({
      title: "Total Premiums",
      detail: totalPremium ? `${currency(totalPremium).format()}` : "-",
    });
    rows.push({
      title: "Min Margin Required",
      detail: minMarginRequired
        ? `${currency(minMarginRequired).format()}`
        : "-",
      color: offerType === "filled" ? COLORS.positive.one : undefined,
    });
    if (offerType === "filled" && usdcBalance) {
      rows.push({
        title: "Wallet Balance",
        detail: `${currency(usdcBalance).format()}`,
        color: !sufficientBalance ? COLORS.negative.one : COLORS.positive.one,
      });
    }
    if (offerType !== "cancel" && offerType !== "filled") {
      rows.push({
        title: "Time till confirmation",
        detail: timeToExpiry,
        color: COLORS.blue.one,
      });
    }
    rows.push({
      title: "Smart Contract",
      detail: (
        <SmartContractRowWrapper onClick={openBlockExplorer}>
          <span>{shortenAddress(contractAddress)}</span>
          <ArrowOut />
        </SmartContractRowWrapper>
      ),
    });
    return rows;
  }, [
    offerPrice,
    indicativePrice,
    optionType,
    expiry,
    strike,
    contracts,
    totalPremium,
    offerType,
    usdcBalance,
    openBlockExplorer,
    contractAddress,
    orderStatus,
    orderStatusColor,
    asset,
    minMarginRequired,
    sufficientBalance,
    timeToExpiry,
  ]);

  const filledContentText = useMemo(() => {
    if (!hasValidSignature) {
      if (permitting) {
        return <Spinner color={COLORS.white.one} />;
      }
      return "Permit USDC";
    }
    if (!executed) {
      if (executing) {
        return <Spinner color={COLORS.white.one} />;
      }
      return "Execute Trade";
    }
    return "View Position";
  }, [executed, executing, hasValidSignature, permitting]);

  const headerContent = useMemo(
    () => (
      <>
        <AssetHeaderWrapper>
          <AssetWrapper asset={asset} />
        </AssetHeaderWrapper>
        <LargeModalButtonV2 type={"button"} onClick={onHide}>
          <Close />
        </LargeModalButtonV2>
      </>
    ),
    [onHide, asset]
  );

  const executeOrderBody = useMemo(() => {
    const {
      approvedAmount: mmPermitAmount,
      signature,
      deadline: mmPermitDeadline,
    } = approvedAmountAndSignature;

    if (!signature || !userPermit) {
      return {};
    }
    const {
      permitAddress: userPermitAddress,
      permitAmount: userPermitAmount,
      permitDeadline: userPermitDeadline,
      permitV: userPermitV,
      permitR: userPermitR,
      permitS: userPermitS,
    } = userPermit;
    const { v: mmPermitV, r: mmPermitR, s: mmPermitS } = signature;
    return {
      orderId: String(orderId),
      userPermit: {
        amount: userPermitAmount,
        deadline: userPermitDeadline,
        acct: userPermitAddress!,
        v: userPermitV,
        r: userPermitR,
        s: userPermitS,
      } as IPermitStruct,
      mmPermit: {
        amount: parseUnits(mmPermitAmount || "0", usdc.decimals),
        deadline: String(mmPermitDeadline),
        acct: account,
        v: String(mmPermitV),
        r: mmPermitR,
        s: mmPermitS,
      } as IPermitStruct,
      totalPremiumAfterFees: String(
        parseUnits(totalPremiumAfterFees || "0", usdc.decimals)
      ),
      usdContractAddress: usdcContractAddress,
      collateralAmount: String(
        parseUnits(mmPermitAmount || "0", usdc.decimals)
      ),
    };
  }, [
    account,
    approvedAmountAndSignature,
    orderId,
    totalPremiumAfterFees,
    usdc.decimals,
    usdcContractAddress,
    userPermit,
  ]);

  const handleExecute = useCallback(async () => {
    const {
      approvedAmount: mmPermitAmount,
      signature,
      deadline: mmPermitDeadline,
    } = approvedAmountAndSignature;
    try {
      setExecuting(true);
      if (isProduction()) {
        if (
          contract
          && signature
          && mmPermitAmount
          && userPermit
          && account
          && totalPremium
          && totalPremiumAfterFees
          && confirmedMinMargin
          && totalFees
          && oraclePrices
          && oraclePrices[asset]
        ) {
          const { v: mmPermitV, r: mmPermitR, s: mmPermitS } = signature;
          const {
            permitAddress: userPermitAddress,
            permitAmount: userPermitAmount,
            permitDeadline: userPermitDeadline,
            permitV: userPermitV,
            permitR: userPermitR,
            permitS: userPermitS,
          } = userPermit;
          await contract.executeOrder(
            String(orderId),
            {
              amount: userPermitAmount,
              deadline: userPermitDeadline,
              acct: userPermitAddress!,
              v: userPermitV,
              r: userPermitR,
              s: userPermitS,
            } as IPermitStruct,
            {
              amount: parseUnits(mmPermitAmount, usdc.decimals),
              deadline: String(mmPermitDeadline),
              acct: account,
              v: String(mmPermitV),
              r: mmPermitR,
              s: mmPermitS,
            } as IPermitStruct,
            parseUnits(totalPremiumAfterFees, usdc.decimals),
            usdcContractAddress,
            parseUnits(mmPermitAmount, usdc.decimals)
          );
          setUpdateOfferAfterExecutionBody({
            order_id: Number(orderId),
            cancel: "false",
            fees: String(formatBigNumber(totalFees, usdc.decimals)),
            im_amount: mmPermitAmount,
            mm_address: account,
          } as IOfferAfterExecutionBody);
        } else {
          throw Error;
        }
      } else {
        await updateOfferAfterExecution({
          order_id: Number(orderId),
          cancel: "false",
          fees: String(formatBigNumber("0", usdc.decimals)),
          im_amount: mmPermitAmount,
          mm_address: account,
        } as IOfferAfterExecutionBody);
      }
      // set the executed order id and wait for mmPositions api
      // to have that order object
      setExecutedOrderId(orderId);
    } catch (error) {
      // eslint-disable-next-line no-console
      await postErrorMessage({
        title: "Execute Order Error",
        stackError:
          error instanceof Error ? String(error.stack) : "unknown error",
        messageError:
          error instanceof Error ? String(error.message) : "unknown error",
        body: JSON.stringify(executeOrderBody),
      });
      setExecuting(false);
      throw Error;
    }
  }, [
    account,
    approvedAmountAndSignature,
    asset,
    confirmedMinMargin,
    contract,
    executeOrderBody,
    oraclePrices,
    orderId,
    postErrorMessage,
    totalFees,
    totalPremium,
    totalPremiumAfterFees,
    updateOfferAfterExecution,
    usdc.decimals,
    usdcContractAddress,
    userPermit,
  ]);

  const handleFilled = useCallback(async () => {
    try {
      setLoading(true);
      if (!hasValidSignature) {
        setPermitting(true);
        setHandlingOraclePrice(true);
        handleSetOraclePrice(asset);
      } else if (!executed) {
        await handleExecute();
      } else if (executedOrder) {
        setOrderType("MMPositions");
        setOrder(executedOrder);
        setShowRequestPositionModal(true);
      }
      setLoading(false);
    } catch (error) {
      addErrorToast("Something went wrong", "Please try again");
      onHide();
    }
  }, [
    hasValidSignature,
    executed,
    executedOrder,
    setPermitting,
    handleSetOraclePrice,
    asset,
    handleExecute,
    setOrderType,
    setOrder,
    setShowRequestPositionModal,
    addErrorToast,
    onHide,
  ]);

  // wait for oraclePrice to be settled
  // only then we execute trade
  // wait for initial margin calculation using oracle
  // then permit
  useEffect(() => {
    if (handlingOraclePrice) {
      if (oraclePrices && oraclePrices[asset] && initialMarginFromOracle) {
        setHandlingOraclePrice(false);
        handlePermit();
      }
    }
  }, [
    asset,
    handlePermit,
    handlingOraclePrice,
    initialMarginFromOracle,
    oraclePrices,
  ]);

  const isExecuteDisabled = useMemo(() => {
    const { approvedAmount: mmPermitAmount, signature }
      = approvedAmountAndSignature;
    return Boolean(
      !signature
        || !mmPermitAmount
        || !userPermit
        || !account
        || !totalPremium
        || !minMarginRequired
    );
  }, [
    account,
    approvedAmountAndSignature,
    minMarginRequired,
    totalPremium,
    userPermit,
  ]);

  const isFilledButtonDisabled = useCallback(() => {
    if (!isProduction()) {
      return permitting || executing;
    }
    if (!hasValidSignature) {
      return permitting || !initialMargin || !sufficientBalance;
    }
    if (!executed) {
      return isExecuteDisabled || executing;
    }
    return false;
  }, [
    executed,
    executing,
    hasValidSignature,
    initialMargin,
    isExecuteDisabled,
    permitting,
    sufficientBalance,
  ]);

  const submitButtonContent = useMemo(() => {
    switch (offerType) {
      case "make":
        return (
          <ButtonsContainer>
            <ModalButtonV2
              type={"submit"}
              disabled={loading || !amount || Boolean(errors?.amount?.type)}
              style={{ flex: 1 }}
            >
              {loading ? <Spinner color={TEXT_COLORS.two} /> : "Confirm"}
            </ModalButtonV2>
          </ButtonsContainer>
        );
      case "cancel":
        return (
          <>
            <ButtonsContainer>
              <ModalButtonV2
                type="button"
                disabled={loading}
                style={{ flex: 1 }}
                onClick={() => {
                  setOfferType("edit");
                }}
              >
                Edit Offer
              </ModalButtonV2>
            </ButtonsContainer>
            <ButtonsContainer>
              <ModalButtonV2
                type={"submit"}
                disabled={loading}
                style={{ flex: 1 }}
                onClick={() => setOfferType("cancel")}
              >
                {loading ? <Spinner color={TEXT_COLORS.two} /> : "Cancel Offer"}
              </ModalButtonV2>
            </ButtonsContainer>
          </>
        );
      case "edit":
        return (
          <ButtonsContainer>
            <ModalButtonV2
              type={"submit"}
              disabled={loading || !amount || Boolean(errors?.amount?.type)}
              style={{ flex: 1 }}
              onClick={() => {
                setOfferType("edit");
              }}
            >
              Update Offer
            </ModalButtonV2>
            <ModalButtonV2 type={"button"} onClick={() => {}}>
              <Info />
            </ModalButtonV2>
          </ButtonsContainer>
        );
      case "filled":
        return (
          <>
            <ButtonsContainer>
              <ModalButtonV2
                type={"button"}
                disabled={isFilledButtonDisabled()}
                style={{ flex: 1 }}
                onClick={() => handleFilled()}
              >
                {filledContentText}
              </ModalButtonV2>
            </ButtonsContainer>
            <BottomText>
              {executed ? (
                <p>
                  You can track all live positions in the{" "}
                  <Link to="/mm/portfolio">Portfolio</Link> section of the app.
                </p>
              ) : (
                <p>
                  You must ensure you have enough USDC in your wallet to execute
                  the trade.
                </p>
              )}
            </BottomText>
          </>
        );
      default:
        return undefined;
    }
  }, [
    amount,
    errors?.amount?.type,
    executed,
    filledContentText,
    handleFilled,
    isFilledButtonDisabled,
    loading,
    offerType,
  ]);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      switch (offerType) {
        case "make":
          await makeOffer({
            order_id: Number(orderId),
            offer: String(parseFloat(amount!)),
            address: account!,
          } as IOfferBody);
          addToast(
            {
              type: ToastEnum.INFO,
              icon: getAssetLogo(asset) as string,
              header: <p>{instrumentName}</p>,
              subheader: (
                <span style={{ color: COLORS.blue.one }}>Offer Placed</span>
              ),
              stats: [
                {
                  label: "Contracts",
                  value: Number(contracts).toFixed(2),
                },
                {
                  label: "Offer Price",
                  value: currency(amount || 0).format(),
                },
              ],
              status: ToastStatusEnum.SUCCESS,
            },
            10000
          );
          break;
        case "cancel":
          await cancelOffer({
            order_id: Number(orderId),
            address: account!,
          } as IOfferBody);
          addToast(
            {
              type: ToastEnum.INFO,
              icon: getAssetLogo(asset) as string,
              header: <p>{instrumentName}</p>,
              subheader: (
                <span style={{ color: COLORS.blue.one }}>Offer Canceled</span>
              ),
              stats: [
                {
                  label: "Contracts",
                  value: Number(contracts).toFixed(2),
                },
                {
                  label: "Offer Price",
                  value: currency(offerPrice || 0, {
                    precision: getAssetPrecision(asset),
                  }).format(),
                },
              ],
              status: ToastStatusEnum.SUCCESS,
            },
            10000
          );
          break;
        case "edit":
          await editOffer({
            order_id: Number(orderId),
            offer: String(parseFloat(amount!)),
            address: account!,
          } as IOfferBody);
          addToast(
            {
              type: ToastEnum.INFO,
              icon: getAssetLogo(asset) as string,
              header: <p>{instrumentName}</p>,
              subheader: (
                <span style={{ color: COLORS.blue.one }}>Offer changed</span>
              ),
              stats: [
                {
                  label: "Contracts",
                  value: Number(contracts).toFixed(2),
                },
                {
                  label: "Offer Price",
                  value: currency(amount || 0).format(),
                },
              ],
              status: ToastStatusEnum.SUCCESS,
            },
            10000
          );
          break;

        default:
      }
      setLoading(false);
    } catch (error: any) {
      addErrorToast("Something went wrong", "Please try again");
      onHide();
      setLoading(false);
    }
    onHide();
  }, [
    onHide,
    offerType,
    makeOffer,
    orderId,
    amount,
    account,
    addToast,
    asset,
    instrumentName,
    contracts,
    cancelOffer,
    offerPrice,
    editOffer,
    addErrorToast,
  ]);

  const getCircleType = useMemo(() => {
    if (executed) {
      return "success";
    }
    if (orderExpired) {
      return "failure";
    }
    return "pending";
  }, [executed, orderExpired]);

  const bottomContent = useMemo(() => {
    if (marginRequirementLoading || otcWrapperLoading || !account) {
      return undefined;
    }
    if ((!initialMargin || isAccountWhitelisted === false) && isProduction()) {
      return (
        <BottomText>
          You are not able to place an offer on this quote at the moment. Kindly
          reach out on Telegram for more information.
        </BottomText>
      );
    }
    return submitButtonContent;
  }, [
    account,
    initialMargin,
    isAccountWhitelisted,
    marginRequirementLoading,
    otcWrapperLoading,
    submitButtonContent,
  ]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <HeaderContainer>{headerContent}</HeaderContainer>
      {(offerType === "cancel" || offerType === "filled") && account && (
        <CircularProgressBar
          timeNow={timeNow}
          startTime={
            isOrderConfirmationTimeWindow
              ? Number(orderOfferPeriodEnd)
              : Number(orderStartTime)
          }
          endTime={
            isOrderConfirmationTimeWindow
              ? Number(orderEndTime) - 1.15 // handle delay in animation
              : Number(orderOfferPeriodEnd) - 1.15 // handle delay in animation
          }
          isOrderConfirmationTimeWindow={isOrderConfirmationTimeWindow}
          size={160}
          strokeWidth={4}
          detail={
            isOrderConfirmationTimeWindow
              ? "Time till confirmation window closes"
              : "Time till order confirmation"
          }
          type={getCircleType}
        />
      )}
      {!marginRequirementLoading
        && !otcWrapperLoading
        && account
        && (offerType === "make" || offerType === "edit")
        && ((initialMargin && isAccountWhitelisted === true)
          || !isProduction()) && (
          <InputContainer>
            <InputHeaderWrapper>
              <InputLabel>Offer Price</InputLabel>
              <InputLabel>
                Wallet Balance:{" "}
                {usdcBalance ? currency(usdcBalance).format() : "-"}{" "}
              </InputLabel>
            </InputHeaderWrapper>
            <Input
              placeholder="0.00"
              type="number"
              disabled={loading}
              {...register("amount", {
                required: true,
                validate: {
                  moreThanZero: (v) => parseFloat(v) > 0,
                  lessThanIndicPrice: (v) => parseFloat(v) <= Number(indicativePrice),
                },
              })}
              error={Boolean(Object.keys(errors).length)}
            />
            {errors.amount?.type === "required" && (
              <InputError>Amount is required</InputError>
            )}
            {errors.amount?.type === "lessThanIndicPrice" && (
              <InputError>
                Amount must be less than or equal to indicative price
              </InputError>
            )}
            {errors.amount?.type === "moreThanZero" && (
              <InputError>Amount must be more than zero</InputError>
            )}
            {errors.amount?.type === "lessThanEqualToBalance" && (
              <InputError>
                Amount is greater than withdrawable balance
              </InputError>
            )}
          </InputContainer>
      )}
      {infoRows.map((infoRow) => (
        <InfoRow key={infoRow.title}>
          <span>{infoRow.title}</span>
          <InfoDetail color={infoRow.color}>{infoRow.detail}</InfoDetail>
        </InfoRow>
      ))}
      {bottomContent}
    </Form>
  );
}

export default LiveBuyRequestModal;
