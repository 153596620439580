import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { positions as Positions } from "../../assets/styledSvg/positions";
import { tradeHistory as TradeHistorySvg } from "../../assets/styledSvg/tradeHistory";
import { plus as Plus } from "../../assets/styledSvg/plus";
import { globe as Globe } from "../../assets/styledSvg/globe";
import { unmatchedRequests as UnmatchedRequests } from "../../assets/styledSvg/unmatchedRequests";
import { COLORS } from "../../constants/design/colors";
import TradeHistoryTab from "../../pages/PortfolioPage/TradeHistoryTab";
import { IRoute, PageEnum, TabEnum } from "../../enums/pages";
import CreateTradeTab from "../../pages/TradePage/CreateTradeTab";
import TradeIdeasTab from "../../pages/TradePage/TradeIdeasTab";
import PositionsTab from "../../pages/PortfolioPage/LivePositionsTab";
import LiveRequestsTab from "../../pages/RequestsPage/LiveRequestsTab";
import UnmatchedRequestsTab from "../../pages/RequestsPage/UnmatchedRequestsTab";
import { PageEndpointEnum } from "../../enums/endpoint";
import MMLivePositionsTab from "../../pages/MMPortfolioPage/LivePositionsTab";
import MMTradeHistoryTab from "../../pages/MMPortfolioPage/TradeHistoryTab";
import UnfilledOrdersTab from "../../pages/PortfolioPage/UnfilledOrdersTab";

const useRoutes = () => {
  const location = useLocation();

  const [currentTab, setCurrentTab] = useState<IRoute>();
  // temporary since MM page not made yet

  const currentPage: PageEnum = useMemo(() => {
    if (location.pathname.includes(PageEndpointEnum.MMPORTFOLIO)) {
      return PageEnum.MMPortfolio;
    }
    if (location.pathname.includes(PageEndpointEnum.REQUESTS)) {
      return PageEnum.Requests;
    }
    if (location.pathname.includes(PageEndpointEnum.PORTFOLIO)) {
      return PageEnum.Portfolio;
    }
    return PageEnum.Trade;
  }, [location]);

  const isMMPage = location.pathname.includes("/mm");

  const pages = useMemo((): IRoute[] => {
    switch (currentPage) {
      case PageEnum.Portfolio:
        return [
          {
            title: TabEnum.LivePositions,
            path: "/",
            redirect: "/portfolio",
            img: (
              <Positions
                color={
                  currentTab?.title === TabEnum.LivePositions
                    ? COLORS.blue.one
                    : "white"
                }
              />
            ),
            element: <PositionsTab />,
            isActive: currentTab?.title === TabEnum.LivePositions,
          },
          {
            title: TabEnum.TradeHistory,
            path: "/trade-history",
            redirect: "/portfolio/trade-history",
            img: (
              <TradeHistorySvg
                color={
                  currentTab?.title === TabEnum.TradeHistory
                    ? COLORS.blue.one
                    : "white"
                }
              />
            ),
            element: <TradeHistoryTab />,
            isActive: currentTab?.title === TabEnum.TradeHistory,
          },
          {
            title: TabEnum.UnfilledOrders,
            path: "/unfilled-orders",
            redirect: "/portfolio/unfilled-orders",
            img: (
              <UnmatchedRequests
                color={
                  currentTab?.title === TabEnum.UnfilledOrders
                    ? COLORS.blue.one
                    : "white"
                }
              />
            ),
            element: <UnfilledOrdersTab />,
            isActive: currentTab?.title === TabEnum.UnfilledOrders
          },
        ];
      case PageEnum.Trade:
        return [
          {
            title: TabEnum.CreateTrade,
            path: "/",
            redirect: "/trade",
            img: (
              <Plus
                color={
                  currentTab?.title === TabEnum.CreateTrade
                    ? COLORS.blue.one
                    : "white"
                }
              />
            ),
            element: <CreateTradeTab />,
            isActive: currentTab?.title === TabEnum.CreateTrade,
          },
          {
            title: TabEnum.TradeIdeas,
            path: "/trade-ideas",
            redirect: "/trade/trade-ideas",
            img: (
              <Globe
                color={
                  currentTab?.title === TabEnum.TradeIdeas
                    ? COLORS.blue.one
                    : "white"
                }
              />
            ),
            element: <TradeIdeasTab />,
            isActive: currentTab?.title === TabEnum.TradeHistory,
          },
        ];
      case PageEnum.Requests:
        return [
          {
            title: TabEnum.LiveRequests,
            path: "/",
            redirect: "/mm/requests",
            img: (
              <Globe
                color={
                  currentTab?.title === TabEnum.LiveRequests
                    ? COLORS.blue.one
                    : "white"
                }
              />
            ),
            element: <LiveRequestsTab />,
            isActive: currentTab?.title === TabEnum.LiveRequests,
          },
          {
            title: TabEnum.UnmatchedRequests,
            path: "/unmatched",
            redirect: "/mm/requests/unmatched",
            img: (
              <UnmatchedRequests
                color={
                  currentTab?.title === TabEnum.UnmatchedRequests
                    ? COLORS.blue.one
                    : "white"
                }
              />
            ),
            element: <UnmatchedRequestsTab />,
            isActive: currentTab?.title === TabEnum.UnmatchedRequests,
          },
        ];
      case PageEnum.MMPortfolio:
        return [
          {
            title: TabEnum.MMLivePositions,
            path: "/",
            redirect: "/mm/portfolio/",
            img: (
              <Positions
                color={
                  currentTab?.title === TabEnum.LivePositions
                    ? COLORS.blue.one
                    : "white"
                }
              />
            ),
            element: <MMLivePositionsTab />,
            isActive: currentTab?.title === TabEnum.LivePositions,
          },
          {
            title: TabEnum.MMTradeHistory,
            path: "/trade-history",
            redirect: "/mm/portfolio/trade-history",
            img: (
              <TradeHistorySvg
                color={
                  currentTab?.title === TabEnum.MMTradeHistory
                    ? COLORS.blue.one
                    : "white"
                }
              />
            ),
            element: <MMTradeHistoryTab />,
            isActive: currentTab?.title === TabEnum.MMTradeHistory,
          },
        ];
      default:
        return [];
    }
  }, [currentPage, currentTab]);

  return { currentPage, pages, currentTab, setCurrentTab, isMMPage };
};

export default useRoutes;
