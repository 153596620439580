import React from "react";

type SVGProps = React.SVGAttributes<SVGElement>;

export const info = ({
  color,
  ...props
}: SVGProps & { color?: string }): JSX.Element => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8.00004C14.6663 4.31814 11.6816 1.33337 7.99967 1.33337C4.31778 1.33337 1.33301 4.31814 1.33301 8.00004C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z"
      stroke={color || "white"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 10.6667V8"
      stroke={color || "white"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 5.33337H8.00667"
      stroke={color || "white"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
