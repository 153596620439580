import { CSSProperties } from "react";
import { CarouselDot, PageCarousel } from "./style";

interface ITabIndicatorProps {
  totalPages: number;
  currentPage: number;
  style?: CSSProperties;
}

function PageIndicator({ totalPages, currentPage, style }: ITabIndicatorProps) {
  return (
    <PageCarousel style={style}>
      {Array(totalPages)
        .fill(0)
        .map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <CarouselDot key={index} isActive={currentPage === index} />
        ))}
    </PageCarousel>
  );
}

export default PageIndicator;
