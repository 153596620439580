import useSWR from "swr";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Assets } from "../../../../utils/assets";
import { IOrder, OrdersResponse } from "../../../../interfaces/Orders";
import { OptionTypeResponse } from "../../../../interfaces/PreloadedStrikes";
import { getInstrumentName } from "../../../../utils/instruments";
import { defaultOrdersResponse } from "../../../../contexts/OptionsDataContext";
import useWallet from "../../../wallet/useWallet";
import { GET_PENDING_ORDERS_URL } from "../../../../constants/endpoints/endpoints";

export interface IPendingOrders {
  order_id: number;
  underlyer: Assets;
  type: OptionTypeResponse;
  expiry: string;
  strike: string;
  indic?: string;
  spot_ref?: string;
  iv?: string;
  permit_amount?: string;
  custom_price?: string;
  order_start_time: string;
  order_offer_period_end: string;
  order_end_time: string;
  size: string;
  is_unwind_order?: "true" | "false";
  order_id_to_unwind?: number;
}

// Get the current account
const useFetchOrders = () => {
  const { account } = useWallet();
  const [pendingOrdersData, setPendingOrdersData] = useState<OrdersResponse>(
    defaultOrdersResponse
  );
  const pendingOrdersApiURL = GET_PENDING_ORDERS_URL;

  const fetcher = (params: any) => (url: string) => {
    if (params.address !== undefined) {
      return axios.get(url, { params }).then((res) => res.data);
    }
    return Promise.resolve(null); // Return a resolved Promise with a null value if address is undefined
  };

  const pendingOrdersSwr = useSWR<IPendingOrders[]>(
    pendingOrdersApiURL,
    fetcher({
      address: account,
    }),
    {
      refreshInterval: 2000,
    }
  );

  const { data: pendingOrdersDataSwr, error: pendingOrdersSwrError }
    = pendingOrdersSwr;

  const fetchPendingOrders = useCallback(async () => {
    if (!pendingOrdersDataSwr || pendingOrdersSwrError || !account) {
      // API call hasn't completed yet or error, return early
      return;
    }

    const requests = pendingOrdersDataSwr.map((pendingOrderData) => ({
      instrumentName: getInstrumentName(
        pendingOrderData.underlyer,
        pendingOrderData.expiry,
        pendingOrderData.strike,
        pendingOrderData.type
      ),
      orderId: pendingOrderData.order_id,
      optionType: pendingOrderData.type,
      asset: pendingOrderData.underlyer,
      expiry: pendingOrderData.expiry,
      strike: pendingOrderData.strike,
      contracts: pendingOrderData.size,
      orderStartTime: pendingOrderData.order_start_time,
      orderOfferPeriodEnd: pendingOrderData.order_offer_period_end,
      orderEndTime: pendingOrderData.order_end_time,
      totalPremiumPaid: pendingOrderData.permit_amount,
      spotRef: pendingOrderData.spot_ref,
      indicativePrice: pendingOrderData.indic,
      iv: pendingOrderData.iv,
      customPrice: pendingOrderData.custom_price,
      isUnwindOrder: pendingOrderData.is_unwind_order === "true",
      orderIdToUnwind: pendingOrderData.order_id_to_unwind,
    })) as IOrder[];

    setPendingOrdersData({
      loading: false,
      orders: requests,
    } as OrdersResponse);
  }, [pendingOrdersDataSwr, pendingOrdersSwrError, account]);

  useEffect(() => {
    fetchPendingOrders();
  }, [fetchPendingOrders]);

  return {
    pendingOrdersData,
  };
};

export default useFetchOrders;
