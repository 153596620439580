import styled from "styled-components";
import { motion } from "framer-motion";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";
import { foregroundComponent } from "../../../style";

export const ComponentWrapper = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  align-items: center;
  color: ${TEXT_COLORS.one};
  font-size: ${FONT_SIZE.two};
  padding-bottom: ${SPACING.two}px;
`;

export const MarginHealthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SPACING.three}px;
  ${foregroundComponent}
  height: ${COMPONENTS.marketModalHeight}px;
  width: ${COMPONENTS.marketsSectionWidth}px;
  background-color: ${LAYER_COLORS.one};
`;

export const CollateralBalanceContainer = styled.div`
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${COMPONENTS.collateralBalanceContainerHeight}px;
  background-color: ${LAYER_COLORS.three};
  > *:not(:first-child) {
    margin-top: ${SPACING.two}px;
  }
`;

export const CollateralBalanceText = styled.div`
  ${FONT_CSS.label.three}
  color: ${TEXT_COLORS.three};
`;

export const CollateralBalanceAmount = styled.div`
  ${FONT_CSS.data.one}
  color: ${TEXT_COLORS.one};
`;

export const ButtonsContainer = styled.div`
  margin-top: ${SPACING.three}px;
  display: flex;
  width: 100%;
  > * {
    &:not(:last-child) {
      margin-right: ${SPACING.two}px;
    }
  }
`;

export const InfoRow = styled.div<{ paddingTop?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${({ paddingTop }) => (paddingTop ?? SPACING.three)}px;
  font-size: ${FONT_SIZE.oneHalf};
  span:first-child {
    color: ${TEXT_COLORS.three};
  }
`;

export const PageContentContainer = styled(motion.div)`
  transition: all 0.2s ease-in-out;
  width: ${COMPONENTS.collateralModalWidth}px;
`;

export const InfoDetail = styled.span<{ color?: string }>`
  ${({ color }) => color && `color: ${color};`}
`;

export const HeaderContainer = styled.div`
  display: flex;

  > * {
    &:not(:last-child) {
      margin-right: ${SPACING.two}px;
    }
  }
`;

export const InputLabel = styled.span`
  font-size: ${FONT_SIZE.one};
  color: ${TEXT_COLORS.three};
`;

export const InputContainer = styled.div`
  margin-bottom: ${SPACING.three}px;
`;

export const InputError = styled.span`
  color: ${COLORS.negative.one};
  font-size: ${FONT_SIZE.one};
`;

export const TopUpText = styled.span`
  margin-top: ${SPACING.two}px;
  color: ${COLORS.negative.one};
  text-align: center;
  ${FONT_CSS.label.two}
`;

export const MinMaxButton = styled.button`
  background-color: ${COLORS.white.two};
  color: ${TEXT_COLORS.one};
  border-radius: 8px;
  padding: ${SPACING.one}px ${SPACING.two}px;
  font-size: ${FONT_SIZE.two};
`;
