import styled from "styled-components";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { FONT_WEIGHT } from "../../../constants/design/fontWeight";
import { SPACING } from "../../../constants/design/spacing";

export const InputWrapper = styled.div<{ width?: string; error?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${COLORS.white.five};
  border-radius: 6px;
  width: ${(props) => props.width ?? "100%"};
  padding: ${SPACING.two}px;
  height: ${SPACING.six}px;

  border: ${({ error }) => (error ? `1px solid ${COLORS.negative.one}` : "none")};

  input {
    color: ${TEXT_COLORS.one};
    background-color: transparent;
    border: none;
    padding-left: 8px;
    font-size: ${FONT_SIZE.three};
    line-height: ${FONT_SIZE.three};
    font-weight: ${FONT_WEIGHT.medium};
    // This fixes the input overflowing the container
    flex: 1;
    width: 0;

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${TEXT_COLORS.three};
      opacity: 1; /* Firefox */
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
`;

export const SuffixWrapper = styled.div`
  position: absolute;
  right: 0;
`;
