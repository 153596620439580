import { Assets } from "../../utils/assets";
import { OptionTypeResponse } from "../PreloadedStrikes";

export const OptionSide = {
  Buy: "buy",
  Sell: "sell",
} as const;

export type OptionSideResponse = (typeof OptionSide)[keyof typeof OptionSide];

export type Reason =
  | "LOSING"
  | "NO_OFFER"
  | "CANCEL"
  | "NO_EXECUTE"
  | "INSUFFICIENT_USER_FUNDS"
  | "PENDING"
  | "EXPIRED"
  | "UNCLAIMED";

export const ReasonText: Record<Reason, string> = {
  LOSING: "Losing offer",
  NO_OFFER: "No offer placed",
  CANCEL: "User cancelled order",
  NO_EXECUTE: "Did not execute trade in time",
  INSUFFICIENT_USER_FUNDS: "User did not have sufficient funds",
  PENDING: "Awaiting settlement",
  EXPIRED: "Expired",
  UNCLAIMED: "Funds not claimed",
};

export type Greeks = {
  delta: string;
  theta: string;
  vega: string;
  gamma: string;
};

export interface IPermitDetails {
  permitAddress: string;
  permitV: string;
  permitR: string;
  permitS: string;
  permitAmount: string;
  permitDeadline: string;
}

export interface IOrder {
  instrumentName: string;
  orderId: number;
  optionType: OptionTypeResponse;
  asset: Assets;
  expiry: string;
  optionSide?: OptionSideResponse;
  contracts: string;
  strike: string;
  totalPremiumPaid: string;
  price?: string;
  markPrice?: string;
  expiredAt?: string;
  performance?: string;
  userPermit?: IPermitDetails;
  orderStartTime?: string;
  orderOfferPeriodEnd?: string;
  orderEndTime?: string;
  offerPrice?: string;
  winningOffer?: string;
  pendingFilled?: boolean;
  reason?: Reason;
  roi?: string;
  iv?: string;
  spotRef?: string;
  indicativePrice?: string;
  customPrice?: string;
  entryPrice?: string;
  priceAtExpiry?: string;
  imAmount?: string;
  mmUtilization?: string;
  marginPosted?: string;
  marginRequirement?: string;
  requireTopUp?: boolean;
  topUpAmount?: string;
  withdrawableCollateral?: string;
  assetExpiryPrice?: string;
  greeks?: Greeks;
  totalMargin?: string;
  isUnwindOrder?: boolean;
  orderIdToUnwind?: number;
  isOriginalMm?: boolean;
  unwindPrice?: string;
}

export type IOrderType =
  | "PendingOrders"
  | "AccountPositions"
  | "TradeHistory"
  | "UnfilledOrders"
  | "LiveRequests"
  | "UnmatchedRequests"
  | "MMPositions"
  | "MMTradeHistory";

export type OrdersResponse = {
  loading: boolean;
  orders: IOrder[];
};
