// Used as reference when handling useForm validate errors
export enum FormValidatorKeysEnum {
  required = "required",
  decimalsTooLarge = "decimalsTooLarge",
  moreThanZero = "moreThanZero",
  notEnoughBalance = "notEnoughBalance",
  orderValueTooSmall = "orderValueTooSmall",
  notionalOutOfBounds = "notionalOutOfBounds",
  totalPremiumTooLow = "totalPremiumTooLow"
}
