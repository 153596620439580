import { useCallback, useMemo, useState } from "react";
import currency from "currency.js";
import { ReactComponent as Close } from "../../../../../assets/svg/close.svg";
import {
  InfoRow,
  HeaderContainer,
  AssetHeaderWrapper,
  InfoDetail,
  SmartContractRowWrapper,
  Form,
  ButtonsContainer,
} from "../../style";
import { IOrder, ReasonText } from "../../../../../interfaces/Orders";
import { LargeModalButtonV2, ModalButtonV2 } from "../../../../Buttons/styles";
import AssetWrapper from "../../../../shared/AssetWrapper";
import { fullExpiryTime } from "../../../../../utils/date";
import { COLORS, TEXT_COLORS } from "../../../../../constants/design/colors";
import { shortenAddress } from "../../../../../utils/strings";
import { ChainIdEnum } from "../../../../../enums/chain";
import { CHAIN_EXPLORER_URLS } from "../../../../../utils/chain";
import { ReactComponent as ArrowOut } from "../../../../../assets/svg/arrow-up-right.svg";
import addresses from "../../../../../constants/addresses/addresses.json";
import { ToastEnum, ToastStatusEnum } from "../../../../../utils/toast";
import { useToast } from "../../../../../hooks/toast";
import PerformanceContent from "../../PerformanceContent";
import { getAssetIndicPrecision } from "../../../../../utils/assets";
import { IInfoRowCol } from "../../../../../interfaces/InfoRow";
import useOtcWrapper from "../../../../../hooks/contracts/otcWrapper/useOtcWrapper";
import { Spinner } from "../../../../shared/Spinner";
import useOrder, {
  IUpdateRedeemedBody,
} from "../../../../../hooks/api/order/user/useOrder";
import { isProduction } from "../../../../../utils/env";

interface IMMTradeHistoryModalProps {
  order: IOrder;
  setShowModal: (show: boolean) => void;
}

function MMUnwindTradeHistoryModal({
  order,
  setShowModal,
}: IMMTradeHistoryModalProps) {
  const [loading, setLoading] = useState<boolean>();
  const {
    orderId,
    contracts,
    optionType,
    performance,
    expiry,
    strike,
    asset,
    offerPrice,
    priceAtExpiry,
    reason,
    totalMargin,
  } = order;
  const isPending = reason === "PENDING";
  const isUnclaimed = reason === "UNCLAIMED";
  const { addToast, addErrorToast } = useToast();
  const contractAddress = addresses.mainnet.contracts.otcWrapper;
  const { contract } = useOtcWrapper();
  const { updateRedeemed } = useOrder();
  const onHide = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const openBlockExplorer = useCallback(() => {
    window.open(
      `${
        CHAIN_EXPLORER_URLS[ChainIdEnum.ETH_MAINNET]
      }/address/${contractAddress}`
    );
  }, [contractAddress]);

  const infoRows: IInfoRowCol[] = useMemo(() => {
    const rows = [];
    if (reason) {
      rows.push({
        title: "Order Status",
        detail: ReasonText[reason],
        color: isPending || isUnclaimed ? COLORS.blue.one : undefined,
      });
    }
    rows.push({
      title: "Option Type",
      detail: `European ${optionType.replace(/^\w/, (c) => c.toUpperCase())}`,
    });
    rows.push({
      title: "Expired",
      detail: `${fullExpiryTime(expiry)}`,
      color: COLORS.blue.one,
    });
    rows.push({
      title: "Strike",
      detail: `${strike}`,
    });
    rows.push({
      title: "Contracts",
      detail: `${contracts}`,
    });
    rows.push({
      title: "Offer Price",
      detail: offerPrice
        ? `${currency(parseFloat(offerPrice), {
          precision: getAssetIndicPrecision(asset),
        }).format()}`
        : "-",
    });
    if (totalMargin) {
      rows.push({
        title: "Total Margin",
        detail: `${currency(totalMargin).format()}`,
        color: COLORS.blue.one,
      });
    }
    rows.push({
      title: "Smart Contract",
      detail: (
        <SmartContractRowWrapper onClick={openBlockExplorer}>
          <span>{shortenAddress(contractAddress)}</span>
          <ArrowOut />
        </SmartContractRowWrapper>
      ),
    });
    return rows;
  }, [reason, optionType, expiry, strike, contracts, offerPrice, asset, totalMargin, openBlockExplorer, contractAddress, isPending, isUnclaimed]);

  const headerContent = useMemo(
    () => (
      <>
        <AssetHeaderWrapper>
          <AssetWrapper asset={asset} priceAtExpiry={priceAtExpiry} />
        </AssetHeaderWrapper>
        <LargeModalButtonV2 type={"button"} onClick={onHide}>
          <Close />
        </LargeModalButtonV2>
      </>
    ),
    [asset, onHide, priceAtExpiry]
  );

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      if (contract) {
        if (isProduction()) {
          await contract.settleVault(String(orderId));
        }
        await updateRedeemed({
          order_id: Number(orderId),
          entity: "mm",
        } as IUpdateRedeemedBody);
        addToast(
          {
            type: ToastEnum.SIMPLE,
            header: "Withdrawal Complete",
            subheader: <span> USDC withdrawn</span>,
            status: ToastStatusEnum.SUCCESS,
          },
          10000
        );
      } else {
        throw Error;
      }
      setLoading(false);
    } catch (error: any) {
      addErrorToast("Something went wrong", "Please try again");
      onHide();
      setLoading(false);
    }
    onHide();
  }, [onHide, contract, orderId, updateRedeemed, addToast, addErrorToast]);

  const submitButtonContent = useMemo(
    () => (
      <ButtonsContainer>
        <ModalButtonV2
          type="button"
          disabled={loading}
          style={{ flex: 1 }}
          onClick={() => onSubmit()}
        >
          {loading ? <Spinner color={TEXT_COLORS.two} /> : "Withdraw Margin"}
        </ModalButtonV2>
      </ButtonsContainer>
    ),
    [loading, onSubmit]
  );
  return (
    <Form onSubmit={() => {}}>
      <HeaderContainer>{headerContent}</HeaderContainer>
      {performance && (
        <PerformanceContent
          title="Performance"
          performance={performance ? Number(performance) : 0}
        />
      )}
      {infoRows.map((infoRow) => (
        <InfoRow key={infoRow.title}>
          <span>{infoRow.title}</span>
          <InfoDetail color={infoRow.color}>{infoRow.detail}</InfoDetail>
        </InfoRow>
      ))}
      {isUnclaimed && submitButtonContent}
    </Form>
  );
}

export default MMUnwindTradeHistoryModal;
