import { useState, useCallback, useEffect } from "react";
import {
  shorthandTimeLeftToExpiry,
  longhandTimeLeftToExpiry,
} from "../../utils/date";
// Get the current account
export const useOrderTimes = () => {
  const [timeNow, setTimeNow] = useState<number>(Date.now() / 1000);
  // initial state setter
  useEffect(() => {
    setTimeNow(Date.now() / 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // change states every second
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeNow(Date.now() / 1000);
    }, 1000);
    return () => clearInterval(interval);
  }, [timeNow]);
  const orderExpiredCallback = useCallback(
    (orderEndTime: string | number) => shorthandTimeLeftToExpiry(orderEndTime) === "Expired",
    []
  );
  const timeArgs = useCallback(
    (startTime: number, offerPeriodEndTime: number, endTime: number) => {
      const orderOfferPeriodExpired = orderExpiredCallback(offerPeriodEndTime);
      const actualStartTime = orderOfferPeriodExpired ? offerPeriodEndTime : startTime;
      const actualEndTime = orderOfferPeriodExpired ? endTime : offerPeriodEndTime;
      const duration = actualEndTime - actualStartTime; // duration in seconds
      const elapsed = timeNow - actualStartTime; // elapsed time in seconds
      const orderExpired = orderExpiredCallback(endTime);
      return {
        timeToExpiry: longhandTimeLeftToExpiry(actualEndTime),
        percentage: (elapsed / duration) * 100, // percentage calculation
        orderOfferPeriodExpired,
        orderExpired,
        isOrderConfirmationTimeWindow: orderOfferPeriodExpired && !orderExpired,
      };
    },
    [orderExpiredCallback, timeNow]
  );

  return {
    timeNow,
    timeArgs,
  };
};
