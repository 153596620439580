import { AnimatePresence } from "framer-motion";
import { useCallback, useState } from "react";
import {
  CloseButton,
  Container,
  LogoContainer,
  NavItem,
  NavItems,
} from "./style";
import { ReactComponent as Logo } from "../../assets/logo/logo_text_white.svg";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
import { LINKS } from "../../constants/links";
import AboutAevoModal from "../Modals/AboutAevoModal";

interface IMobileNavigationMenuProps {
  open: boolean;
  onClose: () => void;
}

function MobileNavigationMenu({ open, onClose }: IMobileNavigationMenuProps) {
  const [showAboutAevoModal, setShowAboutAevoModal] = useState(false);

  const openURL = useCallback(
    (url: string) => {
      onClose();
      window.open(url);
    },
    [onClose]
  );

  return (
    <AnimatePresence>
      <AboutAevoModal
        show={showAboutAevoModal}
        onHide={() => setShowAboutAevoModal(false)}
      />
      {open && (
        <Container
          key="mobileNav"
          transition={{
            duration: 0.4,
            ease: "easeInOut",
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <NavItems>
            <NavItem onClick={() => setShowAboutAevoModal(true)}>About</NavItem>
            <NavItem onClick={() => openURL(LINKS.twitter)}>Twitter</NavItem>
            <NavItem onClick={() => openURL(LINKS.discord)}>Discord</NavItem>
            <NavItem onClick={() => openURL(LINKS.github)}>Github</NavItem>
            <NavItem onClick={() => openURL(LINKS.blog)}>Blog</NavItem>
            <NavItem onClick={() => openURL(LINKS.exchange)}>Exchange</NavItem>
          </NavItems>
          {/* For equal spacing as logo container above */}
          <LogoContainer />
          <CloseButton onClick={onClose}>
            <Close />
          </CloseButton>
        </Container>
      )}
    </AnimatePresence>
  );
}
export default MobileNavigationMenu;
