/* eslint-disable react/no-array-index-key */
import currency from "currency.js";
import { MutableRefObject, useCallback, useContext, useMemo } from "react";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";
import {
  NotificationsWrapper,
  NotificationsHeader,
  MarkAsRead,
  NotificationsList,
  NotificationWrapper,
  Icon,
  Title,
  IconWrapper,
  Timestamp,
  ReadIndicator,
  PillContent,
  FillNotificationTitle,
  FillNotificationBody,
  CloseButton,
  Pill,
  NotificationText,
} from "./style";
import { getAssetLogo } from "../../../utils/assets";
import {
  COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { getTimeAgo } from "../../../utils/date";
import { useNotifications } from "../../../hooks/api/notifications/useNotifications";
import { NotificationContext } from "../../../contexts/NotificationContext";
import { COMPONENTS } from "../../../constants/design/spacing";
import { Badge } from "../Badge";
import { Spinner } from "../Spinner";
import { SpinnerContainerWrapper } from "../Spinner/style";
import useScreenSize from "../../../hooks/screenSize/useScreenSize";
import { ReactComponent as Close } from "../../../assets/svg/close.svg";
import {
  INotification,
  NotificationType,
} from "../../../interfaces/Notification";
import { RequestPositionContext } from "../../../contexts/RequestPositionContext";
import { useOrder } from "../../../hooks/useOrderType";

function FillNotification({
  notification,
  handleNotificationClicked,
}: {
  notification: INotification;
  handleNotificationClicked: (orderId: number) => void;
}) {
  const {
    orderId,
    instrumentName,
    isRead,
    asset,
    contracts,
    offerPrice,
    timestamp,
  } = notification;

  return (
    <NotificationWrapper
      onClick={() => handleNotificationClicked(orderId)}
      isRead={isRead}
    >
      <IconWrapper>
        <Icon src={getAssetLogo(asset)} style={{ padding: 0 }} />
        {!isRead && <ReadIndicator />}
      </IconWrapper>
      <div>
        <Title>
          <FillNotificationTitle>
            <span>{instrumentName}</span>
          </FillNotificationTitle>
          <Pill
            color={COLORS.positive.one}
            backgroundColor={COLORS.positive.six}
            borderRadius={"4px"}
          >
            <PillContent>Filled</PillContent>
          </Pill>
        </Title>
        <FillNotificationBody>
          <span>{contracts}</span>
          <span style={{ color: TEXT_COLORS.three }}>contracts at</span>
          <span>{currency(Number(offerPrice || 0)).format()}</span>
        </FillNotificationBody>
        <Timestamp>{getTimeAgo(moment.unix(Number(timestamp)))}</Timestamp>
      </div>
    </NotificationWrapper>
  );
}

function ExpiredNotification({
  notification,
  handleNotificationClicked,
}: {
  notification: INotification;
  handleNotificationClicked: (orderId: number) => void;
}) {
  const {
    orderId,
    instrumentName,
    isRead,
    asset,
    contracts,
    offerPrice,
    timestamp,
  } = notification;

  return (
    <NotificationWrapper
      onClick={() => handleNotificationClicked(orderId)}
      isRead={isRead}
    >
      <IconWrapper>
        <Icon src={getAssetLogo(asset)} style={{ padding: 0 }} />
        {!isRead && <ReadIndicator />}
      </IconWrapper>
      <div>
        <Title>
          <FillNotificationTitle>
            <span>{instrumentName}</span>
          </FillNotificationTitle>
          <Pill
            color={COLORS.blue.one}
            backgroundColor={COLORS.blue.six}
            borderRadius={"4px"}
          >
            <PillContent>Expired</PillContent>
          </Pill>
        </Title>
        <FillNotificationBody>
          <span>{contracts}</span>
          <span style={{ color: TEXT_COLORS.three }}>contracts at</span>
          <span>{currency(Number(offerPrice || 0)).format()}</span>
        </FillNotificationBody>
        <Timestamp>{getTimeAgo(moment.unix(Number(timestamp)))}</Timestamp>
      </div>
    </NotificationWrapper>
  );
}

function PostMarginNotification({
  notification,
  handleNotificationClicked,
}: {
  notification: INotification;
  handleNotificationClicked: (orderId: number) => void;
}) {
  const {
    orderId,
    instrumentName,
    isRead,
    asset,
    contracts,
    offerPrice,
    timestamp,
    marginRequirement,
  } = notification;

  return (
    <NotificationWrapper
      onClick={() => handleNotificationClicked(orderId)}
      isRead={isRead}
    >
      <IconWrapper>
        <Icon src={getAssetLogo(asset)} style={{ padding: 0 }} />
        {!isRead && <ReadIndicator />}
      </IconWrapper>
      <div>
        <Title>
          <FillNotificationTitle>
            <span>{instrumentName}</span>
          </FillNotificationTitle>
        </Title>
        <FillNotificationBody>
          <span>{contracts}</span>
          <span style={{ color: TEXT_COLORS.three }}>contracts at</span>
          <span>{currency(Number(offerPrice || 0)).format()}</span>
        </FillNotificationBody>
        <FillNotificationBody>
          <span style={{ color: COLORS.negative.one }}>
            Required margin{" "}
            <span style={{ color: TEXT_COLORS.three }}>
              to maintain your short position is now{" "}
            </span>
            {currency(Number(marginRequirement || 0)).format()}
          </span>
        </FillNotificationBody>
        <Timestamp>{getTimeAgo(moment.unix(Number(timestamp)))}</Timestamp>
      </div>
    </NotificationWrapper>
  );
}

interface INotificationPanelProps {
  modalRef: MutableRefObject<any>;
}

export function NotificationPanel({ modalRef }: INotificationPanelProps) {
  const {
    // data: notificationsData,
    // isValidating,
    notifications,
    unreadNotifications,
    markAsRead,
  } = useNotifications();
  const { showPanel, setShowPanel } = useContext(NotificationContext);
  const { setOrder, setOrderType, setShowRequestPositionModal } = useContext(
    RequestPositionContext
  );
  const { getOrder, getOrderType } = useOrder();
  const { isMobileScreen } = useScreenSize();
  const notificationsBadgeColor = useMemo(
    () => (unreadNotifications.length > 0 ? [COLORS.blue.one, COLORS.blue.two] : []),
    [unreadNotifications.length]
  );

  const isLoading = useMemo(() => !notifications, [notifications]);

  const handleNotificationClicked = useCallback(
    (orderId: number) => {
      const order = getOrder(orderId);
      const orderType = getOrderType(orderId);
      if (order && orderType) {
        setOrder(order);
        setOrderType(orderType);
        setShowRequestPositionModal(true);
      }
    },
    [getOrder, getOrderType, setOrder, setOrderType, setShowRequestPositionModal]
  );

  const notificationContent = useCallback(
    (notification: INotification) => {
      if (notification.type === NotificationType.Filled) {
        return (
          <FillNotification
            notification={notification}
            handleNotificationClicked={handleNotificationClicked}
          />
        );
      }

      if (notification.type === NotificationType.Expired) {
        return (
          <ExpiredNotification
            notification={notification}
            handleNotificationClicked={handleNotificationClicked}
          />
        );
      }

      if (notification.type === NotificationType.PostMargin) {
        return (
          <PostMarginNotification
            notification={notification}
            handleNotificationClicked={handleNotificationClicked}
          />
        );
      }

      return null;
    },
    [handleNotificationClicked]
  );

  return (
    <AnimatePresence>
      {showPanel && (
        <NotificationsWrapper
          $isMobileScreen={isMobileScreen}
          ref={modalRef}
          transition={{
            duration: 0.5,
            ease: "easeInOut",
          }}
          initial={{
            opacity: 0,
            transform: `translateX(${COMPONENTS.notificationPanel}px)`,
          }}
          animate={{
            opacity: 1,
            transform: "translateX(0px)",
          }}
          exit={{
            opacity: 0,
            transform: `translateX(${COMPONENTS.notificationPanel}px)`,
          }}
        >
          <NotificationsHeader>
            <NotificationText>
              <p>Notifications </p>
              {unreadNotifications.length > 0 && (
                <Badge
                  color={notificationsBadgeColor[0]}
                  backgroundColor={notificationsBadgeColor[1]}
                >
                  {unreadNotifications.length}
                </Badge>
              )}
            </NotificationText>
            <MarkAsRead>
              <button
                type="button"
                disabled={unreadNotifications.length === 0}
                onClick={() => markAsRead()}
              >
                Mark all as read
              </button>
            </MarkAsRead>
          </NotificationsHeader>
          <NotificationsList>
            {isLoading ? (
              <SpinnerContainerWrapper>
                <Spinner />
              </SpinnerContainerWrapper>
            ) : (
              (notifications || []).map(
                (notification: INotification, i: number) => (
                  <motion.div
                    key={i}
                    transition={{
                      duration: 0.5,
                      ease: "easeInOut",
                      delay: (i + 1) * 0.05,
                    }}
                    initial={{
                      opacity: 0,
                      transform: "translateY(-20px)",
                    }}
                    animate={{
                      opacity: 1,
                      transform: "translateY(0px)",
                    }}
                  >
                    {notificationContent(notification)}
                  </motion.div>
                )
              )
            )}
          </NotificationsList>
          <CloseButton
            onClick={() => {
              markAsRead();
              setShowPanel(false);
            }}
          >
            <Close />
          </CloseButton>
        </NotificationsWrapper>
      )}
    </AnimatePresence>
  );
}
