import React from "react";

type SVGProps = React.SVGAttributes<SVGElement>;

export const plus = ({
  color,
  ...props
}: SVGProps & { color: string }): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 4.16669V15.8334"
      stroke={color || "white"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.16797 10H15.8346"
      stroke={color || "white"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
