import styled from "styled-components";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { BACKGROUND_COLORS, BORDER_COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_STYLE } from "../../constants/design/fontSize";

export const PriceMarqueeWrapper = styled.div`
  width: 100%;
  position: relative;
  height: ${COMPONENTS.marqueeHeight}px;
  border-bottom: 1px solid ${BORDER_COLORS.one};
  top: 0;
  left: 0;
  background: ${BACKGROUND_COLORS.five};
  z-index: 1;
  p {
    margin: auto 0;
  }

  .marquee {
    display: flex;
    height: fit-content;
    justify-content: space-around;

  }

  .marquee-container {
    overflow: hidden;
    height: fit-content;
  }
`;

export const TickerContainer = styled.div`
  display: flex;
  width: fit-content;
  margin-left: ${SPACING.four}px;
  > {
    &:not(:last-child) {
      margin-right: ${SPACING.two}px;
    }
  }
`;

export const MarqueeWrapper = styled.div`
  display: flex;
  height: ${COMPONENTS.marqueeHeight}px;
  align-items: center;
`;

export const TickerTitle = styled.div`
  ${FONT_STYLE.header.seven}
  margin: auto 0;
  color: white;
`;

export const TickerPrice = styled.div`
  ${FONT_STYLE.header.seven}
  margin: auto 0;
  color: ${TEXT_COLORS.two};
`;

export const TickerChange = styled.div<{ color: string }>`
  ${FONT_STYLE.header.seven}
  margin: auto 0;
  color: ${(props) => (props.color)};
`;
