import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useIndicativePriceData from "../../hooks/api/strikes/useIndicativePriceData";
import { Assets, UnderlyerAssetsList } from "../../utils/assets";
import { IndicativePriceDataRequest } from "../../interfaces/IndicativePriceData";
import {
  Data,
  IStrikeData,
  ReversedExpiries,
  OptionTypeResponse,
  OptionResponse,
} from "../../interfaces/PreloadedStrikes";
import { OptionsDataContext } from "../OptionsDataContext";
import useOtcWrapper from "../../hooks/contracts/otcWrapper/useOtcWrapper";
import { formatBigNumber } from "../../utils/format";
import { OptionSide, OptionSideResponse } from "../../interfaces/Orders";

export interface IExtraValues {
  indic: string | undefined;
  indicWithoutFees: string | undefined;
  iv: string | undefined;
  delta: string | undefined;
  theta: string | undefined;
  vega: string | undefined;
}

export interface IIndicativePriceData extends IExtraValues {
  vega: string | undefined;
  strike: string | undefined;
}

interface IOptionsChainContext {
  selectedValues: {
    asset: Assets;
    optionType: OptionTypeResponse;
    optionSide: OptionSideResponse;
    strike: string | undefined;
    expiry: number | undefined;
    spotRef: string | undefined;
    extraValues: IExtraValues | undefined;
  };
  assets: Assets[];
  optionTypes: OptionTypeResponse[];
  optionSides: OptionSideResponse[];
  strikes: string[];
  expiries: number[];
  reversedExpiriesMap: ReversedExpiries;
  isManualStrike: boolean;
  isManualExpiry: boolean;
  requestBody?: IndicativePriceDataRequest;
  setAsset: (asset: Assets) => void;
  setStrike: (strikes?: string) => void;
  setExpiry: (expiry?: number) => void;
  setExtraValues: (extraValues?: IExtraValues) => void;
  setOptionType: (orderType: OptionTypeResponse) => void;
  setOptionSide: (optionSide: OptionSideResponse) => void;
  setIsManualStrike: (isManualStrike: boolean) => void; // whether user is using a manual strike
  setIsManualExpiry: (isManualExpiry: boolean) => void; // whether user is using a manual expiry
  setManualStrikeExtraValues: (
    body: IndicativePriceDataRequest
  ) => Promise<void>;
  setManualExpiryExtraValues: (
    body: IndicativePriceDataRequest
  ) => Promise<void>;
  reset: () => void;
  resetStrike: () => void;
}

// Stores all the available markets,
// and the filters available
export const TradeInstrumentContext = React.createContext<IOptionsChainContext>(
  {
    selectedValues: {
      asset: UnderlyerAssetsList[0],
      optionType: OptionResponse.Call,
      optionSide: OptionSide.Buy,
      spotRef: undefined,
      strike: undefined,
      expiry: undefined,
      extraValues: undefined,
    },
    assets: [],
    optionTypes: [],
    optionSides: [],
    strikes: [],
    expiries: [],
    reversedExpiriesMap: {},
    isManualStrike: false,
    isManualExpiry: false,
    requestBody: undefined,
    // OPTIONS
    setAsset: () => {},
    setStrike: () => {},
    setExpiry: () => {},
    setOptionType: () => {},
    setOptionSide: () => {},
    setExtraValues: () => {},
    setIsManualStrike: () => {},
    setIsManualExpiry: () => {},
    setManualStrikeExtraValues: async () => {},
    setManualExpiryExtraValues: async () => {},
    reset: () => {},
    resetStrike: () => {},
  }
);

export function TradeInstrumentContextProvider({
  children,
}: PropsWithChildren) {
  const [selectedAsset, setAsset] = useState<Assets>(UnderlyerAssetsList[0]);
  const [selectedStrike, setStrike] = useState<string>();
  const [selectedExpiry, setExpiry] = useState<number>();
  const [spotRef, setSpotRef] = useState<string>();
  const [isManualStrike, setIsManualStrike] = useState<boolean>(false);
  const [isManualExpiry, setIsManualExpiry] = useState<boolean>(false);
  const [requestBody, setRequestBody] = useState<IndicativePriceDataRequest>();
  const [selectedOptionType, setOptionType] = useState<OptionTypeResponse>(
    OptionResponse.Call
  );
  const [selectedOptionSide, setOptionSide] = useState<OptionSideResponse>(
    OptionSide.Buy
  );

  const {
    data: { fees },
  } = useOtcWrapper(undefined, undefined, undefined, true);
  const [selectedExtraValues, setExtraValues] = useState<IExtraValues>();

  const { getIndicativePrice } = useIndicativePriceData();

  // makes an api request then sets the values
  // also saves the body to be rerequested after countdown;
  const setManualStrikeExtraValues = async (
    body: IndicativePriceDataRequest
  ) => {
    setStrike(body.strike);
    setIsManualStrike(true);
  };

  // makes an api request then sets the values
  // also saves the body to be rerequested after countdown;
  const setManualExpiryExtraValues = async (
    body: IndicativePriceDataRequest
  ) => {
    setExpiry(Number(body.expiry));
    setIsManualExpiry(true);
  };

  const { preloadedStrikes } = useContext(OptionsDataContext);

  const expiriesMap = useMemo(() => {
    const { expiries } = preloadedStrikes.preloadedStrikes;
    if (expiries.weekly === expiries.monthly) {
      delete expiries.monthly;
    }
    return expiries;
  }, [preloadedStrikes.preloadedStrikes]);

  const reversedExpiriesMap: ReversedExpiries = Object.entries(
    expiriesMap
  ).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {});

  const assets = useMemo(() => UnderlyerAssetsList, []);

  const expiries = useMemo(
    () => [
      ...new Set(Object.values(expiriesMap).map((expiry) => Number(expiry))),
    ],
    [expiriesMap]
  );

  const optionTypes = useMemo(() => {
    const types: OptionTypeResponse[] = ["call", "put"];
    return types;
  }, []);

  const optionSides = useMemo(() => {
    const sides: OptionSideResponse[] = ["buy", "sell"];
    return sides;
  }, []);

  const datas = preloadedStrikes?.preloadedStrikes.data || [];

  const assetMap = datas.find((data: Data) => data.underlyer === selectedAsset);

  const strikeDatas = useMemo(() => {
    if (
      !assetMap
      || !selectedOptionType
      || !selectedExpiry
      || isManualExpiry
      || selectedOptionSide === OptionSide.Sell
    ) {
      return undefined;
    }

    return assetMap.prices[reversedExpiriesMap[selectedExpiry]][
      selectedOptionType
    ];
  }, [
    assetMap,
    isManualExpiry,
    reversedExpiriesMap,
    selectedExpiry,
    selectedOptionSide,
    selectedOptionType,
  ]);

  const selectedStrikeData = useMemo(() => {
    if (!strikeDatas) return undefined;
    return strikeDatas.find(
      (strikeData: IStrikeData) => strikeData.strike === selectedStrike
    );
  }, [selectedStrike, strikeDatas]);

  const strikes = useMemo(() => {
    if (!strikeDatas) {
      return [];
    }
    return strikeDatas.map((strikeData: IStrikeData) => strikeData.strike);
  }, [strikeDatas]);

  const getExtraValues = useCallback(() => {
    if (
      selectedStrikeData
      && selectedOptionType
      && selectedAsset
      && selectedExpiry
      && assetMap
      && fees
    ) {
      const feesPercentage = formatBigNumber(fees[selectedAsset], 6);
      setExtraValues({
        indic: String(
          Number(selectedStrikeData.indic) + Number(spotRef) * feesPercentage
        ),
        indicWithoutFees: selectedStrikeData.indic,
        iv: selectedStrikeData.iv,
        delta: selectedStrikeData.delta,
        theta: selectedStrikeData.theta,
        vega: selectedStrikeData.vega,
      });
    }
  }, [
    assetMap,
    fees,
    selectedAsset,
    selectedExpiry,
    selectedOptionType,
    selectedStrikeData,
    spotRef,
  ]);

  // Return the selected values, or if editing order exists, return that instead
  const selectedValues = useMemo(
    () => ({
      asset: selectedAsset,
      strike: selectedStrike || strikes[0],
      expiry: selectedExpiry,
      optionType: selectedOptionType,
      optionSide: selectedOptionSide,
      extraValues: selectedExtraValues,
      spotRef,
    }),
    [
      selectedAsset,
      selectedExpiry,
      selectedExtraValues,
      selectedOptionSide,
      selectedOptionType,
      selectedStrike,
      spotRef,
      strikes,
    ]
  );

  const reset = useCallback(() => {
    setStrike(strikes[0]);
    if (!isManualStrike) {
      setExpiry(undefined);
    }
    getExtraValues();
    setIsManualStrike(false);
    setIsManualExpiry(false);
    setRequestBody(undefined);
    // we do not want reset whenever dependencies change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetStrike = useCallback(() => {
    setStrike(strikes[0]);
    setIsManualStrike(false);
    // we do not want reset whenever dependencies change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if manual strike is selected, we reset the strike when they change asset, orderType or expiry
  useEffect(() => {
    if (isManualStrike) {
      resetStrike();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues.asset, selectedValues.optionType, selectedValues.expiry]);

  // if manual expiry is selected, we reset the strike when they change asset, orderType
  useEffect(() => {
    if (
      isManualExpiry
      && selectedValues.expiry
      && expiries.includes(selectedValues.expiry)
    ) {
      setIsManualExpiry(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues.asset, selectedValues.optionType, selectedValues.expiry]);

  useEffect(() => {
    if (selectedExpiry) {
      setOptionType((type) => {
        // If no selected type OR if
        // optionTypes doesnt include the current selected type
        // set
        if (!type) {
          return optionTypes[0];
        }
        if (!optionTypes.includes(type)) {
          return optionTypes[0];
        }
        return type;
      });
    }
  }, [selectedExpiry, optionTypes]);

  // When there are existing expiries but none is selected, default to the first
  useEffect(() => {
    if (!selectedExpiry && expiries.length > 0) setExpiry(expiries[0]);
  }, [expiries, selectedExpiry, setExpiry]);

  useEffect(() => {
    if (!selectedStrike) {
      setStrike(strikes[0]);
    }
  }, [getExtraValues, selectedStrike, strikes]);

  // if not manual strike price and
  // if selected strike is not available anymore
  // set default strike again
  useEffect(() => {
    if (
      !isManualStrike
      && selectedStrike
      && !strikes.includes(selectedStrike)
    ) {
      setStrike(strikes[0]);
    }
  }, [getExtraValues, isManualStrike, selectedStrike, strikes]);

  useEffect(() => {
    getExtraValues();
  }, [getExtraValues]);

  useEffect(() => {
    if (assetMap) {
      setSpotRef(assetMap.spot_ref);
    }
  }, [assetMap]);

  return (
    <TradeInstrumentContext.Provider
      value={{
        selectedValues,
        assets,
        optionTypes,
        optionSides,
        strikes,
        expiries,
        reversedExpiriesMap,
        isManualStrike,
        isManualExpiry,
        requestBody,
        setAsset,
        setStrike,
        setExpiry,
        setOptionType,
        setOptionSide,
        setExtraValues,
        setIsManualStrike,
        setIsManualExpiry,
        setManualStrikeExtraValues,
        setManualExpiryExtraValues,
        reset,
        resetStrike,
      }}
    >
      {children}
    </TradeInstrumentContext.Provider>
  );
}
