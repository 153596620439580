import { motion } from "framer-motion";
import styled from "styled-components";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import {
  FONT_CSS,
  FONT_SIZE,
  FONT_STYLE,
} from "../../../constants/design/fontSize";
import { SPACING, COMPONENTS } from "../../../constants/design/spacing";

export const NotificationsWrapper = styled(motion.div)<{
  $isMobileScreen: boolean;
}>`
  position: absolute;
  right: ${SPACING.three}px;
  top: ${COMPONENTS.header.main + SPACING.two}px;
  bottom: ${COMPONENTS.footer.main}px;
  width: ${COMPONENTS.notificationPanel}px;
  z-index: 10;
  border-radius: 8px;
  background-color: ${BACKGROUND_COLORS.component};
  overflow: auto;
  border: 1px solid ${BORDER_COLORS.two};

  ${({ $isMobileScreen }) => $isMobileScreen
    && `top: ${SPACING.three}px;
    bottom: ${SPACING.three}px;
    right: ${SPACING.three}px;
    left: ${SPACING.three}px;
    width: unset;`}
`;

export const NotificationsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${BORDER_COLORS.two};
  box-sizing: content-box;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: ${BACKGROUND_COLORS.component};
  height: ${COMPONENTS.header.sub}px;
  padding: 0;
  box-sizing: border-box;

  div {
    padding: ${SPACING.three}px;
    display: flex;

    > *:not(:last-child) {
      margin-right: ${SPACING.one}px;
    }
  }

  p {
    ${FONT_CSS.header.six};
    line-height: inherit;
    margin: 0;
  }
`;

export const MarkAsRead = styled.div`
  > * {
    transition: 0.2s ease-in-out;
    font-size: ${FONT_SIZE.one};
    color: ${TEXT_COLORS.three};

    &:hover {
      color: ${TEXT_COLORS.one};
    }
  }
`;

export const NotificationsList = styled.div`
  > div {
    border-bottom: 1px solid ${BORDER_COLORS.two};
  }
`;

export const NotificationWrapper = styled.div<{ isRead?: boolean }>`
  display: flex;
  role: button;
  cursor: pointer;
  padding: ${SPACING.three}px;
  background-color: ${({ isRead }) => (isRead ? "transparent" : BACKGROUND_COLORS.component)};
  transition: 0.1s ease-in-out;

  &:hover {
    background-color: ${BACKGROUND_COLORS.component};
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${BORDER_COLORS.two};
  }
`;

export const IconWrapper = styled.div<{
  backgroundColor?: string;
  isRead?: boolean;
}>`
  width: fit-content;
  height: fit-content;
  background-color: ${({ backgroundColor }) => backgroundColor ?? "transparent"};
  border-radius: 50%;
  margin-right: ${SPACING.two}px;
  justify-content: center;
  display: grid;
  align-items: center;
  margin-top: ${SPACING.one}px;
  position: relative;
`;

export const Icon = styled.img`
  width: 32px;
  height: 32px;
  padding: ${SPACING.two}px;
`;

export const Title = styled.div<{ color?: string }>`
  color: ${({ color }) => color ?? TEXT_COLORS.one};
  text-transform: capitalize;
  ${FONT_STYLE.body.three};
`;

export const Content = styled.div<{ color?: string }>`
  color: ${({ color }) => color ?? TEXT_COLORS.one};
  margin-top: ${SPACING.one}px;
  margin-bottom: ${SPACING.three}px;
  ${FONT_STYLE.body.four};
  display: flex;

  > div:not(:last-of-type) {
    margin-right: ${SPACING.one}px;
  }
`;

export const Timestamp = styled.p`
  color: ${TEXT_COLORS.three};
  ${FONT_STYLE.body.five}
  margin-top: ${SPACING.three}px;
  margin-bottom: 0;
`;

export const ReadIndicator = styled.span<{ color?: string }>`
  position: absolute;
  width: 6px;
  height: 6px;
  bottom: 2px;
  right: 2px;
  border-radius: 50%;
  background: ${({ color }) => color ?? COLORS.blue.one};
  transition: 0.2s all ease-in-out;
`;

export const PillContent = styled.div`
  display: inline-block;
  text-transform: capitalize;
  ${FONT_CSS.label.three};
`;

export const FillNotificationTitle = styled.div`
  display: inline-flex;

  > span {
    ${FONT_STYLE.body.four};
  }
`;

export const FillNotificationBody = styled.div`
  display: flex;
  white-space: pre-wrap;
  margin-top: ${SPACING.two}px;
  > span {
    ${FONT_CSS.data.four}
    display: block;
    &:not(:last-of-type) {
      margin-right: ${SPACING.one}px;
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  bottom: ${SPACING.three}px;
  left: 50%;
  transform: translateX(-50%);
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background-color: ${COLORS.white.five};
`;

export const Pill = styled.span<{ color: string; backgroundColor: string; borderRadius?: string }>`
  border-radius: ${({ borderRadius }) => borderRadius ?? "12px"};
  padding: ${SPACING.one}px ${SPACING.two}px;
  font-size: ${FONT_SIZE.one};
  margin-left: ${SPACING.two}px;

  ${({ color, backgroundColor }) => `
    background-color: ${backgroundColor};
    color: ${color};
  `};
`;

export const NotificationText = styled.div`
  display: flex;
  align-items: center;
`;
