/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import {
  LinkText,
  MainFooterWrapper,
  MiddleFooterWrapper,
  SvgContainer,
} from "../style";
import { settings as Settings } from "../../../../assets/styledSvg/settings";
import { messageSquare as MessageSquare } from "../../../../assets/styledSvg/messageSquare";
import { COLORS, ICON_COLORS } from "../../../../constants/design/colors";
import AboutAevoModal from "../../../Modals/AboutAevoModal";
import { PendingOrdersFooter } from "../../../PendingOrders/PendingOrdersFooter";
import { SettingsModal } from "../../../Modals/SettingsModal";
import useRoutes from "../../../../hooks/routes/useRoutes";

function DesktopFooter() {
  const [showAboutAevoModal, setShowAboutAevoModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState<boolean>(false);
  const [showTelegramModal, setShowTelegramModal] = useState<boolean>(false);
  const { isMMPage } = useRoutes();
  return (
    <MainFooterWrapper>
      <AboutAevoModal
        show={showAboutAevoModal}
        onHide={() => setShowAboutAevoModal(false)}
      />
      <SettingsModal
        show={showSettingsModal}
        onHide={() => setShowSettingsModal(false)}
        onlyShowSFX
      />
      {isMMPage ? (
        <SvgContainer
          isOpen={showTelegramModal}
          onClick={() => setShowTelegramModal(!showTelegramModal)}
        >
          <MessageSquare
            color={showTelegramModal ? COLORS.blue.one : ICON_COLORS.two}
          />
        </SvgContainer>
      ) : (
        <PendingOrdersFooter />
      )}
      <MiddleFooterWrapper>
        <LinkText role="button" onClick={() => setShowAboutAevoModal(true)}>
          About OTC
        </LinkText>
      </MiddleFooterWrapper>
      <SvgContainer
        isOpen={showSettingsModal}
        onClick={() => setShowSettingsModal(true)}
      >
        <Settings
          color={showSettingsModal ? COLORS.blue.one : ICON_COLORS.two}
        />
      </SvgContainer>
    </MainFooterWrapper>
  );
}

export default DesktopFooter;
