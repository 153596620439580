/* eslint-disable no-console */
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm, useWatch } from "react-hook-form";
import { AnimatePresence } from "framer-motion";
import { ethers } from "ethers";
import currency from "currency.js";
import { parseUnits } from "ethers/lib/utils";
import { refresh as RefreshButton } from "../../../assets/styledSvg/refresh";
import {
  IExtraValues,
  TradeInstrumentContext,
} from "../../../contexts/TradeInstrumentContext";
import {
  OptionResponse,
  OptionTypeResponse,
} from "../../../interfaces/PreloadedStrikes";
import OptionFilter from "../../OptionFilter";
import {
  PermitButton,
  ContentWrapper,
  Form,
  Padding,
  PlaceOrderButton,
  IndicativePriceWrapper,
  IndicativePriceText,
  IndicativePriceTextLarge,
  RefreshButtonWrapper,
  OptionTypeWrapper,
  InfoRow,
  Title,
  Value,
  OrderInfoWrapper,
  AbsoluteBorderContainer,
  RefreshSvgWrapper,
  ButtonsContainer,
  IndicativePriceTextSmall,
  IndicativePriceValue,
  FlashingText,
  BottomText,
  InputsWrapper,
} from "../style";
import { ContractInput } from "./form";
import usePlaceOrder, {
  IFullPlaceOrderBody,
  IPlaceOrderBody,
} from "../../../hooks/api/order/user/usePlaceOrder";
import useWallet from "../../../hooks/wallet/useWallet";
import useUSDC from "../../../hooks/contracts/usdc/useUSDC";
import SegmentedControl from "../../shared/SegmentedControl";
import {
  BACKGROUND_COLORS,
  COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { info as SmallInfo } from "../../../assets/styledSvg/info";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { IOrderInfo } from "../../../interfaces/OrderInfo";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";
import TradeSelectionDropdown from "../../TradeSelectionDropdown";
import useFetchPreloadedStrikes from "../../../hooks/api/strikes/useFetchPreloadedStrikes";
import { OptionsDataContext } from "../../../contexts/OptionsDataContext";
import {
  getAssetIndicPrecision,
  getAssetPrecision,
  UNDERLYER_ADDRESSES,
} from "../../../utils/assets";
import { ConfirmOrderContext } from "../../../contexts/ConfirmOrderContext";
import ConfirmOrderModal from "../../../pages/TradePage/CreateTradeTab/ConfirmOrderModal";
import { useToast } from "../../../hooks/toast";
import useScreenSize from "../../../hooks/screenSize/useScreenSize";
import useSignature from "../../../hooks/contracts/usdc/useSignature";
import useAssetPriceData from "../../../hooks/api/prices/useAssetPriceData";
import useOtcWrapper from "../../../hooks/contracts/otcWrapper/useOtcWrapper";
import addresses from "../../../constants/addresses/addresses.json";
import { IOrderSignature } from "../../../interfaces/OtcWrapper";
import useMinimalForwarder from "../../../hooks/contracts/minimalForwarder/useMinimalForwarder";
import { FormValidatorKeysEnum } from "../../../enums/form";
import { RequestPositionContext } from "../../../contexts/RequestPositionContext";
import useSetOraclePrice from "../../../hooks/api/prices/useSetOraclePrice";
import { ModalFooterContent } from "../../ModalFooterContent";
import PayoffContent from "../../PayoffContent";
import { ReactComponent as Info } from "../../../assets/svg/info.svg";
import { ReactComponent as BarChart } from "../../../assets/svg/bar-chart.svg";
import { ModalButtonV2 } from "../../Buttons/styles";
import { useSFX } from "../../../hooks/useSFX";
import TradingGuideModal from "../../Modals/TradingGuideModal";
import TooltipExplanation from "../../shared/Tooltip";
import { isProduction } from "../../../utils/env";
import SelectCounterpartyModal from "../../Modals/SelectCounterpartyModal";
import useMarginRequirement from "../../../hooks/contracts/marginRequirement/useMarginRequirement";
import { formatBigNumber } from "../../../utils/format";
import { MINIMUM_TOTAL_PREMIUM } from "../../../constants/endpoints/endpoints";
import useSignSignature, {
  IRequest,
} from "../../../hooks/contracts/usdc/useSignSignature";

export enum PageType {
  Trade,
  Payoff,
}

function OptionsTradeForm() {
  const {
    register,
    control,
    trigger,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  // states
  const [loading, setLoading] = useState<boolean>(false);
  const [confirming, setConfirming] = useState<boolean>(false);
  const [isAnimationPaused, setAnimationPaused] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [selectedCounterparty, setSelectedCounterparty] = useState<string>();
  const [showTradingGuideModal, setShowTradingGuideModal] = useState(false);
  const [showSelectCounterpartyModal, setShowSelectCounterpartyModal]
    = useState(false);
  const [page, setPage] = useState<PageType>(PageType.Trade);
  const [confirmedExtraValues, setConfirmedExtraValues]
    = useState<IExtraValues>();
  const countdownTime = 15;
  const minTotalPremium = MINIMUM_TOTAL_PREMIUM;
  // initialize countdown of 15 seconds
  const [countdown, setCountdown] = useState(countdownTime);
  // hooks
  const { getPreloadedStrikes } = useFetchPreloadedStrikes();
  const { addErrorToast } = useToast();
  const { updatePreloadedStrikes, pendingOrders }
    = useContext(OptionsDataContext);
  const { placeBuyOrder } = usePlaceOrder();
  const { showConfirmOrderModal, setShowConfirmOrderModal }
    = useContext(ConfirmOrderContext);
  const { setOrder, setOrderType, setShowRequestPositionModal } = useContext(
    RequestPositionContext
  );
  const [orderId, setOrderId] = useState<number>();
  const { playSound } = useSFX();
  // after placeOrder, when pendingOrders response has the order, open pendingOrderModal
  useEffect(() => {
    if (orderId) {
      const pendingOrder = pendingOrders.orders.find(
        (obj) => obj.orderId === orderId
      );
      if (pendingOrder) {
        playSound("order_placed");
        setOrderType("PendingOrders");
        setOrder(pendingOrder);
        setShowRequestPositionModal(true);
        setOrderId(undefined);
      }
    }
  }, [
    orderId,
    pendingOrders.orders,
    playSound,
    setOrder,
    setOrderType,
    setShowRequestPositionModal,
  ]);

  const {
    selectedValues,
    setOptionType,
    isManualStrike,
    requestBody,
    reset,
    setManualStrikeExtraValues,
  } = useContext(TradeInstrumentContext);
  const { useAssetPrice } = useAssetPriceData();
  const { price } = useAssetPrice({
    asset: selectedValues.asset,
  });
  const { isMediumScreen } = useScreenSize();

  const hasError = Boolean(errors?.contracts?.type);
  const contracts = useWatch({ control, name: "contracts" });

  const isPut = selectedValues.optionType === OptionResponse.Put;

  const { handleSetOraclePrice } = useSetOraclePrice();
  const { account, provider } = useWallet();

  const {
    contract: otcWrapperContract,
    isNotionalOutOfBounds,
    data: { minMaxNotional },
  } = useOtcWrapper(undefined, true);
  const usdcAddress = addresses.mainnet.assets.usdc;
  const usdc = useUSDC();
  const { contract: minimalForwarderContract, nonce } = useMinimalForwarder();
  const usdcBalance = usdc.userBalance
    ? ethers.utils.formatUnits(usdc.userBalance, usdc.decimals)
    : undefined;

  const modalRef = useRef<HTMLDivElement>(null);

  const [additionalHeight, contentWrapperHeight] = useMemo(() => {
    const errorTextHeight = hasError ? COMPONENTS.errorTextHeight : 0;
    const additionalHeightCalc = errorTextHeight;
    return [
      additionalHeightCalc,
      additionalHeightCalc
        + (isMediumScreen
          ? COMPONENTS.marketModalMobileHeight
          : COMPONENTS.marketModalHeight),
    ];
  }, [hasError, isMediumScreen]);

  const [minNotionalNumber, maxNotionalNumber] = useMemo(() => {
    if (!minMaxNotional) {
      return [0, 0];
    }
    return [
      ethers.utils.formatUnits(
        minMaxNotional[selectedValues.asset].min,
        usdc.decimals
      ),
      ethers.utils.formatUnits(
        minMaxNotional[selectedValues.asset].max,
        usdc.decimals
      ),
    ];
  }, [minMaxNotional, selectedValues.asset, usdc.decimals]);

  const {
    data: { totalFees },
  } = useMarginRequirement({
    collateralAsset: "USDC",
    underlyerAsset: selectedValues.asset,
    isPut: selectedValues.optionType === "put",
    contracts,
  });

  // calculates totalPremiumPaid, if user permitted the value will not update
  const totalPremiumPaid = useCallback(
    (contractSize: string) => {
      if (!totalFees) {
        return "0";
      }
      if (confirmedExtraValues) {
        return (
          Number(contractSize) * Number(confirmedExtraValues.indic)
          + formatBigNumber(totalFees, usdc.decimals)
        ).toFixed(usdc.decimals);
      }
      if (selectedValues.extraValues) {
        return (
          Number(contractSize) * Number(selectedValues.extraValues.indic)
          + formatBigNumber(totalFees, usdc.decimals)
        ).toFixed(usdc.decimals);
      }
      return "0";
    },
    [confirmedExtraValues, selectedValues.extraValues, totalFees, usdc.decimals]
  );

  const totalNotional = useCallback(
    (contractSize: string) => {
      if (selectedValues.optionType === OptionResponse.Call) {
        return (Number(contractSize) * Number(price)).toFixed(usdc.decimals);
      }
      return (Number(contractSize) * Number(selectedValues.strike)).toFixed(
        usdc.decimals
      );
    },
    [price, selectedValues.optionType, selectedValues.strike, usdc.decimals]
  );

  const {
    permitting,
    approvedAmountAndSignature,
    resetSignature,
    handlePermit,
    hasValidSignature,
  } = useSignature({
    amount: totalPremiumPaid(contracts),
    extraValues: selectedValues.extraValues,
    setConfirmedExtraValues,
  });

  const { handleSignSignature } = useSignSignature();
  const verifyEnoughBalance = useCallback(
    (contractSize: string) => {
      if (!isProduction()) {
        return true;
      }
      const totalPremiumPaidValue = parseFloat(totalPremiumPaid(contractSize));
      const usdcBalanceValue = parseFloat(usdcBalance || "0");
      return totalPremiumPaidValue <= usdcBalanceValue;
    },
    [totalPremiumPaid, usdcBalance]
  );

  const verifyNotionalWithinBounds = useCallback(
    (contractSize: string) => !isNotionalOutOfBounds(selectedValues.asset, totalNotional(contractSize)),
    [isNotionalOutOfBounds, selectedValues.asset, totalNotional]
  );

  const verifyOneDecimal = useCallback((contractSize: string) => {
    const [, decimalPart] = Number(contractSize || 0)
      .toString()
      .split(".");
    const decimalPlaces = decimalPart ? decimalPart.length : 0;
    return decimalPlaces <= 1;
  }, []);

  const verifyTotalPremiumPaid = useCallback(
    (contractSize: string) => Number(totalPremiumPaid(contractSize)) >= minTotalPremium,
    [minTotalPremium, totalPremiumPaid]
  );

  const handleReset = useCallback(async () => {
    setLoading(true);
    setCountdown(countdownTime);
    if (isManualStrike && requestBody) {
      await setManualStrikeExtraValues(requestBody);
    } else {
      await updatePreloadedStrikes();
    }
    setLoading(false);
  }, [
    isManualStrike,
    requestBody,
    setManualStrikeExtraValues,
    updatePreloadedStrikes,
  ]);

  const resetStates = useCallback(() => {
    setValue("contracts", "");
    resetSignature();
    clearErrors();
    setConfirmedExtraValues(undefined);
    setConfirmed(false);
    setConfirming(false);
  }, [clearErrors, resetSignature, setValue]);

  // whenever totalPremiumPaid or totalNotional changes, trigger input
  useEffect(() => {
    if (contracts) {
      trigger("contracts");
    }
  }, [trigger, totalPremiumPaid, totalNotional, account, contracts]);

  // need to repermit whenever any value changes
  useEffect(() => {
    if (contracts) {
      resetSignature();
      setConfirmedExtraValues(undefined);
    }
  }, [
    contracts,
    resetSignature,
    selectedValues.asset,
    selectedValues.optionType,
    selectedValues.expiry,
    selectedValues.strike,
  ]);

  // once countdown reaches 0, remake api requests
  // only after requests are done, reset countdown
  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown === 0) {
        handleReset();
      }
      if (!loading && countdown > 0) {
        setCountdown(countdown - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countdown, getPreloadedStrikes, handleReset, loading]);

  const placeOrderSignatureParams: IOrderSignature | undefined = useMemo(() => {
    if (
      !account
      || !selectedValues.asset
      || !selectedValues.expiry
      || !selectedValues.strike
      || !selectedValues.optionType
      || !confirmedExtraValues
      || !contracts
      || hasError
      || !hasValidSignature
      || !verifyNotionalWithinBounds(contracts)
      || !approvedAmountAndSignature.approvedAmount
    ) {
      return undefined;
    }
    return {
      collateralAsset: usdcAddress,
      isPut,
      strike: String(selectedValues.strike),
      expiry: String(selectedValues.expiry),
      premium: parseUnits(
        totalPremiumPaid(contracts),
        usdc.decimals
      ).toString(),
      size: String(contracts),
    } as IOrderSignature;
  }, [
    account,
    approvedAmountAndSignature.approvedAmount,
    confirmedExtraValues,
    contracts,
    hasError,
    hasValidSignature,
    isPut,
    verifyNotionalWithinBounds,
    selectedValues.asset,
    selectedValues.expiry,
    selectedValues.optionType,
    selectedValues.strike,
    totalPremiumPaid,
    usdc.decimals,
    usdcAddress,
  ]);

  const placeOrderBody: IPlaceOrderBody | undefined = useMemo(() => {
    if (
      !account
      || !selectedValues.asset
      || !selectedValues.expiry
      || !selectedValues.strike
      || !selectedValues.optionType
      || !selectedValues.optionSide
      || !selectedValues.spotRef
      || !confirmedExtraValues
      || !contracts
      || hasError
      || !hasValidSignature
      || !verifyNotionalWithinBounds(contracts)
      || !approvedAmountAndSignature.approvedAmount
    ) {
      return undefined;
    }
    return {
      size: String(contracts),
      type: selectedValues.optionType.toLowerCase(),
      strike: parseFloat(selectedValues.strike).toFixed(
        getAssetPrecision(selectedValues.asset)
      ),
      underlyer: selectedValues.asset,
      expiry: selectedValues.expiry.toString(),
      side: selectedValues.optionSide,
      address: account,
      permit_v: approvedAmountAndSignature.signature?.v,
      permit_r: approvedAmountAndSignature.signature?.r,
      permit_s: approvedAmountAndSignature.signature?.s,
      permit_amount: String(
        parseUnits(approvedAmountAndSignature.approvedAmount, usdc.decimals)
      ),
      permit_expiry: String(approvedAmountAndSignature.deadline),
      delta: confirmedExtraValues.delta,
      theta: confirmedExtraValues.theta,
      vega: confirmedExtraValues.vega,
      iv: confirmedExtraValues.iv,
      indic: confirmedExtraValues.indicWithoutFees,
      spot_ref: selectedValues.spotRef,
    } as IPlaceOrderBody;
  }, [
    account,
    approvedAmountAndSignature,
    confirmedExtraValues,
    contracts,
    hasError,
    hasValidSignature,
    verifyNotionalWithinBounds,
    selectedValues,
    usdc,
  ]);

  const fullPlaceOrderBody = useCallback(
    (request: IRequest, signature: string) => {
      if (
        !account
        || !selectedValues.asset
        || !selectedValues.expiry
        || !selectedValues.strike
        || !selectedValues.optionType
        || !selectedValues.optionSide
        || !selectedValues.spotRef
        || !confirmedExtraValues
        || !contracts
        || hasError
        || !hasValidSignature
        || !verifyNotionalWithinBounds(contracts)
        || !approvedAmountAndSignature.approvedAmount
        || !request
        || !signature
      ) {
        return undefined;
      }
      return {
        size: String(contracts),
        type: selectedValues.optionType.toLowerCase(),
        strike: parseFloat(selectedValues.strike).toFixed(
          getAssetPrecision(selectedValues.asset)
        ),
        underlyer: selectedValues.asset,
        expiry: selectedValues.expiry.toString(),
        // side: selectedValues.optionSide,
        address: account,
        permit_v: approvedAmountAndSignature.signature?.v,
        permit_r: approvedAmountAndSignature.signature?.r,
        permit_s: approvedAmountAndSignature.signature?.s,
        permit_amount: String(
          parseUnits(approvedAmountAndSignature.approvedAmount, usdc.decimals)
        ),
        permit_expiry: String(approvedAmountAndSignature.deadline),
        delta: confirmedExtraValues.delta,
        theta: confirmedExtraValues.theta,
        vega: confirmedExtraValues.vega,
        iv: confirmedExtraValues.iv,
        indic: confirmedExtraValues.indicWithoutFees,
        spot_ref: selectedValues.spotRef,
        forward_request: Object.values(request),
        forward_signature: signature,
        ...(selectedCounterparty && { counterparty: selectedCounterparty }),
      } as IFullPlaceOrderBody;
    },
    [
      account,
      selectedValues.asset,
      selectedValues.expiry,
      selectedValues.strike,
      selectedValues.optionType,
      selectedValues.optionSide,
      selectedValues.spotRef,
      confirmedExtraValues,
      contracts,
      hasError,
      hasValidSignature,
      verifyNotionalWithinBounds,
      approvedAmountAndSignature.approvedAmount,
      approvedAmountAndSignature.signature?.v,
      approvedAmountAndSignature.signature?.r,
      approvedAmountAndSignature.signature?.s,
      approvedAmountAndSignature.deadline,
      usdc.decimals,
      selectedCounterparty,
    ]
  );

  const orderInfo = useMemo<IOrderInfo[]>(
    () => [
      {
        title: "Total Premium Paid",
        value: contracts
          ? `${currency(totalPremiumPaid(contracts)).format()}`
          : "---",
        showErrorIcon: true,
        warningOrError:
          contracts && !verifyTotalPremiumPaid(contracts) ? "error" : undefined,
        tooltip: (
          <TooltipExplanation
            title="Total Premium Paid"
            explanation={`Total Premiums Paid refers to the cumulative amount paid as premiums for this trade. Total Premium Paid must be greater than or equal to ${currency(
              minTotalPremium
            ).format()}`}
            renderContent={({ ref, ...triggerHandler }) => (
              <div ref={ref} {...triggerHandler}>
                <SmallInfo
                  color={
                    contracts && !verifyTotalPremiumPaid(contracts)
                      ? COLORS.negative.one
                      : TEXT_COLORS.three
                  }
                  style={{
                    marginLeft: `${SPACING.one}px`,
                    marginTop: `-${SPACING.one / 2}px`,
                  }}
                />
              </div>
            )}
          />
        ),
      },
      {
        title: "Total Notional",
        value: contracts
          ? `${currency(totalNotional(contracts)).format()}`
          : "---",
        showErrorIcon: true,
        warningOrError:
          contracts && !verifyNotionalWithinBounds(contracts)
            ? "error"
            : undefined,
        tooltip: (
          <TooltipExplanation
            title="Total Notional"
            explanation={`Total Notional represents the total value of the underlying assets involved in the trade. Total Notional must be between ${currency(
              minNotionalNumber
            ).format()} and ${currency(maxNotionalNumber).format()}`}
            renderContent={({ ref, ...triggerHandler }) => (
              <div ref={ref} {...triggerHandler}>
                <SmallInfo
                  color={
                    contracts && !verifyNotionalWithinBounds(contracts)
                      ? COLORS.negative.one
                      : TEXT_COLORS.three
                  }
                  style={{
                    marginLeft: `${SPACING.one}px`,
                    marginTop: `-${SPACING.one / 2}px`,
                  }}
                />
              </div>
            )}
          />
        ),
      },
    ],
    [
      contracts,
      totalPremiumPaid,
      verifyTotalPremiumPaid,
      minTotalPremium,
      totalNotional,
      verifyNotionalWithinBounds,
      minNotionalNumber,
      maxNotionalNumber,
    ]
  );

  const onChangeOrderType = useCallback(
    (value: string) => {
      setOptionType(value as OptionTypeResponse);
      setAnimationPaused(true);
      setTimeout(() => {
        setAnimationPaused(false);
      }, 2000);
      reset();
    },
    [reset, setOptionType]
  );

  const placeOrderText = useMemo(() => {
    if (confirming) {
      return "Sign order in your wallet";
    }
    if (!confirmed) {
      return "Place Order";
    }
    return "Transaction Pending";
  }, [confirmed, confirming]);

  const handlePlaceOrder = useCallback(async () => {
    try {
      if (
        placeOrderBody
        && placeOrderSignatureParams
        && account
        && provider
        && otcWrapperContract
        && selectedValues.strike
        && selectedValues.expiry
        && approvedAmountAndSignature.approvedAmount
        && nonce
        && minimalForwarderContract
        && UNDERLYER_ADDRESSES[selectedValues.asset]
      ) {
        if (!showConfirmOrderModal) {
          setShowConfirmOrderModal(true);
        } else {
          setConfirming(true);

          const { isVerified, forwardRequest, signature }
            = await handleSignSignature(
              approvedAmountAndSignature.approvedAmount,
              selectedValues.asset,
              selectedValues.optionType,
              selectedValues.strike,
              String(selectedValues.expiry),
              contracts,
              usdc.decimals
            );

          const fullPlaceOrderBodyObject = fullPlaceOrderBody(
            forwardRequest,
            signature
          );
          setConfirming(false);
          if (fullPlaceOrderBodyObject && isVerified) {
            // if there is price deviation between coingeckoPrice and oraclePrice
            // set oraclePrice before executeOrder
            await handleSetOraclePrice(selectedValues.asset);
            const responseOrderId = await placeBuyOrder(
              fullPlaceOrderBodyObject
            );
            if (responseOrderId) {
              resetStates();
              setOrderId(responseOrderId);
            }
          } else {
            throw Error;
          }
        }
      }
    } catch (err) {
      console.log(err);
      resetStates();
      addErrorToast("Something went wrong", "Please try again");
    }
  }, [
    placeOrderBody,
    placeOrderSignatureParams,
    account,
    provider,
    otcWrapperContract,
    selectedValues.strike,
    selectedValues.expiry,
    selectedValues.asset,
    selectedValues.optionType,
    approvedAmountAndSignature.approvedAmount,
    nonce,
    minimalForwarderContract,
    showConfirmOrderModal,
    setShowConfirmOrderModal,
    handleSignSignature,
    contracts,
    usdc.decimals,
    fullPlaceOrderBody,
    handleSetOraclePrice,
    placeBuyOrder,
    resetStates,
    addErrorToast,
  ]);

  const middleButtonContent = useMemo(
    () => (
      <BottomText isMobileScreen={isMediumScreen}>
        Aevo OTC is under maintenance
      </BottomText>
    ),
    [isMediumScreen]
  );
  return (
    <>
      <ContentWrapper ref={modalRef} height={contentWrapperHeight}>
        <SelectCounterpartyModal
          show={showSelectCounterpartyModal}
          setSelectedCounterparty={setSelectedCounterparty}
          onHide={() => setShowSelectCounterpartyModal(false)}
        />
        <TradingGuideModal
          show={showTradingGuideModal}
          onHide={() => setShowTradingGuideModal(false)}
        />
        <ConfirmOrderModal
          orderInfo={placeOrderBody}
          setConfirmed={setConfirmed}
          handlePlaceOrder={handlePlaceOrder}
        />
        <Padding>
          <TradeSelectionDropdown additionalHeight={additionalHeight} />
          {page === PageType.Trade && !isMediumScreen && (
            <OptionTypeWrapper>
              <SegmentedControl
                segments={[
                  {
                    value: String(OptionResponse.Call),
                    display: "Call",
                    textColor:
                      selectedValues.optionType === OptionResponse.Call
                        ? COLORS.blue.one
                        : TEXT_COLORS.three,
                  },
                  {
                    value: String(OptionResponse.Put),
                    display: "Put",
                    textColor: isPut ? COLORS.blue.one : TEXT_COLORS.three,
                  },
                ]}
                value={String(selectedValues.optionType)}
                onSelect={(value) => {
                  onChangeOrderType(value);
                }}
                config={{
                  theme: "outline",
                  color: COLORS.blue.one,
                  widthType: "fullWidth",
                  backgroundColor: BACKGROUND_COLORS.eight,
                  activeBackgroundColor: COLORS.blue.five,
                  borderRadius: "10px",
                  button: {
                    height: 40,
                    fontSize: FONT_SIZE.two,
                  },
                }}
              />
            </OptionTypeWrapper>
          )}
          <OptionFilter page={page} additionalHeight={additionalHeight} />
          <Form onSubmit={() => {}}>
            {page === PageType.Trade && (
              <>
                <InputsWrapper>
                  <ContractInput
                    register={register("contracts", {
                      disabled: Boolean(!selectedValues.strike),
                      required: true,
                      validate: {
                        [FormValidatorKeysEnum.notEnoughBalance]: (v) => verifyEnoughBalance(v),
                        [FormValidatorKeysEnum.decimalsTooLarge]: (v) => verifyOneDecimal(v),
                        [FormValidatorKeysEnum.notionalOutOfBounds]: (v) => verifyNotionalWithinBounds(v),
                        [FormValidatorKeysEnum.totalPremiumTooLow]: (v) => verifyTotalPremiumPaid(v),
                      },
                    })}
                    errors={errors}
                  />
                </InputsWrapper>
                {/* Border animation */}
                {!isAnimationPaused && (
                  <AnimatePresence initial={false}>
                    <AbsoluteBorderContainer
                      key={`${selectedValues.extraValues?.indic}`}
                      initial={{
                        opacity: 0,
                        border: `1px solid ${COLORS.positive.one}`,
                      }}
                      animate={{
                        opacity: 1,
                        border: `1px solid ${COLORS.positive.six}`,
                      }}
                      exit={{
                        opacity: 0,
                        border: `1px solid ${COLORS.positive.one}`,
                      }}
                      transition={{
                        duration: 1,
                        delay: 0.5,
                        ease: "easeInOut",
                        direction: "reverse",
                      }}
                    />
                  </AnimatePresence>
                )}
                <IndicativePriceWrapper>
                  <RefreshButtonWrapper
                    role="button"
                    onClick={() => handleReset()}
                  >
                    <RefreshSvgWrapper isRefreshing={loading}>
                      <RefreshButton color={COLORS.positive.one} />
                    </RefreshSvgWrapper>
                  </RefreshButtonWrapper>
                  <IndicativePriceText>
                    Indicative Price Per Option
                  </IndicativePriceText>
                  <AnimatePresence exitBeforeEnter initial={false}>
                    <IndicativePriceValue
                      key={`${selectedValues.extraValues?.indic}-${selectedValues.optionType}`}
                      transition={{
                        duration: 0.5,
                        ease: "easeInOut",
                      }}
                      initial={{
                        opacity: 0,
                      }}
                      animate={{
                        opacity: 1,
                      }}
                      exit={{
                        opacity: 0,
                      }}
                    >
                      <IndicativePriceTextLarge>
                        {selectedValues.extraValues
                        && selectedValues.extraValues.indic
                          ? currency(
                            parseFloat(selectedValues.extraValues.indic),
                            {
                              precision: getAssetIndicPrecision(
                                selectedValues.asset
                              ),
                            }
                          ).format()
                          : "---"}
                      </IndicativePriceTextLarge>
                      {selectedValues.extraValues
                        && selectedValues.extraValues.iv && (
                          <IndicativePriceTextSmall>
                            IV {selectedValues.extraValues?.iv}%
                          </IndicativePriceTextSmall>
                      )}
                    </IndicativePriceValue>
                  </AnimatePresence>
                  <IndicativePriceText>
                    Auto refresh in {countdown} seconds
                  </IndicativePriceText>
                </IndicativePriceWrapper>
                <OrderInfoWrapper>
                  {orderInfo.map((info) => (
                    <InfoRow
                      key={`${info.title}-${info.value}}`}
                      warningOrError={info.warningOrError}
                    >
                      <Title>
                        {info.title}
                        {info.tooltip}
                      </Title>
                      <Value>{info.value}</Value>
                    </InfoRow>
                  ))}
                </OrderInfoWrapper>
                <ButtonsContainer>
                  {isMediumScreen && (
                    <ModalButtonV2
                      type={"button"}
                      onClick={() => setPage(PageType.Payoff)}
                    >
                      <BarChart />
                    </ModalButtonV2>
                  )}
                  {middleButtonContent}
                  {isMediumScreen && (
                    <ModalButtonV2
                      type={"button"}
                      onClick={() => setShowTradingGuideModal(true)}
                    >
                      <Info />
                    </ModalButtonV2>
                  )}
                </ButtonsContainer>
              </>
            )}
          </Form>
          {page === PageType.Payoff && (
            <PayoffContent
              additionalHeight={additionalHeight}
              premium={
                selectedValues.extraValues
                  ? Number(selectedValues.extraValues.indic)
                  : 0
              }
              numContracts={Number(contracts) ?? undefined}
              setPage={setPage}
            />
          )}
        </Padding>
      </ContentWrapper>
      {!isMediumScreen && (
        <ModalFooterContent
          contentType="Payoff"
          pageType={page}
          setPage={setPage}
          buttonsDisabled={showConfirmOrderModal}
        />
      )}
    </>
  );
}

export default OptionsTradeForm;
