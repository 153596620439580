import { useForm, useWatch } from "react-hook-form";
import { useContext, useEffect } from "react";
import { Input } from "../shared/Input";
import { InputWrapper } from "./style";
import { LINKS } from "../../constants/links";
import { ImpersonateContext } from "../../contexts/ImpersonateContext";

export function ImpersonateInput() {
  const { register, control } = useForm({
    mode: "onChange",
  });
  const { setImpersonateAddress } = useContext(ImpersonateContext);
  const address = useWatch({ control, name: "address" });
  const isAdmin = window.location.hostname === "localhost" || window.location.hostname === LINKS.admin;

  useEffect(() => {
    setImpersonateAddress(address || "");
  }, [address, setImpersonateAddress]);

  return isAdmin ? (
    <InputWrapper>
      <Input
        error={false}
        placeholder="impersonate address"
        type="string"
        {...register("address", {
          required: true,
        })}
      />
    </InputWrapper>
  ) : null;
}
