/* eslint-disable no-console */
import axios from "axios";
import { OptionTypeResponse } from "../../../../interfaces/PreloadedStrikes";
import { Assets } from "../../../../utils/assets";
import {
  MM_PLACE_ORDER_URL,
  PLACE_ORDER_URL,
} from "../../../../constants/endpoints/endpoints";
// object for telegram post api body
export interface IPlaceOrderBody {
  size: string;
  type: OptionTypeResponse;
  strike: string; // 2 d.p.
  underlyer: Assets;
  // side: OptionSideResponse;
  expiry: string;
  address: string;
  permit_v: number;
  permit_r: string;
  permit_s: string;
  permit_amount: string;
  permit_expiry: string;
  custom_price?: string;
  delta?: string; // 4 d.p.
  theta?: string; // 4 d.p.
  vega?: string; // 4 d.p.
  iv?: string; // 1 d.p.
  indic?: string; // 8 d.p.
  spot_ref?: string;
  counterparty?: string;
}

export interface IFullPlaceOrderBody extends IPlaceOrderBody {
  order_id_to_unwind?: number;
  forward_request?: [];
  forward_signature?: string;
}

export interface IMMPlaceOrderBody {
  forwardRequest: [];
  signature: string;
}

const usePlaceOrder = () => {
  const placeBuyOrder = async (body: IFullPlaceOrderBody) => {
    try {
      const response = await axios.post(PLACE_ORDER_URL, body);
      return response.data.order_id;
    } catch (e) {
      console.log(e);
      throw Error;
    }
  };

  const placeSellOrder = async (body: IPlaceOrderBody) => {
    try {
      const response = await axios.post(PLACE_ORDER_URL, body);
      return response.data.order_id;
    } catch (e) {
      console.log(e);
      throw Error;
    }
  };

  const mmPlaceOrder = async (body: IMMPlaceOrderBody) => {
    try {
      const response = await axios.post(MM_PLACE_ORDER_URL, body);
      return response.data.order_id;
    } catch (e) {
      console.log(e);
      throw Error;
    }
  };

  return { placeBuyOrder, placeSellOrder, mmPlaceOrder };
};

export default usePlaceOrder;
