import { useContext, useMemo } from "react";
import { TradeInstrumentContext } from "../../contexts/TradeInstrumentContext";
import useAssetPriceData from "../../hooks/api/prices/useAssetPriceData";
import { getNumberColor } from "../../utils/math";
import { Input } from "../shared/Input";
import { IInputExtraProps } from "../TradeForm/form";
import {
  CustomStrikeContentErrorText,
  CustomStrikeContentPercentageText,
  CustomStrikeContentText,
  CustomStrikeContentWrapper,
} from "./style";

function CustomStrikeContent({ register, strike, customError }: IInputExtraProps) {
  const { selectedValues } = useContext(TradeInstrumentContext);
  const { useAssetPrice } = useAssetPriceData();
  const { loading, price } = useAssetPrice({ asset: selectedValues.asset });
  const distanceFromStrike = useMemo(() => {
    if (loading || !strike) {
      return 0;
    }
    const difference = Number(strike) - price;
    return (difference / price) * 100;
  }, [loading, price, strike]);

  const textColor = getNumberColor(distanceFromStrike);

  return (
    <CustomStrikeContentWrapper>
      <div>
        <CustomStrikeContentText>Custom Strike Price</CustomStrikeContentText>
        <CustomStrikeContentErrorText>{customError}</CustomStrikeContentErrorText>
        <Input
          placeholder="0.00"
          type="number"
          wrapperStyles={{
            background: "none",
          }}
          inputStyles={{
            padding: 0,
            textAlign: "center",
            fontSize: 28,
            width: "100%",
          }}
          {...register}
        />
      </div>
      <div>
        <CustomStrikeContentText>
          Distance from Current Price
        </CustomStrikeContentText>
        <CustomStrikeContentPercentageText color={textColor}>
          {distanceFromStrike ? `${distanceFromStrike.toFixed(2)}%` : "---"}
        </CustomStrikeContentPercentageText>
      </div>
    </CustomStrikeContentWrapper>
  );
}

export default CustomStrikeContent;
