import { motion } from "framer-motion";
import styled, { createGlobalStyle, css } from "styled-components";
import { BACKGROUND_COLORS, BORDER_COLORS } from "./constants/design/colors";
import "bootstrap/dist/css/bootstrap.min.css";
import { FONT_WEIGHT } from "./constants/design/fontWeight";
import { COMPONENTS } from "./constants/design/spacing";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: BananaGrotesk;
    src: url("/assets/fonts/BananaGrotesk/BananaGrotesk-Light.otf") format("opentype");
  }
  @font-face {
    font-family: BananaGrotesk;
    src: url("/assets/fonts/BananaGrotesk/BananaGrotesk-Light.otf") format("opentype");
    font-weight: ${FONT_WEIGHT.medium};
  }
  @font-face {
    font-family: BananaGrotesk;
    src: url("/assets/fonts/BananaGrotesk/BananaGrotesk-Light.otf") format("opentype");
    font-weight: ${FONT_WEIGHT.bold};
    font-style: normal;
  }
  @font-face {
    font-family: BananaGroteskMono;
    src: url("/assets/fonts/BananaGroteskMono/MNKYBananaGroteskMono-Light-Trial.otf") format("opentype");
  }
  @font-face {
    font-family: BananaGroteskMono;
    font-weight: ${FONT_WEIGHT.medium};
    src: url("/assets/fonts/BananaGroteskMono/MNKYBananaGroteskMono-Light-Trial.otf") format("opentype");
  }
  @font-face {
    font-family: BananaGroteskMono;
    font-weight: ${FONT_WEIGHT.bold};
    src: url("/assets/fonts/BananaGroteskMono/MNKYBananaGroteskMono-Light-Trial.otf") format("opentype");
  }

  // BASE FONT auto applies to BananaGrotesk to alphabets, and Mono to numbers
  @font-face {
    font-family: BaseFont;
    src: url("/assets/fonts/BananaGrotesk/BananaGrotesk-Light.otf") format("opentype");
  }
  @font-face {
    font-family: BaseFont;
    src: url("/assets/fonts/BananaGrotesk/BananaGrotesk-Light.otf") format("opentype");
    font-weight: ${FONT_WEIGHT.medium};
  }
  @font-face {
    font-family: BaseFont;
    src: url("/assets/fonts/BananaGrotesk/BananaGrotesk-Light.otf") format("opentype");
    font-weight: ${FONT_WEIGHT.bold};
    font-style: normal;
  }

  // NUMBERS ONLY
  @font-face {
    font-family: BaseFont;
    src: url("/assets/fonts/BananaGroteskMono/MNKYBananaGroteskMono-Light-Trial.otf") format("opentype");
    unicode-range: U+30-39;
  }
  @font-face {
    font-family: BaseFont;
    font-weight: ${FONT_WEIGHT.medium};
    src: url("/assets/fonts/BananaGroteskMono/MNKYBananaGroteskMono-Light-Trial.otf") format("opentype");
    unicode-range: U+30-39;
  }
  @font-face {
    font-family: BaseFont;
    font-weight: ${FONT_WEIGHT.bold};
    src: url("/assets/fonts/BananaGroteskMono/MNKYBananaGroteskMono-Light-Trial.otf") format("opentype");
    unicode-range: U+30-39;
  }

  body {
    font-family: BaseFont !important;
    background: ${BACKGROUND_COLORS.five};
    color: white;
    overflow: hidden;

    /* For Firefox */
    * {
      scrollbar-width: none;
      -ms-overflow-style: none;
    }

    /* For Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      display: none;
    }
  }

  button {
    border: none;
    background-color: transparent;
    padding: 0;

    &:disabled {
      cursor: not-allowed;
    }
  }

  textarea:focus, input:focus{
    outline: none;
  }

  // GLOBAL BOOTSTRAP OVERRIDES
  .container {
    max-width: unset;
  }

  .row {
    padding: 0;
  }

  [class*="col-"] {
    padding: 0;
  }

  .modal-backdrop.show {
    opacity: 0.8;
  }

  .dropdown-menu {
    min-width: 100%;
  }
`;

export const Scrim = styled(motion.div)`
  position: absolute;
  top: ${COMPONENTS.header.main}px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${BACKGROUND_COLORS.thirteen};
  z-index: 999;
`;

export const foregroundComponent = css`
  border-radius: 8px;
  background-color: ${BACKGROUND_COLORS.component};
  border: 1px solid ${BORDER_COLORS.one};
`;
