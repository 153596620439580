import { BigNumber } from "ethers";
import { COLORS, TEXT_COLORS } from "../constants/design/colors";
import { minMarginDecimals, oracleDecimals } from "../constants/decimals/decimals";

export const getRange = (start: number, stop: number, step: number = 1) => {
  const a = [start];
  let b = start;
  while (b < stop) {
    a.push((b += step));
  }
  return a;
};

export const getNumberColor = (number: number) => {
  if (number === 0) {
    return TEXT_COLORS.two;
  }
  if (number > 0) {
    return COLORS.positive.one;
  }
  return COLORS.negative.one;
};

// minMargin calculation is
// minMarginPercentage * underlyerPrice * contracts
// minMargin decimals = 4
// underlyer oraclePrice = 8
// to get initialMargin for usdc, we divide by 10 ** 6
export const calculateInitialMarginBigNumber = (
  minMarginPercentage: BigNumber,
  underlyerPrice: BigNumber,
  collateralPrice: BigNumber,
  size: string | number,
  collateralDecimals: number
) => minMarginPercentage
  .mul(underlyerPrice)
  .mul(BigNumber.from(String(Number(size) * 10))) // multiply by 10 because contracts can be 1 d.p
  .div(BigNumber.from(10)) // then divide back by 10
  .div(
    BigNumber.from(10).pow(minMarginDecimals + oracleDecimals - collateralDecimals)
  ) // divide by 10 ** 6 to get usdc decimals
  .mul(BigNumber.from(10).pow(oracleDecimals)) // divide by collateral price
  .div(collateralPrice)
  .mul(BigNumber.from("10001")) // multiply by buffer of 1.0001%
  .div(BigNumber.from("10000"));
