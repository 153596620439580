import { useMemo, useRef, useState } from "react";
import { BaseModal } from "../../BaseModal";
import { IOrder, IOrderType, OptionSide } from "../../../interfaces/Orders";
import LiveBuyRequestModal from "./mm/LiveBuyRequestModal";
import LiveSellRequestModal from "./mm/LiveSellRequestModal";
import UnmatchedRequestModal from "./mm/UnmatchedRequestModal";
import PendingOrderModal from "./user/PendingOrderModal";
import AccountPositionModal from "./user/AccountPositionModal";
import TradeHistoryModal from "./user/TradeHistoryModal";
import MMTradeHistoryModal from "./mm/MMTradeHistoryModal";
import UnfilledOrderModal from "./user/UnfilledOrderModal";
import MMAccountPositionModal from "./mm/MMAccountPositionModal";
import MarginHealthContent from "../../MarginHealthContent";
import LiveUnwindRequestModal from "./mm/LiveUnwindRequestModal";
import MMUnwindTradeHistoryModal from "./mm/MMUnwindTradeHistoryModal";

interface IRequestModalProps {
  order: IOrder;
  orderType: IOrderType;
  setOrder: (order: IOrder) => void;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

function RequestPositionModal({
  order,
  setOrder,
  orderType,
  showModal,
  setShowModal,
}: IRequestModalProps) {
  const [showMarginHealthModal, setShowMarginHealthModal] = useState<boolean>(false);

  const modalContentRef = useRef(null);

  const requestPositionModal = useMemo(() => {
    switch (orderType) {
      case "LiveRequests":
        if (order.optionSide === OptionSide.Sell) {
          return (
            <LiveSellRequestModal
              order={order}
              setOrder={setOrder}
              setShowModal={setShowModal}
            />
          );
        }
        return order.isUnwindOrder ? (
          <LiveUnwindRequestModal
            order={order}
            setOrder={setOrder}
            setShowModal={setShowModal}
          />
        ) : (
          <LiveBuyRequestModal
            order={order}
            setOrder={setOrder}
            setShowModal={setShowModal}
          />
        );
      case "UnmatchedRequests":
        return (
          <UnmatchedRequestModal order={order} setShowModal={setShowModal} />
        );
      case "PendingOrders":
        return <PendingOrderModal order={order} setShowModal={setShowModal} />;
      case "AccountPositions":
        return (
          <AccountPositionModal order={order} setShowModal={setShowModal} modalRef={modalContentRef} />
        );
      case "UnfilledOrders":
        return <UnfilledOrderModal order={order} setShowModal={setShowModal} />;
      case "TradeHistory":
        return <TradeHistoryModal order={order} setShowModal={setShowModal} />;
      case "MMPositions":
        return (
          <MMAccountPositionModal order={order} setShowModal={setShowModal} />
        );
      case "MMTradeHistory":
        return order.unwindPrice ? (
          <MMUnwindTradeHistoryModal
            order={order}
            setShowModal={setShowModal}
          />
        ) : (
          <MMTradeHistoryModal order={order} setShowModal={setShowModal} />
        );
      default:
        return (
          <UnmatchedRequestModal order={order} setShowModal={setShowModal} />
        );
    }
  }, [order, orderType, setOrder, setShowModal]);

  const showFooterContentModal = useMemo(
    () => orderType === "MMPositions",
    [orderType]
  );

  return (
    requestPositionModal && (
      <BaseModal
        onHide={() => setShowModal(false)}
        show={showModal}
        header={false}
        style={{
          width: 310,
        }}
        modalRef={modalContentRef}
        footerContent={{
          contentType: "MarginHealth",
          showExtraModal: showMarginHealthModal,
          setShowExtraModal: setShowMarginHealthModal,
          showFooterContent: showFooterContentModal,
          requireTopUp: Boolean(order.requireTopUp),
        }}
      >
        {showMarginHealthModal ? (
          <MarginHealthContent
            order={order}
            modalRef={modalContentRef}
            onHide={() => setShowModal(false)}
          />
        ) : (
          requestPositionModal
        )}
      </BaseModal>
    )
  );
}

export default RequestPositionModal;
