import currency from "currency.js";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { Assets, getAssetIndicPrecision } from "../../../utils/assets";
import MarketIcon from "./MarketIcon";
import {
  MarketCellContainer,
  SecondaryText,
  TertiaryText,
  TextContainer,
} from "./style";

interface IMarketCellProps {
  instrumentName: string;
  asset: Assets;
  isOrderConfirmationTimeWindow: boolean;
  price: string;
  contracts: string;
  isUnwindOrder: boolean;
  hasSecondaryText: boolean;
}

function MarketCell({
  instrumentName,
  asset,
  isOrderConfirmationTimeWindow,
  price,
  contracts,
  isUnwindOrder,
  hasSecondaryText = false,
}: IMarketCellProps) {
  return (
    <MarketCellContainer>
      <MarketIcon asset={asset} />
      <TextContainer>
        {instrumentName}
        {isOrderConfirmationTimeWindow && !isUnwindOrder && (
          <TertiaryText color={COLORS.positive.one}>
            Post initial margin
          </TertiaryText>
        )}
        {hasSecondaryText && (
          <SecondaryText color={TEXT_COLORS.three}>
            <span>{contracts}</span> contracts at{" "}
            <span>
              {currency(price, {
                precision: getAssetIndicPrecision(asset),
              }).format()}
            </span>
          </SecondaryText>
        )}
      </TextContainer>
    </MarketCellContainer>
  );
}

export default MarketCell;
