import styled from "styled-components";
import { Link } from "react-router-dom";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { BACKGROUND_COLORS, BORDER_COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";

export const MobileNavigatorWrapper = styled.div`
  width: 100%;
  position: relative;
  height: ${COMPONENTS.mobileNavigatorHeight}px;
  border-top: 1px solid ${BORDER_COLORS.one};
  z-index: 1;
  background: ${BACKGROUND_COLORS.five};
  
  p {
    margin: auto 0;
  }

  .marquee {
    display: flex;
    height: fit-content;
    justify-content: space-around;

  }

  .marquee-container {
    overflow: hidden;
    height: fit-content;
  }
`;

export const TickerContainer = styled.div`
  display: flex;
  width: fit-content;
  margin-left: ${SPACING.four}px;
  > {
    &:not(:last-child) {
      margin-right: ${SPACING.two}px;
    }
  }
`;

export const MobileNavigatorContent = styled.div`
  display: flex;
  padding: 0 ${SPACING.three}px;
  align-items: center;
  gap: ${SPACING.three}px;
`;

export const TabLink = styled(Link)<{ $isActive: boolean }>`
  ${FONT_CSS.header.six};
  transition: 0.2s ease-in-out;
  height: ${COMPONENTS.sideNavigatorHeight}px;
  display: flex;
  align-items: center;
  text-decoration: none !important;
  border-radius: 8px;
  height: ${COMPONENTS.mobileNavigatorHeight}px;
  color: ${(props) => (props.$isActive ? TEXT_COLORS.one : TEXT_COLORS.three)};
  &:hover {
    color: ${(props) => (props.$isActive ? TEXT_COLORS.one : TEXT_COLORS.three)};
  }
`;
