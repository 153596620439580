import styled from "styled-components";
import { BORDER_COLORS, LAYER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";

export const CounterpartyDetails = styled.button<{isSelected: boolean}>`
  background: ${LAYER_COLORS.two};
  height: 32px;
  border: 1px solid ${({ isSelected }) => (isSelected ? TEXT_COLORS.one : BORDER_COLORS.one)};
  border-radius: 12px;
  &:hover {
    border-color: ${TEXT_COLORS.one};
  }
`;

export const SelectCounterpartyModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${FONT_CSS.body.two}
  width: 280px;
  color: ${TEXT_COLORS.two};

  p::first-line {
    text-indent: 20px;
  }
  
  ${CounterpartyDetails} {
    &:not(:last-child) {
      margin-bottom: ${SPACING.three}px;
    }
  }
  a {
    color: ${TEXT_COLORS.one};
  }
`;

export const Title = styled.p`
  display: inline;
  color: ${TEXT_COLORS.one};
`;

export const ConfirmButtonWrapper = styled.button`
  width: 100%;
  background: ${LAYER_COLORS.two};
  border-radius: 12px;
  ${FONT_CSS.body.four}
  padding: ${SPACING.two}px;
  text-align: center;
  border: 1px solid ${BORDER_COLORS.one};
  &:hover {
    border-color: ${TEXT_COLORS.one};
  }
`;
