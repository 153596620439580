/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { BigNumber } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import { useCallback, useEffect, useState } from "react";
import { OtcWrapper, OtcWrapper__factory } from "../../../codegen";
import addresses from "../../../constants/addresses/addresses.json";
import { Assets, AssetsList, UNDERLYER_ADDRESSES } from "../../../utils/assets";
import { IMinMax } from "../../../interfaces/OtcWrapper";
import useWallet from "../../wallet/useWallet";
import useUSDC from "../usdc/useUSDC";
import { useWeb3Context } from "../../../contexts/Web3Context";

const contractAddress = addresses.mainnet.contracts.otcWrapper;

export const getOtcWrapper = (provider: any, useSigner = true): OtcWrapper | undefined => {
  const signerOrProvider = useSigner ? provider?.getSigner() : provider;
  if (signerOrProvider && contractAddress) {
    return OtcWrapper__factory.connect(contractAddress, signerOrProvider);
  }

  return undefined;
};

export interface IOrderDetails {
  buyer: string;
  seller: string;
  collateral: string;
  expiry: BigNumber;
  isPut: boolean;
  notional: BigNumber;
  oToken: string;
  premium: BigNumber;
  size: BigNumber;
  underlying: string;
  vaultID: BigNumber;
}

export interface IOtcWrapperResponse {
  loading: boolean;
  minMaxNotional?: Record<Assets, IMinMax>;
  isAccountWhitelisted?: boolean;
  orderDetails?: IOrderDetails;
  fees?: Record<Assets, BigNumber>;
  unwindFees?: Record<Assets, BigNumber>;
}

export interface IOtcWrapperData {
  contract?: OtcWrapper;
  data: IOtcWrapperResponse;
  isNotionalOutOfBounds: (asset: Assets, notional: string | number) => boolean;
}

const defaultOtcWrapperResponse = {
  loading: true,
  minMaxNotional: undefined,
  isAccountWhitelisted: undefined,
  fees: undefined,
  unwindFees: undefined,
} as IOtcWrapperResponse;

const useOtcWrapper = (orderId?: number, useMinMaxNotional: boolean = false, useUnwindFees: boolean = false, useFees: boolean = false): IOtcWrapperData => {
  const { provider, account, active, chainId } = useWallet();
  const { baseProvider } = useWeb3Context();
  const [data, setData] = useState<IOtcWrapperResponse>(
    defaultOtcWrapperResponse
  );
  const [contract, setContract] = useState<OtcWrapper>();
  const usdc = useUSDC();
  const getMinMaxNotionals = useCallback(async () => {
    const promises = AssetsList.map(async (asset) => {
      const assetAddress = UNDERLYER_ADDRESSES[asset];
      if (assetAddress && contract) {
        try {
          const { min, max } = await contract.minMaxNotional(assetAddress);
          return {
            [asset]: {
              min,
              max,
            },
          };
        } catch (err) {
          console.log(err);
          return {
            [asset]: { min: BigNumber.from("0"), max: BigNumber.from("0") },
          };
        }
      } else {
        return {
          [asset]: { min: BigNumber.from("0"), max: BigNumber.from("0") },
        };
      }
    });
    const results = await Promise.all(promises);
    const minMaxRecord = Object.assign({}, ...results) as Record<
      string,
      IMinMax
    >;
    return minMaxRecord;
  }, [contract]);

  const getFees = useCallback(async () => {
    const promises = AssetsList.map(async (asset) => {
      const assetAddress = UNDERLYER_ADDRESSES[asset];
      if (assetAddress && contract) {
        try {
          const fee = await contract.fee(assetAddress);
          return { [asset]: fee };
        } catch (err) {
          return { [asset]: BigNumber.from("0") };
        }
      } else {
        return { [asset]: BigNumber.from("0") };
      }
    });
    const results = await Promise.all(promises);
    const feesRecord = Object.assign({}, ...results) as Record<
      Assets,
      BigNumber
    >;
    return feesRecord;
  }, [contract]);

  const getUnwindFees = useCallback(async () => {
    const promises = AssetsList.map(async (asset) => {
      const assetAddress = UNDERLYER_ADDRESSES[asset];
      if (assetAddress && contract) {
        try {
          const fee = await contract.unwindFee(assetAddress);
          return { [asset]: fee };
        } catch (err) {
          return { [asset]: BigNumber.from("0") };
        }
      } else {
        return { [asset]: BigNumber.from("0") };
      }
    });
    const results = await Promise.all(promises);
    const unwindFeesRecord = Object.assign({}, ...results) as Record<
      Assets,
      BigNumber
    >;
    return unwindFeesRecord;
  }, [contract]);

  const getIsAccountWhitelisted = useCallback(async () => {
    if (account && contract) {
      try {
        const isWhitelisted = await contract.isWhitelistedMarketMaker(account);
        return isWhitelisted;
      } catch (err) {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, [account, contract]);

  const getOrderDetails = useCallback(
    async (orderDetailId: number) => {
      if (account && contract) {
        try {
          const orderDetails = await contract.orders(orderDetailId);
          const {
            buyer,
            seller,
            collateral,
            expiry,
            isPut,
            notional,
            oToken,
            premium,
            size,
            underlying,
            vaultID,
          } = orderDetails;
          return {
            buyer,
            seller,
            collateral,
            expiry,
            isPut,
            notional,
            oToken,
            premium,
            size,
            underlying,
            vaultID,
          } as IOrderDetails;
        } catch (err) {
          return undefined;
        }
      } else {
        return undefined;
      }
    },
    [account, contract]
  );

  useEffect(() => {
    setContract(getOtcWrapper(provider || baseProvider, active));
  }, [chainId, provider, active, baseProvider]);

  const fetchOtcWrapperData = useCallback(async () => {
    try {
      const minMaxNotional = useMinMaxNotional ? await getMinMaxNotionals() : undefined;
      const isAccountWhitelisted = await getIsAccountWhitelisted();
      const fees = useFees ? await getFees() : undefined;
      const unwindFees = useUnwindFees ? await getUnwindFees() : undefined;
      const orderDetails = orderId ? await getOrderDetails(orderId) : undefined;
      setData({
        loading: false,
        minMaxNotional,
        isAccountWhitelisted,
        fees,
        unwindFees,
        orderDetails,
      });
    } catch (error) {
      console.log(error);
    }
  }, [useMinMaxNotional, getMinMaxNotionals, getIsAccountWhitelisted, useFees, getFees, useUnwindFees, getUnwindFees, orderId, getOrderDetails]);

  useEffect(() => {
    fetchOtcWrapperData();
  }, [fetchOtcWrapperData]);

  const isNotionalOutOfBounds = useCallback(
    (asset: Assets, notional: string | number) => {
      if (!data.minMaxNotional || !Number(notional)) {
        return false;
      }
      const { min, max } = data.minMaxNotional[asset];
      if (min.isZero() || max.isZero()) {
        return true;
      }
      const notionalToBigNumber = parseUnits(String(notional), usdc.decimals);
      return notionalToBigNumber.lt(min) || notionalToBigNumber.gt(max);
    },
    [data.minMaxNotional, usdc.decimals]
  );
  return {
    contract,
    data,
    isNotionalOutOfBounds,
  };
};

export default useOtcWrapper;
