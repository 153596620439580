import {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";

export type SFXSettings =
  | "disable_all"
  | "order_placed"
  | "order_filled"
  | "order_cancelled"
  | "error";

export enum SettingsEnum {
  SFX = "SFX_SETTINGS",
}

type SettingsEnumToTitle = {
  [key in SettingsEnum]: string
}

export const settingsEnumTitle: SettingsEnumToTitle = {
  [SettingsEnum.SFX]: "Sound Effects",
};

interface ISettingsContextType {
  sfx: Set<SFXSettings>;
  addSfx: (key: SFXSettings) => void;
  removeSfx: (key: SFXSettings) => void;
}

interface ISettingsContextProviderProps {
  children: ReactElement;
}

const defaultSettings: ISettingsContextType = {
  sfx: new Set<SFXSettings>([
    "order_placed",
    "order_filled",
    "order_cancelled",
    "error",
  ]),
  addSfx: () => {},
  removeSfx: () => {}
};

export const SettingsContext
  = createContext<ISettingsContextType>(defaultSettings);

export function SettingsContextProvider({
  children,
}: ISettingsContextProviderProps) {
  const [sfx, setSfx] = useState<Set<SFXSettings>>(defaultSettings.sfx);

  const updateLocalStorage = useCallback(
    (setting: SettingsEnum) => {
      if (setting === SettingsEnum.SFX) {
        localStorage.setItem(SettingsEnum.SFX, JSON.stringify(Array.from(sfx)));
      }
    },
    [sfx]
  );

  // Initialization hook
  useEffect(() => {
    const sfxSettings = localStorage.getItem(SettingsEnum.SFX);

    if (sfxSettings) {
      const parsedSfxSettings = new Set(
        JSON.parse(sfxSettings) as SFXSettings[]
      );
      setSfx(parsedSfxSettings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addSfx = useCallback(
    (code: SFXSettings) => {
      sfx.add(code);
      // Remove disable_all if there are other settings added
      if (sfx.size > 1 && sfx.has("disable_all")) sfx.delete("disable_all");
      setSfx(new Set(sfx));
      updateLocalStorage(SettingsEnum.SFX);
    },
    [sfx, updateLocalStorage]
  );

  const removeSfx = useCallback(
    (code: SFXSettings) => {
      sfx.delete(code);
      // Add disable_all if all settings are intentionally disabled
      if (sfx.size === 0) sfx.add("disable_all");
      setSfx(new Set(sfx));
      updateLocalStorage(SettingsEnum.SFX);
    },
    [sfx, updateLocalStorage]
  );

  return (
    <SettingsContext.Provider
      value={{
        sfx,
        addSfx,
        removeSfx,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}
