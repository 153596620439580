import styled from "styled-components";
import { COLORS } from "../../../constants/design/colors";

export const AbsoluteProgressBarContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 56px;
  border-radius: 8px;
  overflow: hidden;
`;

export const AbsoluteProgressBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  border-radius: 8px;
  transition: width 0.5s ease-in-out; /* Animate the width change smoothly over 0.5 seconds */
  background-color: ${COLORS.blue.one};
`;
