import { Popover } from "react-bootstrap";
import styled from "styled-components";
import { BACKGROUND_COLORS, BORDER_COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";

export const PopoverContainer = styled(Popover)`
  .popover-arrow {
    display: none;
  }
  background: ${BACKGROUND_COLORS.tooltip};
  border: 1px solid ${BORDER_COLORS.two};
  border-radius: 8px;
  padding: ${SPACING.three}px;
  max-width: 280px;
  font-size: ${FONT_SIZE.one};
`;

export const Title = styled.div`
  margin-bottom: ${SPACING.two}px;
`;

export const Explanation = styled.span`
  color: ${TEXT_COLORS.two};
`;
