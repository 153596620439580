export interface IRoute {
  title: string;
  path: string; // Path relative to current route
  redirect: string; // Path to redirect to
  img: JSX.Element;
  element: JSX.Element;
  isActive: boolean;
}

export enum PageEnum {
  Trade,
  Portfolio,
  Requests,
  MMPortfolio,
}

export enum TabEnum {
  CreateTrade = "Create Trade",
  TradeIdeas = "Trade Ideas",
  LivePositions = "Live Positions",
  TradeHistory = "Trade History",
  UnfilledOrders = "Unfilled Orders",
  LiveRequests = "Live Requests",
  UnmatchedRequests = "Unmatched Requests",
  MMLivePositions = "Live Positions",
  MMTradeHistory = "Trade History",
}
