import styled from "styled-components";
import { COLORS, TEXT_COLORS } from "../../../constants/design/colors";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";

export const SideNavigatorWrapper = styled.div`
  display: flex;
  position: absolute;
  left: ${SPACING.three}px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

export const TextContainer = styled.div<{ isActive: boolean }>`
  height: ${COMPONENTS.sideNavigatorHeight}px;
  font-size: 14px;
  color: ${({ isActive }) => (isActive ? COLORS.blue.one : TEXT_COLORS.three)};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
