export enum ToastEnum {
  SIMPLE,
  INFO,
  WIDGET,
}

export enum ToastStatusEnum {
  ERROR,
  SUCCESS,
}
