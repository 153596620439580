import currency from "currency.js";
import { getProfitTextColor, getRoiTextColor } from "../../../utils/strings";
import { SecondaryText, TertiaryText, TextContainer } from "./style";

interface IPerformanceCellProps {
  performance?: string;
  roi?: string;
}

function PerformanceCell({ performance, roi }: IPerformanceCellProps) {
  const pnlColor = getProfitTextColor(Number(performance));
  const roiColor = getRoiTextColor(Number(roi));
  return (
    <TextContainer>
      <SecondaryText color={pnlColor}>
        {performance ? `${currency(performance).format()}` : "-"}
      </SecondaryText>
      {roi && (
        <TertiaryText color={roiColor}>
          {Number(roi) >= 0 ? "+" : ""}
          {`${roi}%`}
        </TertiaryText>
      )}
    </TextContainer>
  );
}

export default PerformanceCell;
