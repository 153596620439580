import styled from "styled-components";
import { TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";

export const AboutModalContainer = styled.div`
  ${FONT_CSS.body.two}
  width: 280px;
  color: ${TEXT_COLORS.two};

  a {
    color: ${TEXT_COLORS.one};
  }
`;
