import { UnderlyerAssetsList } from "../../../utils/assets";
import { BaseModal } from "../../BaseModal";
import {
  ImportantTextWrapper,
  Title,
  TradingGuideModalContainer,
} from "./style";

interface ITradingGuideModalProps {
  show: boolean;
  onHide: () => void;
}

const numberOfAssets = UnderlyerAssetsList.length;
const steps = [
  {
    name: "Select Your Option",
    description: `Choose from ${numberOfAssets} different underlying assets and submit your bid.`,
  },
  {
    name: "Receive Confirmation",
    description:
      "Market makers will respond and fill your bid within 20 minutes.",
  },
  {
    name: "Exercise at Expiry",
    description:
      "Our options are European and can only be exercised at expiry.",
  },
  {
    name: "Settlement",
    description: "All options are settled in USDC.",
  },
];

function TradingGuideModal({ show, onHide }: ITradingGuideModalProps) {
  return (
    <BaseModal show={show} onHide={onHide} title="Trading Guide">
      <TradingGuideModalContainer>
        {steps.map((vc, idx) => (
          <div key={vc.name}>
            {idx + 1}. <Title>{vc.name}:</Title> {vc.description}
          </div>
        ))}
        <ImportantTextWrapper>
          <Title>IMPORTANT</Title>: Please note, positions cannot be closed
          early.
        </ImportantTextWrapper>
      </TradingGuideModalContainer>
    </BaseModal>
  );
}

export default TradingGuideModal;
