import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import currency from "currency.js";
import { parseUnits } from "ethers/lib/utils";
import { ReactComponent as Close } from "../../../../../assets/svg/close.svg";
import CircularProgressBar from "../../../../shared/CircularProgressBar";
import {
  InfoRow,
  HeaderContainer,
  AssetHeaderWrapper,
  InfoDetail,
  ButtonsContainer,
  SmartContractRowWrapper,
  BottomText,
  Form,
} from "../../style";
import { IOrder } from "../../../../../interfaces/Orders";
import { LargeModalButtonV2, ModalButtonV2 } from "../../../../Buttons/styles";
import AssetWrapper from "../../../../shared/AssetWrapper";
import { fullExpiryTime } from "../../../../../utils/date";
import { CHAIN_EXPLORER_URLS } from "../../../../../utils/chain";
import { ChainIdEnum } from "../../../../../enums/chain";
import useWallet from "../../../../../hooks/wallet/useWallet";
import { shortenAddress } from "../../../../../utils/strings";
import { ReactComponent as ArrowOut } from "../../../../../assets/svg/arrow-up-right.svg";
import addresses from "../../../../../constants/addresses/addresses.json";
import useUSDC from "../../../../../hooks/contracts/usdc/useUSDC";
import { useToast } from "../../../../../hooks/toast";
import { COLORS } from "../../../../../constants/design/colors";
import {
  getAssetIndicPrecision,
} from "../../../../../utils/assets";
import useOffer, {
  IOfferAfterExecutionBody,
} from "../../../../../hooks/api/order/mm/useOffer";
import { Spinner } from "../../../../shared/Spinner";
import useSignature from "../../../../../hooks/contracts/usdc/useSignature";
import { useOrderTimes } from "../../../../../hooks/time/useOrderTimes";
import useOtcWrapper from "../../../../../hooks/contracts/otcWrapper/useOtcWrapper";
import useMarginRequirement from "../../../../../hooks/contracts/marginRequirement/useMarginRequirement";
import { formatBigNumber } from "../../../../../utils/format";
import useSetOraclePrice from "../../../../../hooks/api/prices/useSetOraclePrice";
import { isProduction } from "../../../../../utils/env";
import { IInfoRowCol } from "../../../../../interfaces/InfoRow";
import { IPermitStruct } from "../../../../../interfaces/Signing";
import { OptionsDataContext } from "../../../../../contexts/OptionsDataContext";
import { useSFX } from "../../../../../hooks/useSFX";
import { RequestPositionContext } from "../../../../../contexts/RequestPositionContext";
import useOracle from "../../../../../hooks/contracts/oracle/useOracle";
import useMinimalForwarder from "../../../../../hooks/contracts/minimalForwarder/useMinimalForwarder";
import usePlaceOrder from "../../../../../hooks/api/order/user/usePlaceOrder";
import useSignSignature from "../../../../../hooks/contracts/usdc/useSignSignature";

interface IRequestModalProps {
  order: IOrder;
  setOrder: (order: IOrder) => void;
  setShowModal: (show: boolean) => void;
}

type OfferType = "make" | "edit" | "cancel" | "filled";

function LiveSellRequestModal({
  order,
  setOrder,
  setShowModal,
}: IRequestModalProps) {
  const { account, provider } = useWallet();
  const { addToast, addErrorToast } = useToast();
  const { updateOfferAfterExecution } = useOffer();
  const { mmPlaceOrder } = usePlaceOrder();
  const {
    contract: otcWrapperContract,
    data: { loading: otcWrapperLoading, isAccountWhitelisted },
  } = useOtcWrapper();

  const { contract: minimalForwarderContract } = useMinimalForwarder();
  const usdc = useUSDC();
  const { playSound } = useSFX();
  const [offerType, setOfferType] = useState<OfferType>();
  const [executed, setExecuted] = useState(false);
  const [handlingOraclePrice, setHandlingOraclePrice] = useState(false);
  const [executing, setExecuting] = useState(false);
  const [confirmedMinMargin, setConfirmedMinMargin] = useState<string>();
  const [executedOrderId, setExecutedOrderId] = useState<number>(); // the id of the order that was just executed
  const [executedOrder, setExecutedOrder] = useState<IOrder>(); // the order object that was just executed
  const { mmPositions } = useContext(OptionsDataContext);
  const { setShowRequestPositionModal, setOrderType } = useContext(
    RequestPositionContext
  );
  const usdcContractAddress = addresses.mainnet.assets.usdc;
  const contractAddress = addresses.mainnet.contracts.otcWrapper;
  const {
    data: {
      loading: marginRequirementLoading,
      totalFees,
      initialMargin,
      initialMarginFromOracle,
    },
  } = useMarginRequirement({
    collateralAsset: "USDC",
    underlyerAsset: order.asset,
    isPut: order.optionType === "put",
    contracts: order.contracts,
  });
  const { handleSignSignature } = useSignSignature();
  const {
    instrumentName,
    contracts,
    optionType,
    orderId,
    expiry,
    strike,
    asset,
    offerPrice,
    orderStartTime,
    orderOfferPeriodEnd,
    orderEndTime,
    userPermit,
  } = order;

  // after executeOrder, when mmPositions response has the order, set executed as true;
  useEffect(() => {
    if (executedOrderId) {
      const position = mmPositions.orders.find(
        (obj) => obj.orderId === executedOrderId
      );
      if (position) {
        playSound("order_filled");
        setExecuting(false);
        setExecuted(true);
        setExecutedOrder(position);
        setExecutedOrderId(undefined);
      }
    }
  }, [
    addToast,
    asset,
    contracts,
    executedOrderId,
    instrumentName,
    mmPositions.orders,
    offerPrice,
    playSound,
    setOrder,
  ]);

  const { timeNow, timeArgs } = useOrderTimes();
  const { handleSetOraclePrice } = useSetOraclePrice();
  const { oraclePrices } = useOracle();
  const { timeToExpiry, orderExpired, isOrderConfirmationTimeWindow }
    = timeArgs(
      Number(orderStartTime),
      Number(orderOfferPeriodEnd),
      Number(orderEndTime)
    );

  const hasOffer = useMemo(() => Boolean(offerPrice), [offerPrice]);

  const initialOfferType = useMemo(() => {
    if (isOrderConfirmationTimeWindow) {
      return "filled";
    }
    if (hasOffer) {
      return "cancel";
    }
    return "make";
  }, [hasOffer, isOrderConfirmationTimeWindow]);

  useEffect(() => {
    setOfferType(initialOfferType);
  }, [hasOffer, initialOfferType]);

  const totalPremium = useMemo(
    () => (offerPrice ? String(Number(contracts) * Number(offerPrice)) : undefined),
    [contracts, offerPrice]
  );

  const {
    permitting,
    setPermitting,
    resetSignature,
    handlePermit,
    approvedAmountAndSignature,
    hasValidSignature,
  } = useSignature({
    amount: totalPremium || "0",
    setConfirmedMinMargin,
  });

  const resetRequestModal = useCallback(() => {
    setExecuted(false);
    setExecuting(false);
    setOfferType(initialOfferType);
    setConfirmedMinMargin(undefined);
    resetSignature();
  }, [initialOfferType, resetSignature]);

  const onHide = useCallback(() => {
    setShowModal(false);
    resetRequestModal();
  }, [setShowModal, resetRequestModal]);

  // hide modal if orderOfferPeriod has expired or orderExpired
  useEffect(() => {
    if (Math.round(timeNow) === Number(orderOfferPeriodEnd)) {
      onHide();
    }
    if (orderExpired) {
      onHide();
    }
  }, [onHide, orderExpired, orderOfferPeriodEnd, timeNow]);

  const openBlockExplorer = useCallback(() => {
    window.open(
      `${
        CHAIN_EXPLORER_URLS[ChainIdEnum.ETH_MAINNET]
      }/address/${contractAddress}`
    );
  }, [contractAddress]);

  const [orderStatus, orderStatusColor] = useMemo(() => {
    if (executed) {
      return ["Trade Successful", COLORS.positive.one];
    }
    // eslint-disable-next-line no-extra-boolean-cast
    if (isOrderConfirmationTimeWindow) {
      return ["Awaiting Confirmation", COLORS.positive.one];
    }
    return ["Submitted", COLORS.blue.one];
  }, [executed, isOrderConfirmationTimeWindow]);

  const infoRows: IInfoRowCol[] = useMemo(() => {
    const rows = [];
    if (offerPrice) {
      rows.push({
        title: "Order Status",
        detail: orderStatus,
        color: orderStatusColor,
      });
      rows.push({
        title: "Offer Price",
        detail: `${currency(parseFloat(offerPrice), {
          precision: getAssetIndicPrecision(asset),
        }).format()}`,
        color: COLORS.positive.one,
      });
    }
    rows.push({
      title: "Option Type",
      detail: `European ${optionType.replace(/^\w/, (c) => c.toUpperCase())}`,
    });
    rows.push({
      title: "Expiry",
      detail: `${fullExpiryTime(expiry)}`,
    });
    rows.push({
      title: "Strike",
      detail: `${strike}`,
    });
    rows.push({
      title: "Contracts",
      detail: `${contracts}`,
    });
    rows.push({
      title: "Total Premiums",
      detail: totalPremium ? `${currency(totalPremium).format()}` : "-",
    });
    if (offerType !== "cancel" && offerType !== "filled") {
      rows.push({
        title: "Time till confirmation",
        detail: timeToExpiry,
        color: COLORS.blue.one,
      });
    }
    rows.push({
      title: "Smart Contract",
      detail: (
        <SmartContractRowWrapper onClick={openBlockExplorer}>
          <span>{shortenAddress(contractAddress)}</span>
          <ArrowOut />
        </SmartContractRowWrapper>
      ),
    });
    return rows;
  }, [
    offerPrice,
    optionType,
    expiry,
    strike,
    contracts,
    totalPremium,
    offerType,
    openBlockExplorer,
    contractAddress,
    orderStatus,
    orderStatusColor,
    asset,
    timeToExpiry,
  ]);

  const filledContentText = useMemo(() => {
    if (!hasValidSignature) {
      if (permitting) {
        return <Spinner color={COLORS.white.one} />;
      }
      return "Permit USDC";
    }
    if (!executed) {
      if (executing) {
        return <Spinner color={COLORS.white.one} />;
      }
      return "Execute Trade";
    }
    return "View Position";
  }, [executed, executing, hasValidSignature, permitting]);

  const headerContent = useMemo(
    () => (
      <>
        <AssetHeaderWrapper>
          <AssetWrapper asset={asset} />
        </AssetHeaderWrapper>
        <LargeModalButtonV2 type={"button"} onClick={onHide}>
          <Close />
        </LargeModalButtonV2>
      </>
    ),
    [onHide, asset]
  );

  // 1. MM signs signature
  // 2. MM calls defender placeOrder function
  // 3. MM executes order
  // 4. updateOfferAfterExecution is called
  const handleExecute = useCallback(async () => {
    const {
      approvedAmount: mmPermitAmount,
      signature,
      deadline: mmPermitDeadline,
    } = approvedAmountAndSignature;
    try {
      setExecuting(true);
      if (isProduction()) {
        if (
          otcWrapperContract
          && provider
          && signature
          && mmPermitAmount
          && userPermit
          && account
          && totalPremium
          && confirmedMinMargin
          && totalFees
          && oraclePrices
          && oraclePrices[asset]
          && otcWrapperContract
          && minimalForwarderContract
          && approvedAmountAndSignature.approvedAmount
        ) {
          // 1. MM signs signature
          const {
            isVerified,
            forwardRequest,
            signature: forwardSignature,
          } = await handleSignSignature(
            approvedAmountAndSignature.approvedAmount,
            asset,
            optionType,
            strike,
            expiry,
            contracts,
            usdc.decimals
          );

          if (!forwardRequest || !forwardSignature || !isVerified) {
            throw Error;
          }

          const { v: mmPermitV, r: mmPermitR, s: mmPermitS } = signature;
          const {
            permitAddress: userPermitAddress,
            permitAmount: userPermitAmount,
            permitDeadline: userPermitDeadline,
            permitV: userPermitV,
            permitR: userPermitR,
            permitS: userPermitS,
          } = userPermit;

          // get the orderId of the order on chain
          const latestOrder = await otcWrapperContract.latestOrder();
          const confirmedOrderId = Number(latestOrder) + 1;

          // 2. MM calls defender placeOrder function
          await mmPlaceOrder({
            forwardRequest: Object.values(forwardRequest) as [],
            signature: forwardSignature
          });

          // 3. MM executes order
          await otcWrapperContract.executeOrder(
            String(confirmedOrderId),
            {
              amount: parseUnits(mmPermitAmount, usdc.decimals),
              deadline: String(mmPermitDeadline),
              acct: account,
              v: String(mmPermitV),
              r: mmPermitR,
              s: mmPermitS,
            } as IPermitStruct,
            {
              amount: userPermitAmount,
              deadline: userPermitDeadline,
              acct: userPermitAddress!,
              v: userPermitV,
              r: userPermitR,
              s: userPermitS,
            } as IPermitStruct,
            parseUnits(totalPremium, usdc.decimals),
            usdcContractAddress,
            userPermitAmount
          );

          // 4. updateOfferAfterExecution is called
          await updateOfferAfterExecution({
            order_id: Number(orderId),
            cancel: "false",
            fees: String(formatBigNumber(totalFees, usdc.decimals)),
            im_amount: userPermitAmount,
            mm_address: account,
            onchain_order_id: confirmedOrderId
          } as IOfferAfterExecutionBody);

          // set the executed order id and wait for mmPositions api
          // to have that order object
          setExecutedOrderId(Number(confirmedOrderId));
        } else {
          throw Error;
        }
      } else {
        await handleSignSignature(
          approvedAmountAndSignature.approvedAmount!,
          asset,
          optionType,
          strike,
          expiry,
          contracts,
          usdc.decimals
        );
        const latestOrder = await otcWrapperContract?.latestOrder();
        const confirmedOrderId = Number(latestOrder) + 1;
        // eslint-disable-next-line no-console
        // console.log({
        //   confirmedOrderId,
        //   orderId: String(confirmedOrderId),
        //   userPermit: {
        //     amount: parseUnits(mmPermitAmount!, usdc.decimals).toString(),
        //     deadline: String(mmPermitDeadline),
        //     acct: account,
        //     v: String(mmPermitV),
        //     r: mmPermitR,
        //     s: mmPermitS,
        //   } as IPermitStruct,
        //   mmPermit: {
        //     amount: userPermitAmount,
        //     deadline: userPermitDeadline,
        //     acct: userPermitAddress!,
        //     v: userPermitV,
        //     r: userPermitR,
        //     s: userPermitS,
        //   } as IPermitStruct,
        //   totalPremium: parseUnits(totalPremium!, usdc.decimals).toString(),
        //   usdcContract: usdcContractAddress,
        //   collateralAmount: userPermitAmount,
        // });
        await updateOfferAfterExecution({
          order_id: Number(orderId),
          cancel: "false",
          fees: String(formatBigNumber(orderId, usdc.decimals)),
          im_amount: mmPermitAmount,
          mm_address: account,
        } as IOfferAfterExecutionBody);

        // set the executed order id and wait for mmPositions api
        // to have that order object
        setExecutedOrderId(confirmedOrderId);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      setExecuting(false);
      throw Error;
    }
  }, [account, approvedAmountAndSignature, asset, confirmedMinMargin, contracts, expiry, handleSignSignature, minimalForwarderContract, mmPlaceOrder, optionType, oraclePrices, orderId, otcWrapperContract, provider, strike, totalFees, totalPremium, updateOfferAfterExecution, usdc.decimals, usdcContractAddress, userPermit]);

  const handleFilled = useCallback(async () => {
    try {
      if (!hasValidSignature) {
        setPermitting(true);
        setHandlingOraclePrice(true);
        handleSetOraclePrice(asset);
      } else if (!executed) {
        await handleExecute();
      } else if (executedOrder) {
        setOrderType("MMPositions");
        setOrder(executedOrder);
        setShowRequestPositionModal(true);
      }
    } catch (error) {
      addErrorToast("Something went wrong", "Please try again");
      onHide();
    }
  }, [
    hasValidSignature,
    executed,
    executedOrder,
    setPermitting,
    handleSetOraclePrice,
    asset,
    handleExecute,
    setOrderType,
    setOrder,
    setShowRequestPositionModal,
    addErrorToast,
    onHide,
  ]);

  // wait for oraclePrice to be settled
  // only then we execute trade
  // wait for initial margin calculation using oracle
  // then permit
  useEffect(() => {
    if (handlingOraclePrice) {
      if (oraclePrices && oraclePrices[asset] && initialMarginFromOracle) {
        setHandlingOraclePrice(false);
        handlePermit();
      }
    }
  }, [
    asset,
    handlePermit,
    handlingOraclePrice,
    initialMarginFromOracle,
    oraclePrices,
  ]);

  const isExecuteDisabled = useMemo(() => {
    const { approvedAmount: mmPermitAmount, signature }
      = approvedAmountAndSignature;
    return Boolean(
      !signature || !mmPermitAmount || !userPermit || !account || !totalPremium
    );
  }, [account, approvedAmountAndSignature, totalPremium, userPermit]);

  const isFilledButtonDisabled = useCallback(() => {
    if (!isProduction()) {
      return permitting || executing;
    }
    if (!hasValidSignature) {
      return permitting || !initialMargin;
    }
    if (!executed) {
      return isExecuteDisabled || executing;
    }
    return false;
  }, [
    executed,
    executing,
    hasValidSignature,
    initialMargin,
    isExecuteDisabled,
    permitting,
  ]);

  const submitButtonContent = useMemo(() => {
    switch (offerType) {
      case "filled":
        return (
          <>
            <ButtonsContainer>
              <ModalButtonV2
                type={"button"}
                disabled={isFilledButtonDisabled()}
                style={{ flex: 1 }}
                onClick={() => handleFilled()}
              >
                {filledContentText}
              </ModalButtonV2>
            </ButtonsContainer>
            <BottomText>
              {executed ? (
                <p>
                  You can track all live positions in the{" "}
                  <Link to="/mm/portfolio">Portfolio</Link> section of the app.
                </p>
              ) : (
                <p>
                  You must ensure you have enough USDC in your wallet to execute
                  the trade.
                </p>
              )}
            </BottomText>
          </>
        );
      default:
        return undefined;
    }
  }, [
    executed,
    filledContentText,
    handleFilled,
    isFilledButtonDisabled,
    offerType,
  ]);

  const getCircleType = useMemo(() => {
    if (executed) {
      return "success";
    }
    if (orderExpired) {
      return "failure";
    }
    return "pending";
  }, [executed, orderExpired]);

  const bottomContent = useMemo(() => {
    if (marginRequirementLoading || otcWrapperLoading || !account) {
      return undefined;
    }
    if ((!initialMargin || isAccountWhitelisted === false) && isProduction()) {
      return (
        <BottomText>
          You are not able to place an offer on this quote at the moment. Kindly
          reach out on Telegram for more information.
        </BottomText>
      );
    }
    return submitButtonContent;
  }, [
    account,
    initialMargin,
    isAccountWhitelisted,
    marginRequirementLoading,
    otcWrapperLoading,
    submitButtonContent,
  ]);

  return (
    <Form>
      <HeaderContainer>{headerContent}</HeaderContainer>
      {(offerType === "cancel" || offerType === "filled") && account && (
        <CircularProgressBar
          timeNow={timeNow}
          startTime={
            isOrderConfirmationTimeWindow
              ? Number(orderOfferPeriodEnd)
              : Number(orderStartTime)
          }
          endTime={
            isOrderConfirmationTimeWindow
              ? Number(orderEndTime) - 1.15 // handle delay in animation
              : Number(orderOfferPeriodEnd) - 1.15 // handle delay in animation
          }
          isOrderConfirmationTimeWindow={isOrderConfirmationTimeWindow}
          size={160}
          strokeWidth={4}
          detail={
            isOrderConfirmationTimeWindow
              ? "Time till confirmation window closes"
              : "Time till order confirmation"
          }
          type={getCircleType}
        />
      )}
      {infoRows.map((infoRow) => (
        <InfoRow key={infoRow.title}>
          <span>{infoRow.title}</span>
          <InfoDetail color={infoRow.color}>{infoRow.detail}</InfoDetail>
        </InfoRow>
      ))}
      {bottomContent}
    </Form>
  );
}

export default LiveSellRequestModal;
