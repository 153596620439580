import styled from "styled-components";
import {
  BACKGROUND_COLORS,
  COLORS,
  PRIMARY_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { FONT_WEIGHT } from "../../../constants/design/fontWeight";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";
import { foregroundComponent } from "../../../style";

export const AddressWrapper = styled.div<{
  isConnected?: boolean;
  fullWidth?: boolean;
}>`
  ${({ fullWidth }) => fullWidth && "width: 100%;"}
  height: ${COMPONENTS.tabs}px;
  display: flex;
  justify-content: center;
  transition: all 0.4s ease-in-out;
  outline: 1px solid transparent;
  box-shadow: 2px 4px 40px transparent;
  background-color: ${BACKGROUND_COLORS.component};
  &:hover {
    outline: 1px solid ${({ isConnected }) => (isConnected ? COLORS.blue.one : PRIMARY_COLORS.one)};
  }

  ${foregroundComponent}
  border-radius: 12px;
  color: ${TEXT_COLORS.one};

  button,
  .dropdown {
    font-size: ${FONT_SIZE.two};
    transition: 0.2s ease-in-out;
  }
  .dropdown-menu {
    margin-top: ${SPACING.three}px;
  }
`;

export const Container = styled.button<{ tradingEnabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${TEXT_COLORS.one};
  line-height: 1;
  width: 100%;
  padding: ${SPACING.three}px ${SPACING.twoHalf}px;
  font-size: ${FONT_SIZE.one} !important;
  font-weight: ${FONT_WEIGHT.medium};
`;

export const Indicator = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: ${COLORS.blue.one};
  margin-right: ${SPACING.two}px;
`;
