import axios from "axios";
import { UPDATE_MM_COLLATERAL_URL } from "../../../../constants/endpoints/endpoints";

export type UpdateCollateralActionType = "post" | "withdraw"
export interface IUpdateMMCollateralBody {
  order_id: number;
  collateral: string;
  action_type: UpdateCollateralActionType;
}

const useOrder = () => {
  const updateMMCollateralApiURL = UPDATE_MM_COLLATERAL_URL;
  const updateMMCollateral = async (body: IUpdateMMCollateralBody) => {
    try {
      await axios.patch(updateMMCollateralApiURL, body);
    } catch (e) {
      throw Error();
    }
  };

  return { updateMMCollateral };
};

export default useOrder;
