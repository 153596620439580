import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  BORDER_COLORS,
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../../constants/design/fontSize";
import { SPACING } from "../../../constants/design/spacing";
import { FONT_WEIGHT } from "../../../constants/design/fontWeight";

export const Form = styled.form``;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${FONT_SIZE.oneHalf};
  padding-top: ${SPACING.three}px;

  span:first-child {
    color: ${TEXT_COLORS.three};
  }
`;

export const InfoDetail = styled.span<{ color?: string }>`
  ${({ color }) => color && `color: ${color};`}
`;

export const HeaderContainer = styled.div`
  display: flex;

  > * {
    &:not(:last-child) {
      margin-right: ${SPACING.two}px;
    }
  }
`;

export const AssetHeaderWrapper = styled.div`
  margin: auto;
  display: flex;
  text-align: left;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: ${LAYER_COLORS.two};
  border-radius: 8px;
  padding-left: 8px;
  height: 56px;
  > * {
    margin: auto ${SPACING.one}px;
    display: flex;
  }

  > div > img {
    margin-right: ${SPACING.two}px;
  }
`;

export const InputLabel = styled.span`
  font-size: ${FONT_SIZE.one};
  color: ${TEXT_COLORS.three};
`;

export const InputContainer = styled.div`
  margin-top: ${SPACING.two}px;
`;

export const InputError = styled.span`
  color: ${COLORS.negative.one};
  font-size: ${FONT_SIZE.one};
`;

export const MaxButton = styled.button`
  background-color: ${COLORS.white.two};
  color: ${TEXT_COLORS.one};
  border-radius: 8px;
  padding: ${SPACING.one}px ${SPACING.two}px;
  font-size: ${FONT_SIZE.two};
`;

export const ButtonsContainer = styled.div`
  margin-top: ${SPACING.three}px;
  display: flex;
  width: 100%;

  > * {
    &:not(:last-child) {
      margin-right: ${SPACING.two}px;
    }
  }
`;

export const SmartContractRowWrapper = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  color: ${TEXT_COLORS.one};

  span {
    color: ${TEXT_COLORS.one} !important;
    padding: 0 ${SPACING.two}px;
  }

  svg:first-child {
    width: 32px;
    height: 32px;
    border-radius: 10px;
  }
`;

export const InputHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BottomText = styled.div`
  margin-top: ${SPACING.three}px;
  text-align: center;
  color: ${TEXT_COLORS.two};
  ${FONT_CSS.label.two};

  p {
    margin: 0px;
  }

  p > a {
    color: ${TEXT_COLORS.one};
    text-decoration: none;
  }
`;

export const PerformanceWrapper = styled.div<{
  color: string;
}>`
  display: flex;
  flex-direction: column;
  margin-top: ${SPACING.three}px;
  padding: ${SPACING.three}px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: ${TEXT_COLORS.three};
  ${({ color }) => `background: ${color};`}
  border-radius: 8px;
  text-align: center;
  gap: ${SPACING.two}px;
`;

export const Label = styled.div`
  ${FONT_CSS.label.three}
`;

export const PerformanceText = styled.div<{
  color: string;
}>`
  ${({ color }) => `color: ${color};`}
  ${FONT_CSS.data.one}
  margin-top: ${SPACING.two}px;
`;

export const RoiText = styled.div<{
  color: string;
}>`
  ${({ color }) => `color: ${color};`}
  ${FONT_CSS.data.three}
  margin-top: ${SPACING.two}px;
`;

export const SegmentedControlWrapper = styled.div`
  margin-top: ${SPACING.three}px;
`;

export const RowsWrapper = styled.div<{
  height: number;
}>`
  display: inherit;
  ${({ height }) => `height: ${height}px;`}
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const LinkContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${BORDER_COLORS.two};
  color: ${TEXT_COLORS.one};
  font-weight: ${FONT_WEIGHT.medium};
  border-radius: 8px;
  padding: ${SPACING.three}px;
  height: 48px;
  font-size: ${FONT_SIZE.two};
  line-height: ${FONT_SIZE.three};
  border: 1px solid transparent;
  margin-top: ${SPACING.three}px;
  &:hover {
    color: ${TEXT_COLORS.one};
    border: 1px solid ${BORDER_COLORS.five};
  }
  text-decoration: none;
`;
