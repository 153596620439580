import styled from "styled-components";
import {
  BACKGROUND_COLORS,
  TEXT_COLORS,
  BORDER_COLORS,
  COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS, FONT_SIZE } from "../../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";

export const PendingOrderWrapper = styled.div<{ isRead?: boolean }>`
  display: flex;
  padding: ${SPACING.three}px;
  position: relative;
  background-color: ${({ isRead }) => (isRead ? "transparent" : BACKGROUND_COLORS.component)};
  transition: 0.1s ease-in-out;
  height: ${COMPONENTS.pendingOrdersHeight}px;
  width: 100%;

  &:hover {
    background-color: ${BACKGROUND_COLORS.component};
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${BORDER_COLORS.two};
  }
  p {
    margin: 0;
  }
`;

export const NoPendingOrdersWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: ${FONT_SIZE.oneHalf};
  color: ${TEXT_COLORS.two};
  white-space: nowrap;
`;

export const PendingOrderRow = styled.div`
  height: ${COMPONENTS.pendingOrdersHeight}px;
  border-bottom: 1px solid ${BORDER_COLORS.two};
  &:last-child {
    border-radius: unset;
  }
`;

export const IconWrapper = styled.div<{
  backgroundColor?: string;
  isRead?: boolean;
}>`
  width: fit-content;
  height: fit-content;
  background-color: ${({ backgroundColor }) => backgroundColor ?? "transparent"};
  border-radius: 50%;
  margin-right: ${SPACING.two}px;
  margin-top: ${SPACING.one}px;
  justify-content: center;
  display: grid;
  align-items: center;
  position: relative;
`;

export const Icon = styled.img``;

export const Title = styled.div<{ color?: string }>`
  color: ${({ color }) => color ?? TEXT_COLORS.one};
  text-transform: capitalize;
  line-height: ${FONT_CSS.body.four};
`;

export const Content = styled.div<{ color?: string }>`
  font-size: ${FONT_SIZE.two};
  color: ${({ color }) => color ?? TEXT_COLORS.one};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TextWrapper = styled.div<{ color?: string }>`
  display: flex;
  ${FONT_CSS.label.two}
  margin-top: ${SPACING.two}px;
  > div:not(:last-of-type) {
    margin-right: ${SPACING.one}px;
  }
`;

export const Timestamp = styled.p`
  color: ${TEXT_COLORS.three};
  ${FONT_CSS.label.four}
`;

export const MarginBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background: ${COLORS.blue.one};
  border-radius: 100px;
  transition: width 0.5s ease-in-out;
`;
