import { useContext, useMemo, useState } from "react";
import { SPACING } from "../../../constants/design/spacing";
import { ConnectWalletContext } from "../../../contexts/ConnectWalletContext";
import useScreenSize from "../../../hooks/screenSize/useScreenSize";
import useWallet from "../../../hooks/wallet/useWallet";
import { shortenAddress } from "../../../utils/strings";
import { ConnectWalletButton } from "../../Buttons/styles";
import ConnectWalletModal from "../../Modals/ConnectWalletModal";
import AccountModal from "../../Modals/AccountModal";
import { AddressWrapper, Container, Indicator } from "./style";

interface IAccountDropdownProps {
  fullWidth?: boolean;
}

function AccountDropdown({ fullWidth = false }: IAccountDropdownProps) {
  const { account, active } = useWallet();
  const { setShowConnectModal } = useContext(ConnectWalletContext);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const { isMobileScreen } = useScreenSize();

  const buttonContent = useMemo(() => {
    if (account && active) {
      return (
        <Container onClick={() => setShowAccountModal(true)}>
          <Indicator />
          {shortenAddress(account)}
        </Container>
      );
    }
    return (
      <ConnectWalletButton
        fullWidth={fullWidth}
        disableHoverAnimation
        onClick={() => setShowConnectModal(true)}
        style={{
          padding: isMobileScreen
            ? `${SPACING.one}px ${SPACING.two}px !important`
            : `${SPACING.two}px ${SPACING.three}px`,
          height: "100%",
          borderRadius: "12px",
        }}
      >
        Connect Wallet
      </ConnectWalletButton>
    );
  }, [account, active, fullWidth, isMobileScreen, setShowConnectModal]);

  return (
    <AddressWrapper
      isConnected={Boolean(account || active)}
      fullWidth={fullWidth}
    >
      <AccountModal
        show={showAccountModal}
        onHide={() => setShowAccountModal(false)}
      />
      <ConnectWalletModal />
      {buttonContent}
    </AddressWrapper>
  );
}

export default AccountDropdown;
