// 8-point grid system
export const SPACING = {
  one: 4,
  two: 8,
  twoHalf: 12,
  three: 16,
  four: 24,
  five: 32,
  six: 48,
  seven: 64,
  eight: 72,
  nine: 80,
} as const;

// Might be worth including browser height into the mix
export const COMPONENTS = {
  header: {
    main: 72,
    sub: 56,
  },
  footer: {
    main: 88,
    sub: 64,
    mobileNav: 64,
  },
  notificationPanel: 343,
  emptyContent: 236,
  tableRowHeight: 56,
  tableRowMobileHeight: 108,
  tableRowSmall: 40,
  tableHeader: 32,
  tableWidth: 700,
  selectHeight: 32,
  leftTabWidth: 200,
  chart: {
    spotPrice: 48,
    dateFilter: 48,
    portfolio: 240,
  },
  overview: {
    section: 216,
  },
  slideOutModalContentMaxWidth: 400,
  modalHeaderHeight: 56,
  tabs: 56,
  secondaryTabs: 48,
  tertiaryTabs: 32,
  tradeTicketTab: 64,
  dateSelection: 64,
  tradeModalFilterHeight: 300,
  showHideTradeModalButtonSize: 64,
  largeButtonHeight: 48,
  xlButtonHeight: 56,
  baseButtonHeight: 40,
  inputContainerHeight: 48,
  smallButtonHeight: 32,
  sideNavigatorHeight: 56,
  titleHeight: 48,
  accountModalRowHeight: 64,
  marketModalMobileHeight: 440,
  marketModalHeight: 496,
  marketModalCounterpartyHeight: 16,
  marketModalErrorHeight: 32,
  assetDropdownHeight: 408,
  marketsSectionWidth: 320,
  tradeFloatingBottomBar: {
    height: 56,
    heightExpanded: 320,
  },
  portfolioSideTab: 320,
  indicativePriceWrapperHeight: 84,
  marqueeHeight: 40,
  pendingOrdersHeight: 100,
  progressCircleDimension: 160,
  pnlComponentHeight: 136,
  profitChartMobileHeight: 116,
  profitChartHeight: 172,
  collateralBalanceContainerHeight: 164,
  collateralModalWidth: 275,
  mobileNavigatorHeight: 56,
  errorTextHeight: 24,
  infoRowHeight: 36,
  impersonateInputWidth: 182,
  inputHeight: 48,
} as const;

// Screen widths
export const WIDTHS = {
  S: 1120,
  M: 1800,
  L: 1820,
  XL: 1728,
};

export const HEIGHTS = {
  M: 800,
};
