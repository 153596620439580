import { useContext, useMemo, useState } from "react";
import { PortfolioMarketFilterEnum } from "../../../enums/tableFilters";
import OrdersTable from "../../../components/OrdersTable";
import { OptionResponse } from "../../../interfaces/PreloadedStrikes";
import { OptionsDataContext } from "../../../contexts/OptionsDataContext";

function UnfilledOrdersTab() {
  const [filter, setFilter] = useState(PortfolioMarketFilterEnum.ALL);
  const { userUnfilledOrders: { loading, orders: positions } } = useContext(OptionsDataContext);

  const filteredPositions = useMemo(() => {
    if (filter === PortfolioMarketFilterEnum.CALLS) {
      return positions?.filter((p) => p.optionType === OptionResponse.Call);
    }
    if (filter === PortfolioMarketFilterEnum.PUTS) {
      return positions?.filter((p) => p.optionType === OptionResponse.Put);
    }
    return positions;
  }, [filter, positions]);

  return (
    <OrdersTable<PortfolioMarketFilterEnum, typeof PortfolioMarketFilterEnum>
      title="Orders"
      filter={filter}
      onSetFilter={setFilter}
      filterEnum={PortfolioMarketFilterEnum}
      filteredOrders={filteredPositions}
      orderType="UnfilledOrders"
      loading={loading}
    />
  );
}

export default UnfilledOrdersTab;
