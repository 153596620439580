import { BigNumber } from "ethers";
import { useCallback, useEffect, useState } from "react";
import useOToken from "../contracts/oToken/useOToken";
import addresses from "../../constants/addresses/addresses.json";
import { isProduction } from "../../utils/env";
// Get the current account
export const useApproval = (orderId?: number, account?: string) => {
  const [approvedAmount, setApprovedAmount] = useState<BigNumber>();
  const [balance, setBalance] = useState<BigNumber>();
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const { contract: oTokenContract } = useOToken(orderId);
  const otcWrapperAddress = addresses.mainnet.contracts.otcWrapper;

  const checkApprovedAmount = useCallback(async () => {
    try {
      if (!isProduction()) {
        setIsApproved(true);
        return;
      }
      if (!oTokenContract || !account) {
        return;
      }
      const allowance = await oTokenContract.allowance(account, otcWrapperAddress);
      const tokenBalance = await oTokenContract.balanceOf(account);
      setApprovedAmount(allowance);
      setBalance(tokenBalance);
      setIsApproved(allowance.gte(tokenBalance));
    } catch (error) {
      console.log(error);
    }
  }, [account, oTokenContract, otcWrapperAddress]);

  useEffect(() => {
    checkApprovedAmount();
  }, [checkApprovedAmount]);

  return {
    isApproved,
    balance,
    approvedAmount
  };
};
