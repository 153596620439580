import React, { ReactElement, useState } from "react";
import { IOrder, IOrderType } from "../../interfaces/Orders";

interface IRequestPositionContextType {
  order?: IOrder;
  setOrder: (order: IOrder) => void;
  orderType?: IOrderType;
  setOrderType: (orderType: IOrderType) => void;
  showRequestPositionModal: boolean;
  setShowRequestPositionModal: (show: boolean) => void;
}

interface IRequestPositionContextProviderProps {
  children: ReactElement;
}

export const RequestPositionContext
  = React.createContext<IRequestPositionContextType>({
    order: undefined,
    setOrder: () => {},
    orderType: "AccountPositions",
    setOrderType: () => {},
    showRequestPositionModal: false,
    setShowRequestPositionModal: () => {},
  });

export function RequestPositionContextProvider({
  children,
}: IRequestPositionContextProviderProps) {
  const [order, setOrder] = useState<IOrder>();
  const [orderType, setOrderType] = useState<IOrderType>();
  const [showRequestPositionModal, setShowRequestPositionModal]
    = useState(false);

  return (
    <RequestPositionContext.Provider
      value={{
        order,
        setOrder,
        orderType,
        setOrderType,
        showRequestPositionModal,
        setShowRequestPositionModal,
      }}
    >
      {children}
    </RequestPositionContext.Provider>
  );
}
