import styled from "styled-components";
import { TEXT_COLORS } from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";

export const LabelText = styled.p<{ color?: string }>`
  margin: 0;
  font-size: ${FONT_SIZE.two};
  color: ${({ color }) => color ?? TEXT_COLORS.one};
`;

export const SublabelText = styled.p<{ color?: string; marginLeft?: number }>`
  margin: 0;
  font-size: ${FONT_SIZE.one};
  color: ${({ color }) => color ?? TEXT_COLORS.three};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}px`};
`;
