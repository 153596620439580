import USDCLogo from "../assets/svg/tokens/USDC.svg";
import ETHLogo from "../assets/svg/tokens/ETH.svg";
import SOLLogo from "../assets/svg/tokens/SOL.svg";
import BTCLogo from "../assets/svg/tokens/BTC.svg";
import MATICLogo from "../assets/svg/tokens/MATIC.svg";
import BNBLogo from "../assets/svg/tokens/BNB.svg";
import DOGELogo from "../assets/svg/tokens/DOGE.svg";
import LDOLogo from "../assets/svg/tokens/LDO.svg";
import APTLogo from "../assets/svg/tokens/APT.svg";
import ARBLogo from "../assets/svg/tokens/ARB.svg";
import OPLogo from "../assets/svg/tokens/OP.svg";
import SUILogo from "../assets/svg/tokens/SUI.svg";
// import PEPELogo from "../assets/svg/tokens/PEPE.svg";
import LTCLogo from "../assets/svg/tokens/LTC.svg";
// import UNILogo from "../assets/svg/tokens/UNI.svg";
// import CRVLogo from "../assets/svg/tokens/CRV.png";
// import XRPLogo from "../assets/svg/tokens/XRP.svg";
import AVAXLogo from "../assets/svg/tokens/AVAX.svg";
// import MKRLogo from "../assets/svg/tokens/MKR.svg";
import BLANKLogo from "../assets/svg/tokens/BLANK.svg";

export const AssetsList = [
  "USDC",
  "ETH",
  "BTC",
  "MATIC",
  "BNB",
  "DOGE",
  "LDO",
  "APT",
  "SOL",
  "OP",
  "ARB",
  "SUI",
  // "PEPE",
  "LTC",
  // "BLUR",
  // "APE",
  // "CRV",
  // "UNI",
  // "XRP",
  "AVAX",
  // "MKR",
] as const;

export type Assets = (typeof AssetsList)[number];

export const UnderlyerAssetsList: Assets[] = [
  "ETH",
  "BTC",
  "BNB",
  "SUI",
  "DOGE",
  // "PEPE",
  "ARB",
  "LDO",
  "APT",
  "LTC",
  "SOL",
  "MATIC",
  "OP",
  // "APE",
  // "CRV",
  // "XRP",
  "AVAX",
  // "UNI",
  // "MKR",
];

export const ManualPriceAssetList: Assets[] = [
  "LDO",
  "OP",
  "APT",
  "ARB",
  "SUI",
  // "PEPE",
  // "BLUR",
];

export const isManualPricerAsset = (asset: Assets) => ManualPriceAssetList.includes(asset);

export const getAssetName = (asset: Assets): string => {
  switch (asset) {
    case "ETH":
      return "Ethereum";
    case "BTC":
      return "Bitcoin";
    case "MATIC":
      return "Polygon";
    case "BNB":
      return "BNB";
    case "DOGE":
      return "Dogecoin";
    case "LDO":
      return "Lido DAO";
    case "APT":
      return "Aptos";
    case "SOL":
      return "Solana";
    case "OP":
      return "Optimism";
    case "ARB":
      return "Arbitrum";
    case "SUI":
      return "Sui";
    // case "PEPE":
    //   return "Pepe";
    case "LTC":
      return "Litecoin";
    // case "BLUR":
    //   return "Blur";
    // case "APE":
    //   return "Apecoin";
    // case "CRV":
    //   return "Curve Dao Token";
    // case "UNI":
    //   return "Uniswap";
    // case "XRP":
    //   return "XRP";
    case "AVAX":
      return "Avalanche";
    // case "MKR":
    //   return "Maker";
    default:
      return "";
  }
};

export const getAssetPrecision = (asset: Assets): number => {
  switch (asset) {
    case "ETH":
      return 0;
    case "BTC":
      return 0;
    case "MATIC":
      return 2;
    case "BNB":
      return 0;
    case "DOGE":
      return 4;
    case "LDO":
      return 2;
    case "APT":
      return 2;
    case "SOL":
      return 2;
    case "OP":
      return 2;
    case "ARB":
      return 2;
    case "SUI":
      return 2;
    // case "PEPE":
    //   return 8;
    case "LTC":
      return 2;
    // case "BLUR":
    //   return 3;
    // case "APE":
    //   return 2;
    // case "CRV":
    //   return 3;
    // case "UNI":
    //   return 3;
    // case "XRP":
    //   return 4;
    case "AVAX":
      return 2;
    // case "MKR":
    //   return 2;
    default:
      return 0;
  }
};

export const getAssetIndicPrecision = (asset: Assets): number => {
  switch (asset) {
    case "ETH":
      return 2;
    case "BTC":
      return 2;
    case "MATIC":
      return 4;
    case "BNB":
      return 2;
    case "DOGE":
      return 6;
    case "LDO":
      return 4;
    case "APT":
      return 3;
    case "SOL":
      return 3;
    case "OP":
      return 4;
    case "ARB":
      return 4;
    case "SUI":
      return 4;
    // case "PEPE":
    //   return 10;
    case "LTC":
      return 3;
    // case "BLUR":
    //   return 4;
    // case "APE":
    //   return 4;
    // case "CRV":
    //   return 4;
    // case "UNI":
    //   return 4;
    // case "XRP":
    //   return 4;
    case "AVAX":
      return 4;
    // case "MKR":
    //   return 2;
    default:
      return 0;
  }
};

export const getDefaultSignificantDecimalsFromAssetDecimals = (
  decimals: number
) => {
  switch (decimals) {
    case 18:
      return 6;
    case 8:
      return 5;
    case 9:
      return 4;
    case 6:
    default:
      return 2;
  }
};

export const getAssetLogo = (asset: Assets): string => {
  switch (asset) {
    case "USDC":
      return USDCLogo;
    case "ETH":
      return ETHLogo;
    case "BTC":
      return BTCLogo;
    case "MATIC":
      return MATICLogo;
    case "BNB":
      return BNBLogo;
    case "DOGE":
      return DOGELogo;
    case "LDO":
      return LDOLogo;
    case "APT":
      return APTLogo;
    case "SOL":
      return SOLLogo;
    case "OP":
      return OPLogo;
    case "ARB":
      return ARBLogo;
    case "SUI":
      return SUILogo;
    // case "PEPE":
    //   return PEPELogo;
    case "LTC":
      return LTCLogo;
    // case "BLUR":
    //   return BLURLogo;
    // case "APE":
    //   return APELogo;
    // case "UNI":
    //   return UNILogo;
    // case "XRP":
    //   return XRPLogo;
    case "AVAX":
      return AVAXLogo;
    // case "CRV":
    //   return CRVLogo;
    // case "MKR":
    //   return MKRLogo;
    default:
      return BLANKLogo;
  }
};

export const UNDERLYER_ADDRESSES: Record<Assets, string | undefined> = {
  ETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", // WETH
  BTC: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
  SOL: "0xD31a59c85aE9D8edEFeC411D448f90841571b89c",
  BNB: "0xB8c77482e45F1F44dE1745F52C74426C631bDD52",
  MATIC: "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
  LDO: "0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32",
  APT: "0xc85Be07A0157D2f1c677a71cfc7BF5f2b28dF5Ed",
  DOGE: "0x3832d2F059E55934220881F831bE501D180671A7",
  OP: "0x53796B65e0d06e3BE01eEe92715e842415b54205",
  ARB: "0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1",
  SUI: "0x8d7Cfe0C1045Ed9D3e65F9746F270c9c1f65262a",
  // PEPE: "0x6982508145454Ce325dDbE47a25d4ec3d2311933",
  LTC: "0x53c4871322Bb47e7A24136fce291a6dcC832a294",
  // BLUR: "0x5283D291DBCF85356A21bA090E6db59121208b44",
  // APE: "0x4d224452801ACEd8B2F0aebE155379bb5D594381",
  // CRV: "0xD533a949740bb3306d119CC777fa900bA034cd52",
  // UNI: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
  USDC: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  // XRP: "0x39fBBABf11738317a448031930706cd3e612e1B9",
  AVAX: "0x85f138bfEE4ef8e540890CFb48F620571d67Eda3",
  // MKR: "0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2",
};
