import { LogoWrapper } from "./style";

interface ILogoContainerProps {
  logo?: string;
  marginRight?: number;
}

function LogoContainer({ logo, marginRight }: ILogoContainerProps) {
  return (
    <LogoWrapper marginRight={marginRight}>
      <img src={logo} alt={logo} width={32} height={32} />
    </LogoWrapper>
  );
}
export default LogoContainer;
