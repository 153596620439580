import styled, { css } from "styled-components";
import {
  TEXT_COLORS,
  BORDER_COLORS,
  COLORS,
  LAYER_COLORS,
} from "../../../constants/design/colors";
import { FONT_CSS } from "../../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";
import { PendingOrderWrapper } from "../PendingOrdersPanel/style";
import { flash } from "../../../constants/animations/animations";

export const SvgContainer = styled.button<{ isOpen?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ isOpen }) => (isOpen ? COLORS.blue.six : LAYER_COLORS.one)};
  border: ${({ isOpen }) => (isOpen ? "none" : `1px solid ${BORDER_COLORS.one}`)};
  border-radius: 8px;
  min-width: ${COMPONENTS.xlButtonHeight}px;
  height: ${COMPONENTS.xlButtonHeight}px;
  &:hover {
    border: 1px solid
      ${({ isOpen }) => (isOpen ? COLORS.blue.one : COLORS.white.one)};
  }
`;

const textFormat = css`
  a {
    cursor: pointer;
    color: ${TEXT_COLORS.two};
    text-decoration: none;

    margin-right: ${SPACING.twoHalf}px;
    margin-left: ${SPACING.twoHalf}px;
  }
`;
export const LeftFooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${textFormat}
`;

export const PendingOrderText = styled.div`
  margin-left: ${SPACING.two}px;
  color: ${COLORS.blue.one};
  white-space: nowrap;
  ${FONT_CSS.body.two};
  opacity: 0;
  animation: ${flash} 1.2s ease-in-out infinite;
`;

export const AbsoluteLeftContainer = styled.div<{ isMobileScreen: boolean }>`
  position: absolute;
  ${({ isMobileScreen }) => isMobileScreen
    && `left: ${SPACING.three}px;
     right: ${SPACING.three}px;
     `}
  bottom: ${COMPONENTS.footer.main + SPACING.three}px;
  max-height: ${({ isMobileScreen }) => (isMobileScreen
    ? `calc(100vh - ${
      COMPONENTS.footer.main
          + COMPONENTS.header.main
          + COMPONENTS.mobileNavigatorHeight
          + 2 * SPACING.three
    }px)`
    : `calc(100vh - ${
      COMPONENTS.footer.main
          + COMPONENTS.header.main
          + COMPONENTS.marqueeHeight
          + 2 * SPACING.three
    }px)`)};
  overflow: auto;
  width: ${({ isMobileScreen }) => (isMobileScreen
    ? `calc(100% - ${SPACING.three})px`
    : `${COMPONENTS.notificationPanel}px`)};
  background: ${LAYER_COLORS.one};
  border: 1px solid ${BORDER_COLORS.two};
  z-index: 1;
  backdrop-filter: blur(4px);
  border-radius: 8px;
  ${PendingOrderWrapper} {
    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`;
