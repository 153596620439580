import axios from "axios";
import {
  POST_ERROR_MESSAGE_URL,
} from "../../../../constants/endpoints/endpoints";

export interface IPostErrorMessageBody {
  title: string;
  stackError: string;
  messageError: string;
  body?: string;
}

const usePostErrorMessage = () => {
  const apiURL = POST_ERROR_MESSAGE_URL;
  const postErrorMessage = async (body: IPostErrorMessageBody) => {
    try {
      await axios.post(apiURL, body);
    } catch (e) {
      console.log(e);
    }
  };

  return { postErrorMessage };
};

export default usePostErrorMessage;
