import { Select } from "../shared/Select";

export interface IFilterProps<FilterType extends string, FilterEnum extends Record<string, FilterType>> {
  title: string;
  filter: FilterType;
  onSetFilter: (filter: FilterType) => void;
  filterEnum: FilterEnum;
}

export function Filter<FilterType extends string, FilterEnum extends Record<string, FilterType>>({
  title,
  filter,
  onSetFilter,
  filterEnum,
}: IFilterProps<FilterType, FilterEnum>) {
  return (
    <Select
      isRound
      options={Object.keys(filterEnum).map((k) => {
        const f = filterEnum[k as keyof typeof filterEnum];
        return {
          label: f === filterEnum.ALL ? `${f} ${title}` : f,
          isActive: f === filter,
          onClick: () => onSetFilter(f),
        };
      })}
    />
  );
}
