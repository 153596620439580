import axios from "axios";
import { useState } from "react";
import { IIndicativePriceData } from "../../../contexts/TradeInstrumentContext";
import {
  IndicativePriceDataRequest,
  IndicativePriceDataResponse,
} from "../../../interfaces/IndicativePriceData";
import { GET_INDICATIVE_PRICE_URL } from "../../../constants/endpoints/endpoints";
import { useToast } from "../../toast";

const useIndicativePriceData = () => {
  const [error, setError] = useState<boolean>(false);
  const { addErrorToast } = useToast();
  const getIndicativePrice = async (body: IndicativePriceDataRequest, showErrorToast: boolean) => {
    try {
      setError(false);
      const apiURL = GET_INDICATIVE_PRICE_URL || "";
      const response = await axios.get(apiURL, {
        params: body,
      });
      return {
        loading: false,
        data: response.data as IIndicativePriceData,
      } as IndicativePriceDataResponse;
    } catch (e) {
      if (showErrorToast) {
        addErrorToast("Error setting custom strike", "Please try again");
      }
      return {
        loading: true,
        data: {
          indic: "",
          indicWithoutFees: "",
          strike: "",
          delta: "",
          spot_ref: "",
          iv: "",
          theta: "",
          vega: "",
        } as IIndicativePriceData,
      };
    }
  };

  return { error, getIndicativePrice };
};

export default useIndicativePriceData;
