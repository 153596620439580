import styled from "styled-components";
import {
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";
import { FONT_SIZE, FONT_STYLE } from "../../constants/design/fontSize";
import { SelectButton } from "../shared/Select/style";

export const Title = styled.p`
  color: ${TEXT_COLORS.three};
  display: flex;
  align-items: center;
  margin: auto 0;
  font-size: ${FONT_SIZE.one};
`;

export const Value = styled.p`
  color: ${TEXT_COLORS.one};
  font-size: ${FONT_SIZE.oneHalf};
  font-family: BananaGroteskMono;
`;

export const InfoRow = styled.div<{ warningOrError?: "warning" | "error" }>`
  font-size: ${FONT_SIZE.one};
  line-height: calc(${FONT_STYLE.header.seven.lineHeight} - 2px);
  > * {
    color: ${({ warningOrError }) => {
    if (warningOrError === "warning") {
      return `${COLORS.orange.one} !important`;
    }
    if (warningOrError === "error") {
      return `${COLORS.negative.one} !important`;
    }
    return undefined;
  }};
  }
`;

export const OrderInfoWrapper = styled.div`
  margin: ${SPACING.three}px ${SPACING.two}px;

  > * {
    display: flex;
    justify-content: space-between;
  }

  p {
    margin: 0;
  }

  > ${InfoRow} {
    &:not(:first-child) {
      padding-top: ${SPACING.two}px;
    }
    &:not(:last-child) {
      padding-bottom: ${SPACING.two}px;
    }
  }
`;

export const OptionTypeWrapper = styled.div`
  margin-top: ${SPACING.three}px;
  ${SelectButton} {
    width: 100%;
    padding: ${SPACING.two}px;
    font-size: ${FONT_SIZE.three};
  }
`;

export const ChartContainer = styled.div<{ height: number }>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ height }) => height}px;
`;

export const GreekInfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: ${FONT_SIZE.one};
`;

export const GreekInfoRow = styled.div`
  display: flex;
  margin-top: 4px;
  margin-bottom: ${SPACING.three}px;
  height: 32px;
  width: 50%;
`;

export const GreekIconWrapper = styled.div`
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${LAYER_COLORS.three};
  border-radius: 8px;
  color: ${TEXT_COLORS.two};
`;

export const GreekValuesWrapper = styled.div`
  margin-left: ${SPACING.two}px;
`;

export const PlaceholderContent = styled.div`
  color: ${TEXT_COLORS.three};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${FONT_SIZE.two};
`;

export const SegmentControlWrapper = styled.div`
  position: absolute;
  z-index: 1;
  left: ${SPACING.three}px;
  right: ${SPACING.three}px;
  bottom: ${SPACING.three - 2}px;
  display: flex;
  justify-content: center;
  gap: ${SPACING.two}px;
`;
