import { useContext, useEffect, useMemo, useState } from "react";
import { BORDER_COLORS, LAYER_COLORS } from "../../constants/design/colors";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { TradeInstrumentContext } from "../../contexts/TradeInstrumentContext";
import useAssetPriceData from "../../hooks/api/prices/useAssetPriceData";
import { getAssetName, getAssetLogo } from "../../utils/assets";
import { getNumberColor } from "../../utils/math";
import AssetWrapper from "../shared/AssetWrapper";
import { Chevron } from "../shared/Chevron/style";
import Dropdown, { IDropdownItemsItem } from "../shared/Dropdown";
import { LabelText, SublabelText } from "../shared/Label/style";
import {
  DropdownHeader,
  EmptySearchResult,
  TradeLabelValues,
  TradeLabelWrapper,
  TradeDropdownWrapper,
  SublabelWrapper,
} from "./style";
import useScreenSize from "../../hooks/screenSize/useScreenSize";
import LogoContainer from "../shared/LogoContainer";

interface IOptionLabelProps {
  logo?: string;
  title: string;
  subtitle: string;
  price: string;
  percentChange: string;
  priceColor: string;
}
function TradeLabel({
  logo,
  title,
  subtitle,
  price,
  percentChange,
  priceColor,
}: IOptionLabelProps) {
  return (
    <TradeLabelWrapper>
      <LogoContainer logo={logo} />
      <TradeLabelValues>
        <div>
          <LabelText>{title}</LabelText>
          <SublabelText>{subtitle}</SublabelText>
        </div>

        <SublabelWrapper>
          <SublabelText>{price}</SublabelText>
          <SublabelText marginLeft={SPACING.two} color={priceColor}>
            {percentChange}
          </SublabelText>
        </SublabelWrapper>
      </TradeLabelValues>
    </TradeLabelWrapper>
  );
}

interface ITradeSelectionDropdownProps {
  additionalHeight: number;
}
function TradeSelectionDropdown({
  additionalHeight,
}: ITradeSelectionDropdownProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isMediumScreen } = useScreenSize();
  const { selectedValues, setAsset, assets, reset } = useContext(
    TradeInstrumentContext
  );

  const selectedAsset = selectedValues.asset;

  const { useAssetsPrice } = useAssetPriceData();

  const { prices } = useAssetsPrice();

  // Whenever underlyer changes, reset trade values
  useEffect(() => {
    reset();
  }, [selectedAsset, selectedValues.optionType, reset]);

  const items: IDropdownItemsItem[] = useMemo(() => {
    const allItems: IDropdownItemsItem[] = [];
    const matchedAssets = assets;

    if (matchedAssets.length) {
      matchedAssets.forEach((asset) => {
        const { loading, price, percentChange } = prices[asset];
        allItems.push({
          label: (
            <TradeLabel
              logo={getAssetLogo(asset)}
              title={asset}
              subtitle={getAssetName(asset) ?? ""}
              price={loading ? "-" : String(price)}
              percentChange={
                loading
                  ? "-"
                  : `${percentChange > 0 ? "+" : ""}${percentChange.toFixed(
                    2
                  )}%`
              }
              priceColor={getNumberColor(percentChange)}
            />
          ),
          onSelect: () => {
            setAsset(asset);
          },
        });
      });
    }
    return allItems;
  }, [assets, prices, setAsset]);

  return (
    <Dropdown
      width="100%"
      height={COMPONENTS.header.sub}
      toggleStyles={{
        backgroundColor: `${LAYER_COLORS.two}`,
        border: `1px solid ${BORDER_COLORS.one}`,
      }}
      dropdownMenuProps={{
        popperConfig: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, SPACING.three - 2],
              },
            },
          ],
        },
      }}
      dropdownMenuContainerStyles={{
        borderRadius: 8,
        backgroundColor: LAYER_COLORS.two,
        height:
          (isMediumScreen
            ? COMPONENTS.marketModalMobileHeight
            : COMPONENTS.marketModalHeight)
          - COMPONENTS.modalHeaderHeight
          - 3 * SPACING.three
          + 2
          + additionalHeight,
        border: `1px solid ${BORDER_COLORS.one}`,
        overflow: "scroll",
        zIndex: 3,
      }}
      onToggle={setIsModalOpen}
      title={
        <TradeDropdownWrapper>
          <AssetWrapper asset={selectedAsset} />
          <Chevron direction={isModalOpen ? "up" : "down"} />
        </TradeDropdownWrapper>
      }
      dropdownHeader={<DropdownHeader />}
      items={items}
      dropdownFooter={
        !items.length ? (
          <EmptySearchResult>No search results</EmptySearchResult>
        ) : undefined
      }
    />
  );
}

export default TradeSelectionDropdown;
