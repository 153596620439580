import { useContext, useMemo, useState } from "react";
import { RequestsFilterEnum } from "../../../enums/tableFilters";
import OrdersTable from "../../../components/OrdersTable";
import { OptionsDataContext } from "../../../contexts/OptionsDataContext";
import { useOrderTimes } from "../../../hooks/time/useOrderTimes";

function LiveRequestsTab() {
  const [filter, setFilter] = useState(RequestsFilterEnum.ALL);
  const { liveRequests: { loading, orders: liveRequests } } = useContext(OptionsDataContext);
  const { timeArgs } = useOrderTimes();
  // filter based on whether there is offer price
  // place those with pendingFilled at the top
  const filteredRequests = useMemo(() => {
    let requests = liveRequests;
    if (filter === RequestsFilterEnum.OFFERSPLACED) {
      requests = requests?.filter((r) => r.offerPrice);
    }
    if (filter === RequestsFilterEnum.NOOFFERS) {
      requests = requests?.filter((r) => !r.offerPrice);
    }
    // return requests that have not expired and those that have a permit when orderOfferPeriod has expired;
    requests = requests?.filter((r) => {
      const { orderOfferPeriodExpired, orderExpired } = timeArgs(Number(r.orderStartTime), Number(r.orderOfferPeriodEnd), Number(r.orderEndTime));
      return !orderOfferPeriodExpired ? !orderExpired : !orderExpired && Boolean(r.pendingFilled);
    });
    requests = requests.sort((a, b) => {
      if (a.pendingFilled === b.pendingFilled) {
        return 0;
      }
      if (a.pendingFilled) {
        return -1;
      }
      return 1;
    });
    return requests;
  }, [filter, liveRequests, timeArgs]);

  return (
    <OrdersTable<RequestsFilterEnum, typeof RequestsFilterEnum>
      title="Live Requests"
      filter={filter}
      onSetFilter={setFilter}
      filterEnum={RequestsFilterEnum}
      filteredOrders={filteredRequests}
      orderType="LiveRequests"
      loading={loading}
    />
  );
}

export default LiveRequestsTab;
