import styled from "styled-components";
import { SPACING } from "../../constants/design/spacing";
import { ModalButton } from "../Buttons/styles";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${SPACING.three}px;
`;

export const StepButtonContainer = styled.div`
  display: flex;
  > * {
    &:not(:last-child) {
      margin-right: ${SPACING.two}px;
    }
  }
`;

export const StepButton = styled(ModalButton)<{ direction: "left" | "right" }>`
  svg {
    transform: scaleX(${({ direction }) => (direction === "left" ? -1 : 1)})
  }
`;

export const Label = styled.span`
  padding-right: ${SPACING.two}px;
`;
