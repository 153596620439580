import { useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { CustomExpiryWrapper, ErrorText, RdpWrapper } from "./style";

export interface ICustomExpiryContentProps {
  setManualExpiry: (expiry?: number) => void;
}

function CustomExpiryContent({ setManualExpiry }: ICustomExpiryContentProps) {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [error, setError] = useState<boolean>(false);
  const handleDateChange = (date?: Date) => {
    if (date) {
      // Adjust the time to 16:00:00
      date.setHours(16);
      date.setMinutes(0);
      date.setSeconds(0);

      // Convert to Unix timestamp (seconds)
      const timestamp = Math.floor(date.getTime() / 1000);
      if (date > new Date()) {
        setError(false);
        setSelectedDate(date);
        setManualExpiry(timestamp);
      } else {
        setError(true);
        setSelectedDate(undefined);
        setManualExpiry(undefined);
      }
    }
  };
  return (
    <CustomExpiryWrapper>
      <RdpWrapper>
        <DayPicker
          mode="single"
          selected={selectedDate}
          onSelect={handleDateChange}
        />
      </RdpWrapper>
      {error && <ErrorText>Selected date has already passed</ErrorText>}
    </CustomExpiryWrapper>
  );
}

export default CustomExpiryContent;
