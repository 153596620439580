import { Assets } from "../../utils/assets";

export const OptionResponse = {
  Put: "put",
  Call: "call",
} as const;

export type OptionTypeResponse =
  (typeof OptionResponse)[keyof typeof OptionResponse];

export type IStrikeData = {
  strike: string;
  iv: string;
  delta: string;
  theta: string;
  vega: string;
  indic: string;
};

export enum ExpiryTexts {
  Weekly = "weekly",
  Biweekly = "biweekly",
  Triweekly = "triweekly",
  Monthly = "monthly",
  NextMonth = "next_month"
}

export type Expiries = {
  [key in ExpiryTexts]?: string;
};

export type ReversedExpiries = {
  [key: string]: ExpiryTexts;
};

export type Data = {
  underlyer: Assets;
  spot_ref: string;
  prices: {
    [expiryText in ExpiryTexts]: {
      [optionType in OptionTypeResponse]: IStrikeData[];
    };
  };
};

// format of data from api
export type PreloadedStrikes = {
  expiries: Expiries;
  data: Data[];
};

export type PreloadedStrikesResponse = {
  loading: boolean;
  preloadedStrikes: PreloadedStrikes;
};
