import { motion } from "framer-motion";
import { MouseEventHandler, PropsWithChildren } from "react";
import styled, { css, CSSProperties, keyframes } from "styled-components";
import {
  BACKGROUND_COLORS,
  COLORS,
  LAYER_COLORS,
  TEXT_COLORS,
} from "../../../constants/design/colors";
import { FONT_SIZE } from "../../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../../constants/design/spacing";

export const TableContainerWrapper = styled(motion.div).attrs({
  transition: {
    duration: 0.25,
    ease: "easeInOut",
  },
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
})`
  width: 100%;
`;

export const TableHeaderWrapper = styled.div`
  line-height: 1;
  z-index: 2;
  display: flex;
  justify-content: space-around;
  position: sticky;
  top: 0;

  > div {
    height: ${COMPONENTS.tableHeader}px;
    text-align: center;
    width: 100%;
    display: flex;
  }

  span {
    text-align: center;
    align-self: center;
    color: ${TEXT_COLORS.three};
    padding: ${SPACING.two}px;
  }
`;

export const TableContentWrapper = styled.div``;

const RowBackground = styled.div<{
  fillColor?: string;
  fillPercent?: number;
  fillTo?: "left" | "right";
}>`
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  pointer-events: none;

  // Background
  ${({ fillColor, fillPercent, fillTo }) => {
    if (fillColor && fillPercent) {
      return css`
        background: linear-gradient(
          to ${fillTo || "right"},
          ${fillColor} ${fillPercent || 100}%,
          transparent ${fillPercent || 100}%,
          transparent 100%
        );
      `;
    }
    return "";
  }}
`;

export const Row = styled.div<{
  width?: string;
  highlightOnHover?: boolean;
  isActive?: boolean;
}>`
  position: relative;
  display: flex;
  width: ${({ width }) => width || "100%"};
  ${({ isActive }) => isActive && `border: 1px solid ${COLORS.blue.one} !important;`}

  ${({ highlightOnHover }) => {
    if (highlightOnHover) {
      return css`
        &:hover {
          background-color: ${BACKGROUND_COLORS.four};
        }
      `;
    }
    return "";
  }}

  span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const flashAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const RowFlashAnimation = styled.div<{
  fillColor?: string;
  needsAnimation?: boolean;
}>`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ fillColor, needsAnimation }) => {
    if (fillColor) {
      if (needsAnimation) {
        return css`
          background: ${fillColor};
          &:before {
            content: " ";
            display: block;
            background: ${fillColor};
            height: 100%;
            width: 100%;
          }
          opacity: 0;
          animation: ${flashAnimation} 0.6s ease-in-out forwards;
        `;
      }
    }
    return "";
  }}
`;

/**
 * A wrapper around Row.
 */
interface IRowWithSizeIndicatorProps {
  fillColor?: string;
  fillPercent?: number;
  fillTo?: "left" | "right";
  width?: string;
  highlightOnHover?: boolean;
  needsAnimation?: boolean;
  style?: CSSProperties;
  onRowHover?: (hover: boolean) => void;
}

export function RowWithSizeIndicator({
  fillColor,
  fillPercent,
  fillTo,
  width,
  highlightOnHover,
  children,
  needsAnimation,
  onRowHover,
  ...others
}: PropsWithChildren<IRowWithSizeIndicatorProps>) {
  return (
    <Row
      width={width}
      highlightOnHover={highlightOnHover}
      onMouseEnter={() => onRowHover?.(true)}
      onMouseLeave={() => onRowHover?.(false)}
      {...others}
    >
      <RowBackground
        fillColor={fillColor}
        fillPercent={fillPercent}
        fillTo={fillTo}
      />
      {children}
      <RowFlashAnimation
        fillColor={fillColor}
        needsAnimation={needsAnimation}
      />
    </Row>
  );
}

export const TableHeaderCell = styled.th<{
  onClick?: () => void;
}>`
  cursor: ${({ onClick }) => (onClick ? "pointer" : "auto")};
`;

export const StyledTableRow = styled.tr<{
  isOrderConfirmationTimeWindow?: boolean;
  isMobileScreen?: boolean;
}>`
  background-color: ${({ isOrderConfirmationTimeWindow }) => (isOrderConfirmationTimeWindow
    ? `${COLORS.positive.six}`
    : `${LAYER_COLORS.one}`)};
  position: relative;
  border-radius: 8px;
  height: ${({ isMobileScreen }) => (isMobileScreen
    ? `${COMPONENTS.tableRowMobileHeight}`
    : `${COMPONENTS.tableRowHeight}`)}px;
  td {
    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &:nth-last-child(2) {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    &:last-child {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 56px;
      border-radius: 8px;
      overflow: hidden;
    }
  }
  &:hover {
    cursor: pointer;
    background-color: ${({ isOrderConfirmationTimeWindow }) => (isOrderConfirmationTimeWindow
    ? `${COLORS.positive.five}`
    : `${LAYER_COLORS.two}`)};
  }
`;
// Used for react table library
export const TableWrapper = styled.div`
  width: 100%;
  position: relative;
  table {
    border-collapse: separate;
    border-spacing: 0 16px;
    min-width: 100%;
    font-size: ${FONT_SIZE.oneHalf};
    color: ${TEXT_COLORS.two};
    thead {
      top: 0;
      z-index: 2;
      ${StyledTableRow} {
        height: ${COMPONENTS.tableHeader}px;
        background: none;
        &::after {
          background: none;
        }
      }
    }
    ${StyledTableRow} {
    }

    th {
      font-weight: normal;
    }

    th,
    td {
      margin: 0;
      padding: 0 ${SPACING.three}px;
      text-transform: capitalize;
      white-space: nowrap;
      background: transparent;
    }
  }
`;

export const Align = styled.div<{
  align: "left" | "right" | "center";
  color?: string;
  onClick?: MouseEventHandler<HTMLElement> | undefined;
}>`
  text-align: ${({ align }) => align};
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ color }) => color && `color: ${color};`}
  justify-content: ${({ align }) => {
    switch (align) {
      case "left":
        return "flex-start";
      case "center":
        return "center";
      case "right":
        return "flex-end";
      default:
        return "center";
    }
  }};
`;
export const ButtonGroup = styled.div`
  display: flex;
`;
