import React from "react";

type SVGProps = React.SVGAttributes<SVGElement>;

export const positions = ({
  color,
  ...props
}: SVGProps & { color: string }): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1112_79638)">
      <path
        d="M8 1.33333L1.33333 4.66666L8 7.99999L14.6667 4.66666L8 1.33333Z"
        stroke={color || "white"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33333 11.3333L8 14.6667L14.6667 11.3333"
        stroke={color || "white"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33333 8L8 11.3333L14.6667 8"
        stroke={color || "white"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1112_79638">
        <rect width="16" height="16" fill={color || "white"} />
      </clipPath>
    </defs>
  </svg>
);
