import React, { ReactElement, useState } from "react";

interface IConfirmOrderContextType {
  showConfirmOrderModal: boolean;
  setShowConfirmOrderModal: (show: boolean) => void;
}

interface IConfirmOrderContextProviderProps {
  children: ReactElement;
}

export const ConfirmOrderContext = React.createContext<IConfirmOrderContextType>({
  showConfirmOrderModal: false,
  setShowConfirmOrderModal: () => {},
});

export function ConfirmOrderContextProvider({ children }: IConfirmOrderContextProviderProps) {
  const [showConfirmOrderModal, setShowConfirmOrderModal] = useState(false);
  return (
    <ConfirmOrderContext.Provider value={{ showConfirmOrderModal, setShowConfirmOrderModal }}>
      {children}
    </ConfirmOrderContext.Provider>
  );
}
