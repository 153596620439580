import { MutableRefObject, useMemo, useState } from "react";
import currency from "currency.js";
import {
  ButtonsContainer,
  CollateralBalanceAmount,
  CollateralBalanceContainer,
  CollateralBalanceText,
  InfoDetail,
  InfoRow,
  TopUpText,
} from "./style";
import { ModalButtonV2 } from "../Buttons/styles";
import { getAssetLogo } from "../../utils/assets";
import { IInfoRowCol } from "../../interfaces/InfoRow";
import CollateralModal from "../Modals/CollateralModal";
import { IOrder } from "../../interfaces/Orders";
import { COLORS } from "../../constants/design/colors";
import { getProfitTextColor } from "../../utils/strings";
import LogoContainer from "../shared/LogoContainer";

export type ActionType = "Post" | "Withdraw";

interface ICollateralModalProps {
  order: IOrder;
  modalRef: MutableRefObject<null>;
  onHide: () => void;
}

function MarginHealthContent({
  order,
  modalRef,
  onHide,
}: ICollateralModalProps) {
  const [showCollateralModal, setShowCollateralModal]
    = useState<boolean>(false);
  const [actionType, setActionType] = useState<ActionType>();
  const {
    performance,
    marginPosted,
    marginRequirement,
    withdrawableCollateral,
    requireTopUp,
  } = order;

  const topUpAmount = Number(marginRequirement) - Number(marginPosted);

  const infoRows: IInfoRowCol[] = useMemo(() => {
    const rows = [];
    rows.push({
      title: "Margin Required",
      detail: marginRequirement ? currency(marginRequirement).format() : "-",
      color: requireTopUp ? COLORS.negative.one : undefined,
    });
    rows.push({
      title: "Unrealized PNL",
      detail: performance ? currency(performance).format() : "-",
      color: getProfitTextColor(performance ? Number(performance) : 0),
    });
    rows.push({
      title: "Withdrawable Collateral",
      detail: withdrawableCollateral
        ? currency(withdrawableCollateral).format()
        : "-",
    });
    return rows;
  }, [marginRequirement, performance, requireTopUp, withdrawableCollateral]);

  const logo = getAssetLogo("USDC");

  return (
    <>
      {actionType && (
        <CollateralModal
          order={order}
          modalRef={modalRef}
          title={actionType ? `${actionType} Collateral` : ""}
          actionType={actionType}
          showCollateralModal={showCollateralModal}
          setShowCollateralModal={setShowCollateralModal}
          onHide={onHide}
        />
      )}
      <CollateralBalanceContainer>
        <LogoContainer logo={logo} />
        <CollateralBalanceText>Margin Posted</CollateralBalanceText>
        <CollateralBalanceAmount>
          {marginPosted ? currency(marginPosted).format() : "-"}
        </CollateralBalanceAmount>
      </CollateralBalanceContainer>
      {infoRows.map((infoRow) => (
        <InfoRow key={infoRow.title}>
          <span>{infoRow.title}</span>
          <InfoDetail color={infoRow.color}>{infoRow.detail}</InfoDetail>
        </InfoRow>
      ))}
      <ButtonsContainer>
        <ModalButtonV2
          type="button"
          disabled={false}
          style={{ flex: 1 }}
          onClick={() => {
            setActionType("Post");
            setShowCollateralModal(true);
          }}
        >
          Post Margin
        </ModalButtonV2>
      </ButtonsContainer>
      <ButtonsContainer>
        <ModalButtonV2
          type="button"
          disabled={Boolean(!withdrawableCollateral)}
          style={{ flex: 1 }}
          onClick={() => {
            setActionType("Withdraw");
            setShowCollateralModal(true);
          }}
        >
          Withdraw Collateral
        </ModalButtonV2>
      </ButtonsContainer>
      {requireTopUp && (
        <TopUpText>
          Please post additional margin of {currency(topUpAmount).format()} in
          order to meet margin requirements for this position{" "}
        </TopUpText>
      )}
    </>
  );
}

export default MarginHealthContent;
