import { AbsoluteProgressBar, AbsoluteProgressBarContainer } from "./style";

interface IProgressBarProps {
  percentage: number;
}

export function ProgressBar({ percentage }: IProgressBarProps) {
  return (
    <AbsoluteProgressBarContainer>
      <AbsoluteProgressBar style={{ width: `${percentage}%` }} />
    </AbsoluteProgressBarContainer>
  );
}
