import React from "react";

type SVGProps = React.SVGAttributes<SVGElement>;

export const marginHealth = ({
  color,
  ...props
}: SVGProps & { color?: string }): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.3346 10H15.0013L12.5013 17.5L7.5013 2.5L5.0013 10H1.66797"
      stroke={color || "#A5A5A8"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
