import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { BaseModal } from "../../BaseModal";
import { ExtrasContainer, ExtrasText, PageContentContainer } from "./style";
import { WalletEnum } from "../../../utils/wallet/connectors";
import useWallet from "../../../hooks/wallet/useWallet";
import Transition from "../../shared/Transition";
import { ConnectWalletContext } from "../../../contexts/ConnectWalletContext";
import ConnectWallet from "./ConnectWallet";
import StepButtons from "../../StepButtons";
import { SPACING } from "../../../constants/design/spacing";

enum ConnectWalletPageEnum {
  CONNECT_WALLET = "connectWallet",
}

// We need this to allow the height to transition seamlessly
type ConnectWalletPageHeightType = {
  [key in ConnectWalletPageEnum]: string;
};
const connectWalletPageHeights: ConnectWalletPageHeightType = {
  [ConnectWalletPageEnum.CONNECT_WALLET]: "240px",
};

function ConnectWalletModal() {
  const { account, provider, activate, isWrongNetwork } = useWallet();
  const { showConnectModal, setShowConnectModal }
    = useContext(ConnectWalletContext);

  const [pageError, setPageError] = useState("");
  const [selectedWallet, setWallet] = useState<WalletEnum | undefined>();
  const [availablePages, setAvailablePages] = useState<ConnectWalletPageEnum[]>(
    [ConnectWalletPageEnum.CONNECT_WALLET]
  );
  const [pageIndex, setPageIndex] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const onHide = useCallback(() => {
    setPageError("");
    setShowConnectModal(false);
  }, [setShowConnectModal]);

  useEffect(() => {
    // On show, decide which page to show
    setAvailablePages([ConnectWalletPageEnum.CONNECT_WALLET]);
    setLoading(false);
  }, [onHide, account, isWrongNetwork]);

  // When the page changes, stop loading
  useEffect(() => {
    setLoading(false);
  }, [pageIndex]);

  useEffect(() => {
    if (!account) setPageIndex(0);
  }, [account]);

  const page = useMemo(
    () => availablePages[pageIndex >= availablePages.length ? 0 : pageIndex],
    [availablePages, pageIndex]
  );

  const connectWallet = useCallback(async () => {
    setLoading(true);

    if (!account && !provider) {
      if (selectedWallet) {
        onHide();
        await activate(selectedWallet);
        setLoading(false);
      }
    }
  }, [account, activate, onHide, provider, selectedWallet]);

  const onNextPage = useCallback(() => {
    setPageError("");
    switch (page) {
      case ConnectWalletPageEnum.CONNECT_WALLET:
        if (account) {
          setLoading(false);
          setPageIndex(pageIndex + 1);
        } else {
          connectWallet();
        }
        break;
      default:
        break;
    }
  }, [page, pageIndex, account, connectWallet]);

  const currentPageTitle = useMemo(() => {
    switch (page) {
      case ConnectWalletPageEnum.CONNECT_WALLET:
        return "Select Your Wallet";
      default:
        return "";
    }
  }, [page]);

  const pageContent = useMemo(() => {
    switch (page) {
      case ConnectWalletPageEnum.CONNECT_WALLET:
        return (
          <ConnectWallet
            selectedWallet={selectedWallet}
            setWallet={setWallet}
          />
        );
      default:
        return null;
    }
  }, [page, selectedWallet]);

  const stepInfo = useMemo(() => {
    switch (page) {
      case ConnectWalletPageEnum.CONNECT_WALLET:
        return {
          prevDisabled: isLoading,
          nextLabel: "Connect Wallet",
          nextDisabled: !selectedWallet,
          info: {
            onInfoClick: () => {
              window.open("https://ethereum.org/en/wallets/");
            },
          },
        };
      default:
        return {
          nextLabel: "",
          nextDisabled: true,
        };
    }
  }, [page, selectedWallet, isLoading]);

  return (
    <BaseModal
      noContentPadding
      onHide={onHide}
      show={showConnectModal}
      title={currentPageTitle}
      page={{
        totalPages: availablePages.length,
        currentPage: pageIndex,
      }}
    >
      <PageContentContainer
        style={{
          width: 320,
          height: `calc(${
            connectWalletPageHeights[page] + (pageError ? "40px" : "0px")
          })`,
        }}
      >
        <Transition
          key={page}
          style={{
            transitionDuration: "0.3s",
            height: "100%",
          }}
        >
          {pageContent}
          {pageError && (
            <ExtrasContainer>
              <ExtrasText type="error">{pageError}</ExtrasText>
            </ExtrasContainer>
          )}
        </Transition>
      </PageContentContainer>
      <StepButtons
        style={{
          marginTop: `${SPACING.four}px`,
        }}
        info={stepInfo.info}
        rightTitle={stepInfo.nextLabel}
        onClickRight={!stepInfo.nextDisabled ? onNextPage : undefined}
      />
    </BaseModal>
  );
}

export default ConnectWalletModal;
