import moment from "moment";
import { PropsWithChildren, useCallback, useContext } from "react";
import { ModalHeader, Title } from "../../../components/BaseModal/style";
import {
  ConfirmButton,
  ReverseChevronButton,
} from "../../../components/Buttons/styles";
import {
  ButtonsContainer,
  StrikeButtonsWrapper,
} from "../../../components/OptionFilter/style";
import { Chevron } from "../../../components/shared/Chevron/style";
import { HeaderSubText } from "../../../components/shared/SharedModalStyles/styles";
import { BaseModalWrapper, ContentWrapper, StyledModalContent } from "./style";
import { COLORS } from "../../../constants/design/colors";
import { ConfirmOrderContext } from "../../../contexts/ConfirmOrderContext";
import { IPlaceOrderBody } from "../../../hooks/api/order/user/usePlaceOrder";

export interface IConfirmOrderModalProps {
  orderInfo?: IPlaceOrderBody;
  setConfirmed: (confirmed: boolean) => void;
  handlePlaceOrder: () => Promise<void>;
}
function ConfirmOrderModal({
  orderInfo,
  setConfirmed,
  handlePlaceOrder,
}: PropsWithChildren<IConfirmOrderModalProps>) {
  const { showConfirmOrderModal, setShowConfirmOrderModal }
    = useContext(ConfirmOrderContext);

  const onHide = useCallback(() => {
    setShowConfirmOrderModal(false);
  }, [setShowConfirmOrderModal]);

  return showConfirmOrderModal ? (
    <BaseModalWrapper>
      <ContentWrapper>
        <StyledModalContent
          transition={{
            duration: 0.2,
            ease: "easeInOut",
          }}
          exit={{
            scale: 0.9,
            opacity: 0,
          }}
        >
          <ModalHeader>
            <Title>NOTICE</Title>
          </ModalHeader>
          <HeaderSubText>
            You can request to{" "}
            <span style={{ color: COLORS.white.one }}>
              unwind your position anytime before the
              options expiry on{" "}
              {moment.unix(Number(orderInfo?.expiry)).format("DD MMM YYYY")}.
            </span>
          </HeaderSubText>
          <HeaderSubText>
            You are only allowed to have{" "}
            <span style={{ color: COLORS.white.one }}>1 pending order</span> at
            any moment.
          </HeaderSubText>
          <ButtonsContainer>
            <StrikeButtonsWrapper>
              <ReverseChevronButton role="button" onClick={() => onHide()}>
                <Chevron direction="left" />
              </ReverseChevronButton>
              <ConfirmButton
                onClick={async () => {
                  if (orderInfo) {
                    onHide();
                    setConfirmed(true);
                    await handlePlaceOrder();
                  }
                }}
              >
                Confirm
              </ConfirmButton>
            </StrikeButtonsWrapper>
          </ButtonsContainer>
        </StyledModalContent>
      </ContentWrapper>
    </BaseModalWrapper>
  ) : null;
}

export default ConfirmOrderModal;
