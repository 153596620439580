import styled from "styled-components";
import { COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";

export const PageCarousel = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${SPACING.two}px;
`;

export const CarouselDot = styled.span<{ isActive: boolean }>`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  transition: 0.2s;
  background-color: ${(props) => (props.isActive ? COLORS.blue.one : COLORS.blue.three)};

  &:not(:last-child) {
    margin-right: ${SPACING.two}px;
  }
`;
