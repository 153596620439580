import { COLORS, TEXT_COLORS } from "../constants/design/colors";

export const shortenString = (
  str: string,
  startLen: number,
  endLen: number
) => {
  if (str.length > startLen + endLen) {
    return `${str.slice(0, startLen)}...${str.slice(-endLen)}`;
  }
  return str;
};

export const shortenAddress = (address: string) => shortenString(address, 6, 4);

/**
 * Given a string, convert the first letter of every word to uppercase
 * and returns the new string
 * @param str Any string
 */
export const startCase = (str: string) => str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

/**
 * Given a string, convert all letters except first to lowercase
 * @param str Any string
 */
export const strToTitle = (str: string) => str.charAt(0) + str.slice(1).toLowerCase();

export const copyStringToClipboard = (text: string) => {
  const textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  if (window.navigator.platform === "iPhone") {
    textField.setSelectionRange(0, 99999);
  } else {
    textField.select();
  }
  document.execCommand("copy");
  textField.remove();
};

export const getProfitTextColor = (value: number) => {
  if (value > 0) {
    return COLORS.positive.one;
  }
  if (value < 0) {
    return COLORS.negative.one;
  }
  return TEXT_COLORS.one;
};

export const getRoiTextColor = (value: number) => {
  if (value > 0) {
    return COLORS.positive.two;
  }
  if (value < 0) {
    return COLORS.negative.two;
  }
  return TEXT_COLORS.one;
};

export const getProfitBackgroundColor = (value: number) => {
  if (value >= 0) {
    return COLORS.positive.six;
  }
  return COLORS.negative.six;
};
/**
 * Given a number string
 * and returns the string with chosen decimal places
 * @param value number string
 * @param decimals Number of decimals
 */
export const formatStringDecimals = (value: string, decimalPlaces: number) => Number(value).toFixed(decimalPlaces);
