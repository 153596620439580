import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";
import {
  BACKGROUND_COLORS,
  BORDER_COLORS,
  COLORS,
  TEXT_COLORS,
} from "../../constants/design/colors";
import { FONT_CSS } from "../../constants/design/fontSize";
import { SPACING } from "../../constants/design/spacing";

// ANIMATIONS
const floatIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// STYLES
export const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  height: 100vh;
  background: ${BACKGROUND_COLORS.black};
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
  width: 100%;

  svg {
    height: 64px;
    width: 192px;
    animation: ${floatIn} 0.6s ease-in-out forwards;floatIn
  }
`;

export const NavItem = styled.button`
  ${FONT_CSS.header.six}
  color: ${TEXT_COLORS.two};
  height: 64px;
  width: 100%;
`;

export const NavItems = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  ${NavItem} {
    border-top: 1px solid ${BORDER_COLORS.two};
  }

  ${NavItem}:last-child {
    border-bottom: 1px solid ${BORDER_COLORS.two};
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  bottom: ${SPACING.five}px;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background-color: ${COLORS.white.five};
`;
