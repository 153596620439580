import useSWR from "swr";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Assets } from "../../../../utils/assets";
import { IOrder, OrdersResponse } from "../../../../interfaces/Orders";
import { OptionTypeResponse } from "../../../../interfaces/PreloadedStrikes";
import { getInstrumentName } from "../../../../utils/instruments";
import { defaultOrdersResponse } from "../../../../contexts/OptionsDataContext";
import useWallet from "../../../wallet/useWallet";
import { GET_USER_UNFILLED_ORDERS_URL } from "../../../../constants/endpoints/endpoints";

export interface IUserUnfilledOrders {
  order_id: number;
  underlyer: Assets;
  type: OptionTypeResponse;
  expiry: string;
  strike: string;
  size: string;
  iv: string;
  indic: string;
  permit_expiry: string;
  is_unwind_order?: "true" | "false";
}

// Get the current account
const useFetchUserPositions = () => {
  const { account } = useWallet();
  const [userUnfilledOrdersData, setUserUnfilledOrdersData]
    = useState<OrdersResponse>(defaultOrdersResponse);
  const userUnfilledOrdersApiURL = GET_USER_UNFILLED_ORDERS_URL;

  const fetcher = (params: any) => (url: string) => {
    if (params.address !== undefined) {
      return axios.get(url, { params }).then((res) => res.data);
    }
    return Promise.resolve(null); // Return a resolved Promise with a null value if address is undefined
  };

  const userUnfilledOrdersSwr = useSWR<IUserUnfilledOrders[]>(
    userUnfilledOrdersApiURL,
    fetcher({
      address: account,
    }),
    {
      refreshInterval: 15000,
    }
  );

  const { data: userUnfilledOrdersDataSwr, error: userUnfilledOrdersSwrError }
    = userUnfilledOrdersSwr;

  const fetchUserUnfilledOrders = useCallback(async () => {
    if (!userUnfilledOrdersDataSwr || userUnfilledOrdersSwrError || !account) {
      // API call hasn't completed yet or error, return early
      return;
    }

    const unfilledOrders = userUnfilledOrdersDataSwr.map(
      (unfilledOrdersData) => ({
        instrumentName: getInstrumentName(
          unfilledOrdersData.underlyer,
          unfilledOrdersData.expiry,
          unfilledOrdersData.strike,
          unfilledOrdersData.type
        ),
        orderId: unfilledOrdersData.order_id,
        optionType: unfilledOrdersData.type,
        asset: unfilledOrdersData.underlyer,
        expiry: unfilledOrdersData.expiry,
        strike: unfilledOrdersData.strike,
        contracts: unfilledOrdersData.size,
        totalPremiumPaid: "0",
        indicativePrice: unfilledOrdersData.indic,
        iv: unfilledOrdersData.iv,
        expiredAt: unfilledOrdersData.permit_expiry,
        isUnwindOrder: unfilledOrdersData.is_unwind_order === "true",
      })
    ) as IOrder[];
    setUserUnfilledOrdersData({
      loading: false,
      orders: unfilledOrders,
    } as OrdersResponse);
  }, [userUnfilledOrdersDataSwr, userUnfilledOrdersSwrError, account]);

  useEffect(() => {
    fetchUserUnfilledOrders();
  }, [fetchUserUnfilledOrders]);

  return {
    userUnfilledOrdersData,
  };
};

export default useFetchUserPositions;
